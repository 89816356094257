import React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AuditorSubscriptions from "./AuditorSubscriptions";

const AdminAuditorSubscriptions = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <AuditorSubscriptions />
        </DashboardLayout>
    );
};

export default AdminAuditorSubscriptions; 