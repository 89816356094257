import { Card, Grid, Icon, IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDProgress from 'components/MDProgress';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { getInvoiceDetail } from 'redux/actions/adminSmokiDashboard.action';


export default function ViewInvoice() {
    // Destructure the invoiceId variable from the URL parameter using the useParams hook
    let { invoiceId } = useParams();

    // Get access to the dispatch function from the Redux store
    const dispatch = useDispatch();

    // Use the useEffect hook to fetch the invoice details when the invoiceId changes
    React.useEffect(() => {
        // Only fetch the invoice details if the invoiceId is truthy (i.e., not null or undefined)
        if (invoiceId) {
            // Dispatch the getInvoiceDetail action creator with the invoiceId as the argument
            dispatch(getInvoiceDetail(invoiceId)).then(data => {
                // Update the invoiceData and items state variables with the result data
                setInvoiceData(data.result)
                setItems(data.result.items)
            });
        }
    }, [invoiceId])

    // Initialize the invoiceData state variable to null
    const [invoiceData, setInvoiceData] = React.useState(null)

    // Initialize the items state variable to an empty array
    const [items, setItems] = React.useState([])

    // Calculate the subtotal of the invoice by reducing the items array
    const subtotal = items.length > 0 ? items.reduce(function (total, currentValue) {
        return total + (parseInt(currentValue?.price) * parseInt(currentValue.qty));
    }, 0) : 0


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {invoiceData ? <MDBox>
                <MDBox>
                    <MDTypography variant='h6' marginBottom> Invoice Status: {invoiceData.status}  </MDTypography>

                </MDBox>
                <MDBox>
                    <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                        <MDTypography variant="h6" color="white" textAlign='center'>Invoice Info</MDTypography>
                    </MDBox>

                    <MDInput disabled type='text' sx={{ mb: 2 }} value={invoiceData.title} label='Title' name='title' fullWidth />
                    <MDInput disabled type='text' sx={{ mb: 2 }} value={invoiceData.description} label='Description' name='description' fullWidth />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <MDTypography variant='h6'> Invoice Type: {invoiceData.recurring ? "Recurring" : 'One Time'}  </MDTypography>
                        </Grid>
                        {invoiceData.recurring && <Grid item xs={12} md={4}>
                            <MDTypography variant='h6'> Recurring Interval: {invoiceData.recurring.interval}  </MDTypography>
                        </Grid>}
                        {invoiceData.recurring && <Grid item xs={12} md={4}>
                            <MDTypography variant='h6'> Recurring Interval Count: {invoiceData.recurring.intervalCount}  </MDTypography>
                        </Grid>}
                    </Grid>
                </MDBox>
                <MDBox>
                    <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                        <MDTypography variant="h6" color="white" textAlign='center'>Invoice items</MDTypography>

                    </MDBox>
                    <MDBox overflow='auto'>
                        <Card sx={{ p: 2, minWidth: 880 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={1} ><MDTypography variant='h6'> Sr.</MDTypography></Grid>
                                <Grid item xs={2} ><MDTypography variant='h6'> Item Name</MDTypography></Grid>
                                <Grid item xs={2} ><MDTypography variant='h6'> item Description</MDTypography></Grid>
                                <Grid item xs={2} ><MDTypography variant='h6'> QTY</MDTypography></Grid>
                                <Grid item xs={2} ><MDTypography variant='h6'> Unit Price</MDTypography></Grid>
                                <Grid item xs={2} ><MDTypography variant='h6' textAlign='center'> Subtotal</MDTypography></Grid>
                                <Grid item xs={1} > </Grid>
                            </Grid>
                            {items.map((item, index) => {
                                return (<OrderItem items={items} setItems={setItems} key={index} index={index} item={item} />)
                            })}

                            <MDBox display='flex' justifyContent='flex-end'>
                                <MDTypography variant='h6' textAlign='center'> Total: ${subtotal} </MDTypography>
                            </MDBox>
                            <MDBox display='flex' alignItems='flex-end' flexDirection='column'>
                                <MDBox width='100%' my={1} display='flex' justifyContent='flex-end' alignItems='center'>
                                    <MDTypography variant='h6' mr={2}>Discount:</MDTypography>
                                    <MDInput
                                        InputProps={{
                                            inputProps: {
                                                min: 0, max: subtotal
                                            }
                                        }}
                                        required={true}
                                        sx={{ maxWidth: 150 }}
                                        disabled={true}
                                        fullWidth
                                        type='number'
                                        value={invoiceData.discount} />
                                </MDBox>
                                <MDBox width='100%' my={1} display='flex' justifyContent='flex-end' alignItems='center'>
                                    <MDTypography variant='h6' mr={2}>Tax Percentage:</MDTypography>
                                    <MDInput
                                        disabled={true}
                                        InputProps={{
                                            inputProps: {
                                                min: 0, max: 100
                                            }
                                        }}
                                        required={true}

                                        fullWidth
                                        sx={{ maxWidth: 150 }}
                                        type='number'
                                        value={invoiceData.taxAmount} />
                                </MDBox>

                                <MDTypography variant='h5' > Grand Total: ${parseFloat(parseInt(subtotal) - parseFloat(subtotal * (invoiceData.taxAmount / 100)) - parseInt(invoiceData.discount)).toFixed(2)} </MDTypography>

                            </MDBox>

                        </Card>
                    </MDBox>
                </MDBox>
            </MDBox> :
                <MDBox display='flex' alignItems='center' justifyContent='center'>
                    <MDProgress circular />
                </MDBox>
            }
        </DashboardLayout>
    )
}


function OrderItem({ index, items, setItems }) {


    return (
        <MDBox my={2} >
            <Grid container spacing={2}>
                <Grid item xs={1}  ><MDTypography variant='h6'> {index + 1}</MDTypography></Grid>
                <Grid item xs={2}  ><MDInput disabled type='text' required={true} placeholder='name' fullWidth name='name' value={items[index].name} /> </Grid>
                <Grid item xs={2}  ><MDInput disabled type='text' required={true} value={items[index].description} placeholder='description' fullWidth name='description' /></Grid>
                <Grid item xs={2}  ><MDInput disabled type='number' inputprops={{
                    min: 1
                }} required={true} value={items[index].qty} min={1} placeholder='1' fullWidth name='qty' /></Grid>
                <Grid item xs={2}  ><MDInput disabled type='number' inputprops={{
                    min: 1
                }} required={true} min={1} value={items[index]?.price} placeholder='2' /></Grid>
                <Grid item xs={2}  > <MDTypography variant='h6' textAlign='center'>${items[index].qty * items[index]?.price}</MDTypography></Grid>

            </Grid>
        </MDBox >
    )
}