import React from "react";
import { useParams, Link, useNavigate } from 'react-router-dom';

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import colors from "assets/theme/base/colors";
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDInput from "components/MDInput";
import MuiSelect from '@mui/material/Select'
import MDButton from "components/MDButton";
import { getOrgUser, addOrgUser, updateOrgUser, deleteOrgUser } from "redux/actions/adminUserManagement.action";
import PermissionsComponent from "./Permissions";

const UpdateUser = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const { userId, orgId, pageNum } = params
	const navigate = useNavigate();
	const { updateOrgUserLoading, getOrgUserLoading, organizationUsers } = useSelector(state => state.adminUserManagement)
	const permissions = useSelector(state => state.auth.config)
	const [currentUser, setCurrentUser] = React.useState(null)
	const [updatedPermissions, setUpdatedPermissions] = React.useState(null);

	const [userValues, setUserValues] = React.useState({
		email: '',
		role: '',
		password: ''
	})



	function fetchUsers() {
		if (orgId, pageNum) {
			dispatch(getOrgUser(pageNum, params.orgId))
		}
	}

	React.useEffect(() => {
		if (organizationUsers.length < 1) {
			fetchUsers();
		}
	}, [])

	React.useEffect(() => {
		if (userId && organizationUsers.length > 0) {
			const user = organizationUsers.find(orgUser => orgUser.id === userId)
			console.log(user)
			if (user) {
				setCurrentUser(user)
				setUpdatedPermissions(permissions.userPermissions[user.role])
				setUserValues({
					...userValues,
					email: user.email,
					role: user.role,
					password: user.password,
					displayName: user.displayName,
					phoneNumber: user.phoneNumber,
				})
			}
		}
	}, [userId, organizationUsers])


	React.useEffect(() => {
		if (currentUser) {
			console.log(permissions.userPermissions[currentUser.role])
			setUpdatedPermissions(permissions.userPermissions[currentUser.role])
			setUserValues({
				...userValues,
				email: currentUser.email,
				role: currentUser.role,
				password: currentUser.password

			})
		}
	}, [organizationUsers, currentUser])

	const allReadPermissionsData = {
		...permissions.userPermissions["all-read"]
	};


	function updateUser(e) {
		e.preventDefault()
		dispatch(updateOrgUser({ userId: userId, ...userValues, permissions: updatedPermissions })).then(data => {
			if (data) {
				navigate(`/accessblend-admin/saas/accessBlend/users/${orgId}`)
			}
		}).catch(err => {
			console.log(err)
		})
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Card sx={{ p: 2, my: 4 }}>
				{
					getOrgUserLoading ?
						<MDBox
							display="flex"
							py={1}
							flexDirection='column'
							justifyContent="center"
							alignItems="center"
						>
							<MDProgress circular />
							<MDTypography variant="h6">
								Loading Organizations...
							</MDTypography>
						</MDBox> :
						<MDBox >
							{
								currentUser ?
									<MDBox component='form' onSubmit={updateUser} mt={2}>
										<MDBox my={3}>
											<MDTypography variant='h3' textAlign='center' sx={{ my: 1 }}>Update Organization User</MDTypography>

										</MDBox>
										<Grid container spacing={3}>
											<Grid item xs={12} md={6} lg={4}>
												<MDInput variant='outlined' name='email' label='Email' onChange={(e) => {
													setUserValues({ ...userValues, [e.target.name]: e.target.value })
												}} fullWidth value={userValues.email} required={true} />
											</Grid>
											<Grid item xs={12} md={6} lg={4}>
												<MDInput variant='outlined' name='displayName' label='Name' onChange={(e) => {
													setUserValues({ ...userValues, [e.target.name]: e.target.value })
												}} fullWidth value={userValues.displayName || ""} />
											</Grid>
											<Grid item xs={12} md={6} lg={4}>
												<MDInput variant='outlined' name='phoneNumber' label='Phone Number' onChange={(e) => {
													setUserValues({ ...userValues, [e.target.name]: e.target.value })
												}} fullWidth value={userValues.phoneNumber || ""} />
											</Grid>
											<Grid item xs={12} md={6} lg={4}>
												<MDInput variant='outlined' name='password' label='Password' onChange={(e) => {
													setUserValues({ ...userValues, [e.target.name]: e.target.value })
												}} fullWidth value={userValues.password || ""} />
											</Grid>


											<Grid item xs={12} md={6} lg={4}>
												<FormControl style={{ width: '100%' }} >
													<InputLabel>Role</InputLabel>
													<MuiSelect
														fullWidth
														labelId="order-test"
														id="demo-simple-select"
														label='Role'
														name='role'
														value={userValues?.role}
														onChange={(e) => {
															setUserValues({
																...userValues, role: e.target.value
															})
														}}
													>
														<MenuItem value={'root'}>Root</MenuItem>
														<MenuItem value={'super-admin'}>Super Admin</MenuItem>
														<MenuItem value={'admin'}>Admin</MenuItem>
														<MenuItem value={'client'}>Client</MenuItem>
														<MenuItem value={'viewer'}>Viewer</MenuItem>
													</MuiSelect>
												</FormControl>
											</Grid>

										</Grid>
										<PermissionsComponent
											permissions={currentUser && permissions.userPermissions[currentUser.role]}
											updatedPermissions={updatedPermissions}
											setUpdatedPermissions={setUpdatedPermissions} // Provide the permissions object for the specified role
											allReadPermissions={allReadPermissionsData} // Pass the all-read objects
										/>
										<DialogActions>
											<MDButton variant='contained' color='error' type='submit' loading={updateOrgUserLoading} >Submit</MDButton>
										</DialogActions>
									</MDBox>
									:
									<MDBox >
										<MDTypography variant='h3' textAlign='center' sx={{ my: 1 }}>No  user found</MDTypography>
									</MDBox>
							}


						</MDBox>
				}
			</Card>
		</DashboardLayout>

	)
}

export default UpdateUser