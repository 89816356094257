import axios from 'axios';
import { BASE_URL } from 'Source/constant';
import { getFRToken } from 'services/firebase';
import { toast } from 'react-toastify';

export const UPDATE_USER_ORGANIZATION_LOADING = 'UPDATE_USER_ORGANIZATION_LOADING';
export const GET_USER_ORGANIZATIONS = 'GET_USER_ORGANIZATIONS';
export const GET_USER_ORGANIZATIONS_LOADING = 'GET_USER_ORGANIZATIONS_LOADING';
export const GET_USER_ORGANIZATIONS_ERROR = 'GET_USER_ORGANIZATIONS_ERROR';
export const ADD_USER_ORGANIZATIONS_LOADING = 'ADD_USER_ORGANIZATIONS_LOADING';
export const DELETE_USER_ORGANIZATIONS_LOADING = 'DELETE_USER_ORGANIZATIONS_LOADING';

export const addOrgUser = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_USER_ORGANIZATIONS_LOADING,
            payload: true
        });
        const token = await getFRToken();
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-org/users/add`,
            { ...data },
        );
        if (res.data) {
            toast.success(res.data.message)
            dispatch({
                type: ADD_USER_ORGANIZATIONS_LOADING,
                payload: false
            });
            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: ADD_USER_ORGANIZATIONS_LOADING,
            payload: false
        });
    }
}

export const getOrgUser = (page, orgId, searchValue, selectedLabel, selectedValue) => async (dispatch) => {

    const token = await getFRToken();
    try {
        dispatch({
            type: GET_USER_ORGANIZATIONS_LOADING,
            payload: true
        });
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-org/users?page=${page}&limit=${5}&orgId=${orgId}&${selectedValue}=${searchValue}`);
        if (res.data) {
            dispatch({
                type: GET_USER_ORGANIZATIONS,
                payload: res.data
            });
            return res.data

        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: GET_USER_ORGANIZATIONS_LOADING,
            payload: false
        });
    }
};

export const updateOrgUser = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_USER_ORGANIZATION_LOADING,
            payload: true
        });
        const token = await getFRToken();
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-org/users/update`,
            { ...data },
        );
        if (res.data) {
            toast.success(res.data.message)
            dispatch({
                type: UPDATE_USER_ORGANIZATION_LOADING,
                payload: false
            });
            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: UPDATE_USER_ORGANIZATION_LOADING,
            payload: false
        });
    }
}

export const deleteOrgUser = (id) => async (dispatch) => {

    try {
        dispatch({
            type: DELETE_USER_ORGANIZATIONS_LOADING,
            payload: true
        });
        const token = await getFRToken();
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.delete(`${BASE_URL}/accessblend/saas/admin-org/users/delete?userId=${id}`);
        if (res.data) {
            toast.success(res.data.message);
            dispatch({
                type: DELETE_USER_ORGANIZATIONS_LOADING,
                payload: false
            });
            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: DELETE_USER_ORGANIZATIONS_LOADING,
            payload: false
        });
    }
};
