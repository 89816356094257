
import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Grid, FormControlLabel, Switch, FormGroup } from '@mui/material';
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from 'components/MDBox';
import { useParams, useNavigate } from 'react-router-dom';
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';
import MDProgress from 'components/MDProgress';
import { Card, Divider } from "@mui/material";
import { getRootUsers } from 'redux/actions/adminSmokiDashboard.action';
import { assignAdminCustomWidget } from 'redux/actions/adminSmokiDashboard.action';

export default function AssignAdminWidget() {
    let { widgetId } = useParams();
    const dispatch = useDispatch()

    const navigate = useNavigate();

    const [allUsers, setAllUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [getLoading, setGetLoading] = React.useState(false);
    const [userId, setUserId] = React.useState();

    React.useEffect(() => {
        dispatch(getRootUsers()).then((data) => {
            if (data) {
                setAllUsers(data.result)
            }
        })
    }, [])

    // Function to handle the change of the Select component
    const handleSelectChange = (event) => {
        const selectedUserId = event.target.value;
        setUserId(selectedUserId);
    };


    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        dispatch(assignAdminCustomWidget({
            widgetId: widgetId,
            userId: userId
        })).then(data => {
            setLoading(false)
            navigate(`/accessblend-admin/saas/accessBlend/assign/custom-widgets`)
        }).catch(err => {
            setLoading(false)

        })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDTypography variant='h4' sx={{ mt: 2 }} marginBottom>Assign Widget</MDTypography>
            {getLoading ? <MDBox display='flex' justifyContent='center' alignItems='center' height='100%'>
                <MDProgress circular />
            </MDBox> :
                <Card sx={{ p: 2, my: 2 }}>
                    <MDBox my={4} component='form' onSubmit={handleSubmit}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth sx={{ maxWidth: 450 }}>
                                    <InputLabel id="user-status">Select Users</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="user-status"
                                        id="user-status-select"
                                        label="Select User"
                                        value={userId}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            allUsers.map((user, index) => {
                                                return (
                                                    <MenuItem value={user.id} key={user.id}>{user.email}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <MDBox display='flex' my={3}>
                            <MDButton variant='contained' color='primary' type='submit' loading={loading} onSubmit={handleSubmit} onChange>Update</MDButton>
                        </MDBox>
                    </MDBox>
                </Card>
            }
        </DashboardLayout>
    )
}