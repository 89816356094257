import React, { useState } from "react";


//   components
import MDBox from "components/MDBox";
import { auth } from 'services/firebase'
import { signInWithCustomToken } from "firebase/auth";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";


import MDProgress from "components/MDProgress";
import { useSelector } from "react-redux";


function LoginCallBack() {

	let navigate = useNavigate();
	const user = useSelector(state => state.auth.user);

	const [loading, setLoading] = useState(false);


	// const { token } = useParams()

	const [searchParams, setSearchParams] = useSearchParams();
	const token = searchParams.get("token")

	// This function handles user login, and dispatches loginUser action to login user
	const handleAction = (e) => {
		setLoading(true)

		// If a token is retrieved, sign in with the token using Firebase authentication
		if (token) {
			signInWithCustomToken(auth, token)
				.then((userCredential) => {

					// TODO: dispatch loadUser action and handle response data
				})
				.catch((error) => {
					setLoading(false)

					navigate("/authentication/sign-in")
				})
		} else {
			setLoading(false)
		}

	}

	React.useEffect(() => {
		if (token) {
			handleAction()
		}
	}, [token])



	React.useEffect(() => {
		if (user) {
			navigate("/")

		}
	}, [user])


	if (loading) {
		<MDBox display='flex' alignItems='center' justifyContent='center' height='100vh'>
			<MDProgress circular />
		</MDBox>
	}

	return (
		<>

		</>
	);
}
export default LoginCallBack;
