import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { getWebsiteReport } from "redux/actions/userDashboard.action";
import PermissionBox from "components/PermissionBox";

export default function WebsiteReport() {
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(null);

	const [data, setData] = React.useState(null);
	const [status, setStatus] = React.useState(null);

	const selectedWebsite = useSelector(state => state.userDashboard.selectedSubdomain)

	function fetchReport(id) {
		setLoading(true)
		setError(null)

		dispatch(getWebsiteReport(id)).then(data => {
			if (data) {
				setData(data.result);
				setLoading(false)
			} else {
				setError("Error Occured!")
				setLoading(false)
			}
		}).catch(err => {
			setError("Error Occured!")
			setLoading(false)
		})

	}

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (selectedWebsite && data && data.status !== 'completed') {
				fetchReport(selectedWebsite.id)
			}
		}, 5000);

		// Clean up the interval when the component unmounts
		return () => {
			clearInterval(interval);
		};
	}, [selectedWebsite,data]);

	React.useEffect(() => {
		if (selectedWebsite) {
			fetchReport(selectedWebsite.id)
		}
	}, [selectedWebsite])
    const {websites}=useSelector(state=>state.auth.user.permissions)

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{
 websites && websites?.viewWebsiteScan ?
 <>
 {error ? <MDTypography variant='body2'>An Error Occured</MDTypography> :
	 !selectedWebsite ?
		 <MDTypography variant='body2'>Please add Website first</MDTypography>
		 :
		 <>
			 {loading ?
				 <MDBox display='flex' alignItems='center' justifyContent='center' flexDirection='column' height={500}><MDProgress circular size={36} /></MDBox> :
				 <MDBox>
					 {data?.status !== "completed" ?
						 <MDBox display='flex' alignItems='center' justifyContent='center' flexDirection='column' height={500}>
							 <MDTypography variant='body2'>Your Report is being generated please wait....</MDTypography>
							 <MDProgress circular size={36} />
						 </MDBox>
						 :
						 <><MDTypography variant='body2' onClick={() => window.open(data.link)} textAlign='center' sx={{ my: 4, cursor: 'pointer' }}>Click here to view one page report in a new tab. </MDTypography>
							 <iframe style={{ border: 'none', height: '800px', width: '100%' }} src={data.link} />
						 </>
					 }
				 </MDBox>
			 }
		 </>
 }
 </>:
			<PermissionBox text={"to view website scan"}/>
			}
		</DashboardLayout>
	)
}