
import { Card } from '@mui/material'
import MDTypography from 'components/MDTypography'
import { ErrorBoundary } from 'react-error-boundary'
// import { getDatabase } from "firebase/database";
// import { doc, setDoc } from "firebase/firestore";

import { v4 as uuidv4 } from 'uuid';
import React from 'react';
// import { getFirestore } from "firebase/firestore";
// import app from 'firebase-config';
import { getDatabase, ref, set } from "firebase/database";
import { useLocation } from 'react-router-dom';
import MDBox from 'components/MDBox';

export default function ErrorHandler({ children }) {

    function writeUserData(error) {

        const db = getDatabase();
        set(ref(db, 'errors/' + uuidv4()), {
            error: error.message
        });
    }

    const { pathname } = useLocation()
    const originalPathname = React.useRef(pathname)
    function ErrorFallback({ error, resetErrorBoundary }) {


        React.useEffect(() => {
            if (pathname !== originalPathname.current) {
                resetErrorBoundary()
            }
        }, [pathname])

        return (
            <MDBox height='100vh' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                <Card role="alert" sx={{
                    p: 2,
                    textAlign: 'center', maxWidth: 300, margin: 'auto'
                }} >
                    <MDTypography variant='h6' marginBottom={1}>Something went wrong</MDTypography>
                    <MDTypography variant='caption' fontWeight='regular'>Contact us at: support@accessBlend.com
                    </MDTypography>
                </Card>
            </MDBox>
        )
    }



    return (
        <ErrorBoundary
            onError={(error) => {
                writeUserData(error)
            }}
            FallbackComponent={ErrorFallback}
        >
            {children}
        </ErrorBoundary>
    )
}



