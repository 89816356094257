import { useSelector } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import BillingAllInvoices from "./BillingAllInvoices";
import PermissionBox from "components/PermissionBox";

export default function BillingUserInvoices() {
  const { billing } = useSelector((state) => state.auth.user.permissions);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {billing && billing?.viewInvoices ? (
        <>
          <BillingAllInvoices
            type="website-auditor-order-subscription"
            title="Auditor Invoices"
          />
          <BillingAllInvoices
            type="website-widget-order-subscription"
            title="Widget Invoices"
          />
          <BillingAllInvoices
            type="organization-user-subscription"
            title="User Invoices"
          />
        </>
      ) : (
        <PermissionBox text={"to view invoices details"}/>
      )}
    </DashboardLayout>
  );
}
