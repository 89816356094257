import React, { useState, useEffect, Suspense } from "react";
// react-router components
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider } from '@mui/material/styles';

//   example components
import Sidenav from "examples/Sidenav";

//   themes
import theme from "assets/theme";

//   Dark Mode themes
import themeDark from "assets/theme-dark";

//   routes
import routes from "routes";

//   contexts
import {
  setDarkMode,
  useMaterialUIController, setMiniSidenav
} from "context";

import { useDispatch, useSelector } from "react-redux";

//Private Route Wrapper
import PrivateRoute from 'PrivateRoute';
import { Provider } from 'react-redux';
import store from './redux/store';

import { Box, CircularProgress, LinearProgress } from "@mui/material";

import { auth } from 'services/firebase';
import { onAuthStateChanged } from "firebase/auth";
import logo from 'assets/images/logos/logo.svg'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadUser, loadingFalse } from "redux/actions/auth.action";
import MDProgress from "components/MDProgress";
import { BASE_URL } from "Source/constant";
import axios from "axios";
import ErrorHandler from "components/ErrorHandler";
import { PREV_PATH } from "redux/actions/auth.action";
import DomainWrapper from "components/DomainWrapper";

export default function App() {

  const [loading, setLoading] = React.useState(true)

  //Get & Set Config for app
  async function healthCheck() {
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `${BASE_URL}/config`,
        options
      );

      if (res.data) {
        if (res.data.config) {
          localStorage.setItem("config", JSON.stringify(res.data.config));
          setLoading(false)
        }
      }
    } catch (err) {
      setLoading(false)

    }
  }


  React.useEffect(() => {
    healthCheck();
  }, []);


  return (
    <Provider store={store}>
      {loading ? <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box> :
        <Validate
        //  checkMaintenance={checkMaintenance}
        />
      }
    </Provider>
  );
}

const Validate = ({ checkMaintenance }) => {
  return (
    <>
      {/* {checkMaintenance.check ?
        <Box display="flex" justifyContent="center" alignItems="center" p={4} height='80vh' textAlign='center'>
          <Box display='flex' justifyContent='center' flexDirection='column'>
            <Box component='img' src={maintenaceLottie} style={{ maxWidth: 400, }} margin='auto' />
            <Box mt={-10}>
              <h3>We are currently in  maintaince mode, working on a new feature</h3>
              <h4>&mdash; The accessBlend Team &mdash;</h4>
            </Box>
          </Box>
        </Box>
        : */}
      <MainApp />
      {/* } */}
    </>
  );
};

function MainApp() {

  const disaptch = useDispatch();
  const { userLoading } = useSelector(state => state.auth);
  const user = useSelector(state => state.auth.user);
  const config = useSelector(state => state.auth);

  const location = useLocation();
  const navigate = useNavigate();

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [reciveNotification, setReciveNotification] = useState(false);

  const { pathname } = useLocation();

  const [controller, dispatch] = useMaterialUIController();


  React.useEffect(() => {
    const themeModeDark = JSON.parse(localStorage.getItem('darkmode'))
    setDarkMode(dispatch, themeModeDark)
  }, [])


  //Firebase auth hook on login
  React.useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      const path = location.pathname.split('/');
      if (user) {
        //Private Routes Check
        if ((!path.includes('register') && !path.includes('affiliate')) || (!path.includes('register') && !path.includes('user')
        )) {
          if (!path.includes('system-status')) {
            disaptch(loadUser(user))
          } else {
            disaptch(loadingFalse())

          }
        } else {
          disaptch(loadingFalse())
        }

      } else {
        disaptch(loadingFalse())
      }
    });
  }, [])



  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    darkMode,
  } = controller;

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route && route.private) {
        if ((user && route.layout.split('/')[0] === getRoles(user.role))) {
          return <Route exact path={`${route.layout}${route.route}`} element={<PrivateRoute Component={route.component} />} key={route.key} />
        }
      } else {
        return <Route exact path={route.route} element={route.component} key={route.key} />
      }
    });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // // Setting page scroll to 0 when changing the route
  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  // }, [pathname]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  function getAllRoute() {
    if (user) {

      if (user.role !== 'accessblend-admin') {
        return <Route path="*" element={<Navigate to="/user/main/accessBlend/websites" />} />
      } else {
        return <Route path="*" element={<Navigate to="/accessblend-admin/saas/accessBlend/dashboard" />} />
      }


    } else {
      return <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
    }
  }
  //Switch between Enterprise and Saas dahsbaord
  React.useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths.includes("saas")) {
      localStorage.setItem("dashboard", "saas")
    } else {
      localStorage.setItem("dashboard", "saas")
    }
  }, [location])



  // Store the path of the previous page in a state variable.

  const prevPath = useSelector(state => state.auth.prevPath)
  React.useEffect(() => {
    if (prevPath === null) {
      disaptch({
        type: PREV_PATH,
        payload: location.pathname
      })
    }
  }, [prevPath]);

  React.useEffect(() => {
    if (user && user.role !== 'accessblend-admin') {
      if (!user?.emailVerified) {
        navigate("user/main/accessBlend/verify-email", { state: { email: user?.email } })
      } else if (!user?.organization?.isWebsiteAdded) {
        navigate("/user/main/accessBlend/add-website")
      }
    }
  }, [user])

  function getRoles(role) {
    if (role === 'accessblend-admin') {
      return role
    } else {
      return 'user'
    }
  }
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {!userLoading ? <DomainWrapper>
            {user && !location.pathname.split("/").includes("onboarding") && !location.pathname.split("/").includes("checkout") && !location.pathname.split("/").includes("verify-email") && <>
              <Sidenav
                color={sidenavColor}
                brand={logo}
                brandName="accessBlend"
                routes={routes.filter(ro => ro.layout.split('/')[0] === getRoles(user.role))}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>}
            <ErrorHandler>
              <Suspense fallback={<Box width='100%' overflow='hidden' ><LinearProgress sx={{ height: 3 }} color='info' /></Box>}>
                <Routes>
                  {getRoutes(routes)}
                  {getAllRoute()}
                </Routes>
              </Suspense>
            </ErrorHandler>
            <ToastContainer
              style={{ fontSize: 12, zIndex: 9999999999 }}
              role='alert'
              limit={3}
              closeButton={true}
              autoClose={2000}
              position='top-right'
            />
          </DomainWrapper> :
            <Box height="100vh" width="100%" display='flex' alignItems="center" justifyContent="center"> <MDProgress circular /></Box>
          }
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}