import moment from "moment-timezone";


function checkAt(dt) {
    let stringsArray = dt.split(' ');

    const findAt = stringsArray.findIndex(s => s === 'at')

    stringsArray = stringsArray.splice(1, findAt).join(" ")
    return stringsArray

}

function simpleDateparser(dt) {

    const myDate = new Date(dt);
    return myDate.toGMTString();
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // let dt2 = moment(dt).tz(timezone);

    // let thatTime = moment(dt).format("hh:mm A");

    // const days = moment(dt2).date()

    // let currentDate = new Date();
    // let currentDateDay = moment(currentDate).date();
    // let currentDateDays = moment(currentDate).startOf('day');

    // const daysDiff = days - currentDateDay;

    // if (daysDiff > 0 && daysDiff < 7) {
    //     return moment(currentDateDays).add(daysDiff, 'days').calendar();

    // } else if (daysDiff < 0 && daysDiff > -7) {
    //     let pastDate = moment(currentDateDays).subtract(Math.abs(daysDiff), 'days').calendar();
    //     return `${checkAt(pastDate)} ${thatTime}`;

    // } else {
    //     return moment(dt2, "YYYYMMDD").tz(timezone).fromNow();
    // }

}


export default simpleDateparser;