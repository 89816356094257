import React from 'react';
//   components
import MDBox from "components/MDBox";
//   example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Websites from './websites';
import { useParams } from 'react-router-dom';

function AdminUserWebsites() {
    const { userId } = useParams()
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <>
                    <MDBox mt={1}>
                        <Websites userId={userId} type='saas' title='SaaS' />
                    </MDBox>
                    {/* <MDBox mt={6}>
                        <Websites type='enterprise' title='Enterprise' />
                    </MDBox> */}
                </>
            </MDBox>
        </DashboardLayout>
    );
}

export default AdminUserWebsites;
