
import React from "react";

// @mui material components
import Card from "@mui/material/Card";

//   components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
// import CoverLayout from "pages/authentication/components/CoverLayout";
import BasicLayout from "pages/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userAction } from "redux/actions/auth.action";
import { toast } from "react-toastify";

function ResetPassword() {

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState('');



  function handleSubmit(e) {
    setLoading(true)
    e.preventDefault();
    dispatch(userAction({ email: value, action: "reset-password" })).then(data => {
      if (data) {
        toast.success(`Reset password link sent to ${value}`)
        setLoading(false)
      } else {
        setLoading(false)
      }
    }).catch((error) => {
      setLoading(false)

    })

  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1} px={2}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" autoComplete="off" role="form" autoComplete="off" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput type="email" label="Email" variant="outlined" fullWidth required={true} onChange={(e) => setValue(e.target.value)} />
            </MDBox>
            <MDBox mt={3} mb={1}>
              <MDButton variant="gradient" color="info" type='submit' fullWidth loading={loading}>
                reset
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox mt={2} mb={1} textAlign="center">
            <MDTypography component={Link}
              to={"/authentication/sign-in"}
              variant="button"
              fontWeight="medium"
            >
              Click here to login
            </MDTypography>
          </MDBox>
        </MDBox>

      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
