import React from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch } from 'react-redux';
import { getPaymentMethods } from 'redux/actions/userDashboard.action';
import { useMaterialUIController } from 'context';
import { removePaymentMethod } from 'redux/actions/userDashboard.action';
import MDTypography from 'components/MDTypography';
import { Card, FormControl, FormControlLabel, FormLabel, Pagination, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import MDBox from 'components/MDBox';
import { updateDefaultPaymentMethod } from 'redux/actions/userDashboard.action';
import MDProgress from 'components/MDProgress';
import colors from 'assets/theme/base/colors';
import simpleDateparser from 'utils/simpledateParser';
import MDButton from 'components/MDButton';
import AddPAymentMethod from './AddPaymentMethod';
import PaymentStatus from './PaymentStatus';
import PermissionBox from 'components/PermissionBox';
export default function Billings() {
  const { billing } = useSelector((state) => state.auth.user.permissions);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const dispatch = useDispatch();

    function fetchPaymentMethods() {
        setLoading(true)
        dispatch(getPaymentMethods(page)).then(data => {
            if (data) {
                setRows(data.result)
                setPages(data.pages)
                setLoading(false)
            }
        });
    }

    React.useEffect(() => {
        fetchPaymentMethods()
    }, [page])

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                billing && billing?.addPaymentMethods ?
                <AddPAymentMethod />:
                <PermissionBox text={"to add payment method"} />
            }
            <PaymentStatus />
            <MDBox mt={5}>
                {
                    billing && billing?.viewPaymentMethods ?
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize' }}>
                            Payment Methods
                        </MDTypography>
                    </MDBox>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <thead>
                                <TableRow>
                                    <TableCell align="left" sx={{ minWidth: 260 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            ID
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 185 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Type
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 180 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Last 4
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 180 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Expire Month
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 180 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Expire Year
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 180 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Brand
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 180 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Account Holder Type
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 180 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Account Type
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 180 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Bank Name
                                        </MDTypography>
                                    </TableCell>

                                    <TableCell align="left" sx={{ minWidth: 185 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Funding
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 250 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Added
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 120 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Default
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ minWidth: 300 }}>
                                        <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                            Action
                                        </MDTypography>
                                    </TableCell>
                                </TableRow>
                            </thead>
                            {loading ?
                                <TableBody>
                                    <TableRow >
                                        <TableCell align="center" colSpan={6} >
                                            <MDBox
                                                display="flex"
                                                py={1}
                                                flexDirection='column'
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <MDProgress circular />
                                                <MDTypography variant="h6">
                                                    Loading Methods...
                                                </MDTypography>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    {rows && rows.length > 0 ? rows.map((row, index) => {
                                        return <OrderRow fetchPaymentMethods={fetchPaymentMethods} row={row} key={`order-${index}`} 
                                        remove={billing && billing?.removePaymentMethods}
                                        />
                                    }) :
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} >
                                                <MDTypography variant='caption' component='span' fontWeight="bold">no payment methods found</MDTypography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>}
                        </Table>
                        {rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                            <Pagination color='info' variant="outlined"
                                sx={{
                                    '& .MuiPagination-ul > li > button': {
                                        fontWeight: 400,
                                        color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                        borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                    },
                                    '& .MuiPagination-ul > li > div': {
                                        color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                    }
                                }}
                                count={pages} page={page} onChange={(e, value) => setPage(value)} />
                        </MDBox>}
                    </TableContainer>
                </Card> :
                <PermissionBox text={"to view payment methods details"} />
                }
            </MDBox>
        </DashboardLayout>
    )
}


function OrderRow({ row, fetchPaymentMethods,remove }) {

    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false)
    const [loading2, setLoading2] = React.useState(false)



    function remove() {
        setLoading(true);
        dispatch(removePaymentMethod(row.id)).then(data => {
            fetchPaymentMethods()
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    function makeDefault() {
        setLoading2(true);
        dispatch(updateDefaultPaymentMethod({ paymentMethodId: row.id })).then(data => {
            fetchPaymentMethods()
            setLoading2(false)
        }).catch(err => {
            setLoading2(false)
        })
    }


    return (
        <React.Fragment>
            {/* <RadioGroup name='payment-method'> */}
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.type}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.card?.last4}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.card?.exp_month}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.card?.exp_year}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.card?.brand}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.us_bank_account?.account_holder_type}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.us_bank_account?.account_type}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.us_bank_account?.bank_name}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.card?.funding}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {simpleDateparser(row?.createdAt)}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row?.default.toString()}
                    </MDTypography>
                </TableCell>

                <TableCell align="left">
                    <MDBox component='span' display='flex' gap={2} >
                        {!row.default && <MDButton variant='contained' color='info' disabled={loading2 || loading} loading={loading2} onClick={() => makeDefault()}>Make Default</MDButton>}
                        {
                            remove &&
                        <MDButton variant='contained' color='info' disabled={loading2 || loading} loading={loading} onClick={() => remove()}>Remove</MDButton>
                        }
                    </MDBox>
                </TableCell>

            </TableRow>

        </React.Fragment>
    )
}
