import { Card, Grid, Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDProgress from 'components/MDProgress';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getWidgetOrdersDetails } from 'redux/actions/userDashboard.action';


export default function UserViewWidgetOrder() {

    let { orderId } = useParams();
    const dispatch = useDispatch()
    const [orderDetails, setOrderDetails] = React.useState(null)
    // const [methods, setMethods] = React.useState({ bankAccount: false, card: false })

    // const [editLoading, setEditLoading] = React.useState(false)
    const [statusLoading, setStatusLoading] = React.useState(false)

    const [orderStatus, setOrderStatus] = React.useState('')

    const [sLoading, setSLoading] = React.useState(false)

    const [subscribed, setSubscribed] = React.useState(false)




    // This function is responsible for getting the order details for a given order ID
    function getDetails(orderId) {
        dispatch(getWidgetOrdersDetails(orderId)).then(data => { // Dispatches action to get order details and waits for response
            if (data) {
                console.log(data)
                setSubscribed(data.result.subscribed)// If data is present in the response
                setOrderDetails({ // Sets the state of order details with the response data
                    "orgName": data.result.orgName,
                    "phone": data.result.phone,
                    "name": data.result.name,
                    "email": data.result.email,
                    // "noOfWebsites": data.result.noOfWebsites,
                    // "noOfPages": data.result.noOfPages,
                    "discount_total": data.result.discount_total,
                    "discount_tax": data.result.discount_tax,
                    "total": data.result.total,
                    "total_tax": data.result.total_tax,
                    description: data?.result?.description || "",
                    billing: data?.result?.billing,
                    test: data?.result?.test,
                    totalDiscounted: data?.result?.totalDiscounted,
                    invoiceLink: data?.result?.invoiceLink || "",
                    "recurring": {
                        "intervalCount": data?.result?.recurring?.intervalCount || 1,
                        "interval": data?.result?.recurring?.interval || "week"
                    },
                    "trialDays": data?.result?.trialDays,
                })
                // setSelectedResolutions(data.result?.resolutions)

                // setMethods({ ...data.result.methods }) // Sets the state of methods with the response data
                setOrderStatus({ label: data.result.status, value: data.result.status }) // Sets the state of order status with the response data
            }
        })
    }

    // This effect runs when orderId is changed and calls the functions to get clusters and order details for the given orderId
    React.useEffect(() => {
        if (orderId) {
            getDetails(orderId)
        }
    }, [orderId])

    return (
        <DashboardLayout>
                        <DashboardNavbar />
            {orderDetails ?
                <Card sx={{ p: 2, my: 2 }}>
                    <MDBox mb={3} component='form'>
                        <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                            <MDTypography variant="h6" color="white" textAlign='center'>Order Main Info</MDTypography>
                        </MDBox>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>User Full Name:</MDTypography> <br />
                                <MDInput value={orderDetails?.name} name='name' required={true}  fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Organization Name:</MDTypography> <br />
                                <MDInput value={orderDetails?.orgName} name='orgName' required={true}  fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>User Email:</MDTypography> <br />
                                <MDInput value={orderDetails?.email} name='email' required={true}  fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>User Phone:</MDTypography> <br />
                                <MDInput value={orderDetails?.phone} name='phone' required={true}  fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Total Discounted:</MDTypography> <br />
                                <MDInput value={orderDetails?.totalDiscounted} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Total:</MDTypography> <br />
                                <MDInput value={orderDetails?.total} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDTypography variant='button' fontWeight='bold'>Interval:</MDTypography> <br />
                                <MDInput value={`${orderDetails?.recurring?.intervalCount} ${""} ${orderDetails?.recurring?.interval}`} fullWidth />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <MDTypography variant='button' fontWeight='bold'>Trial Days:</MDTypography> <br />
                                <MDInput value={orderDetails?.trialDays} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant='button' fontWeight='bold'>Invoice Link:</MDTypography> <br />
                                <MDInput value={orderDetails?.invoiceLink} name='invoiceLink' fullWidth />
                            </Grid>
                        </Grid>
                        <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                            <MDTypography variant="h6" color="white" textAlign='center'>Order Billing Info</MDTypography>
                        </MDBox>
                        <MDBox >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>First Name:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.firstName}  fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Last Name:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.lastName} name='last_name' required={true}  fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Company:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.company} name='company' required={true}  fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Address 1:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.address1} name='address_1' required={true}  fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Address 2:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.address2} name='address_2'  fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Country:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.country} name='country' required={true}  fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>City:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.city} name='city' required={true}  fullWidth />

                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>State:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.state} name='state' required={true}  fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Post Code:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.postcode} name='postcode' required={true}  fullWidth />
                                </Grid>
                            </Grid>

                        </MDBox>
                    </MDBox>
                    <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                        <MDTypography variant="h6" color="white" textAlign='center'>Order Status: {orderDetails?.status}</MDTypography>
                    </MDBox>
                    <MDBox display='flex' justifyContent='center' alignItems='center' gap={3} mt={3}>
                    <MDInput value={orderStatus?.value} name='name' justifyContent='center'/>
                    </MDBox>
                </Card>
                :
                <MDBox display='flex' justifyContent='center' alignitems='center' height='60vh'>
                    <MDProgress circular />
                </MDBox>
            }

        </DashboardLayout>
    )
}