import React from 'react';
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from 'redux/actions/auth.action';
import { toast } from 'react-toastify';
import { updatePasswords } from 'redux/actions/auth.action';


export default function Settings() {

    const dispatch = useDispatch();

    const [values, setValues] = React.useState({
        email: "",
        displayName: "",
        phoneNumber: ""
    });

    const confirmRef = React.useRef(null);



    const [passwords, setPasswords] = React.useState({
        password: "",
        confirmPassword: ""
    });

    const [loading, setLoading] = React.useState(false);
    const [passLoading, setPassLoading] = React.useState(false);


    const user = useSelector(state => state.auth.user);


    function handleValues(e) {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        dispatch(updateUser(values)).then(data => {
            if (data) {
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    function handlePassowrds(e) {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value
        })
    }


    React.useEffect(() => {
        if (user) {
            setValues({
                email: user?.email,
                displayName: user?.displayName,
                phoneNumber: user?.phoneNumber
            })
        }
    }, [user])

    function submitPasswords(e) {
        e.preventDefault();
        if (passwords.password !== passwords.confirmPassword) {
            toast.error("Passwords do not match")
        } else {
            setPassLoading(true)

            dispatch(updatePasswords(passwords)).then(data => {
                setPassLoading(false)
            }).catch(data => {
                setPassLoading(false)
            })
        }


    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={5}>
                <Card sx={{ width: '100%', maxWidth: 600, margin: 'auto', py: 3, px: 3 }}>
                    <MDBox component='form' onSubmit={handleSubmit} >
                        <MDBox pt={2} pb={2} px={4}>
                            <MDTypography
                                component="h1"
                                variant="h3"
                                align="center"
                                gutterBottom
                            >
                                Settings
                            </MDTypography>
                            <MDTypography variant='body2'
                                align="center"
                            > Make changes to your basic personal information like your name and contact information.</MDTypography>
                        </MDBox>
                        <MDBox p={2}>
                            <MDInput variant='outlined' value={user.orgName} label="Organization" fullWidth name='displayName' />
                        </MDBox>
                        <MDBox p={2} mb={2}>
                            <MDTypography variant='h4' sx={{ mb: 2 }}>Profile</MDTypography>
                            <MDInput variant='outlined' value={values.displayName} label="Full Name" required={true} fullWidth name='displayName' onChange={handleValues} />
                        </MDBox>
                        <MDBox p={2} mb={3}>
                            <MDTypography variant='h4' sx={{ mb: 2 }}>Contact Information</MDTypography>
                            <MDInput variant='outlined' label='Email' value={values.email} required={true} name='email' sx={{ mb: 2 }} fullWidth onChange={handleValues} />
                            <MDInput variant='outlined' sx={{ mb: 2 }} label='Phone' value={values.phoneNumber} name='phoneNumber' fullWidth onChange={handleValues} />
                            <MDButton sx={{ margin: 'auto' }} color='info' variant='contained' type='submit' loading={loading}>Save Changes</MDButton>
                        </MDBox>

                    </MDBox>
                    <MDBox p={2} >
                        <MDTypography variant='h4' sx={{ mb: 2 }}>Update Password</MDTypography>
                        <MDBox component='form' onSubmit={submitPasswords}>
                            <MDInput variant='outlined' label='Password' value={passwords.password} required={true} name='password' onChange={handlePassowrds} id='password' sx={{ mb: 2 }} fullWidth />
                            <MDInput variant='outlined' label='Confirm Password' value={passwords.confirmPassword} name='confirmPassword' id='confirmPassowrd' onChange={handlePassowrds} sx={{ mb: 2 }} required={true} fullWidth ref={confirmRef} />

                            <MDButton sx={{ margin: 'auto' }} color='info' variant='contained' type='submit' loading={passLoading}>Save Changes</MDButton>
                        </MDBox>
                    </MDBox>

                </Card>
            </MDBox>

        </DashboardLayout>
    )
}

