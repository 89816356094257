

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

//   components
import MDBox from "components/MDBox";

//   example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";


function OnBoardLayout({ image, children }) {
  return (
    <PageLayout>
      <DefaultNavbar
        action={{
          type: "external",
          route: "https://accessBlend.com/",
          label: ".",
          color: "dark",
        }}
        dark
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto"
        position='relative' className='unauth-background'
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the OnBoardLayout
OnBoardLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default OnBoardLayout;
