import MDBox from "components/MDBox";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import MDTypography from "components/MDTypography";
import React from "react";
import { Alert, Card, CardContent, Icon, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';


function AfterPaymentPage({ stripe }) {

    const [open, setOpen] = React.useState(false);
    const stripeFlb = useStripe();

    const [message, setMessage] = React.useState({ type: '', message: '' })

    //handle stripe intent to check the status of payment
    React.useEffect(() => {
        if (stripe) {
            if (!stripeFlb) {
                return;
            }

            const clientSecret = new URLSearchParams(window.location.search).get(
                "setup_intent_client_secret"
            );

            if (!clientSecret) {
                return;
            }
            //set message on stripe response
            stripeFlb.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
                switch (setupIntent.status) {
                    case 'succeeded':
                        setMessage({ type: setupIntent.status, message: "Success! Your payment method has been saved." });
                        setOpen(true)
                        break;

                    case 'processing':
                        setMessage({ type: setupIntent.status, message: "Processing payment details. We'll update you when processing is complete." });
                        setOpen(true)

                        break;

                    case 'requires_payment_method':
                        setMessage({ type: setupIntent.status, message: 'Failed to process payment details. Please try another payment method.' });
                        setOpen(true)
                        break;
                    default: setMessage({ type: '', message: '' })
                }
            });
        }
    }, [stripeFlb, stripe]);

    const msgType = {
        'succeeded': 'success',
        'processing': 'info',
        'requires_payment_method': 'error'
    }

    return (
        <>
            {message?.message && open ?
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    type={msgType[message.type]} >{message.message}</Alert> : null}
        </>
    )
}

export default function PaymentStatus() {
    const [stripe, setStripe] = React.useState(null);
    const location = useLocation();

    //get config and check if url has test in route to make test order validation
    React.useEffect(() => {
        const getConfig = localStorage.getItem("config");
        const configValue = JSON.parse(getConfig);
        setStripe(loadStripe(configValue.stripeKey || configValue.stripeTestKey))
    }, [location])

    const clientSecret2 = new URLSearchParams(window.location.search).get(
        "setup_intent_client_secret"
    );
    return (
        <>
            {stripe && clientSecret2 && <Elements stripe={stripe} options={{ clientSecret: clientSecret2 }} >
                <AfterPaymentPage stripe={stripe} />
            </Elements>}
        </>
    )
}