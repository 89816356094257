import React from 'react'
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import {Card} from "@mui/material"


const PermissionBox = ({rest,text}) => {
  return (
    <MDBox display='flex' justifyContent='center' alignItems='center' height='60vh'>
				<Card sx={{ p: 4, width: '100%', maxWidth: 800,display:"flex",justifyContent:"center",...rest }}>
        <MDBox textAlign='center'>
							<MDTypography variant='h6'>You don't have permission to {text}</MDTypography>
							<MDTypography variant='body2'>Request permission from your administrator.</MDTypography>

						</MDBox>
        </Card>
        </MDBox>
    
  )
}

export default PermissionBox