import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import Slide from '@mui/material/Slide';
import MDButton from 'components/MDButton';
import { addUserWebsite } from 'redux/actions/userDashboard.action';
import { Card, Grid } from '@mui/material';
import { useMaterialUIController } from "context";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDTypography from 'components/MDTypography';
import { checkWebsite } from 'redux/actions/userDashboard.action';
import { useNavigate } from 'react-router-dom';

export default function AddWebsite() {
	const [website, setWebsite] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [checkLoading, setCheckLoading] = React.useState(false);

	const navigate = useNavigate();
	const [websiteCheckData, setWebsiteCheckData] = React.useState('');

	const user = useSelector(state => state.auth.user);


	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	const dispatch = useDispatch();

	function handleCheckWebsite(e) {
		e.preventDefault();
		setCheckLoading(true)
		dispatch(checkWebsite(website.website)).then(data => {
			if (data) {
				setCheckLoading(false);
				setWebsiteCheckData({
					website: data.result.website,
					subdomain: data.result.subdomain,
				})
			} else {
				setCheckLoading(false);
			}
		})
	}


	function handleAddWebsite() {
		setLoading(true)
		dispatch(addUserWebsite(website)).then(data => {
			if (data) {
				if (!user?.organization?.isWebsiteAdded) {
					window.location.pathname = "/user/main/accessBlend/websites"
				} else {
					navigate("/user/main/accessBlend/websites");
				}
			}
			setLoading(false);

		}).catch(err => {
			setLoading(false)
		})
	}

	function handleChange(e) {
		setWebsite({ ...website, [e.target.name]: e.target.value })
	}

	// function handleChangePages(e) {
	// 	setnumberOfPages({ ...numberOfPages, [e.target.name]: e.target.value })
	// }

	// function handlePageViews(e) {
	// 	setnumberOfPageViews({ ...numberOfPageViews, [e.target.name]: e.target.value })
	// }


	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox display='flex' justifyContent='center' alignItems='center' height='60vh'>
				<Card sx={{ p: 4, width: '100%', maxWidth: 800 }}>
					{user?.permissions?.websites?.addWebsite ? <>
						{!websiteCheckData ?
							<>
								<MDTypography variant='h4' textAlign='center'>Add Website</MDTypography>
								<MDBox mt={2} component='form' onSubmit={handleCheckWebsite}>

									<Grid container spacing={2}>
										<Grid item xs={12}>
											<MDInput
												label='Enter Website'
												type="text"
												name='website'
												required={true}
												onChange={handleChange}
												size="large"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<MDInput
												label='Number Of Pages'
												type="number"
												inputProps={{
													min: 0 // Corrected the placement of min property
												}}
												required={true}
												name='numberOfPages'

												onChange={handleChange}
												size="large"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<MDInput
												label='Number Of Page Views'
												type="number"
												inputProps={{
													min: 0 // Corrected the placement of min property
												}}
												required={true}
												name='numberOfPagesViews'

												onChange={handleChange}
												size="large"
												fullWidth
											/>
										</Grid>
									</Grid>
									<MDBox display='flex' justifyContent='center' mt={2}>
										<MDButton type="submit" loading={checkLoading} variant="gradient" color="info"  >Next</MDButton>
									</MDBox>
								</MDBox>
							</> :
							<MDBox >
								<MDTypography variant='subtitle1' sx={{ fontWeight: '500' }} >Your Website: {websiteCheckData.website}</MDTypography>
								<MDTypography variant='subtitle1' sx={{ fontWeight: '500' }} >Your Subdomain: {websiteCheckData.subdomain}</MDTypography>
								<MDBox display='flex' gap={2} alignItems='center' my={2}>
									<MDTypography variant='body2'>Is this your information correct?</MDTypography> <MDButton onClick={() => handleAddWebsite()} variant='contained' color='success' loading={loading}>Yes, add my website</MDButton> <MDButton variant='contained' color='error' disabled={loading} onClick={() => setWebsiteCheckData(null)}>No</MDButton>
								</MDBox>
							</MDBox>
						}
					</> :
						<MDBox textAlign='center'>
							<MDTypography variant='h6'>You don't have permission to add Website</MDTypography>
							<MDTypography variant='body2' style={{ marginTop: '20px', marginBottom: '20px' }}>
								Request permission from your administrator.
							</MDTypography>

						</MDBox>
					}
				</Card>
			</MDBox>
		</DashboardLayout>
	)
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});