import { Card, Grid, Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDProgress from 'components/MDProgress';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateWidgetOrderDetails, generateSitemap } from 'redux/actions/adminSmokiDashboard.action';
import { getUserOrderClusters } from 'redux/actions/adminSmokiDashboard.action';
import { getWidgetOrderDetails } from 'redux/actions/adminSmokiDashboard.action';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { makeOrderPayment } from 'redux/actions/adminSmokiDashboard.action';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MenuItem } from "@mui/material";
import MuiSelect from "@mui/material/Select";
import Sitemaps from './Sitemaps';
import { widgetOrderSubscribe } from 'redux/actions/adminSmokiDashboard.action';
import { widgetOrderUnSubscribe } from 'redux/actions/adminSmokiDashboard.action';


export default function ViewWidgetOrder() {

    let { orderId } = useParams();
    const dispatch = useDispatch()
    const [orderDetails, setOrderDetails] = React.useState(null)
    const [methods, setMethods] = React.useState({ bankAccount: false, card: false })

    const [editLoading, setEditLoading] = React.useState(false)
    const [sLoading, setSLoading] = React.useState(false)

    const [orderStatus, setOrderStatus] = React.useState('')


    const [subscribed, setSubscribed] = React.useState(false)




    // This function is responsible for getting the order details for a given order ID
    function getDetails(orderId) {
        dispatch(getWidgetOrderDetails(orderId)).then(data => { // Dispatches action to get order details and waits for response
            if (data) {
                setSubscribed(data.result.subscribed)// If data is present in the response
                setOrderDetails({ // Sets the state of order details with the response data
                    "orgName": data.result.orgName,
                    "phone": data.result.phone,
                    "name": data.result.name,
                    "email": data.result.email,

                    // "noOfWebsites": data.result.noOfWebsites,
                    // "noOfPages": data.result.noOfPages,
                    "discount_total": data.result.discount_total,
                    "discount_tax": data.result.discount_tax,
                    "total": data.result.total,
                    "total_tax": data.result.total_tax,
                    description: data?.result?.description || "",
                    billing: data.result.billing,
                    test: data?.result?.test,
                    "recurring": {
                        "intervalCount": data?.result?.recurring?.intervalCount || 1,
                        "interval": data?.result?.recurring?.interval || "week"
                    },
                    "trialDays": data?.result?.trialDays,
                })
                setMethods({ ...data.result.methods }) // Sets the state of methods with the response data
                setOrderStatus({ label: data.result.status, value: data.result.status }) // Sets the state of order status with the response data
            }
        })
    }

    // This effect runs when orderId is changed and calls the functions to get clusters and order details for the given orderId
    React.useEffect(() => {
        if (orderId) {
            getDetails(orderId)
        }
    }, [orderId])

    // This function is responsible for handling changes in the billing information
    function handleChangeBillingInfo(e) {
        setOrderDetails({ // Sets the state of order details with the updated billing information
            ...orderDetails,
            billing: {
                ...orderDetails?.billing,
                [e.target.name]: e.target.value
            }
        })
    }

    // This function is responsible for updating the billing information
    function editBillingInfo(e) {
        e.preventDefault();
        setEditLoading(true) // Sets loading state to true
        dispatch(updateWidgetOrderDetails({ orderId: orderId, details: { ...orderDetails, status: orderStatus?.value, methods } })).then(data => { // Dispatches action to update order details and waits for response
            setEditLoading(false) // Sets the loading state to false
            getDetails(orderId) // Calls the function to get the order details
        }).catch(error => { // If an error occurs
            setEditLoading(false) // Sets the loading state to false
            toast.error("Error while updating") // Displays an error message
        })
    }

    const orderStatuses = [
        { label: 'processing', value: 'processing' },
        { label: 'pending', value: 'pending' },
        { label: 'cancelled', value: 'cancelled' },
        { label: 'completed', value: 'completed' },
    ]

    // Function to handle selecting an order status option
    function handleOrderStatus(option) {
        setOrderStatus(option)
    }


    // Function to handle changes to main order information (e.g. customer name, address)
    function handleChangeMainInfo(e) {
        // Update the order details state object with the new input value
        setOrderDetails({
            ...orderDetails,
            [e.target.name]: e.target.value
        })
    }

    // Function to initiate a payment for the current order


    // Function to handle changes to checkbox input values
    const handleChangeMethods = (e) => {
        // Update the methods state object with the new checkbox value
        setMethods({
            ...methods,
            [e.target.name]: e.target.checked
        })
    };
    function subscription(val) {
        setSLoading(true)
        if (val === 1) {
            dispatch(widgetOrderSubscribe(orderId)).then(Data => {
                setSLoading(false)
                getDetails(orderId)

            }).catch(err => {
                setSLoading(false)

            })
        } else {
            dispatch(widgetOrderUnSubscribe(orderId)).then(Data => {
                setSLoading(false)
                getDetails(orderId)

            }).catch(err => {
                setSLoading(false)

            })
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {orderDetails ?
                <Card sx={{ p: 2, my: 2 }}>
                    <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                        <MDTypography variant="h6" color="white" textAlign='center'>Order Status: {orderDetails?.status}</MDTypography>
                    </MDBox>
                    <MDBox display='flex' justifyContent='center' alignItems='center' gap={3} mt={3}>
                        <Select
                            styles={{
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    maxWidth: 200,
                                    width: '100%'
                                })
                            }}
                            required={true}
                            value={orderStatus || orderStatus?.value}
                            options={orderStatuses}
                            onChange={handleOrderStatus}
                        />
                        {/* <MDButton variant='contained' color='info' loading={statusLoading} onClick={() => handleUpdateStatus()}>Update Status</MDButton> */}
                    </MDBox>

                    <MDBox mb={3} component='form' onSubmit={editBillingInfo}>
                        <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                            <MDTypography variant="h6" color="white" textAlign='center'>Order Main Info</MDTypography>
                        </MDBox>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Full Name:</MDTypography> <br />
                                <MDInput value={orderDetails?.name} name='name' required={true} onChange={handleChangeMainInfo} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Organization Name:</MDTypography> <br />
                                <MDInput value={orderDetails?.orgName} name='orgName' required={true} onChange={handleChangeMainInfo} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Email:</MDTypography> <br />
                                <MDInput value={orderDetails?.email} name='email' required={true} onChange={handleChangeMainInfo} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Phone:</MDTypography> <br />
                                <MDInput value={orderDetails?.phone} name='phone' required={true} onChange={handleChangeMainInfo} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Discount Total:</MDTypography> <br />
                                <MDInput value={orderDetails?.discount_total} name='discount_total' type='number' required={true} InputProps={{ inputProps: { min: 0 } }} onChange={handleChangeMainInfo} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MDTypography variant='button' fontWeight='bold'>Total:</MDTypography> <br />
                                <MDInput value={orderDetails?.total} name='total' type='number' required={true} InputProps={{ inputProps: { min: 0 } }} onChange={handleChangeMainInfo} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                {/* <FormControl fullWidth> */}
                                <MDTypography variant='button' fontWeight='bold'>Select Interval:</MDTypography> <br />
                                <MuiSelect
                                    fullWidth
                                    labelId="cluster-selection"
                                    id="demo-simple-select"
                                    name='clusterId'
                                    value={orderDetails?.recurring?.interval}
                                    label="Select Interval"
                                    onChange={(e) => setOrderDetails({
                                        ...orderDetails, recurring: { ...orderDetails?.recurring, interval: e.target.value }
                                    })}
                                >
                                    <MenuItem value={"day"}>Day</MenuItem>
                                    <MenuItem value={"week"}>Weekly</MenuItem>
                                    <MenuItem value={"month"}>Monthly</MenuItem>
                                    <MenuItem value={"year"}>Yearly</MenuItem>
                                </MuiSelect>
                                {/* </FormControl> */}
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDTypography variant='button' fontWeight='bold'>Interval Count:</MDTypography> <br />

                                <MDInput fullWidth value={orderDetails?.recurring?.intervalCount} type='number'
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        }
                                    }} required={true}
                                    name='intervalCount' onChange={(e) => setOrderDetails({
                                        ...orderDetails, recurring: { ...orderDetails?.recurring, intervalCount: e.target.value }
                                    })} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDTypography variant='button' fontWeight='bold'>Trial Days:</MDTypography> <br />

                                <MDInput fullWidth type='number' InputProps={{
                                    inputProps: {
                                        min: 0,
                                    }
                                }} required={true} value={orderDetails?.trialDays} name='trialDays' onChange={(e) => setOrderDetails({
                                    ...orderDetails, trialDays: e.target.value
                                })} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <MDTypography variant='button' fontWeight='bold'>Invoice Link:</MDTypography> <br />
                                <MDInput value={orderDetails?.invoiceLink} name='invoiceLink' onChange={handleChangeMainInfo} fullWidth />
                            </Grid>
                        </Grid>
                        <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                            <MDTypography variant="h6" color="white" textAlign='center'>Order Billing Info</MDTypography>
                        </MDBox>
                        <MDBox >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>First Name:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.first_name} name='first_name' required={true} onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Last Name:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.last_name} name='last_name' required={true} onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Company:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.company} name='company' required={true} onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Address 1:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.address_1} name='address_1' required={true} onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Address 2:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.address_2} name='address_2' onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Country:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.country} name='country' required={true} onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>City:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.city} name='city' required={true} onChange={handleChangeBillingInfo} fullWidth />

                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>State:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.state} name='state' required={true} onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDTypography variant='button' fontWeight='bold'>Post Code:</MDTypography> <br />
                                    <MDInput value={orderDetails?.billing.postcode} name='postcode' required={true} onChange={handleChangeBillingInfo} fullWidth />
                                </Grid>
                            </Grid>

                        </MDBox>

                        <MDBox variant='gradient' bgColor='info' borderRadius='lg' py={2} my={3}>
                            <MDTypography variant="h6" color="white" textAlign='center'>Payment Methods</MDTypography>
                        </MDBox>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={methods?.bankAccount || false} onChange={handleChangeMethods} name='bankAccount' />} label="US Bank Account" />
                            <FormControlLabel control={<Checkbox checked={methods?.card || false} onChange={handleChangeMethods} />} name='card' label="Card" />
                        </FormGroup>

                        <MDBox display='flex' alignItems='center' justifyContent='center' my={2}>
                            <MDButton variant='contained' color={subscribed ? "error" : "secondary"} loading={sLoading} onClick={() => subscription(subscribed ? 2 : 1)}>{subscribed ? "Cancel Subscription" : "Make Subscription"}</MDButton>
                        </MDBox>
                        <MDBox display='flex' justifyContent='center' alignitems='center' py={4}>
                            <MDButton variant='gradient' type='submit' color='info' loading={editLoading}>Update Order Info</MDButton>
                        </MDBox>
                    </MDBox>
                </Card>
                :
                <MDBox display='flex' justifyContent='center' alignitems='center' height='60vh'>
                    <MDProgress circular />
                </MDBox>
            }

        </DashboardLayout>
    )
}




