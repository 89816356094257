import React from "react";
import { useNavigate } from "react-router";
import simpleDateparser from "utils/simpledateParser";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDInput from "components/MDInput";
import MuiSelect from '@mui/material/Select'
import MDButton from "components/MDButton";
import { addOrgUser } from "redux/actions/userDashboard.action";
import { getOrgUsers } from "redux/actions/userDashboard.action";
import { deleteOrgUser } from "redux/actions/userDashboard.action";
import { updateOrgUser } from "redux/actions/userDashboard.action";
import PermissionsComponent from "./Permissions";
import PermissionBox from "components/PermissionBox";

const UserManagement = () => {
	const navigate=useNavigate()
	const {usersManage}=useSelector(state=>state.auth.user.permissions)
	

	const dispatch = useDispatch();
	const [page, setPage] = React.useState(1);
	const [pages, setPages] = React.useState(0);
	const [rows, setRows] = React.useState([]);

	const [page2, setPage2] = React.useState(1);
	const [pages2, setPages2] = React.useState(0);
	const [rows2, se] = React.useState([]);

	const [loading, setLoading] = React.useState(false);
	const [addLoading, setAddLoading] = React.useState(false);


	const [userValues, setUserValues] = React.useState({
		email: '',
		role: '',
		password: ''
	})

	function fetchUsers() {
		setLoading(true)
		dispatch(getOrgUsers(page)).then(data => {
			if (data) {
				setRows(data.result)
				setPages(data.pages)
				setLoading(false)
			}
		}).catch(err => {
			setLoading(false)
		})
	}



	function addUser(e) {
		e.preventDefault();
		setAddLoading(true)
		dispatch(addOrgUser(userValues)).then(data => {
			if (data) {
				setUserValues({
					email: '',
					role: '',
					password: ''
				})
				fetchUsers(1)
			}
			setAddLoading(false)

		}).catch(err => {
			setAddLoading(false)
		})
	}

	React.useEffect(() => {
		return () => {
			setPage(1)
			setPages(0)
			setRows([])
			setLoading(false)
			setPage2(1)
			setPages2(0)


		}
	}, [])


	React.useEffect(() => {
		// fetchReports();
		fetchUsers();
	}, [page])

	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const {numberOfUsers,numberOfUserSlots,userSubscription}=useSelector(state=>state.auth.user.organization)



	return (
		<DashboardLayout>
			<DashboardNavbar />

			{
				usersManage && usersManage?.addUsers &&
			<Card component='form' onSubmit={addUser} sx={{ p: 2, my: 4 }}>
				<MDBox display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
					<MDTypography variant='h4' >Add User</MDTypography>
					<MDTypography variant='subtitle2'>Remaining Users {numberOfUserSlots-numberOfUsers} out of {numberOfUserSlots} </MDTypography>
					<MDButton variant='gradient'  color='info'  onClick={()=>{
						navigate('/user/main/accessBlend/user-billing')
					}}>Add more users</MDButton>
				</MDBox>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={4}>
						<MDInput variant='outlined' name='email' label='Email' onChange={(e) => {
							setUserValues({ ...userValues, [e.target.name]: e.target.value })
						}} fullWidth value={userValues.email} required={true} />
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<MDInput variant='outlined' name='password' label='Password' onChange={(e) => {
							setUserValues({ ...userValues, [e.target.name]: e.target.value })
						}} fullWidth value={userValues.password} required={true} />
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<FormControl style={{ width: '100%' }} >
							<InputLabel>Role</InputLabel>
							<MuiSelect
								fullWidth
								labelId="order-test"
								id="demo-simple-select"
								label='Role'
								name='role'
								value={userValues?.role}
								onChange={(e) => {
									setUserValues({
										...userValues, role: e.target.value
									})
								}}
							>
								{/* <MenuItem value={'root'}>Root</MenuItem> */}
								<MenuItem value={'super-admin'}>Super Admin</MenuItem>
								<MenuItem value={'admin'}>Admin</MenuItem>
								<MenuItem value={'client'}>Client</MenuItem>
								<MenuItem value={'viewer'}>Viewer</MenuItem>
							</MuiSelect>
						</FormControl>
					</Grid>

				</Grid>
				<MDBox display='flex' justifyContent='center' alignItems='center'>

					<MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={addLoading}>Submit</MDButton>
				</MDBox>
			</Card> 
			}

{
	usersManage && usersManage?.viewUsers ?

			<Card sx={{ my: 5 }}>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<MDTypography variant="h6" color="white" textTransform='capitalize'>
						Organization Users
					</MDTypography>
				</MDBox>

				<TableContainer>
					<Table size="small" aria-label="a dense table">
						<thead>
							<TableRow>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Name
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Email
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Email Verified
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Phone
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 150 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Created at
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 150 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Actions
									</MDTypography>
								</TableCell>

							</TableRow>
						</thead>
						{loading ?
							<TableBody>
								<TableRow >
									<TableCell align="center" colSpan={6} >
										<MDBox
											display="flex"
											py={1}
											flexDirection='column'
											justifyContent="center"
											alignItems="center"
										>
											<MDProgress circular />
											<MDTypography variant="h6">
												Loading Users...
											</MDTypography>
										</MDBox>
									</TableCell>
								</TableRow>
							</TableBody>
							:
							<TableBody>
								{rows && rows.length > 0 ? rows.map((row, index) => {
									return <OrderRow2 fetchUsers={fetchUsers} darkMode={darkMode} row={row} rows={rows} key={`order-${index}`} 
									editUser={usersManage && usersManage?.editUsers}
									removeUser={usersManage && usersManage?.removeUsers}
									
									/>
								}) :
									<TableRow>
										<TableCell align="center" colSpan={11} >
											<MDTypography variant='caption' component='span' fontWeight="bold">no users found</MDTypography>
										</TableCell>
									</TableRow>
								}
							</TableBody>}
					</Table>
					{rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
						<Pagination color='info' variant="outlined"
							sx={{
								'& .MuiPagination-ul > li > button': {
									fontWeight: 400,
									color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
									borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								},
								'& .MuiPagination-ul > li > div': {
									color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								}
							}}
							count={pages} page={page} onChange={(e, value) => setPage(value)} />
					</MDBox>}
				</TableContainer>
			</Card>
			:
			<PermissionBox sx={{ my: 5 }} text={`to ${!usersManage?.addUsers && "add user and"} view users details`}/>
}

		</DashboardLayout>
	);
};




function OrderRow2({ row, fetchUsers,editUser,removeUser }) {
	const permissions = useSelector(state => state.auth.config)
	const dispatch = useDispatch();
	const [updatedPermissions, setUpdatedPermissions] = React.useState(row.permissions || permissions.userPermissions[row.role]);

	const [dialogOpen, setDialogOpen] = React.useState(null);
	const [userValues, setUserValues] = React.useState({
		email: row.email,
		password: row.password || "",
		role: row.role,
		phoneNumber: row.phoneNumber || "",
		displayName: row.displayName || ""
	});

	const [loading, setLoading] = React.useState(false);


	function deleteUser() {
		setLoading(true)
		dispatch(deleteOrgUser(row.id)).then(data => {
			if (data) {
				fetchUsers();
			}
			setLoading(false);

		}).catch(err => {
			setLoading(false)
		})
	}

	function updateUser(e) {
		e.preventDefault()
		setLoading(true)
		dispatch(updateOrgUser({ userId: row.id, ...userValues })).then(data => {
			if (data) {
				fetchUsers();
			}
			setLoading(false);

		}).catch(err => {
			setLoading(false)
		})
	}


	const handleUpdatePermissions = () => {
		setLoading(true)
		dispatch(updateOrgUser({ userId: row.id, ...userValues, permissions: updatedPermissions })).then(data => {
			if (data) {
				fetchUsers();
			}
			setLoading(false);

		}).catch(err => {
			setLoading(false)
		})
	};


	const allReadPermissionsData = {

		...permissions.userPermissions["all-read"]

	};

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
				<TableCell>
					<MDTypography variant="caption" fontWeight="bold" >
						{row.displayName}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row.email}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row.emailVerified ? "yes" : "no"}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
						{row?.phoneNumber}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
						{simpleDateparser(row?.createdAt)}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDBox display='flex' compoenent='span' gap={2}>
						{
							editUser &&
							<MDBox display='flex' compoenent='span' gap={2}>
							<MDButton color='info' variant='contained' size='small' onClick={() => setDialogOpen('update')}>Update</MDButton>
							<MDButton color='info' variant='contained' size='small' onClick={() => setDialogOpen('permissions')}>Permissions</MDButton>
							</MDBox>
						}
						{
							removeUser &&
						<MDButton color='error' variant='contained' size='small' onClick={() => setDialogOpen('remove')}>Remove</MDButton>
						}
					</MDBox>
				</TableCell>
			</TableRow>
			<Dialog onClose={() => setDialogOpen(false)} open={Boolean(dialogOpen)} maxWidth={dialogOpen === 'permissions' ? 'md' : 'sm'} fullWidth>
				<DialogTitle>{dialogOpen === 'permissions' ? "Permissions" : dialogOpen === 'update' ? 'Update User' : 'Remove'} </DialogTitle>
				{dialogOpen === 'permissions' && <DialogContent>
					<div>
						<PermissionsComponent

							permissions={permissions.userPermissions[row.role]}
							updatedPermissions={updatedPermissions}
							setUpdatedPermissions={setUpdatedPermissions} // Provide the permissions object for the specified role
							allReadPermissions={allReadPermissionsData} // Pass the all-read objects
						/>
						<DialogActions>
							<MDButton variant='outlined' color='error' disabled={loading} onClick={() => setDialogOpen(null)}>Cancel</MDButton>
							<MDButton variant='contained' color='error' loading={loading} onClick={() => handleUpdatePermissions()}>Submit</MDButton>
						</DialogActions>
					</div>
				</DialogContent>}
				{dialogOpen === 'remove' && <DialogContent>
					<MDBox >
						<MDTypography variant='h6'>Are you sure want to remove ths User?</MDTypography>
					</MDBox>
					<DialogActions>
						<MDButton variant='outlined' color='error' disabled={loading} onClick={() => setDialogOpen(null)}>Cancel</MDButton>
						<MDButton variant='contained' color='error' loading={loading} onClick={() => deleteUser()}>Confirm</MDButton>
					</DialogActions>
				</DialogContent>}
				{dialogOpen === 'update' && <DialogContent>
					<MDBox >
						<MDBox component='form' onSubmit={updateUser} mt={2}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='email' label='Email' onChange={(e) => {
										setUserValues({ ...userValues, [e.target.name]: e.target.value })
									}} fullWidth value={userValues.email} required={true} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='displayName' label='Name' onChange={(e) => {
										setUserValues({ ...userValues, [e.target.name]: e.target.value })
									}} fullWidth value={userValues.displayName || ""} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='phoneNumber' label='Phone Number' onChange={(e) => {
										setUserValues({ ...userValues, [e.target.name]: e.target.value })
									}} fullWidth value={userValues.phoneNumber || ""} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='password' label='Password' onChange={(e) => {
										setUserValues({ ...userValues, [e.target.name]: e.target.value })
									}} fullWidth value={userValues.password || ""} />
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<FormControl style={{ width: '100%' }} >
										<InputLabel>Role</InputLabel>
										<MuiSelect
											fullWidth
											labelId="order-test"
											id="demo-simple-select"
											label='Role'
											name='role'
											value={userValues?.role}
											onChange={(e) => {
												setUserValues({
													...userValues, role: e.target.value
												})
											}}
										>
											<MenuItem value={'root'}>Root</MenuItem>
											<MenuItem value={'super-admin'}>Super Admin</MenuItem>
											<MenuItem value={'admin'}>Admin</MenuItem>
											<MenuItem value={'client'}>Client</MenuItem>
											<MenuItem value={'viewer'}>Viewer</MenuItem>
										</MuiSelect>
									</FormControl>
								</Grid>

							</Grid>
							<DialogActions>
								<MDButton variant='outlined' color='error' disabled={loading} onClick={() => setDialogOpen(null)}>Cancel</MDButton>
								<MDButton variant='contained' color='error' type='submit' loading={loading} >Submit</MDButton>
							</DialogActions>
						</MDBox>


					</MDBox>

				</DialogContent>}
			</Dialog>
		</React.Fragment>
	)
}

export default UserManagement;

