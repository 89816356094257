import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { SET_USER_SUBDOMAIN } from "redux/actions/userDashboard.action";
import { SET_SELECTED_WEBSITE } from "redux/actions/userDashboard.action";
import { getWebsites } from "redux/actions/userDashboard.action";
import MDBox from "./MDBox";
import MDProgress from "./MDProgress";

export default function DomainWrapper({ children }) {

    const websites = useSelector(state => state.userDashboard.websites);
    const authRole = useSelector(state => state.auth.user);
    const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)
    const selectedWebsite = useSelector(state => state.userDashboard.selectedWebsite)

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    function fetchWebsites() {
        setLoading(true);
        dispatch(getWebsites()).then(data => {
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    React.useEffect(() => {
        if (authRole && authRole?.role !== 'accessblend-admin') {
            if (authRole?.organization?.isWebsiteAdded && !authRole.isFirstLogin) {
                fetchWebsites()
            }



        } else {
            setLoading(false)
        }
    }, [authRole])



    React.useEffect(() => {
        if (authRole && authRole?.role !== 'accessblend-admin') {

            if (selectedSubdomain && !selectedSubdomain?.verified) {
                navigate("/user/main/accessBlend/verify-dns")
            }

        }
    }, [authRole, selectedSubdomain])


    React.useEffect(() => {
        if (websites.length > 0) {
            if (selectedWebsite) {
                const web = websites.filter(w => w.id === selectedWebsite.id)[0]
                dispatch({
                    type: SET_SELECTED_WEBSITE,
                    payload: web
                })
                if (web && web.subdomains.length > 0) {
                    dispatch({
                        type: SET_USER_SUBDOMAIN,
                        payload: web.subdomains[0]
                    })
                }
            } else {
                dispatch({
                    type: SET_SELECTED_WEBSITE,
                    payload: websites[0]
                })
                if (websites[0].subdomains.length > 0) {
                    dispatch({
                        type: SET_USER_SUBDOMAIN,
                        payload: websites[0].subdomains[0]
                    })
                }
            }
        }
    }, [websites])

    return loading ? <MDBox display='flex' justifyContent='center' alignItems='center' height='100vh'><MDProgress circular size={32} /></MDBox> : children
}