import axios from 'axios';
import { BASE_URL } from 'Source/constant';
import { getFRToken } from 'services/firebase';
import { toast } from 'react-toastify';
import { getDashboardType } from 'utils/getDashboardType';
import { loadUserWithoutLoading } from './auth.action';

export const GET_ALL_USERS_ORDERS = 'GET_ALL_USERS_ORDERS';
export const GET_ALL_USERS_ORDERS_LOADING = 'GET_ALL_USERS_ORDERS_LOADING';
export const GET_USER_ANALYTICS = 'GET_USER_ANALYTICS';
export const GET_USER_ANALYTICS_ERROR = 'GET_USER_ANALYTICS_ERROR';
export const GET_USER_ANALYTICS_LOADING = 'GET_USER_ANALYTICS_LOADING';
export const SET_USER_WEBSITES = 'SET_USER_WEBSITES';
export const SET_SELECTED_WEBSITE = 'SET_SELECTED_WEBSITE';
export const SET_USER_SUBDOMAIN = 'SET_USER_SUBDOMAIN';

export const GET_USER_OTHER_WEBSITES = 'GET_USER_OTHER_WEBSITES';
export const GET_USER_OTHER_WEBSITES_LOADING = 'GET_USER_OTHER_WEBSITES_LOADING';
export const GET_USER_OTHER_WIDGETS = 'GET_USER_OTHER_WIDGETS';
export const GET_USER_OTHER_WIDGETS_LOADING = 'GET_USER_OTHER_WIDGETS_LOADING';


export const CREATE_USER_SUBSCRIPTION = 'CREATE_USER_SUBSCRIPTION';
export const UPDATE_USER_SUBSCRIPTION = 'UPDATE_USER_SUBSCRIPTION';





export const getUserAnalytics = () => async (dispatch) => {
  const token = await getFRToken();

  dispatch({
    type: GET_USER_ANALYTICS_LOADING
  })

  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/user-analytics`);
    if (res.data) {
      dispatch({
        type: GET_USER_ANALYTICS,
        payload: res.data.result
      })
      return res.data
    }

  } catch (error) {
    toast.error('Something went wrong');
    dispatch({
      type: GET_USER_ANALYTICS_ERROR
    })
  }
};

export const generateUserWidgetLink = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/saas/widget-orders/add`,
      { ...data },
    );
    if (res.data) {
      await dispatch(getWebsites()).then(data => {
        toast.success(res.data.message)
      })
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const addOrgUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/saas/org/users/add`,
      { ...data },
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const dnsVerify = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/saas/websites/verify`,
      { ...data },
    );
    if (res.data) {
      await dispatch(getWebsites()).then(data => {
        toast.success(res.data.message)
      })
      return res.data

    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getOrgUsers = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/org/users?page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getOrgAllUsers = () => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/org/users?page=1&limit=${100}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateOrgUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/saas/org/users/update`,
      { ...data },
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const deleteOrgUser = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(`${BASE_URL}/accessblend/saas/org/users/delete?userId=${id}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


//team

export const addOrgTeam = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/saas/org/teams/add`,
      { ...data },
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getOrgTeams = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/org/teams?page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateOrgTeam = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/saas/org/teams/update`,
      { ...data },
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const deleteOrgTeam = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(`${BASE_URL}/accessblend/saas/org/teams/delete?teamId=${id}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};




export const getAllUserOrders = (type, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_USERS_ORDERS_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/${type}/user-orders?page=${page}&limit=${5}`);
    if (res.data) {
      dispatch({
        type: GET_ALL_USERS_ORDERS,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAllUserTokenOrders = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/enterprise/user-token-orders?page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getWidgetOrders = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/widget-orders?page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAuditorOrders = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/auditor-orders?page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAuditorOrdersDetails = (orderId) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/auditor-orders/single-details?orderId=${orderId}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getWidgetOrdersDetails = (orderId) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/widget-orders/single-details?orderId=${orderId}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserOrderClusters = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(
      `${BASE_URL}/accessblend/enterprise/order-clusters?orderId=${orderId}`
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserOrderDetails = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/enterprise/user-orders/details?orderId=${orderId}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserInvoiceDetail = (invoiceId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(
      `${BASE_URL}/accessblend/enterprise/user-invoices/details?invoiceId=${invoiceId}`
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getUserInvoices = (type, page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/${type}/billing/user-invoices?limit=${5}&page=${page}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getBillingAllInvoices = (type, page, searchValue, selectedLabel, selectedValue) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/user-invoices?limit=${5}&page=${page}&type=${type}&${selectedValue}=${searchValue}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserWidgetInvoices = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/widget-invoices?limit=${5}&page=${page}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getPaymentMethods = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/customer/get-methods?limit=${5}&page=${page}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getClientSecretKey = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/customer/add-method`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    };
  }
};

export const getWebsiteReport = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/${getDashboardType()}/websites/scan?websiteId=${id}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const removePaymentMethod = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(`${BASE_URL}/customer/remove-method?paymentMethodId=${id}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateDefaultPaymentMethod = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/customer/default-method`, { ...data });
    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const userAuditorOrderAdd = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/saas/auditor-orders/add`, { ...data });
    if (res.data) {
      await dispatch(getWebsites()).then(data => {
        toast.success(res.data.message)
        return res.data
      })
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};



export const getAuditorOrderDetails = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/auditor-orders/details?websiteId=${id}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getWidgetOrderDetails = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/widget-orders/details?websiteId=${id}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserWidgetSubscriptions = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/widget-subscriptions?limit=${5}&page=${page}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const unsubscribeWidget = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(`${BASE_URL}/widget-subscriptions/cancel?websiteId=${id}`);
    if (res.data) {
      await dispatch(getWebsites()).then(data => {
        toast.success(res.data.message)
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};




export const getUserOrganizations = (type, page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/${type}/user-child-organizations?page=${page}&limit=5`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getUserSubscriptions = (type, page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/${type}/billing/user-subscriptions?page=${page}&limit=5`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getUserBillingSubscriptions = (type, page, searchValue, selectedLabel, selectedValue) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/user-subscriptions?page=${page}&limit=5&type=${type}&${selectedValue}=${searchValue}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserWebsites = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/${getDashboardType()}/websites-subdomains?page=${page}&limit=5`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};



export const getWidgetLink = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 5,
      ...data
    }
    const res = await axios.get(`${BASE_URL}/widget-link`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getWidgetSnippets = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 5,
      ...data
    }
    const res = await axios.get(`${BASE_URL}/widget-snippets`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const addWidgetSnippets = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/widget-snippets/add`, { ...data });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const deleteSnippet = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(`${BASE_URL}/widget-snippets/remove?widgetSnippetId=${id}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const evalauteWidgetPackage = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/evaluate-widget-package`, { ...data });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const subscribeAuditor = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/saas/auditor-order/subscription`, { ...data });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const unsubscribeAuditor = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/saas/auditor-orders/subscription/cancel`, { ...data });
    if (res.data) {
      await dispatch(getWebsites()).then(data => {
        toast.success(res.data.message)
      })
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const addUserWebsite = (website) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/${getDashboardType()}/websites/add`, { ...website });
    if (res.data) {

      dispatch(loadUserWithoutLoading()).then(data => {
        dispatch(getWebsites()).then(data => {
          if (data) {
            toast.success(res.data.message);
            return res.data;
          }
        })
      })

    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const checkWebsite = (website) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/${getDashboardType()}/websites/check`, { website });
    if (res.data) {
      return res.data;
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      if (error.hasOwnProperty("response")) {
        if (error.response.hasOwnProperty("data")) {
          if (error.response.data.hasOwnProperty("message")) {
            toast.error(error.response.data.message);
          }
        }
      } else {
        toast.error('Something went wrong');
      }
    }
  }
};


export const getWebsites = () => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 50,
      page: 1
    }
    const res = await axios.get(`${BASE_URL}/accessblend/${getDashboardType()}/websites`, { params });
    if (res.data) {
      const websites = res?.data?.result;
      dispatch({
        type: SET_USER_WEBSITES,
        payload: websites
      })

      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getOtherWebsites = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 5,
      page,
    }
    const res = await axios.get(`${BASE_URL}/accessblend/saas/websites-others`, { params });
    if (res.data) {
      const websites = res?.data?.result;
      dispatch({
        type: GET_USER_OTHER_WEBSITES,
        payload: websites
      })

      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getOtherWidgets = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 5,
      page,
    }
    const res = await axios.get(`${BASE_URL}/accessblend/saas/widget-others`, { params });
    if (res.data) {
      const websites = res?.data?.result;
      dispatch({
        type: GET_USER_OTHER_WEBSITES,
        payload: websites
      })

      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const createSubscription = (numberOfUsers) => async (dispatch) => {
  try {
    if (numberOfUsers >= 5) {

      const res =
        await axios.post(`${BASE_URL}/accessblend/saas/org/user-subcription`,
          { numberOfUsers },
        );
      if (res.data) {
        toast.success(res.data.message)
        return res.data
      }
    } else toast.error("Slots should be greater then 5");
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const cancelSubscription = (data) => async (dispatch) => {
  try {
    const res = await axios.delete(`${BASE_URL}/accessblend/saas/org/user-subcription/cancel`
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}