import React from 'react'
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch,useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import { Card,Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import PermissionBox from 'components/PermissionBox';
import { useMaterialUIController } from "context";

import {createSubscription,cancelSubscription} from "redux/actions/userDashboard.action"

const index = () => {
    const navigate=useNavigate()
    const dispatch=useDispatch()
	const {usersManage}=useSelector(state=>state.auth.user.permissions)
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    
    const [disableBtn,setDisableBtn]=React.useState(false)
    const [newNumberOfUsersSlots,setNewNumberOfUsersSlots]=React.useState(0)
    const [loading,setLoading]=React.useState({
        create:false,
        cancel:false
    })
    const [price,setPrice]=React.useState(null)
    const {numberOfUsers,numberOfUserSlots,userSubscription,isPaymentMethodAdded}=useSelector(state=>state.auth.user.organization)
    const {userPrice}=useSelector(state=>state.auth.config)

    React.useEffect(()=>{
        if(numberOfUserSlots){
            setNewNumberOfUsersSlots(numberOfUserSlots);
        }
    },[numberOfUserSlots])


    React.useEffect(()=>{
        if(newNumberOfUsersSlots>5){
const p=userPrice*(newNumberOfUsersSlots-5)
            setPrice(p)
        }else 
        setPrice(0)
    },[newNumberOfUsersSlots])

    React.useEffect(()=>{
        if(newNumberOfUsersSlots==numberOfUserSlots){
setDisableBtn(true)
        }else setDisableBtn(false)
    },[newNumberOfUsersSlots])

    function addSubscription(){
        if(isPaymentMethodAdded){
            setLoading({
                  ...loading,
                  create:true,
              })
          dispatch(createSubscription(newNumberOfUsersSlots)).then(data => {
              setLoading({
                  ...loading,
                  create:false,
              })
              window.location.reload(true)
          });
        }else{
            toast.info("Add payment method")
            navigate('/user/main/accessBlend/payment-methods')
        }

    }
    function removeSubscription(){
          setLoading({
                ...loading,
                cancel:true,
            })
        dispatch(cancelSubscription()).then(data => {
            setLoading({
                ...loading,
                cancel:false,
            })
        });

    }

  return (
    <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={5}>
                {
                    usersManage && usersManage?.userBilling ?

                <Card>
                    <MDBox>
                        <MDBox mt={2} sx={{paddingLeft:"30px"}}>
                            <MDBox mb={2}>
                                <MDBox display="flex" alignItems="center">

                         <MDTypography variant="h6">
                            Note : 
                        </MDTypography>
                            <span style={{marginLeft:"5px"}}>
                                <MDTypography variant="p" >

 First 5 users are free , will not be charged for them
                                </MDTypography>
                            </span>
                                </MDBox>
                        <MDTypography variant="p" >
                                
                                User price : <span style={{fontWeight:"bold",fontSize:"14px"}}> ${userPrice} </span>  <br/>
                                No of users slots available : <span style={{fontWeight:"bold",fontSize:"14px"}}>
                                   {numberOfUserSlots} </span>  <br/>
                                No of users slots used : <span style={{fontWeight:"bold",fontSize:"14px"}}>{numberOfUsers}</span>  <br/>
                         </MDTypography>
                            </MDBox>

                        <MDTypography variant="h6" >
                            Enter number of slots
                        </MDTypography>
                        </MDBox>
                        <MDBox mt={2} mb={1} sx={{padding:"10px 30px"}}>
                            <Grid container spacing={3} >
							<Grid item xs={12} md={6}>
                            <MDInput type='number' variant='outlined' name='numberOfUsers' label='Number of Slots' 
                            InputProps={{ inputProps: { min: 5 } }}
                            onChange={(e) => {
							setNewNumberOfUsersSlots( e.target.value )
						}} fullWidth value={newNumberOfUsersSlots} required={true} />
                            </Grid>
                            </Grid>
                            <MDBox mt={1}>
                            {
                                price >= 5 &&
                                <MDBox sx={{display:"flex",alignItems:"center",height:"100%"}}>
                                    <MDTypography variant="h5" color="black" >
                           Subscription Total :
                        </MDTypography>
                                <MDTypography sx={{ml:2}} variant="h5" color="black" >
                           {price}$
                        </MDTypography>
                                </MDBox>
                            }
                            
                            </MDBox>
                      
                        <MDBox sx={{display:"flex",alignItems:"center"}}>

                         <MDButton sx={{mt:3}} variant='contained' color='info' disabled={loading.cancel || loading.create || disableBtn} loading={loading.create} onClick={addSubscription}>{userSubscription ? "Update " :"Create "}Subscription</MDButton>
                         {
                            userSubscription &&
                          <MDButton sx={{mt:3,ml:3}} variant='contained' color='error' disabled={loading.cancel || loading.create} loading={loading.cancel} onClick={removeSubscription}>Cancel Subscription</MDButton>
                         }

                        </MDBox>
                        </MDBox>

                    </MDBox>
                
                    </Card>
                    :
                    <PermissionBox text={"to update user billing details"} />
                }
                    </MDBox>
            </DashboardLayout>
  )
}

export default index