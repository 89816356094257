import React from "react";
import { Link } from "react-router-dom";
import simpleDateparser from "utils/simpledateParser";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { getAllOrganizations, updateOrganization } from "redux/actions/adminSmokiDashboard.action";


const UserManagement = () => {

	const dispatch = useDispatch();
	const [page, setPage] = React.useState(1);
	const [pages, setPages] = React.useState(0);


	const [page2, setPage2] = React.useState(1);
	const [pages2, setPages2] = React.useState(0);


	const [loading, setLoading] = React.useState(false);
	const [addLoading, setAddLoading] = React.useState(false);


	const [userValues, setUserValues] = React.useState({
		email: '',
		role: '',
		password: ''
	})

	const { organizationsDetails, organizationsLoading, organizations } = useSelector(state => state.adminSmokiDashboard)

	function fetchOrganizations() {
		setLoading(true)
		dispatch(getAllOrganizations(page))
	}

	React.useEffect(() => {
		return () => {
			setPage(1)
			setPages(0)
			setLoading(false)
			setPage2(1)
			setPages2(0)
		}
	}, [])


	React.useEffect(() => {
		// fetchReports();
		fetchOrganizations();
	}, [page])

	React.useEffect(() => {
		if (organizationsDetails) {
			setPage(organizationsDetails.page)
			setPages(organizationsDetails.pages)

		}
	}, [organizationsDetails])

	const [controller] = useMaterialUIController();
	const { darkMode } = controller;



	return (
		<DashboardLayout>
			<DashboardNavbar />

			<Card sx={{ my: 5 }}>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<MDTypography variant="h6" color="white" textTransform='capitalize'>
						Organizations
					</MDTypography>
				</MDBox>

				<TableContainer>
					<Table size="small" aria-label="a dense table">
						<thead>
							<TableRow>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Id
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Name
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 100 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Number of User
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 100 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										User slots
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 250, maxWidth: 250 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Created at
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 600, maxWidth: 700 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Action
									</MDTypography>
								</TableCell>

							</TableRow>
						</thead>
						{organizationsLoading ?
							<TableBody>
								<TableRow >
									<TableCell align="center" colSpan={6} >
										<MDBox
											display="flex"
											py={1}
											flexDirection='column'
											justifyContent="center"
											alignItems="center"
										>
											<MDProgress circular />
											<MDTypography variant="h6">
												Loading Organizations...
											</MDTypography>
										</MDBox>
									</TableCell>
								</TableRow>
							</TableBody>
							:
							<TableBody>
								{organizations && organizations.length > 0 ? organizations.map((row, index) => {
									return <OrderRow2 fetchOrganizations={fetchOrganizations} darkMode={darkMode} row={row} rows={organizations} key={`org-${index}`} />
								}) :
									<TableRow>
										<TableCell align="center" colSpan={11} >
											<MDTypography variant='caption' component='span' fontWeight="bold">no users found</MDTypography>
										</TableCell>
									</TableRow>
								}
							</TableBody>}
					</Table>
					{organizations.length > 0 && !organizationsLoading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
						<Pagination color='info' variant="outlined"
							sx={{
								'& .MuiPagination-ul > li > button': {
									fontWeight: 400,
									color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
									borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								},
								'& .MuiPagination-ul > li > div': {
									color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								}
							}}
							count={pages} page={page} onChange={(e, value) => setPage(value)} />
					</MDBox>}
				</TableContainer>
			</Card>
		</DashboardLayout>
	);
};




function OrderRow2({ row, fetchOrganizations }) {
	const permissions = useSelector(state => state.auth.config)
	const dispatch = useDispatch();

	const [dialogOpen, setDialogOpen] = React.useState(null);
	const [orgValues, setOrgValues] = React.useState({
		orgId: row.id,
		orgName: row.orgName,
		numberOfUserSlots: row.numberOfUserSlots,
		billingDetails: row.billingDetails ? row.billingDetails : {
			"firstName": "",
			"lastName": "",
			"country": "",
			"address2": "",
			"city": "",
			"phone": "",
			"address1": "",
			"postcode": "",
			"company": "",
			"state": "",
			"email": ""
		}
	});

	const [loading, setLoading] = React.useState(false);

	function updateUser(e) {
		e.preventDefault()
		setLoading(true)
		dispatch(updateOrganization(orgValues)).then(data => {
			if (data) {
				fetchOrganizations();
			}
			setLoading(false);
		}).catch(err => {
			setLoading(false)
		})
	}







	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
				<TableCell>
					<MDTypography variant="caption" fontWeight="bold" >
						{row.id}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row.orgName}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row.numberOfUsers}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
						{row.numberOfUserSlots}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
						{simpleDateparser(row.createdAt)}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDBox display='flex' compoenent='span' gap={2}>
						<MDButton color='info' variant='contained' size='small' to={`/accessblend-admin/saas/accessBlend/user-management/update/${row.id}`} component={Link} 
						>Update</MDButton>

						<MDButton size='small' variant='contained' color='primary' to={`/accessblend-admin/saas/accessBlend/users/${row.id}`} component={Link}>View User</MDButton>
						<MDButton size='small' variant='contained' color='success' to={`/accessblend-admin/saas/accessBlend/user-management-invoices/${row.id}`} component={Link}>View Invoices</MDButton>

						{row.userSubscription ?
							(
								<MDButton size='small' variant='contained' color='success' to={`/accessblend-admin/saas/accessBlend/user-management-subscription/${row.id}`} component={Link}>View Subscriptions</MDButton>
							) : null
						}
					</MDBox>
				</TableCell>

			</TableRow>
			<Dialog onClose={() => setDialogOpen(false)} open={Boolean(dialogOpen)} maxWidth={dialogOpen === 'permissions' ? 'md' : 'sm'} fullWidth>
				<DialogTitle>{dialogOpen === 'permissions' ? "Permissions" : dialogOpen === 'update' ? 'Update Organization' : 'Remove'} </DialogTitle>

				{/* {dialogOpen === 'remove' && <DialogContent>
					<MDBox >
						<MDTypography variant='h6'>Are you sure want to remove ths User?</MDTypography>
					</MDBox>
					<DialogActions>
						<MDButton variant='outlined' color='error' disabled={loading} onClick={() => setDialogOpen(null)}>Cancel</MDButton>
						<MDButton variant='contained' color='error' loading={loading} onClick={() => deleteUser()}>Confirm</MDButton>
					</DialogActions>
				</DialogContent>} */}
				{dialogOpen === 'update' && <DialogContent>
					<MDBox >
						<MDBox component='form' onSubmit={updateUser} mt={2}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6} lg={6}>
									<MDInput variant='outlined' name='orgName' label='Organization Name' onChange={(e) => {
										setOrgValues({ ...orgValues, [e.target.name]: e.target.value })
									}} fullWidth value={orgValues.orgName} />
								</Grid>
								<Grid item xs={12} md={6} lg={6}>
									<MDInput variant='outlined' name='numberOfUserSlots' label='Users Slots' onChange={(e) => {
										setOrgValues({ ...orgValues, [e.target.name]: e.target.value })
									}} fullWidth value={orgValues.numberOfUserSlots} />
								</Grid>
							</Grid>
							<MDBox my={3}>
								<MDTypography variant='h5' textAlign='center' sx={{ my: 1 }}>Billing Details</MDTypography>

							</MDBox>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='firstName' label='First Name' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.firstName} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='lastName' label='Last Name' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.lastName} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='country' label='Country' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.country} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='city' label='City' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.city} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='phone' label='Phone' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.phone} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='company' label='Company' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.company} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='postcode' label='Postcode' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.postcode} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='email' label='Email' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.email} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<MDInput variant='outlined' name='state' label='State' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.state} />
								</Grid>
								<Grid item xs={12} md={12} lg={6}>
									<MDInput variant='outlined' name='address1' label='Address 1' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.address1} />
								</Grid>
								<Grid item xs={12} md={12} lg={6}>
									<MDInput variant='outlined' name='address2' label='Address 2' onChange={(e) => {
										setOrgValues({
											...orgValues,
											billingDetails: {
												...orgValues.billingDetails,
												[e.target.name]: e.target.value
											}
										})
									}} fullWidth value={orgValues.billingDetails.address2} />
								</Grid>

							</Grid>
							<DialogActions>
								<MDButton variant='outlined' color='error' disabled={loading} onClick={() => setDialogOpen(null)}>Cancel</MDButton>
								<MDButton variant='contained' color='error' type='submit' loading={loading} >Submit</MDButton>
							</DialogActions>
						</MDBox>


					</MDBox>

				</DialogContent>}
			</Dialog>
		</React.Fragment >
	)
}

export default UserManagement;

