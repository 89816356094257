function sidenavFormat(arr) {

    let result = [];
    const remainingItems = arr;

    const types = {};

    // Group remaining items by type
    for (const item of remainingItems) {
        if (item.type) {
            if (!types[item.type]) {
                types[item.type] = [];
            }
            types[item.type].push(item);
        } else {
            result.push(item);
        }
    }

    // Create nested objects for each type
    for (const type in types) {
        const items = types[type];
        const nestedObj = {
            name: type.charAt(0).toUpperCase() + type.slice(1),
            type,
            key: items[0].key,
            icon: items[0].icon,
            private: items[0].private,
            sidenav: items[0].sidenav,
            layout: items[0].layout,
            layoutName: items[0].layoutName,
            items: items
                .filter((item) => item.sidenav !== false)
                .map((child) => ({
                    ...child
                }))
        };
        if (items[0].hasOwnProperty("no")) {
            nestedObj.no = items[0].no;
        }

        if (nestedObj.items.length > 0) {
            result.splice(0, 0, nestedObj);
        }
    }


    // Include remaining objects without type and with sidenav not equal to false
    for (const item of arr) {
        if (!item.type && item.sidenav !== false) {
            const key = item.key;
            const isDuplicate = result.some((obj) => obj.key === key);
            if (!isDuplicate) {
                result.push(item);
            }
        }
    }

    result = result.sort((a, b) => {
        // Check if 'no' property exists in both objects
        const aHasNo = 'no' in a;
        const bHasNo = 'no' in b;

        // If both objects have 'no' property or none of them have 'no' property, sort by 'no' property
        if ((aHasNo && bHasNo) || (!aHasNo && !bHasNo)) {
            return (a.no || 0) - (b.no || 0);
        }

        // If only one of them has 'no' property, place the one without 'no' first
        return aHasNo ? -1 : 1;
    });

    // console.log(result);

    return result;
}



export default sidenavFormat;