import * as Actions from '../actions/adminUserManagement.action';

const initialState = {
    updateOrgUserLoading: false,
    getOrgUserLoading: false,
    addOrgUserLoading: false,
    deleteOrgUserLoading: false,
    organizationUsers: [],
    organizationUsersDetails: {
        "pages": 1,
        "limit": 1,
        "count": 1,
        "page": 1
    }
};

function userManagement(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case Actions.GET_USER_ORGANIZATIONS_LOADING:
            return {
                ...state,
                getOrgUserLoading: payload
            };

        case Actions.GET_USER_ORGANIZATIONS:
            return {
                ...state,
                getOrgUserLoading: false,
                organizationUsers: payload.result,
                organizationUsersDetails: {
                    "pages": payload.pages,
                    "limit": payload.limit,
                    "count": payload.count,
                    "page": payload.page
                }
            }
        case Actions.ADD_USER_ORGANIZATIONS_LOADING:
            return {
                ...state,
                addOrgUserLoading: payload
            }
        case Actions.UPDATE_USER_ORGANIZATION_LOADING:
            return {
                ...state,
                updateOrgUserLoading: payload
            }
        case Actions.DELETE_USER_ORGANIZATIONS_LOADING:
            return {
                ...state,
                deleteOrgUserLoading: payload
            }
        default:
            return state;

    }
}

export default userManagement;
