import React from "react";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import WidgetInvoices from "./WidgetInvoices";

const AdminWidgetInvoices = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <WidgetInvoices type="website-widget-order-subscription" name="Widget Paid" paid="true" />
            <WidgetInvoices type="website-widget-order-subscription" name="Widget Unpaid" paid="false" />
        </DashboardLayout>
    );
};

export default AdminWidgetInvoices; 