import React from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import Slide from '@mui/material/Slide';
import MDButton from 'components/MDButton';
import { addUserWebsite } from 'redux/actions/userDashboard.action';
import { Icon, IconButton } from '@mui/material';
import { useMaterialUIController } from "context";

export default function AddWebsiteDialog({ open, handleClose }) {
    const [website, setWebsite] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const dispatch = useDispatch();

    function handleAddWebsite(e) {
        e.preventDefault();
        setLoading(true)
        dispatch(addUserWebsite(website)).then(data => {
            if (data) {
                setLoading(false);
                setWebsite("")
                handleClose();
            } else {
                setLoading(false);
            }
        })
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="add website dialog"
            TransitionComponent={Transition}
            keepMounted
            maxWidth="sm"
            component="form"
            style={{ zIndex: 99999 }}
            sx={{
                '& .MuiDialog-paper': { width: '100%', maxWidth: 500, background: darkMode ? '#344767' : '#ffffff' },
                '& .MuiDialog-root': { zIndex: '99999 !important' }
            }}
            onSubmit={handleAddWebsite}
        >
            <DialogTitle id="alert-dialog-title">
                Add Website

                {handleClose ? (
                    <IconButton
                        aria-label="close"
                        disabled={loading}
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <MDBox mt={2}>
                    <MDInput
                        placeholder="Enter Website URL..."
                        type="text"
                        required={true}
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        size="large"
                        fullWidth
                    />
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton type="submit" loading={loading} variant="gradient" color="info"  >ADD</MDButton>
            </DialogActions>
        </Dialog>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});