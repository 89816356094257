import React from 'react';
import { useSelector } from 'react-redux';
//   components
import MDBox from "components/MDBox";
//   example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Websites from './websites';
import PermissionBox from 'components/PermissionBox';

function CustomWebsites() {
    const {others}=useSelector(state=>state.auth.user.permissions)

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                others && others?.viewOtherWebsites ?

            <MDBox py={3}>
                <>
                    <MDBox mt={1}>
                        <Websites type='saas' title='SaaS' />
                    </MDBox>
                    {/* <MDBox mt={6}>
                        <Websites type='enterprise' title='Enterprise' />
                    </MDBox> */}
                </>
            </MDBox>
            :
            <PermissionBox text={"to view other websites details"} />
            }
        </DashboardLayout>
    );
}

export default CustomWebsites;
