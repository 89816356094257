import React from "react";
import { useParams, Link } from 'react-router-dom';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "context";
import useDebounce from "hooks/useDebouce";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDInput from "components/MDInput";
import MuiSelect from '@mui/material/Select'
import Select from 'react-select';
import MDButton from "components/MDButton";
import { getOrgUser, addOrgUser, updateOrgUser, deleteOrgUser } from "redux/actions/adminUserManagement.action";

import PermissionsComponent from "./Permissions";

const UserManagement = ({ type, setType }) => {

	const dispatch = useDispatch();
	const params = useParams();
	const { updateOrgUserLoading, getOrgUserLoading, addOrgUserLoading, deleteOrgUserLoading, organizationUsers, organizationUsersDetails } = useSelector(state => state.adminUserManagement)
	const [page, setPage] = React.useState(1);
	const [pages, setPages] = React.useState(0);
	const [rows, setRows] = React.useState([]);

	const [loading, setLoading] = React.useState(false);
	const [addLoading, setAddLoading] = React.useState(false);
	const [searchValue, setSearchValue] = React.useState('');
	const [selectedLabel, setSelectedLabel] = React.useState('Name');
    const [selectedValue, setSelectedValue] = React.useState('displayName');




	const [userValues, setUserValues] = React.useState({
		orgId: params.orgId,
		email: '',
		role: '',
		password: ''
	})

	function fetchUsers() {
		if (page && params.orgId)
			dispatch(getOrgUser(page, params.orgId, searchValue, selectedLabel, selectedValue))
	}

	useDebounce(() => {
        fetchUsers()
    }, [searchValue, page], 500);



	function addUser(e) {
		e.preventDefault();
		setAddLoading(true)
		dispatch(addOrgUser(userValues)).then(data => {
			if (data) {
				setUserValues({
					email: '',
					role: '',
					password: ''
				})
				fetchUsers()
			}
		})
	}

	React.useEffect(() => {
		return () => {
			setPage(1)
			setPages(0)
			setRows([])
		}
	}, [])

	React.useEffect(() => {
		if (organizationUsersDetails) {
			setPage(organizationUsersDetails.page)
			setPages(organizationUsersDetails.pages)
		}
	}, [organizationUsersDetails])


	React.useEffect(() => {
		// fetchReports();
		fetchUsers();
	}, [page])

	const [controller] = useMaterialUIController();
	const { darkMode } = controller;



	return (
		<DashboardLayout>
			<DashboardNavbar />

			<Card component='form' onSubmit={addUser} sx={{ p: 2, my: 4 }}>
				<MDBox display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
					<MDTypography variant='h4' >Add User</MDTypography>
				</MDBox>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={4}>
						<MDInput variant='outlined' name='email' label='Email' onChange={(e) => {
							setUserValues({ ...userValues, [e.target.name]: e.target.value })
						}} fullWidth value={userValues.email} required={true} />
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<MDInput variant='outlined' name='password' label='Password' onChange={(e) => {
							setUserValues({ ...userValues, [e.target.name]: e.target.value })
						}} fullWidth value={userValues.password} required={true} />
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<FormControl style={{ width: '100%' }} >
							<InputLabel>Role</InputLabel>
							<MuiSelect
								fullWidth
								labelId="order-test"
								id="demo-simple-select"
								label='Role'
								name='role'
								value={userValues?.role}
								onChange={(e) => {
									setUserValues({
										...userValues, role: e.target.value
									})
								}}
							>
								{/* <MenuItem value={'root'}>Root</MenuItem> */}
								<MenuItem value={'super-admin'}>Super Admin</MenuItem>
								<MenuItem value={'admin'}>Admin</MenuItem>
								<MenuItem value={'client'}>Client</MenuItem>
								<MenuItem value={'viewer'}>Viewer</MenuItem>
							</MuiSelect>
						</FormControl>
					</Grid>

				</Grid>
				<MDBox display='flex' justifyContent='center' alignItems='center'>

					<MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={addOrgUserLoading}>Submit</MDButton>
				</MDBox>
			</Card>

			<Card sx={{ my: 5 }}>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"


					display='flex' justifyContent='space-between' alignItems='center'
				>
					<MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize', width: '100%' }}>
						Organization Users
					</MDTypography>

					<MDBox display='flex' flexDirection={{ xs: 'column', sm: 'row' }} alignItems='center' width='100%' >
                    <FormControl style={{ width: '100%', maxWidth: { xs: '100%', sm: 150 }}} >

							<Select

								id='change-api'
								defaultValue={{ label: 'Name', value: 'displayName' }}
								options={[
									{
										value: "displayName",
										label: 'Name'
									},
									{
										value: "email",
										label: 'Email'
									},
									{
										value: "role",
										label: 'Role'
									}
								]}
								isSearchable={false}
								onChange={(newValue) => {
									setSelectedLabel(newValue.label);
									setSelectedValue(newValue.value);
								}}
							/>
						</FormControl>
						<MDInput variant='outlined'

							sx={{
								'& .MuiInputBase-input': {
									color: 'white !important',

								},
								'&::placeholder': {
									color: '#999', // change the color of the placeholder text
									fontStyle: 'italic', // add italic font style to the placeholder text
									opacity: 0.5, // reduce the opacity of the placeholder text
								},
								marginLeft: { xs: 0, sm: 2 },
								marginTop: { xs: 2, sm: 0 },
							}}
							fullWidth placeholder='Enter search value' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
					</MDBox>
				</MDBox>

				<TableContainer>
					<Table size="small" aria-label="a dense table">
						<thead>
							<TableRow>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Name
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Email
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Team
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 200 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Role
									</MDTypography>
								</TableCell>
								<TableCell align="left" sx={{ minWidth: 150 }}>
									<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
										Action
									</MDTypography>
								</TableCell>

							</TableRow>
						</thead>
						{getOrgUserLoading ?
							<TableBody>
								<TableRow >
									<TableCell align="center" colSpan={6} >
										<MDBox
											display="flex"
											py={1}
											flexDirection='column'
											justifyContent="center"
											alignItems="center"
										>
											<MDProgress circular />
											<MDTypography variant="h6">
												Loading Users...
											</MDTypography>
										</MDBox>
									</TableCell>
								</TableRow>
							</TableBody>
							:
							<TableBody>
								{organizationUsers && organizationUsers.length > 0 ? organizationUsers.map((row, index) => {
									return <OrderRow2 fetchUsers={fetchUsers} darkMode={darkMode} row={row} rows={organizationUsers} key={`order-${index} `} page={page} />
								}) :
									<TableRow>
										<TableCell align="center" colSpan={11} >
											<MDTypography variant='caption' component='span' fontWeight="bold">no users found</MDTypography>
										</TableCell>
									</TableRow>
								}
							</TableBody>}
					</Table>
					{rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
						<Pagination color='info' variant="outlined"
							sx={{
								'& .MuiPagination-ul > li > button': {
									fontWeight: 400,
									color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
									borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								},
								'& .MuiPagination-ul > li > div': {
									color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								}
							}}
							count={pages} page={page} onChange={(e, value) => setPage(value)} />
					</MDBox>}
				</TableContainer>
			</Card>
		</DashboardLayout>
	);
};




function OrderRow2({ row, fetchUsers, page }) {
	const params = useParams()
	const permissions = useSelector(state => state.auth.config)
	const dispatch = useDispatch();
	const [updatedPermissions, setUpdatedPermissions] = React.useState(row.permissions || permissions.userPermissions[row.role]);
	const { updateOrgUserLoading, deleteOrgUserLoading } = useSelector(state => state.adminUserManagement)

	const [dialogOpen, setDialogOpen] = React.useState(null);
	const [userValues, setUserValues] = React.useState({
		email: row.email,
		password: row.password || "",
		role: row.role,
		phoneNumber: row.phoneNumber || "",
		displayName: row.displayName || ""
	});

	const [loading, setLoading] = React.useState(false);


	function deleteUser() {
		setLoading(true)
		dispatch(deleteOrgUser(row.id)).then(data => {
			if (data) {
				fetchUsers();
			}

		}).catch(err => {

		})
	}

	function updateUser(e) {
		e.preventDefault()
		setLoading(true)
		dispatch(updateOrgUser({ userId: row.id, ...userValues })).then(data => {
			if (data) {
				fetchUsers();
			}
			setLoading(false);

		}).catch(err => {
			setLoading(false)
		})
	}


	const handleUpdatePermissions = () => {
		setLoading(true)
		dispatch(updateOrgUser({ userId: row.id, ...userValues, permissions: updatedPermissions })).then(data => {
			if (data) {
				fetchUsers();
			}
			setLoading(false);

		}).catch(err => {
			setLoading(false)
		})
	};


	const allReadPermissionsData = {

		...permissions.userPermissions["all-read"]

	};

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
				<TableCell>
					<MDTypography variant="caption" fontWeight="bold" >
						{row.displayName}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row.email}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row.team}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
						{row?.role}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDBox display='flex' compoenent='span' gap={2}>
						<MDButton color='info' variant='contained' size='small' to={`/accessblend-admin/saas/accessBlend/users/update/${params.orgId}/${row.id}/${page}`} component={Link}>Update</MDButton>
						<MDButton color='error' variant='contained' size='small' onClick={() => setDialogOpen('remove')}>Remove</MDButton>
					</MDBox>
				</TableCell>
			</TableRow>
			<Dialog onClose={() => setDialogOpen(false)} open={Boolean(dialogOpen)} maxWidth={dialogOpen === 'permissions' ? 'md' : 'sm'} fullWidth>
				<DialogTitle>{dialogOpen === 'permissions' ? "Permissions" : dialogOpen === 'update' ? 'Update User' : 'Remove'} </DialogTitle>

				{dialogOpen === 'remove' && <DialogContent>
					<MDBox >
						<MDTypography variant='h6'>Are you sure want to remove ths User?</MDTypography>
					</MDBox>
					<DialogActions>
						<MDButton variant='outlined' color='error' disabled={loading} onClick={() => setDialogOpen(null)}>Cancel</MDButton>
						<MDButton variant='contained' color='error' loading={deleteOrgUserLoading} onClick={() => deleteUser()}>Confirm</MDButton>
					</DialogActions>
				</DialogContent>}

			</Dialog>
		</React.Fragment>
	)
}

export default UserManagement;

