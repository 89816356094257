import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, Grid, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import simpleDateparser from "utils/simpledateParser";
import { useDispatch } from "react-redux";
import { useMaterialUIController } from "context";
import { getUserWebsites } from "redux/actions/userDashboard.action";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { getCustomWebsites } from "redux/actions/adminSmokiDashboard.action";
import { addCustomWebsite } from "redux/actions/adminSmokiDashboard.action";
import CopyToClipboard from "react-copy-to-clipboard";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { deleteAdminReport, verifyWebsite, unAssignAdminCustomWebsite } from "redux/actions/adminSmokiDashboard.action";
import { FileCopyOutlined } from '@mui/icons-material';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Websites = () => {

    const [website, setWebsite] = React.useState("");

    const [addLoading, setAddLoading] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [rows, setRows] = React.useState(0);

    const dispatch = useDispatch();

    function fetchWebsites() {
        setLoading(true)
        dispatch(getCustomWebsites(page)).then(data => {
            if (data) {
                setRows(data.result)
                setPages(data.pages)
                setLoading(false)
            }
        });
    }

    React.useEffect(() => {
        fetchWebsites()
    }, [page])

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    function handleSubmit(e) {
        e.preventDefault();
        setAddLoading(true);

        dispatch(addCustomWebsite(website)).then(data => {
            if (data) {
                setAddLoading(false);
                fetchWebsites();
            } else {
                setAddLoading(false);
            }
        }).catch(err => {
            setAddLoading(false);
        })

    }

    return (

        <>
            <MDBox mb={5} component='form' onSubmit={handleSubmit}>
                <Card sx={{ p: 2 }}>
                    <MDTypography variant='h5' marginBottom>Add Custom Homepage Reports</MDTypography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <MDInput label='Enter Website' fullWidth onChange={(e) => setWebsite(e.target.value)} variant='outlined' placeholder='xyz.com' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDButton variant='contained' color='primary' type='submit' loading={addLoading}>Add</MDButton>
                        </Grid>
                    </Grid>
                </Card>
            </MDBox>
            <Card>
                <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white">
                        {/* All {title}  */}
                        Custom Websites
                    </MDTypography>
                </MDBox>

                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <thead>
                            <TableRow>
                                <TableCell align="left" sx={{ minWidth: 250 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Website
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 220 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Website ID
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 180 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Scan Status
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 220 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Assigned
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 180 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Report Link
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 230 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Created At
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 300 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Action
                                    </MDTypography>
                                </TableCell>
                            </TableRow>
                        </thead>
                        {loading ?
                            <TableBody>
                                <TableRow >
                                    <TableCell align="center" colSpan={6} >
                                        <MDBox
                                            display="flex"
                                            py={1}
                                            flexDirection='column'
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <MDProgress circular />
                                            <MDTypography variant="h6">
                                                Loading Reports...
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {rows && rows.length > 0 ? rows.map((row, index) => {
                                    return <OrderRow fetchWebsites={fetchWebsites} row={row} key={`order-${index}`} />
                                }) :
                                    <TableRow>
                                        <TableCell align="center" colSpan={11} >
                                            <MDTypography variant='caption' component='span' fontWeight="bold">no reports found</MDTypography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>}
                    </Table>
                    {rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                        <Pagination color='info' variant="outlined"
                            sx={{
                                '& .MuiPagination-ul > li > button': {
                                    fontWeight: 400,
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                    borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                },
                                '& .MuiPagination-ul > li > div': {
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                }
                            }}
                            count={pages} page={page} onChange={(e, value) => setPage(value)} />
                    </MDBox>}
                </TableContainer>
            </Card>
        </>

    );
};


function OrderRow({ row, fetchWebsites }) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [vl, setVL] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();




    function openConfirmationDialog() {
        setOpen(true);
    }

    function closeConfirmationDialog() {
        setOpen(false);
    }

    function handleConfirmation() {
        setLoading(true)
        dispatch(deleteAdminReport(row.id)).then(data => {
            setLoading(false)
            fetchWebsites()
        }).catch(err => {
            setLoading(false)
        })
    }

    function verifyIt() {
        setVL(true);
        dispatch(verifyWebsite({ userId: row.user, websiteId: row.id })).then(data => {
            setVL(false)
            fetchWebsites()
        }).catch(err => {
            setVL(false)
        })
    }

    function handleUnassign(e) {
        e.preventDefault();
        setLoading(true);
        dispatch(unAssignAdminCustomWebsite({
            websiteId: row.id,
        })).then(data => {
            setLoading(false)
            // getLink()
            fetchWebsites();
        }).catch(err => {
            setLoading(false)

        })
    }
    const [controller]=useMaterialUIController()
  const { darkMode } = controller;
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={row?.website}>
                        {row?.subDomainPrettyName}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                        {row?.scan.status}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    {row?.assigned === false || row?.assigned === null ? (
                        <MDTypography variant="caption" fontWeight="bold">
                            Not Assigned
                        </MDTypography>
                    ) : (
                        <MDTypography variant="caption" fontWeight="bold">
                             {row?.assigned}
                        </MDTypography>
                    )}
                </TableCell>

                {/* <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.organizationId}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.userId}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row?.price ? row.price : "N/A"}
                    </MDTypography>
                </TableCell> */}


                {row.scan.status === "completed" ? (
                    <TableCell align="left">
                        <MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={row?.scan?.link}>
                            Open Report
                        </MDTypography>
                        {<CopyToClipboard
                            text={row?.scan?.link}
                            onCopy={() => toast.success("Link Copied!")}
                        >
                            <FileCopyOutlined style={{ fontSize: '1.2rem', marginLeft: '10px', verticalAlign: 'middle', cursor: "pointer", color:darkMode?"white":"black" }} />

                        </CopyToClipboard>}
                    </TableCell>

                ) : (
                    <TableCell align="left">
                        <MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                            Scanning...
                        </MDTypography>
                    </TableCell>
                )}
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {simpleDateparser(row?.createdAt)}
                    </MDTypography>
                </TableCell>
                <TableCell align="center">
                    <MDBox display='flex' gap={2} alignItems='center'>
                        {!row.verified && <MDButton variant='contained' color='warning' loading={vl} onClick={verifyIt}>Verify Website</MDButton>}

                        <MDButton variant='contained' color='error' onClick={openConfirmationDialog}>Delete</MDButton>

                        {row?.assigned === false || row?.assigned === null? (
                            <MDButton variant='contained' color='success' onClick={() => navigate(`/accessblend-admin/saas/accessBlend/assign/custom-website/${row.id}`)}>
                                assign
                            </MDButton>
                        ) : (
                            <MDButton variant='contained' color='success' onClick={handleUnassign}>
                                unassign
                            </MDButton>
                        )}
                    </MDBox>
                </TableCell>

            </TableRow>
            <Dialog open={open} onClose={loading ? null : closeConfirmationDialog}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete?</p>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={closeConfirmationDialog} disabled={loading}>Cancel</MDButton>
                    <MDButton onClick={handleConfirmation} color="primary" loading={loading}>Confirm</MDButton>
                </DialogActions>
            </Dialog>



        </React.Fragment>
    )
}

export default Websites; 