import axios from 'axios';
import { BASE_URL } from 'Source/constant';
import { getFRToken } from 'services/firebase';
import { toast } from 'react-toastify';

export const GET_USER_ANALYTICS = 'GET_USER_ANALYTICS';
export const GET_USER_ANALYTICS_ERROR = 'GET_USER_ANALYTICS_ERROR';
export const GET_USER_ANALYTICS_LOADING = 'GET_USER_ANALYTICS_LOADING';

export const GET_SYSTEM_STATUSES = 'GET_SYSTEM_STATUSES';
export const GET_SYSTEM_STATUSES_LOADING = 'GET_SYSTEM_STATUSES_LOADING';

export const GET_WEBSITE_BACKUPS = 'GET_WEBSITE_BACKUPS';
export const GET_WEBSITE_BACKUPS_LOADING = 'GET_WEBSITE_BACKUPS_LOADING';
export const SEND_HELP_QUERY_LOADING = 'SEND_HELP_QUERY_LOADING';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS';
export const FILTER_NOTIFICATIONS = 'FILTER_NOTIFICATIONS';
export const FILTER_NOTIFICATIONS_LOADING = 'FILTER_NOTIFICATIONS_LOADING';

export const GET_WEBSITE_DATABASES = 'GET_WEBSITE_DATABASES';
export const GET_WEBSITE_DATABASES_LOADING = 'GET_WEBSITE_DATABASES_LOADING';

export const getUserAnalytics = () => async (dispatch) => {
  const token = await getFRToken();

  dispatch({
    type: GET_USER_ANALYTICS_LOADING
  })

  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/user/analytics`);
    if (res.data) {
      dispatch({
        type: GET_USER_ANALYTICS,
        payload: res.data.result
      })
      return res.data
    }

  } catch (error) {
    toast.error('Something went wrong');
    dispatch({
      type: GET_USER_ANALYTICS_ERROR
    })
  }
};



export const getWebsiteDatabases = (websiteId) => async (dispatch) => {
  dispatch({
    type: GET_WEBSITE_DATABASES_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/user/files?websiteId=${websiteId}&page=${1}&limit=${1000}`
    );
    if (res.data) {
      dispatch({
        type: GET_WEBSITE_DATABASES,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const sendHelpQuery = (data) => async (dispatch) => {
  dispatch({
    type: SEND_HELP_QUERY_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/help-and-support`,
      { ...data }
    );

    if (res.data) {
      dispatch({
        type: SEND_HELP_QUERY_LOADING
      });
      toast.success(res.data.message);

      return res.data
    }
  } catch (error) {
    dispatch({
      type: SEND_HELP_QUERY_LOADING
    })
    toast.error('Something went wrong');
  }
};

export const getNotifications = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      page,
      limit: 3
    }

    const res = await axios.get(`${BASE_URL}/user/notifications`, {
      params,

    });

    if (res.data) {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data
      });
    }
  } catch (err) {

  }
}

export const hideNotification = (id) => async (dispatch) => {
  const token = await getFRToken();

  dispatch({
    type: FILTER_NOTIFICATIONS_LOADING,
  });

  axios.defaults.headers.common['Authorization'] = token;

  try {
    const res = await axios.post(`${BASE_URL}/user/dismiss-notification`, {
      id
    });

    dispatch({
      type: FILTER_NOTIFICATIONS,
      payload: id
    });

  } catch (err) {
    dispatch({
      type: FILTER_NOTIFICATIONS_LOADING,
    });
  }
}

export const getHealthStatuses = (dashboard) => async (dispatch) => {
  dispatch({
    type: GET_SYSTEM_STATUSES_LOADING
  })
  try {
    const res = await axios.get(`${BASE_URL}/wordpress/health-statuses`
    );
    if (res.data) {
      dispatch({
        type: GET_SYSTEM_STATUSES,
        payload: res.data.result
      })
      return res.data
    }

  } catch (error) {
    dispatch({
      type: GET_SYSTEM_STATUSES_LOADING
    })
  }
};
