import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, Dialog, Icon, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import simpleDateparser from "utils/simpledateParser";
import { useDispatch } from "react-redux";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { getWidgetOrders } from "redux/actions/adminSmokiDashboard.action";
import { deleteOrder } from "redux/actions/adminSmokiDashboard.action";
import { useMaterialUIController } from "context";
import useDebounce from "hooks/useDebouce";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopyOutlined } from '@mui/icons-material';
import { toast } from "react-toastify";

const WidgetOrdersTable = ({ status }) => {

    const dispatch = useDispatch();
    const [page2, setPage2] = React.useState(1);

    const [allOrders, setAllOrders] = React.useState([]);
    const [allOrdersPages, setAllOrdersPages] = React.useState([]);
    const [allOrdersLoading, setAllOrdersLoading] = React.useState(true);

    const [searchValue, setSearchValue] = React.useState('');


    // Using MaterialUIController to get the current theme mode
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    // Defining a function to fetch all orders with search and pagination functionality
    function fetchOrders() {
        setAllOrdersLoading(true)
        dispatch(getWidgetOrders(page2, status)).then(data => {
            setAllOrdersPages(data.pages)
            setAllOrders(data.result)
            setAllOrdersLoading(false)
        }).catch(err => {
            setAllOrdersLoading(false)
        })
    }

    // Using useDebounce hook to debounce the fetchOrders function call with searchValue and page parameters
    useDebounce(() => {
        fetchOrders(searchValue)
    }, [searchValue, page2], 500);


    return (
        <MDBox>
            <Card sx={{ mt: 7 }}>
                <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    display='flex' justifyContent='space-between' alignItems='center'
                >
                    <MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize', width: '100%' }}>
                        Widget {status}  Orders
                    </MDTypography>
                </MDBox>
                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <thead>
                            <TableRow>
                                <TableCell align="left" sx={{ width: 60 }}>
                                    {""}
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 190 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        ID
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 150 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Status
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Total
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Order Type
                                    </MDTypography>
                                </TableCell>
                                {/* <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Total Tax
                                    </MDTypography>
                                </TableCell> */}
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Total Discount
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 180 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Invoice Link
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        ORG Name
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Name
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Phone
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Email
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 230 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Created At
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200, maxWidth: 270 }}>

                                </TableCell>
                            </TableRow>
                        </thead>
                        {allOrdersLoading ?
                            <TableBody >
                                <TableRow >
                                    <TableCell align="center" colSpan={9} >
                                        <MDBox

                                            display="flex"
                                            py={1}
                                            flexDirection='column'
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <MDProgress circular />
                                            <MDTypography variant="h6">
                                                Loading Orders...
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody >
                                {allOrders && allOrders.length > 0 ? allOrders.map((row, index) => {
                                    return <OrderRow darkMode={darkMode} row={row} allOrders={allOrders} key={`order-${index}`} fetchOrders={fetchOrders} />
                                }) :
                                    <TableRow >
                                        <TableCell align="center" colSpan={9} >
                                            <MDTypography variant='caption' component='span' fontWeight="bold">no orders found</MDTypography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>}
                    </Table>
                    {allOrders.length > 0 && !allOrdersLoading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                        <Pagination color='info' variant="outlined"
                            sx={{
                                '& .MuiPagination-ul > li > button': {
                                    fontWeight: 400,
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                    borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                },
                                '& .MuiPagination-ul > li > div': {
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                }
                            }}
                            count={allOrdersPages} page={page2} onChange={(e, value) => setPage2(value)} />
                    </MDBox>}
                </TableContainer>
            </Card>
        </MDBox>
    );
};


function OrderRow({ row, darkMode, fetchOrders }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
 

    const [open, setOpen] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false)
    const [actionModal, setActionModal] = React.useState({
        open: false,
        orderId: null
    });

    // This function is used to open the modal that confirms cancellation of an invoice
    function openCancelInvoiceModal(data) {
        // Set the actionModal state variable to the data passed as a parameter
        setActionModal(data);
    }

    // This function is called when the delete button is clicked in the confirmation modal
    function handleDelete() {
        // Set deleteLoading state variable to true to display loading spinner
        setDeleteLoading(true);
        // Dispatch the deleteOrder action, passing in the orderId from the actionModal state variable
        dispatch(deleteOrder(actionModal.orderId)).then(data => {
            // Once the deleteOrder action is complete, set deleteLoading state variable back to false to hide the loading spinner
            setDeleteLoading(false);
            // Open the cancel invoice modal with open set to false and orderId set to null to close the modal
            openCancelInvoiceModal({
                open: false,
                orderId: null
            });
            // Fetch the orders again to update the list after the order has been deleted
            fetchOrders(1);
        })
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <Icon fontSize="medium">expand_more</Icon> : <Icon fontSize="medium">expand_less</Icon>}
                    </IconButton>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row.status}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        ${row.total}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {!row.test ? "Live Order" : "Test Order"}
                    </MDTypography>
                </TableCell>
                {/* <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.total_tax}
                    </MDTypography>
                </TableCell> */}
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.totalDiscounted}
                    </MDTypography>
                </TableCell>

                {row.invoiceLink ? (
                    <TableCell align="left">
                        <MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={row?.invoiceLink}>
                            Open Invoice
                        </MDTypography>
                        {<CopyToClipboard
                            text={row?.invoiceLink}
                            onCopy={() => toast.success("Link Copied!")}
                        >
                            <FileCopyOutlined style={{ fontSize: '1.2rem', marginLeft: '10px', verticalAlign: 'middle', cursor:"pointer", color:darkMode?"white":"black" }} />

                        </CopyToClipboard>}
                    </TableCell>

                ) : (
                    <TableCell align="left">
                        <MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                           
                        </MDTypography>
                    </TableCell>
                )}
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.orgName}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.name}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.phone}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.email}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {simpleDateparser(row?.createdAt)}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDBox display='flex' gap={2}>
                        <MDButton variant='contained' color='success' size='small' onClick={() => navigate(`/accessblend-admin/saas/accessBlend/view-widget-order/${row.id}`)}>View Order</MDButton>
                        {/* <MDButton variant='contained' color='error' size='small' onClick={() => openCancelInvoiceModal({ open: true, orderId: row.id })}> Cancel</MDButton> */}
                    </MDBox>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12} >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <MDBox display='flex' alignItems='center' sx={{ mt: 2 }}>
                            <MDTypography variant="h6" component="div">
                                Billing  Details
                            </MDTypography>
                        </MDBox>
                        <MDBox sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    <TableRow >
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>First Name </MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Last Name</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Company</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Address 1</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Address 2</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>City</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>State</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Post Code</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Country</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Email</MDTypography>
                                        </TableCell>
                                        <TableCell >
                                            <MDTypography component='span' variant='caption' fontWeight='medium' color={darkMode ? 'white' : 'info'}>Phone</MDTypography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                {/* </th> */}
                                <TableBody>
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        <TableCell >
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.firstName}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.lastName}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.company}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.address1}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.addres}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.city}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.state}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.postcode}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.country}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.email}
                                            </MDTypography>
                                        </TableCell>
                                        <TableCell>
                                            <MDTypography variant='caption' fontWeight='medium'  >
                                                {row?.billing?.phone}
                                            </MDTypography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </MDBox>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


export default WidgetOrdersTable; 