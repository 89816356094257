import { Add } from "@mui/icons-material";
import { FormControl, Icon, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SET_USER_SUBDOMAIN } from "redux/actions/userDashboard.action";
import { SET_SELECTED_WEBSITE } from "redux/actions/userDashboard.action";
import AddWebsiteDialog from "./AddWebsiteDialog";
import {
  websitesRow,
} from "examples/Navbars/DashboardNavbar/styles";


export default function WebsiteSelector() {
    const user = useSelector(state => state.auth.user)

    const websites = useSelector(state => state.userDashboard.websites)
    const selectedWebsite = useSelector(state => state.userDashboard.selectedWebsite)
    const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)

    const [subdomains, setSubdomains] = React.useState([])


    const [openWebsiteDialog, setOpenWebsiteDialog] = React.useState(false);

    const dispatch = useDispatch();

    const handleClose = () => {
        setOpenWebsiteDialog(false);
    };

    const handleClickOpen = () => {
        setOpenWebsiteDialog(true);
    };

    React.useEffect(() => {
        if (selectedWebsite) {
            console.log(selectedWebsite)
            if (selectedWebsite.subdomains.length > 0) {
                setSubdomains(selectedWebsite.subdomains)
            }

        }
    }, [selectedWebsite])
    
    function handleWebsite(e) {
        const domain = websites.filter(web => web.id === e.target.value)[0];
        dispatch({
            type: SET_SELECTED_WEBSITE,
            payload: domain
        })
        if (domain?.subdomains?.length > 0) {
            setSubdomains(domain?.subdomains);
            dispatch({
                type: SET_USER_SUBDOMAIN,
                payload: domain?.subdomains[0]
            })
        } else {
            setSubdomains([]);
            dispatch({
                type: SET_USER_SUBDOMAIN,
                payload: null
            })
        }
    }


    function handleSubdomain(e) {
        const subdomain = e.target.value;
        dispatch({
            type: SET_USER_SUBDOMAIN,
            payload: subdomain
        })
        // setSelectedSubdomain(subdomain)
    }

    if (user.role !== 'accessblend-admin' && !user.isFirstLogin) {
        return (
            <MDBox display='flex' alignItems='center'
            sx={(theme) => websitesRow(theme)}
            
            gap={1} my={0.9}>
                { websites && websites?.length > 0 ?
                    <FormControl fullWidth sx={{ minWidth: 250, maxWidth: 300 }}>
                        <InputLabel id="cluster-status">Select Website</InputLabel>
                        <Select
                            labelId="cluster-status"
                            fullWidth
                            id="cluster-status-select"
                            name='secure'
                            value={selectedWebsite ? selectedWebsite.id :""}
                            label="Select Website"
                            onChange={handleWebsite}
                        >
                            {
                                websites.map((website, index) => {
                                    return (
                                        <MenuItem value={website.id} key={website.id}>{website?.domainPrettyName}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    :

                    <MDBox display='flex' justifyContent='center' >
                        <MDButton variant='contained' color='info' endIcon={<Add />} component={Link} to='/user/main/accessBlend/add-website' >
                            Add Website
                        </MDButton>
                    </MDBox>
                }
                {subdomains.length > 0 && websites?.length > 0 && <FormControl fullWidth sx={{ minWidth: 250, maxWidth: 300 }}>
                    <InputLabel id="cluster-status">Select Subdomain</InputLabel>
                    <Select
                        fullWidth
                        labelId="cluster-status"
                        id="cluster-status-select"
                        name='secure'
                        value={selectedSubdomain}
                        label="Select Subdomain"
                        onChange={handleSubdomain}
                    >
                        {
                            subdomains.map((subdomain, index) => {
                                return (
                                    <MenuItem value={subdomain} key={subdomain.id}>{subdomain.subDomainPrettyName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>}

                {websites?.length > 0 && <IconButton component={Link} to='/user/main/accessBlend/add-website'><Icon>add</Icon></IconButton>}
                <AddWebsiteDialog open={openWebsiteDialog} handleClose={handleClose} />
            </MDBox>
        )
    }
    else {
        return <></>
    }
}
