import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import Pagination from '@mui/material/Pagination';
import colors from 'assets/theme/base/colors';

import { Card, Checkbox, Dialog, Icon, TableContainer } from '@mui/material';
import MDProgress from 'components/MDProgress';

import simpleDateparser from 'utils/simpledateParser';
import { useDispatch } from 'react-redux';

import MDButton from 'components/MDButton';
import { useNavigate } from 'react-router-dom';
import { useMaterialUIController } from 'context';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { childOrganizationAction } from 'redux/actions/adminSmokiDashboard.action';
import { getChildOrganizations } from 'redux/actions/adminSmokiDashboard.action';
import MDInput from 'components/MDInput';
import { childOrganizationLickey } from 'redux/actions/adminSmokiDashboard.action';
import { childOrganizationEdit } from 'redux/actions/adminSmokiDashboard.action';
import { getAdminClustersList } from 'redux/actions/adminSmokiDashboard.action';
import { childOrganizationLicMode } from 'redux/actions/adminSmokiDashboard.action';
import { getDashboardType } from 'utils/getDashboardType';
import { v4 as uuidv4 } from 'uuid';
import { changeLickey } from 'redux/actions/adminSmokiDashboard.action';

const ChildOrganizations = () => {
  const [loading, setLoading] = React.useState(false);

  const [org, setOrg] = React.useState({
    orgName: '',
    email: '',
    password: '',
    websiteQuotas: 10,
    role: '',
    demo: false,
    trial: false,
    partialScanLinks: 0,
  });

  const [openai, setOpenai] = React.useState({
    openai_key: '',
    openai_model: '',
    openai_tokens: '',
  });

  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const [rows, setRows] = React.useState([]);

  const [addLoading, setAddLoading] = React.useState(false);

  const [clusters, setClusters] = React.useState([]);
  const [selectedCluster, setSelectedCluster] = React.useState('');
  const [gettingClusters, setGettingClusters] = React.useState(true);

  // const [orgConfigLoading, setConfigLoading] = React.useState(false);

  const [orgConfig, setOrgConfig] = React.useState({
    smtpConfig: {
      hostname: '',
      secure: '',
      username: '',
      password: '',
      port: 0,
      emailFrom: '',
    },
    s3Config: {
      keyId: '',
      secretKey: '',
      region: '',
      bucketName: '',
      endpoint: '',
    },
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // This function fetches child organizations from the server and updates state accordingly.
  function fetchOrganizations() {
    setLoading(true); // Set loading state to true.
    dispatch(getChildOrganizations(page)).then((data) => {
      // Dispatch an action to get child organizations, passing in the current page number. When the promise resolves, update state with the returned data.
      if (data) {
        setRows(data.result); // Set the rows of the table to the returned organizations.
        setPages(data.pages); // Set the number of pages for pagination.
        setLoading(false); // Set loading state to false.
      }
    });
  }

  // This function updates the 'org' state object when a user types into an input field.
  function handleOrg(e) {
    setOrg({
      ...org,
      [e.target.name]: e.target.value,
    });
  }

  // This function adds a new organization to the server and updates state accordingly.
  function addOrganizations(e) {
    e.preventDefault(); // Prevent default form submission behavior.
    setAddLoading(true); // Set loading state to true.
    dispatch(
      childOrganizationAction({
        // Dispatch an action to add a child organization, passing in the organization details, cluster ID, SMTP configuration, and S3 configuration.
        action: 'add',
        details: {
          ...org,
          ...openai,
          clusterId: selectedCluster,
          smtpConfig: orgConfig.smtpConfig,
          s3Config: orgConfig.s3Config,
        },
      })
    )
      .then((data) => {
        // When the promise resolves, set loading state to false and fetch the updated organizations list.
        setAddLoading(false);
        fetchOrganizations();
      })
      .catch((err) => {
        setAddLoading(false); // If there is an error, set loading state to false.
      });
  }

  // This function fetches a list of available clusters from the server and updates state accordingly.
  async function fetchClsuters() {
    setGettingClusters(true); // Set gettingClusters state to true.
    dispatch(getAdminClustersList(1, 100))
      .then((data) => {
        // Dispatch an action to get a list of available clusters. When the promise resolves, set state with the returned data.
        setClusters(data.result);
      })
      .then((data) => {
        // After updating state, set gettingClusters state to false.
        setGettingClusters(false);
      })
      .catch((err) => {
        // If there is an error, set gettingClusters state to false.
        setGettingClusters(false);
      });
  }

  React.useEffect(() => {
    return () => {
      setOrgConfig({
        smtpConfig: {
          hostname: '',
          secure: '',
          username: '',
          password: '',
          port: 0,
          emailFrom: '',
        },
        s3Config: {
          keyId: '',
          secretKey: '',
          region: '',
          bucketName: '',
          endpoint: '',
        },
      });
      setOpenai({
        openai_key: '',
        openai_model: '',
        openai_tokens: '',
      });
      setLoading(false);
      setOrg({
        orgName: '',
        email: '',
        password: '',
        websiteQuotas: 10,
        role: '',
        demo: false,
        trial: false,
      });
      setPage(1);
      setPages(0);
      setRows([]);
      setAddLoading(false);
      setClusters([]);
      setSelectedCluster('');
    };
  }, []);

  React.useEffect(() => {
    fetchClsuters();
    fetchOrganizations();
  }, [page]);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // This function is used to handle changes in SMTP configuration for an organization
  function handleOrgSmtp(e) {
    setOrgConfig({
      // Use the spread operator to copy the existing orgConfig object
      ...orgConfig,
      // Use the spread operator again to copy the existing smtpConfig object
      smtpConfig: {
        ...orgConfig.smtpConfig,
        // Use computed property names to dynamically set the value of the changed input field
        [e.target.name]: e.target.value,
      },
    });
  }

  // This function is used to handle changes in S3 configuration for an organization
  function handleOrgS3Config(e) {
    setOrgConfig({
      // Use the spread operator to copy the existing orgConfig object
      ...orgConfig,
      // Use the spread operator again to copy the existing s3Config object
      s3Config: {
        ...orgConfig.s3Config,
        // Use computed property names to dynamically set the value of the changed input field
        [e.target.name]: e.target.value,
      },
    });
  }

  // This function is used to handle changes in OpenAI configuration
  function handleOpenAi(e) {
    setOpenai({
      // Use the spread operator to copy the existing openai object
      ...openai,
      // Use computed property names to dynamically set the value of the changed input field
      [e.target.name]: e.target.value,
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br />
      <MDBox myt={3} mb={6}>
        {!gettingClusters ? (
          <Card style={{ marginBottom: 20, marginTop: 20, padding: 20 }}>
            <FormControl style={{ width: '100%', maxWidth: 400 }}>
              <InputLabel id='cluster-selection'>
                Select Cluster To Perform Action
              </InputLabel>
              <Select
                labelId='cluster-selection'
                id='demo-simple-select'
                name='clusterId'
                value={selectedCluster}
                label='Select Cluster To Perform Action'
                onChange={(e) => setSelectedCluster(e.target.value)}
              >
                {clusters.map((cluster) => {
                  return (
                    <MenuItem key={cluster.id} value={cluster?.id}>
                      {cluster?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Card>
        ) : (
          <MDTypography variant='h5'>Getting Clusters...</MDTypography>
        )}
      </MDBox>
      <MDTypography variant='h5'>
        * Users having same organization name in user panel, will be synced on
        adding organization.
      </MDTypography>
      <br />

      <MDBox mb={6} component='form' onSubmit={addOrganizations}>
        <MDBox component='fieldset' sx={{ p: 2, borderRadius: 2 }}>
          <legend>
            <MDTypography variant='h5'> Add Organization </MDTypography>
          </legend>





          <Card style={{ marginBottom: 20, marginTop: 20, padding: 20 }}>
            <MDTypography variant='h1' style={{ fontSize: 22 }}>
              Organization Configuration
            </MDTypography>

            <MDBox my={3}>
              <MDTypography
                variant='h1'
                style={{ marginBottom: 10, fontSize: 18 }}
              >
                Organizations details
              </MDTypography>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <MDInput
                    required={true}
                    value={org.orgName}
                    name='orgName'
                    label='Organization Name'
                    onChange={handleOrg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDInput
                    required={true}
                    value={org.email}
                    name='email'
                    type='email'
                    label='Email'
                    onChange={handleOrg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDInput
                    required={true}
                    value={org.password}
                    name='password'
                    label='Password'
                    onChange={handleOrg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDInput
                    required={true}
                    type='number'
                    value={org?.partialScanLinks}
                    name='partialScanLinks'
                    label='Partial Scan Links'
                    onChange={handleOrg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDInput
                    required={true}
                    InputProps={{ inputProps: { min: 1 } }}
                    value={org.websiteQuotas}
                    type='number'
                    label='Website Quotas'
                    name='websiteQuotas'
                    onChange={handleOrg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-selection'>Demo</InputLabel>
                    <Select
                      labelId='demo-selection'
                      id='demo-simple-select'
                      name='demo'
                      value={org.demo}
                      label='Demo'
                      onChange={(e) => setOrg({ ...org, demo: e.target.value })}
                      required={true}
                    >
                      <MenuItem value={'true'}>True</MenuItem>
                      <MenuItem value={'false'}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id='trial-selection'>Trial</InputLabel>
                    <Select
                      labelId='trial-selection'
                      id='trial-simple-select'
                      name='trial'
                      value={org.trial}
                      label='Trial'
                      onChange={(e) => setOrg({ ...org, trial: e.target.value })}
                      required={true}
                    >
                      <MenuItem value={'true'}>True</MenuItem>
                      <MenuItem value={'false'}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id='role-selection'> Select Role</InputLabel>
                    <Select
                      labelId='role-selection'
                      id='role-simple-select'
                      name='role'
                      value={org.role}
                      label='Select Role'
                      onChange={(e) => setOrg({ ...org, role: e.target.value })}
                      required={true}
                    >
                      {/* <MenuItem value={'root'}>Root</MenuItem>
                                    <MenuItem value={'super-admin'}>Super Admin</MenuItem> */}
                      <MenuItem value={'admin'}>Admin</MenuItem>
                      <MenuItem value={'client'}>Client</MenuItem>
                      <MenuItem value={'viewer'}>Viewer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </MDBox>




            <MDBox my={3}>
              <MDTypography
                variant='h1'
                style={{ marginBottom: 10, fontSize: 18 }}
              >
                SMTP Configuration
              </MDTypography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Hostname'
                    value={orgConfig?.smtpConfig?.hostname}
                    id='hostname'
                    name='hostname'
                    required={true}
                    onChange={handleOrgSmtp}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id='secure'>Secure</InputLabel>
                    <Select
                      labelId='secure'
                      label='Secure'
                      onChange={handleOrgSmtp}
                      value={orgConfig?.smtpConfig?.secure}
                      id='secure'
                      name='secure'
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Username'
                    value={orgConfig?.smtpConfig?.username}
                    name='username'
                    id='username'
                    required={true}
                    onChange={handleOrgSmtp}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Password'
                    value={orgConfig?.smtpConfig?.password}
                    name='password'
                    required={true}
                    onChange={handleOrgSmtp}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Port'
                    value={orgConfig?.smtpConfig?.port}
                    name='port'
                    id='port'
                    required={true}
                    onChange={handleOrgSmtp}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Email From'
                    value={orgConfig?.smtpConfig?.emailFrom}
                    name='emailFrom'
                    type='email'
                    required={true}
                    onChange={handleOrgSmtp}
                  />
                </Grid>
              </Grid>
              <MDTypography
                variant='h1'
                style={{ marginTop: 30, marginBottom: 10, fontSize: 18 }}
              >
                S3 Configuration
              </MDTypography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Key ID'
                    value={orgConfig?.s3Config?.keyId}
                    name='keyId'
                    id='keyId'
                    required={true}
                    onChange={handleOrgS3Config}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Secret key'
                    value={orgConfig?.s3Config?.secretKey}
                    name='secretKey'
                    id='secretKey'
                    required={true}
                    onChange={handleOrgS3Config}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Region'
                    value={orgConfig?.s3Config?.region}
                    name='region'
                    id='region'
                    required={true}
                    onChange={handleOrgS3Config}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Bucket Name'
                    value={orgConfig?.s3Config?.bucketName}
                    name='bucketName'
                    id='bucketName'
                    required={true}
                    onChange={handleOrgS3Config}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDInput
                    fullWidth
                    variant='outlined'
                    label='Endpoint'
                    value={orgConfig?.s3Config?.endpoint}
                    name='endpoint'
                    id='endpoint'
                    required={true}
                    onChange={handleOrgS3Config}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <Card style={{ marginBottom: 20, marginTop: 20, padding: 20 }}>
            <MDBox>
              <MDTypography
                variant='h1'
                style={{ marginBottom: 10, fontSize: 18 }}
              >
                OpenAI Settings
              </MDTypography>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDInput
                    required={true}
                    label='OpenAI Key'
                    value={openai.openai_key || ''}
                    name='openai_key'
                    fullWidth
                    onChange={handleOpenAi}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDInput
                    required={true}
                    label='OpenAI Model'
                    value={openai.openai_model || ''}
                    name='openai_model'
                    fullWidth
                    onChange={handleOpenAi}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDInput
                    required={true}
                    label='OpenAI Tokens'
                    type='number'
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    value={openai.openai_tokens || ''}
                    name='openai_tokens'
                    fullWidth
                    onChange={handleOpenAi}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <MDBox mt={3} display='flex' justifyContent='center'>
            <MDButton
              variant='gradient'
              color='info'
              type='submit'
              loading={addLoading}
              disabled={!selectedCluster}
            >
              Add
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
        >
          <MDTypography variant='h6' color='white'>
            All Organizations
          </MDTypography>
        </MDBox>

        <TableContainer>
          <Table size='small' aria-label='a dense table'>
            <thead>
              <TableRow>
                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    ORG Name
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Organization ID
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 185 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Order ID
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Cluster ID
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Trial
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 230 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Created At
                  </MDTypography>
                </TableCell>

                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Website Quota
                  </MDTypography>
                </TableCell>

                <TableCell align='left' sx={{ minWidth: 150 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Demo
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 150 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    OpenAI Tokens
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 150 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    OpenAI Key
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 150 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    OpenAI Model
                  </MDTypography>
                </TableCell>
                {/* <TableCell align="left" sx={{ minWidth: 180 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        License Key
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        License Key Expiry Date
                                    </MDTypography>
                                </TableCell> */}

                <TableCell align='left' sx={{ minWidth: 120 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Last Action
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 1500 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Action
                  </MDTypography>
                </TableCell>
              </TableRow>
            </thead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell align='center' colSpan={6}>
                    <MDBox
                      display='flex'
                      py={1}
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <MDProgress circular />
                      <MDTypography variant='h6'>
                        Loading Organizations...
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {rows && rows.length > 0 ? (
                  rows.map((row, index) => {
                    return (
                      <OrderRow
                        fetchOrganizations={fetchOrganizations}
                        clusterId={selectedCluster}
                        darkMode={darkMode}
                        row={row}
                        rows={rows}
                        key={`order-${index}`}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align='center' colSpan={6}>
                      <MDTypography
                        variant='caption'
                        component='span'
                        fontWeight='bold'
                      >
                        no organizations found
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {rows.length > 0 && !loading && (
            <MDBox
              mt={2}
              mb={2}
              display='flex'
              justifyContent='center'
              width='100%'
            >
              <Pagination
                color='info'
                variant='outlined'
                sx={{
                  '& .MuiPagination-ul > li > button': {
                    fontWeight: 400,
                    color: darkMode
                      ? 'white !important'
                      : `${colors?.info?.main} !important`,
                    borderColor: darkMode
                      ? 'white !important'
                      : `${colors?.info?.main} !important`,
                  },
                  '& .MuiPagination-ul > li > div': {
                    color: darkMode
                      ? 'white !important'
                      : `${colors?.info?.main} !important`,
                  },
                }}
                count={pages}
                page={page}
                onChange={(e, value) => setPage(value)}
              />
            </MDBox>
          )}
        </TableContainer>
      </Card>
    </DashboardLayout>
  );
};

function OrderRow({ row, fetchOrganizations }) {
  const dispatch = useDispatch();

  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [trialDays, setTrialDays] = React.useState(1);

  const [orgConfigLoading, setConfigLoading] = React.useState(false);

  const [changeLicensekey, setChangeLicensekey] = React.useState(uuidv4());

  const [org, setOrg] = React.useState({
    name: '',
    websiteQuotas: 10,
    partialScanLinks: 0,
    orderId: '',
    demo: false,
    trial: false,
  });

  const [orgConfig, setOrgConfig] = React.useState({
    smtpConfig: {
      hostname: '',
      secure: '',
      username: '',
      password: '',
      port: 0,
      emailFrom: '',
    },
    s3Config: {
      keyId: '',
      secretKey: '',
      region: '',
      bucketName: '',
      endpoint: '',
    },
  });

  const [actionModal, setActionModal] = React.useState({
    open: false,
    orgName: null,
    action: '',
  });

  function openCancelInvoiceModal(data) {
    setOrgConfig({
      smtpConfig: data?.orgName?.smtp_config,
      s3Config: data?.orgName?.s3_config,
    });

    setOrg({
      name: data?.orgName?.name,
      websiteQuotas: data?.orgName?.websites_quotas,
      partialScanLinks: data?.orgName?.partialScanLinks,
      orderId: data?.orgName?.order_id,
      demo: data?.orgName?.demo,
      trial: data?.orgName?.trial,
      openai_key: data.orgName.openai_Key,
      openai_model: data.orgName.openai_Model,
      openai_tokens: data.orgName.openai_Tokens,
    });
    setActionModal(data);
  }

  const [openai, setOpenai] = React.useState({
    openai_key: '',
    openai_model: '',
    openai_tokens: '',
  });
  function handleOpenAi(e) {
    setOpenai({
      ...openai,
      [e.target.name]: e.target.value,
    });
  }

  const [authLink, setAuthLink] = React.useState(null);

  function handleDelete() {
    // Sets the state of deleteLoading to true
    setDeleteLoading(true);

    // Checks the actionModal's action and dispatches the appropriate child organization action
    if (actionModal.action === 'delete') {
      dispatch(
        childOrganizationAction({
          action: 'delete',
          details: {
            clusterId: row.cluster,
            orgName: actionModal?.orgName?.name,
          },
        })
      )
        .then((data) => {
          // Sets deleteLoading state to false and resets the cancel invoice modal state
          setDeleteLoading(false);
          openCancelInvoiceModal({
            open: false,
            orgName: null,
            action: '',
          });
          // Fetches organizations to update the UI
          fetchOrganizations();
        })
        .catch((err) => {
          // If there is an error, sets deleteLoading state to false
          setDeleteLoading(false);
        });
    }
    // Checks if the actionModal's action is 'enable' or 'disable' and dispatches the appropriate child organization license mode action
    else if (
      actionModal.action === 'enable' ||
      actionModal.action === 'disable'
    ) {
      dispatch(
        childOrganizationLicMode({
          action: actionModal.action,
          id: actionModal.orgName.id,
        })
      )
        .then((data) => {
          // Sets deleteLoading state to false and resets the cancel invoice modal state
          setDeleteLoading(false);
          openCancelInvoiceModal({
            open: false,
            orgName: null,
            action: '',
          });
          // Fetches organizations to update the UI
          fetchOrganizations();
        })
        .catch((err) => {
          // If there is an error, sets deleteLoading state to false
          setDeleteLoading(false);
        });
    }
    // Checks if the actionModal's action is 'Change License Key' and dispatches the appropriate change license key action
    else if (actionModal.action === 'Change License Key') {
      dispatch(
        changeLickey({
          key: changeLicensekey,
          id: actionModal.orgName.id,
        })
      )
        .then((data) => {
          // Sets deleteLoading state to false and resets the cancel invoice modal state
          setDeleteLoading(false);
          openCancelInvoiceModal({
            open: false,
            orgName: null,
            action: '',
          });
          // Fetches organizations to update the UI
          fetchOrganizations();
        })
        .catch((err) => {
          // If there is an error, sets deleteLoading state to false
          setDeleteLoading(false);
        });
    }
    // Checks if the actionModal's action is 'update-org' and dispatches the appropriate child organization action
    else if (actionModal.action === 'update-org') {
      dispatch(
        childOrganizationAction({
          action: 'update-org',
          details: {
            clusterId: row.cluster,
            orgId: actionModal?.orgName?.organizationId,
            details: {
              ...org,
            },
          },
        })
      )
        .then((data) => {
          // Sets deleteLoading state to false and resets the cancel invoice modal state
          setDeleteLoading(false);
          openCancelInvoiceModal({
            open: false,
            orgName: null,
            action: '',
          });
          // Fetches organizations to update the UI
          fetchOrganizations();
        })
        .catch((err) => {
          // If there is an error, sets deleteLoading state to false
          setDeleteLoading(false);
        });
    }
    // Checks if the actionModal's action is 'update-openai-key' and dispatches the appropriate child organization action
    else if (actionModal.action === 'update-openai-key') {
      dispatch(
        childOrganizationAction({
          action: 'update-openai-key',
          details: {
            clusterId: row.cluster,
            orgId: actionModal?.orgName?.organizationId,
            openaiKey: openai.openai_key,
            openaiModel: openai.openai_model,
            openaiTokens: parseInt(openai.openai_tokens),
          },
        })
      )
        .then((data) => {
          fetchOrganizations();
          setDeleteLoading(false);
          openCancelInvoiceModal({
            open: false,
            orgName: null,
            action: '',
          });
        })
        .catch((err) => {
          setDeleteLoading(false);
        });
    } else if (actionModal.action === 'login-code') {
      dispatch(
        childOrganizationAction({
          action: 'login-code',
          details: {
            clusterId: row.cluster,
            orgId: actionModal?.orgName?.organizationId,
          },
        })
      )
        .then((data) => {
          setAuthLink(data?.result);
          setDeleteLoading(false);
          openCancelInvoiceModal({
            open: false,
            orgName: null,
            action: '',
          });
        })
        .catch((err) => {
          setDeleteLoading(false);
        });
    } else {
      dispatch(
        childOrganizationLickey({
          action: actionModal.action,
          id: actionModal?.orgName?.id,
          days: parseInt(trialDays),
        })
      )
        .then((data) => {
          setDeleteLoading(false);
          openCancelInvoiceModal({
            open: false,
            orgName: null,
            action: '',
          });
          setTrialDays(1);
          fetchOrganizations();
        })
        .catch((err) => {
          setDeleteLoading(false);
        });
    }
  }

  // This function handles changes to the main organization form inputs
  function handleOrg(e) {
    // Update the org state with the new value
    setOrg({
      ...org,
      [e.target.name]: e.target.value,
    });
  }

  // This function handles changes to the SMTP configuration form inputs
  function handleOrgSmtp(e) {
    // Update the orgConfig state with the new value
    setOrgConfig({
      ...orgConfig,
      smtpConfig: {
        ...orgConfig.smtpConfig,
        [e.target.name]: e.target.value,
      },
    });
  }

  // This function handles changes to the S3 configuration form inputs
  function handleOrgS3Config(e) {
    // Update the orgConfig state with the new value
    setOrgConfig({
      ...orgConfig,
      s3Config: {
        ...orgConfig.s3Config,
        [e.target.name]: e.target.value,
      },
    });
  }

  // This function submits the organization configuration updates
  function submitOrgConfig(e) {
    // Prevent the default form submission behavior
    e.preventDefault();
    // Set the config loading state to true
    setConfigLoading(true);
    // Dispatch the childOrganizationEdit action with the new details
    dispatch(
      childOrganizationEdit({
        action: 'update',
        details: {
          clusterId: row.cluster,
          orgId: actionModal?.orgName?.organizationId,
          details: {
            ...orgConfig,
          },
        },
      })
    )
      // After the dispatch is complete, update the UI and fetch new organization data
      .then((data) => {
        setDeleteLoading(false);
        openCancelInvoiceModal({
          open: false,
          orgName: null,
          action: '',
        });
        fetchOrganizations();
      })
      // If there is an error, update the UI and do not fetch new data
      .catch((err) => {
        setDeleteLoading(false);
        openCancelInvoiceModal({
          open: false,
          orgName: null,
          action: '',
        });
      });
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align='left'>
          <MDTypography
            variant='caption'
            fontWeight='bold'
            component='a'
            target='_blank'
            href={row?.fullDomain}
          >
            {row?.name}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.organizationId}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.order}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.cluster}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.trial.toString()}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {simpleDateparser(row?.createdAt)}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.websites_quotas}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.demo ? 'true' : 'false'}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.openai_tokens}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.openai_key}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.openai_model}
          </MDTypography>
        </TableCell>

        {/* <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.licenseKey}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.licKeyExpiryDate ? simpleDateparser(row?.licKeyExpiryDate) : "NA"}
                    </MDTypography>
                </TableCell> */}

        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.action}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          {row.action.toLowerCase() !== 'deleted' && (
            <MDBox display='flex' alignItems='center' gap={2}>
              <MDButton
                variant='contained'
                color='error'
                size='small'
                onClick={() =>
                  openCancelInvoiceModal({
                    action: 'edit',
                    open: true,
                    orgName: row,
                  })
                }
              >
                {' '}
                Edit Config
              </MDButton>
              <MDButton
                variant='contained'
                color='error'
                size='small'
                onClick={() =>
                  openCancelInvoiceModal({
                    action: 'delete',
                    open: true,
                    orgName: row,
                  })
                }
              >
                {' '}
                Delete
              </MDButton>
              <MDButton
                variant='contained'
                color='success'
                size='small'
                onClick={() =>
                  openCancelInvoiceModal({
                    action: 'extend',
                    open: true,
                    orgName: row,
                  })
                }
              >
                {' '}
                Extend Days
              </MDButton>
              <MDButton
                variant='contained'
                color='error'
                size='small'
                onClick={() =>
                  openCancelInvoiceModal({
                    action: 'reduce',
                    open: true,
                    orgName: row,
                  })
                }
              >
                {' '}
                Reduce Days
              </MDButton>

              <MDButton
                variant='contained'
                color='error'
                size='small'
                onClick={() =>
                  openCancelInvoiceModal({
                    action: 'update-org',
                    open: true,
                    orgName: row,
                  })
                }
              >
                {' '}
                Update
              </MDButton>
              <MDButton
                variant='contained'
                color='error'
                size='small'
                onClick={() => {
                  openCancelInvoiceModal({
                    action: 'update-openai-key',
                    open: true,
                    orgName: row,
                  });
                  setOpenai({
                    openai_key: row?.openai_key,
                    openai_model: row?.openai_model,
                    openai_tokens: 1,
                  });
                }}
              >
                {' '}
                Update OpenAI
              </MDButton>
              <MDButton
                variant='contained'
                color='error'
                size='small'
                onClick={() => {
                  openCancelInvoiceModal({
                    action: 'login-code',
                    open: true,
                    orgName: row,
                  });
                }}
              >
                {' '}
                Generate Login Link
              </MDButton>
              {getDashboardType() === 'enterprise' && (
                <MDButton
                  variant='contained'
                  color='error'
                  size='small'
                  onClick={() =>
                    openCancelInvoiceModal({
                      action: 'Change License Key',
                      open: true,
                      orgName: row,
                    })
                  }
                >
                  {' '}
                  Change License key
                </MDButton>
              )}

              {row.licenseKeyValid ? (
                <MDButton
                  variant='contained'
                  color='error'
                  size='small'
                  onClick={() =>
                    openCancelInvoiceModal({
                      action: 'disable',
                      open: true,
                      orgName: row,
                    })
                  }
                >
                  Disable License
                </MDButton>
              ) : (
                <MDButton
                  variant='contained'
                  color='error'
                  size='small'
                  onClick={() =>
                    openCancelInvoiceModal({
                      action: 'enable',
                      open: true,
                      orgName: row,
                    })
                  }
                >
                  Enable License
                </MDButton>
              )}
            </MDBox>
          )}
        </TableCell>
      </TableRow>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={actionModal.open}
        onClose={() =>
          !deleteLoading
            ? setActionModal({
              open: false,
              action: '',
              orgName: null,
            })
            : null
        }
      >
        <MDBox p={3}>
          <MDTypography
            variant='h6'
            align='center'
            sx={{ mb: 3, textTransform: 'capitalize' }}
          >
            {' '}
            {actionModal.action} Organization
          </MDTypography>
          {/* {actionModal.action === ''} */}

          {actionModal.action === 'extend' ||
            actionModal.action === 'reduce' ? (
            <>
              <MDInput
                required={true}
                sx={{ my: 2 }}
                value={trialDays}
                name='trialDays'
                type='number'
                label='Trial Days'
                onChange={(e) => setTrialDays(e.target.value)}
                fullWidth
              />
              <MDButton
                variant='contained'
                color='success'
                disabled={deleteLoading}
                sx={{ mx: 1 }}
                onClick={() => {
                  setActionModal({
                    open: false,
                    action: '',
                    orgName: null,
                  });
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant='contained'
                color='warning'
                loading={deleteLoading}
                onClick={() => handleDelete()}
              >
                Confirm
              </MDButton>
            </>
          ) : actionModal.action === 'edit' ? (
            <Card style={{ marginBottom: 20, padding: 20 }}>
              <MDTypography variant='h1' style={{ fontSize: 22 }}>
                Organization Configuration
              </MDTypography>
              <MDBox my={3} component='form' onSubmit={submitOrgConfig}>
                <MDTypography
                  variant='h1'
                  style={{ marginBottom: 10, fontSize: 18 }}
                >
                  SMTP Configuration
                </MDTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Hostname'
                      value={orgConfig?.smtpConfig?.hostname}
                      id='hostname'
                      name='hostname'
                      required={true}
                      onChange={handleOrgSmtp}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id='secure'>Secure</InputLabel>
                      <Select
                        labelId='secure'
                        label='Secure'
                        onChange={handleOrgSmtp}
                        value={orgConfig?.smtpConfig?.secure}
                        id='secure'
                        name='secure'
                      >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Username'
                      value={orgConfig?.smtpConfig?.username}
                      name='username'
                      id='username'
                      required={true}
                      onChange={handleOrgSmtp}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Password'
                      value={orgConfig?.smtpConfig?.password}
                      name='password'
                      required={true}
                      onChange={handleOrgSmtp}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Port'
                      value={orgConfig?.smtpConfig?.port}
                      name='port'
                      id='port'
                      required={true}
                      onChange={handleOrgSmtp}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Email From'
                      value={orgConfig?.smtpConfig?.emailFrom}
                      name='emailFrom'
                      type='email'
                      required={true}
                      onChange={handleOrgSmtp}
                    />
                  </Grid>
                </Grid>
                <MDTypography
                  variant='h1'
                  style={{ marginTop: 30, marginBottom: 10, fontSize: 18 }}
                >
                  S3 Configuration
                </MDTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Key ID'
                      value={orgConfig?.s3Config?.keyId}
                      name='keyId'
                      id='keyId'
                      required={true}
                      onChange={handleOrgS3Config}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Secret key'
                      value={orgConfig?.s3Config?.secretKey}
                      name='secretKey'
                      id='secretKey'
                      required={true}
                      onChange={handleOrgS3Config}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Region'
                      value={orgConfig?.s3Config?.region}
                      name='region'
                      id='region'
                      required={true}
                      onChange={handleOrgS3Config}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Bucket Name'
                      value={orgConfig?.s3Config?.bucketName}
                      name='bucketName'
                      id='bucketName'
                      required={true}
                      onChange={handleOrgS3Config}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      variant='outlined'
                      label='Endpoint'
                      value={orgConfig?.s3Config?.endpoint}
                      name='endpoint'
                      id='endpoint'
                      required={true}
                      onChange={handleOrgS3Config}
                    />
                  </Grid>
                </Grid>
                <MDBox display='flex' alignItems='center' mt={2} gap={2}>
                  <MDButton
                    variant='gradient'
                    color='primary'
                    type='submit'
                    loading={orgConfigLoading}
                  >
                    Update Configuration
                  </MDButton>
                  <MDButton
                    variant='contained'
                    color='success'
                    disabled={orgConfigLoading}
                    onClick={() => {
                      setActionModal({
                        open: false,
                        action: '',
                        orgName: null,
                      });
                    }}
                  >
                    Cancel
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          ) : (
            <>
              {actionModal.action === 'Change License Key' ? (
                <>
                  <MDInput
                    sx={{ my: 2 }}
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                    value={changeLicensekey}
                    name='licKey'
                    label='Change License Key'
                    fullWidth
                  />
                  <MDButton
                    variant='contained'
                    color='success'
                    disabled={deleteLoading}
                    sx={{ mx: 1 }}
                    onClick={() => {
                      setActionModal({
                        open: false,
                        action: '',
                        orgName: null,
                      });
                    }}
                  >
                    Cancel
                  </MDButton>

                  <MDButton
                    variant='contained'
                    color='warning'
                    loading={deleteLoading}
                    onClick={() => handleDelete()}
                  >
                    Confirm
                  </MDButton>
                </>
              ) : actionModal.action === 'update-org' ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <MDInput
                        required={true}
                        value={org.name}
                        name='name'
                        label='Organization Name'
                        onChange={handleOrg}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <MDInput
                        required={true}
                        InputProps={{ inputProps: { min: 1 } }}
                        value={org.websiteQuotas}
                        type='number'
                        label='Website Quotas'
                        name='websiteQuotas'
                        onChange={handleOrg}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDInput
                        required={true}
                        value={org.orderId}
                        type='text'
                        label='Order ID'
                        name='orderId'
                        onChange={handleOrg}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDInput
                        required={true}
                        InputProps={{ inputProps: { min: 1 } }}
                        value={org.partialScanLinks}
                        type='number'
                        label='Partial Scan Links'
                        name='partialScanLinks'
                        onChange={handleOrg}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id='demo-selection'>Demo</InputLabel>
                        <Select
                          labelId='demo-selection'
                          id='demo-simple-select'
                          name='demo'
                          value={org.demo}
                          label='Demo'
                          onChange={(e) =>
                            setOrg({ ...org, demo: e.target.value })
                          }
                          required={true}
                        >
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <InputLabel id='trial-selection'>Trial</InputLabel>
                        <Select
                          labelId='trial-selection'
                          id='trial-simple-select'
                          name='trial'
                          value={org.trial}
                          label='Trial'
                          onChange={(e) =>
                            setOrg({ ...org, trial: e.target.value })
                          }
                          required={true}
                        >
                          <MenuItem value={'true'}>True</MenuItem>
                          <MenuItem value={'false'}>False</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <MDBox
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    mt={2}
                  >
                    <MDButton
                      variant='contained'
                      color='success'
                      disabled={deleteLoading}
                      sx={{ mx: 1 }}
                      onClick={() => {
                        setActionModal({
                          open: false,
                          action: '',
                          orgName: null,
                        });
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      variant='contained'
                      color='warning'
                      loading={deleteLoading}
                      onClick={() => handleDelete()}
                    >
                      Confirm
                    </MDButton>
                  </MDBox>
                </>
              ) : actionModal.action === 'update-openai-key' ? (
                <>
                  <MDBox mt={2}>
                    <MDTypography
                      variant='h1'
                      style={{ marginBottom: 10, fontSize: 18 }}
                    >
                      OpenAI Settings
                    </MDTypography>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDInput
                          required={true}
                          label='OpenAI Key'
                          value={openai.openai_key || ''}
                          name='openai_key'
                          fullWidth
                          onChange={handleOpenAi}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDInput
                          required={true}
                          label='OpenAI Model'
                          value={openai.openai_model || ''}
                          name='openai_model'
                          fullWidth
                          onChange={handleOpenAi}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDInput
                          required={true}
                          label='Add OpenAI Tokens'
                          type='number'
                          InputProps={{
                            inputProps: {
                              min: 1,
                            },
                          }}
                          value={openai.openai_tokens || ''}
                          name='openai_tokens'
                          fullWidth
                          onChange={handleOpenAi}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    mt={2}
                  >
                    <MDButton
                      variant='contained'
                      color='success'
                      disabled={deleteLoading}
                      sx={{ mx: 1 }}
                      onClick={() => {
                        setActionModal({
                          open: false,
                          action: '',
                          orgName: null,
                        });
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      variant='contained'
                      color='warning'
                      loading={deleteLoading}
                      onClick={() => handleDelete()}
                    >
                      Confirm
                    </MDButton>
                  </MDBox>
                </>
              ) : actionModal.action === 'login-code' ? (
                <>
                  <MDBox
                    mt={2}
                    display='flex'
                    justifyContent='center'
                    py={3}
                    gap={2}
                  >
                    <MDButton
                      color='info'
                      variant='contained'
                      loading={deleteLoading}
                      onClick={() => handleDelete()}
                    >
                      {' '}
                      Generate{' '}
                    </MDButton>
                    <br />
                    {authLink && (
                      <MDButton
                        variant='contained'
                        component='a'
                        disabled={deleteLoading}
                        href={authLink}
                        target='_blank'
                        size='small'
                        color='success'
                      >
                        Open Auth Link
                      </MDButton>
                    )}
                  </MDBox>
                  <MDBox
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    mt={2}
                  >
                    <MDButton
                      variant='contained'
                      color='error'
                      disabled={deleteLoading}
                      onClick={() => {
                        setActionModal({
                          open: false,
                          action: '',
                          orgName: null,
                        });
                      }}
                    >
                      Cancel
                    </MDButton>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <MDButton
                    variant='contained'
                    color='success'
                    disabled={deleteLoading}
                    sx={{ mx: 1 }}
                    onClick={() => {
                      setActionModal({
                        open: false,
                        action: '',
                        orgName: null,
                      });
                    }}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    variant='contained'
                    color='warning'
                    loading={deleteLoading}
                    onClick={() => handleDelete()}
                  >
                    Confirm
                  </MDButton>
                </MDBox>
              )}
            </>
          )}
        </MDBox>
      </Dialog>
    </React.Fragment>
  );
}

export default ChildOrganizations;
