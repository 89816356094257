

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
export default function PrivateRoute({ Component }) {
  const user = useSelector(state => state.auth.user);

  return (
    <>
      {!user ? <Navigate to='/authentication/sign-in' /> : <Component />}
    </>
  )
}