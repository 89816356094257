// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
// import App from "App";
// import './app.css'
// // Soft UI Context Provider
// import { MaterialUIControllerProvider } from "context";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// if (process.env.REACT_APP_SENTRY === "true") {
//   Sentry.init({
//     dsn: process.env.SENTRY_DSN,
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });
// }

// ReactDOM.render(
//   <BrowserRouter>
//     <MaterialUIControllerProvider>
//       <App />
//     </MaterialUIControllerProvider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );

import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App"; // Assuming App.js is in the same directory
import './app.css';
// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from 'react-dom/client';

if (process.env.REACT_APP_SENTRY === "true") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root'); // Should be 'root' instead of 'app'
const root = ReactDOM.createRoot(container);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  // </React.StrictMode>
);