import React, { useState } from 'react';
import { Typography, FormControlLabel, Checkbox, Button, Grid } from '@mui/material';
import formatString from 'utils/formatString';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';

const PermissionsComponent = ({ permissions, allReadPermissions, updatedPermissions, setUpdatedPermissions }) => {


	const [allRead, setAllRead] = useState(false);

	const handlePermissionChange = (app, permission) => {
		const updatedAppPermissions = { ...updatedPermissions[app], [permission]: !updatedPermissions[app][permission] };
		setUpdatedPermissions({ ...updatedPermissions, [app]: updatedAppPermissions });
	};

	const handleAllReadCheckboxChange = (e) => {
		if (e.target.checked) {
			if (allReadPermissions) {
				const rolePermissions = allReadPermissions;
				if (rolePermissions) {
					setUpdatedPermissions(rolePermissions);
				}
			}
		} else {
			setUpdatedPermissions(permissions);
		}
		setAllRead(e.target.checked)
	};

	function areAllPermissionsTrue(allReadPermissions, updatedPermissions) {
		for (let category in allReadPermissions) {
			const allReadCategoryPermissions = allReadPermissions[category];
			const updatedCategoryPermissions = updatedPermissions[category];

			for (let permission in allReadCategoryPermissions) {
				if (allReadCategoryPermissions[permission] !== updatedCategoryPermissions[permission]) {
					return false;
				}
			}
		}
		return true;
	}

	// React.useEffect(() => {
	// 	console.log({ updatedPermissions, allReadPermissions })
	// 	if (areAllPermissionsTrue(allReadPermissions, updatedPermissions)) {
	// 		setAllRead(true)
	// 	} else {
	// 		setAllRead(false)

	// 	}
	// }, [updatedPermissions])
	return (
		<div>
			<FormControlLabel
				control={
					<Checkbox
						checked={allRead}
						onChange={handleAllReadCheckboxChange}
						color="primary"
					/>
				}
				label="All Read"
			/>
			{Object.entries(updatedPermissions).map(([app, appPermissions]) => (
				<MDBox my={2}>
					<MDTypography variant="h5" marginBottom>{formatString(app)}</MDTypography>
					<Grid container spacing={1}>
						{Object.entries(appPermissions).map(([permission, value]) => (
							<Grid item xs={12} sm={6} md={4} key={permission}>
								<FormControlLabel

									control={
										<Checkbox
											// disabled={allRead}
											checked={value}
											onChange={() => handlePermissionChange(app, permission)}
											color="primary"
										/>
									}
									label={formatString(permission)}
								/>
							</Grid>
						))}
					</Grid>
				</MDBox>
			))}
		</div>
	);
};

export default PermissionsComponent;
