
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import List from '@mui/material/List';
// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useLocation, NavLink } from "react-router-dom";

// Custom styles for the SidenavCollapse
import {
    collapseNestedItem,
    collapseItem,
    collapseIconBox,
    collapseIcon,
    collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import React from "react";



import Collapse from '@mui/material/Collapse';


function Collapsible({ icon, bundled, name, childrens, currentRoute, active, disabled, ...rest }) {

    const [controller] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const location = useLocation();

    const collapseName = location.pathname.split("/")[2];
    var mainActive = childrens.filter(child => `/${child.layout}${child.route}` === currentRoute).length > 0

    React.useMemo(() => {
        setOpen(mainActive)
    }, [location])

    return (
        <MDBox mr={1}>
            <MDBox
                {...rest}
                style={{ maring: 0 }}
                disabled={disabled}
                onClick={() => disabled ? null : handleClick()}
                sx={(theme) =>
                    collapseItem(theme, {
                        active: mainActive,
                        transparentSidenav,
                        whiteSidenav,
                        darkMode,
                        sidenavColor,
                    })
                }
            >
                <ListItemIcon
                    sx={(theme) =>
                        collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active: mainActive })
                    }
                >
                    {typeof icon === "string" ? (
                        <Icon sx={(theme) => collapseIcon(theme, { active: mainActive })}>{icon}</Icon>
                    ) : (
                        icon
                    )}
                </ListItemIcon>

                <ListItemText
                    primary={name}

                    sx={(theme) =>
                        collapseText(theme, {
                            miniSidenav: false,
                            transparentSidenav,
                            whiteSidenav,
                            active: mainActive,
                        })
                    }
                />
                <MDBox display='flex' alignItems='center'> {open ? <Icon fontSize='medium' sx={{ color: { xs: !miniSidenav ? '#fff' : 'default', md: darkMode ? '#fff' : 'inherit !important' } }}>expand_less</Icon> : <Icon fontSize='medium' sx={{ color: { xs: !miniSidenav ? '#fff' : 'default', md: darkMode ? '#fff' : 'inherit !important' } }}>expand_more</Icon>}</MDBox>
            </MDBox>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ mr: -2 }}>
                <List sx={{ pl: 3 }} >

                    {childrens.map((child, index) => {
                        const active = `/${child.layout}${child.route}` === currentRoute

                        return (
                            <NavLink key={index} to={`/${child.layout}${child.route}`} >
                                <ListItem component="li" sx={{ margin: '4px 0px' }} >
                                    <MDBox
                                        {...rest}
                                        sx={(theme) =>
                                            collapseNestedItem(theme, {
                                                active,
                                                transparentSidenav,
                                                whiteSidenav,
                                                darkMode,
                                                sidenavColor,
                                            })
                                        }
                                        style={{ padding: '5px 10px', margin: 0 }}
                                    >
                                        <ListItemIcon
                                            sx={(theme) =>
                                                collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active: active })
                                            }
                                        >
                                            {typeof icon === "string" ? (
                                                <Icon sx={(theme) => collapseIcon(theme, { active: mainActive })}>{child.icon}</Icon>
                                            ) : (
                                                child.icon
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={child.name}

                                            sx={(theme) =>
                                                collapseText(theme, {
                                                    miniSidenav,
                                                    transparentSidenav,
                                                    whiteSidenav,
                                                    active,
                                                })
                                            }
                                        />
                                    </MDBox>
                                </ListItem>
                            </NavLink>
                        )
                    })}


                </List>
            </Collapse>

        </MDBox>
    );
}

// Setting default values for the props of SidenavCollapse
Collapsible.defaultProps = {
    active: false,
};

// Typechecking props for the Collapsible
Collapsible.propTypes = {
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
};

export default Collapsible;
