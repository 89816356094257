import React from "react";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import UserManagementInvoices from "../invoices/UserManagementInvoices";

const AdminUserManagementInvoices = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <UserManagementInvoices type="organization-user-subscription" name="User Paid" paid="true" />
            <UserManagementInvoices type="organization-user-subscription" name="User Unpaid" paid="false" />
            <UserManagementInvoices type="website-widget-order-subscription" name="Widget Paid" paid="true" />
            <UserManagementInvoices type="website-widget-order-subscription" name="Widget Unpaid" paid="false" />
            <UserManagementInvoices type="website-auditor-order-subscription" name="Auditor Paid" paid="true" />
            <UserManagementInvoices type="website-auditor-order-subscription" name="Auditor Unpaid" paid="false" />
        </DashboardLayout>
    );
};

export default AdminUserManagementInvoices; 