import parseTablesData from 'utils/tablesDataParse';
import * as Actions from '../actions/adminSmokiDashboard.action';

const initialState = {
  analytics: null,
  loading: true,

  analyticsUnified: null,
  loadingUnified: true,

  users: [],
  loadingUsers: false,
  pagesUsers: 0,

  usersRoot: [],
  loadingRootUsers: false,
  pagesRootUsers: 0,

  adminInvoices: [],
  loadingAdminInvoices: false,

  adminSubs: [],
  loadingAdminSubs: false,

  adminCoupons: [],
  loadingAdminCoupons: false,
  pagesCoupons: 0,

  createCouponLoading: false,
  deleteCouponLoading: false,

  addHealthStatusLoading: false,
  editHealthStatusLoading: false,

  subscriptionInvoices: [],
  subscriptionInvoicesPages: 0,
  subscriptionInvoicesLoading: false,

  userChargeInvoices: [],
  userChargeInvoicesPages: 0,
  userChargeInvoicesLoading: false,

  oneTimeChargeInvoices: [],
  oneTimeChargeInvoicesPages: 0,
  oneTimeChargeInvoicesLoading: false,

  adminSupportRequests: [],
  adminSupportRequestsColumns: [],
  adminpagesSupportRequests: 0,
  loadingAdminSupportRequests: false,

  allOrders: [],
  allOrdersColumns: [],
  allOrdersPages: 0,
  allOrdersLoading: false,

  tokenOrders: [],
  tokenOrdersPages: 0,
  tokenOrdersLoading: false,

  orderInvoices: [],
  orderInvoicesPages: 0,
  orderInvoicesLoading: false,

  organizations: [],
  organizationsLoading: false,
  organizationsDetails: {
    "pages": 1,
    "limit": 1,
    "count": 1,
    "page": 1
  },
  updateOrganizationsLoading: false,

};

function adminSmokiDashboard(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_ADMIN_ANALYTICS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_ADMIN_ANALYTICS:
      return {
        ...state,
        analytics: payload,
        loading: false,
      };

    case Actions.GET_ADMIN_UNIFIED_ANALYTICS_LOADING:
      return {
        ...state,
        loadingUnified: true
      };
    case Actions.GET_ADMIN_UNIFIED_ANALYTICS:
      return {
        ...state,
        analyticsUnified: payload,
        loadingUnified: false,
      };
    case Actions.GET_USERS_WORDPRESS:
      return {
        ...state,
        users: payload.result,
        pagesUsers: payload.pages,
        loadingUsers: false
      };
    case Actions.GET_USERS_WORDPRESS_LOADING:
      return {
        ...state,
        loadingUsers: true
      };


    case Actions.GET_USERS_ROOT:
      return {
        ...state,
        usersRoot: payload.result,
        pagesRootUsers: payload.pages,
        loadingRootUsers: false
      };
    case Actions.GET_USERS_ROOT_LOADING:
      return {
        ...state,
        loadingRootUsers: true
      };


    case Actions.GET_ADMIN_INVOICES:
      return {
        ...state,
        adminInvoices: payload,

        loadingAdminInvoices: false
      };
    case Actions.GET_ADMIN_INVOICES_LOADING:
      return {
        ...state,
        loadingAdminInvoices: true
      };
    case Actions.GET_ADMIN_SUBSCRIPTIONS:
      return {
        ...state,
        adminSubs: payload,
        loadingAdminSubs: false
      };
    case Actions.GET_ADMIN_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        loadingAdminSubs: true
      };
    case Actions.GET_COUPONS:
      return {
        ...state,
        adminCoupons: payload.result,
        loadingAdminCoupons: false,
        pagesCoupons: payload.pages
      };
    case Actions.GET_COUPONS_LOADING:
      return {
        ...state,
        loadingAdminCoupons: true
      };
    case Actions.CREATE_COUPON_LOADING:
      return {
        ...state,
        createCouponLoading: !state.createCouponLoading
      };
    case Actions.COUPON_DELETE_LOADING:
      return {
        ...state,
        deleteCouponLoading: !state.deleteCouponLoading
      };
    case Actions.SEND_ADMIN_TICKET_LOADING:
      return {
        ...state,
        loadingAdminTicket: !state.loadingAdminTicket
      };
    case Actions.ADD_HEALTH_STATUS_LOADING:
      return {
        ...state,
        addHealthStatusLoading: !state.addHealthStatusLoading
      };
    case Actions.EDIT_HEALTH_STATUS_LOADING:
      return {
        ...state,
        editHealthStatusLoading: !state.editHealthStatusLoading
      };
    case Actions.GET_SUBSCRIPTION_INVOICES:
      return {
        ...state,
        subscriptionInvoices: payload.result,
        subscriptionInvoicesPages: payload.pages,
        subscriptionInvoicesLoading: false
      };
    case Actions.GET_SUBSCRIPTION_INVOICES_LOADING:
      return {
        ...state,
        subscriptionInvoicesLoading: !state.subscriptionInvoicesLoading
      };
    case Actions.GET_USER_CHARGE_INVOICES:
      return {
        ...state,
        userChargeInvoices: payload.result,
        userChargeInvoicesPages: payload.pages,
        userChargeInvoicesLoading: false
      };
    case Actions.GET_USER_CHARGE_INVOICES_LOADING:
      return {
        ...state,
        userChargeInvoicesLoading: !state.userChargeInvoicesLoading
      };

    case Actions.GET_ALL_ORDERS:
      return {
        ...state,
        allOrders: payload.result,
        allOrdersPages: payload.pages,
        allOrdersLoading: false
      };
    case Actions.GET_ALL_ORDERS_LOADING:
      return {
        ...state,
        allOrdersLoading: !state.allOrdersLoading
      };

    case Actions.GET_TOKEN_ORDERS:
      return {
        ...state,
        tokenOrders: payload.result,
        tokenOrdersPages: payload.pages,
        tokenOrdersLoading: false
      };
    case Actions.GET_TOKEN_ORDERS_LOADING:
      return {
        ...state,
        tokenOrdersLoading: !state.tokenOrdersLoading
      };
    case Actions.GET_ONE_TIME_CHARGE_INVOICES:
      return {
        ...state,
        oneTimeChargeInvoices: payload.result,
        oneTimeChargeInvoicesPages: payload.pages,
        oneTimeChargeInvoicesLoading: false
      };
    case Actions.GET_ONE_TIME_CHARGE_INVOICES_LOADING:
      return {
        ...state,
        oneTimeChargeInvoicesLoading: !state.oneTimeChargeInvoicesLoading
      };

    case Actions.GET_ORDER_INVOICES:
      return {
        ...state,
        orderInvoices: payload.result,
        // orderInvoicesPages: payload.pages,
        orderInvoicesLoading: false
      };
    case Actions.GET_ORDER_INVOICES_LOADING:
      return {
        ...state,
        orderInvoicesLoading: !state.orderInvoicesLoading
      };
    case Actions.GET_ORGANIZATIONS_LOADING:
      return {
        ...state,
        organizationsLoading: payload,
      }
    case Actions.GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: payload.result,
        organizationsDetails: {
          "pages": payload.pages,
          "limit": payload.limit,
          "count": payload.count,
          "page": payload.page,
        },
        organizationsLoading: false,
      };
    case Actions.UPDATE_ORGANIZATIONS_LOADING: {
      return { ...state, updateOrganizationsLoading: payload }
    }
    default:
      return state;
  }
}

export default adminSmokiDashboard;
