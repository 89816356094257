import { Card, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuditorOrderDetails } from "redux/actions/userDashboard.action";
import UserAuditorBilling from "./UserAuditorBilling";

export default function AutomaticAuditor() {
	const user = useSelector(state => state.auth.user);
	const navigate = useNavigate();
	const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)
	const [loading, setLoading] = React.useState(true);

	const [getAuditor, setGetAuditor] = React.useState(false)
	const [orderDetails, setOrderDetails] = React.useState(null);
	const dispatch = useDispatch();

	function handleClick() {
		setGetAuditor(true);
		// if (user?.organization?.isPaymentMethodAdded) {
		// 	navigate("/user/main/accessBlend/auditor/billing")
		// } else {
		// 	navigate("/user/main/accessBlend/payment-methods")
		// }
	}

	function getOrderDetails() {
		setLoading(true);
		dispatch(getAuditorOrderDetails(selectedSubdomain.id)).then(data => {
			if (data) {
				setOrderDetails(data.result);
				setLoading(false);
			}
		}).catch(err => {
			setLoading(false)
		});
	}

	React.useEffect(() => {
		if (selectedSubdomain?.auditor) {
			getOrderDetails();
		} else {
			setLoading(false);

		}
	}, [selectedSubdomain]);


	return (
		<DashboardLayout>
			<DashboardNavbar />
			{loading ?
				<MDBox display='flex' alignItems='center' justifyContent='center' height='60vh' >
					<CircularProgress size={36} />
				</MDBox>
				:
				<>
					{orderDetails && orderDetails?.status === 'completed' && <MDBox >

						<MDBox display='flex' alignItems='center' justifyContent='center' ></MDBox>
					</MDBox>}
					{selectedSubdomain && !getAuditor && !selectedSubdomain.auditor ? <Card sx={{ mt: 3, p: 2 }}>
						<MDBox mt={3}>
							<MDTypography variant='h3' sx={{ mb: 1, textAlign: 'center' }}>AccessBlend Automatic Auditor</MDTypography>
							<MDTypography variant='subtitle2' sx={{ fontWeight: 'regular', textAlign: 'center', maxWidth: 800, mx: 'auto' }} >Find your performance and compliance issues in one tool </MDTypography>

							<MDBox textAlign='center' my={3}>
								<img src='/assets/auditor-screenshot.png' style={{ width: '100%', height: 'auto', margin: 'auto' }} />
							</MDBox>
							<MDTypography variant='subtitle2' sx={{ fontWeight: 'regular', textAlign: 'center', maxWidth: 800, mx: 'auto' }} >Get your website accessible auditor </MDTypography>
							<MDBox display='flex' justifyContent='center' my={3} >
								<MDButton variant='gradient' color='primary' size='large' onClick={() => handleClick()}>GET STARTED!</MDButton>
							</MDBox>
						</MDBox>
					</Card> :
						<UserAuditorBilling orderDetails={orderDetails} />
					}
				</>}
		</DashboardLayout>
	)
}