import { Grid, Card, CardContent, CircularProgress, Alert, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateUserWidgetLink } from "redux/actions/userDashboard.action";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MDTypography from "components/MDTypography";
import { evalauteWidgetPackage } from "redux/actions/userDashboard.action";
import MDInput from "components/MDInput";
import { unsubscribeWidget } from "redux/actions/userDashboard.action";
import { getWidgetOrderDetails } from "redux/actions/userDashboard.action";

export default function WidgetBilling({ orderDetails }) {

	const dispatch = useDispatch();

	const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)
	const [billingLoading, setBillingLoading] = React.useState(false);
	const [unsubscribeLoading, setUnsubscribeLoading] = React.useState(false);

	const [packages, setPackages] = React.useState(null);

	const [selectedPackage, setSelectedPackage] = React.useState('monthly');

	const [genWidgetLoading, setGenWidgetLoading] = React.useState(false);
	const [getLoading, setGetLoading] = React.useState(false);

	const [numberOfViews, setNumberOfViews] = React.useState(0);
	const [extraInfo, setExtraInfo] = React.useState(null);
	const [confirmationOpen, setConfirmationOpen] = React.useState(false);
	const [unsubscribeOpen, setUnsubscribeOpen] = React.useState(false);
	// const [orderDetails, setOrderDetails] = React.useState(null);
	const [loading, setLoading] = React.useState(true);


	function openConfirmationDialog() {
		setConfirmationOpen(true);
	}

	function genWidget(e) {
		e.preventDefault();
		openConfirmationDialog();
	}

	function closeConfirmationDialog() {
		setConfirmationOpen(false);
	}

	function closeUnsubscribenDialog() {
		setUnsubscribeOpen(false);
	}

	function handleConfirmation() {
		setBillingLoading(true)
		if(selectedSubdomain){
			dispatch(
				generateUserWidgetLink({ "details": { numberOfPageViews: numberOfViews, tenure: selectedPackage, extraInfo: extraInfo }, websiteId: selectedSubdomain.id })
			).then((data) => {
				if (data) {
					setBillingLoading(false)
					closeConfirmationDialog()
				}
			}).catch((err) => {
				setGenWidgetLoading(false);
				setBillingLoading(false)
			});
		}
	}

	function ePackage(e) {
		e.preventDefault()
		setGetLoading(true)
		dispatch(evalauteWidgetPackage({ numberOfViews })).then(data => {
			if (data) {
				setPackages(data.result);
				setGetLoading(false)
			}
		}).catch(err => {
			setGetLoading(false)
		})
	}

	function openUnsubscribeDialog() {
		setUnsubscribeOpen(true);
	}

	function handleUnsubscribe() {
		setUnsubscribeLoading(true);
		dispatch(
			unsubscribeWidget(selectedSubdomain.id)
		).then((data) => {
			if (data) {
				setUnsubscribeLoading(false)
				closeUnsubscribenDialog()
				window.location.reload();
			}
		}).catch((err) => {
			setUnsubscribeLoading(false)
		});
	}



	return (
		<MDBox>

			{orderDetails?.status === 'pending' || orderDetails?.status === 'processing' ? (
				<MDBox my={3}>
					<Alert severity="warning">Your Order is being reviewed</Alert>
				</MDBox>)
				: <>
					{orderDetails?.package && orderDetails?.status === 'completed' ?
						<>
							<MDTypography sx={{ textAlign: 'center', mb: 4, mt: 10 }} variant='h4'>Your Selected Package</MDTypography>
							<Grid container spacing={2} justifyContent='center'>

								<Grid item key={selectedSubdomain?.package?.id} xs={12} sm={6} md={4} >
									<Card
										// sx={{ p: 2 }}
										variant={'elevation'}
										sx={{
											cursor: 'pointer',
											px: 2,
											py: 3,
											pt: 4,
											overflow: 'hidden',
											boxShadow:

												'0px 4px 8px rgba(0, 0, 0, 0.2)'

										}}
									> {selectedSubdomain?.package?.pkgForYou && <MDTypography
										variant="body2"
										color="textSecondary"

										sx={{ top: 0, left: 0, position: 'absolute', background: '#e91e63', width: '100%', textAlign: 'center', fontWeight: 'bold', color: 'white !important' }}
									>
										Recommended for you
									</MDTypography>}
										<CardContent sx={{ p: 0, pb: '0 !important', textAlign: 'center' }}>

											<MDTypography variant="h5" sx={{ textTransform: 'capitalize', mb: 0.5 }} >{orderDetails?.package?.name}</MDTypography>
											{/* <MDTypography variant="body2" marginBottom>{selectedSubdomain?.package?.description}</MDTypography> */}

											<MDTypography variant="body2" fontWeight='regular'>
												Total Price: ${orderDetails?.package?.total}
											</MDTypography>

											<MDTypography variant="body2" fontWeight='regular'>
												Total Discount: ${orderDetails?.package?.totalDiscounted}
											</MDTypography>
											<MDTypography variant="body2" fontWeight='regular'>
												Number of Views: {orderDetails?.package?.numberOfPageViews}
											</MDTypography>
											<MDTypography variant="body2" fontWeight='regular'>
												Recurring Interval: {orderDetails?.package?.recurring?.intervalCount}{' '}
												{orderDetails?.package?.recurring?.interval}
											</MDTypography>

											<MDBox display='flex' justifyContent='center' alignItems='center' mt={2}>
												<MDButton variant='contained' color='error' onClick={() => openUnsubscribeDialog()}>Cancel Subscription</MDButton>
											</MDBox>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</>
						: <>
							{!packages ? <MDBox component='form' onSubmit={ePackage} mt={5}>
								<Card sx={{ p: 2 }}>
									<MDTypography variant='h4' textAlign='center' sx={{ my: 2 }}>Enter Monthly views of page</MDTypography>
									<MDInput label='Number of Views' onChange={(e) => setNumberOfViews(e.target.value)} sx={{ mx: 'auto', maxWidth: 400, width: '100%' }} />
									<MDBox display='flex' alignItems='center' justifyContent='center' >
										<MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={getLoading}>proceed</MDButton>
									</MDBox>
								</Card>
							</MDBox> :
								<MDBox component='form' onSubmit={genWidget} mt={5}>
									<Card sx={{ p: 2 }}>
										<MDTypography variant='h3' textAlign='center' sx={{ my: 1 }}>Select Your Tenure</MDTypography>
										<MDBox display='flex' justifyContent='center' mb={4} ><MDButton size='small' variant='outlined' color='primary'
											onClick={() => setPackages(null)}> Change Number of Views?</MDButton></MDBox>
										<PackagesList packages={packages} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} />
										<MDTypography variant='h5' textAlign='center' sx={{ my: 1 }}>Enter Any Extra Info</MDTypography>
										<MDInput  multiline 
					rows={5}
										label='Enter Extra Info' onChange={(e) => setExtraInfo(e.target.value)} sx={{ mx: 'auto', maxWidth: 300, width: '100%' }} />
										<MDBox display='flex' alignItems='center' justifyContent='center'  >
											{/* <Select>
										<MenuItem>Monthly</MenuItem>
									</Select> */}
											<MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={genWidgetLoading}>Submit Order</MDButton>
										</MDBox>
									</Card>
								</MDBox>}
						</>
					}
					{/* } */}
					<Dialog open={confirmationOpen} onClose={billingLoading ? null : closeConfirmationDialog}>
						<DialogTitle>Confirmation</DialogTitle>
						<DialogContent>
							<p>Are you sure you want to subscribe?</p>
						</DialogContent>
						<DialogActions>
							<MDButton onClick={closeConfirmationDialog} disabled={billingLoading}>Cancel</MDButton>
							<MDButton onClick={handleConfirmation} color="primary" loading={billingLoading}>Confirm</MDButton>
						</DialogActions>
					</Dialog>

					<Dialog open={unsubscribeOpen} onClose={unsubscribeLoading ? null : closeUnsubscribenDialog}>
						<DialogTitle>Confirmation</DialogTitle>
						<DialogContent>
							<p>Are you sure you want to cancel subscription?</p>
						</DialogContent>
						<DialogActions>
							<MDButton onClick={closeUnsubscribenDialog} disabled={unsubscribeLoading}>Cancel</MDButton>
							<MDButton onClick={handleUnsubscribe} color="primary" loading={unsubscribeLoading}>Confirm</MDButton>
						</DialogActions>
					</Dialog>
				</>}

		</MDBox>
	)
}



const PackagesList = ({ selectedPackage, setSelectedPackage }) => {

	const handleCardSelect = (type) => {
		setSelectedPackage(type);
	};

	React.useEffect(() => {
		setSelectedPackage("monthly")
	}, [])

	return (
		<MDBox mb={4}>
			<MDBox display='flex' justifyContent='center' gap={2} mb={3}>
				<MDButton variant={selectedPackage === "monthly" ? `contained` : 'outlined'} color='primary' onClick={() => handleCardSelect("monthly")} >Monthly</MDButton>
				<MDButton variant={selectedPackage === "yearly" ? `contained` : 'outlined'} color='primary' onClick={() => handleCardSelect("yearly")}>Yearly</MDButton>
			</MDBox>

		</MDBox >
	);
};