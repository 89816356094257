import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDSwitch from "components/MDSwitch"
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSettings } from "redux/actions/adminSmokiDashboard.action";
import { getSettings } from "redux/actions/adminSmokiDashboard.action";


export default function Settings() {
    const dispatch = useDispatch();

    const [settings, setSettings] = React.useState({
        "sitemapGeneratorLink": "",
        "widgetLink": "",
        // affiliateLink: "",
        scanReportLink: "",
        openaiTokenPrice: "",
        // widgetTrialDays: 0,
        // widgetSubscriptionPrice: 0,
        onePageScannerTemplateLink: '',
        onePageScannerOpenaiKey: "",
        s3ConfigProd: {
            "accessKeyId": "",
            "secretAccessKey": "",
            "region": "",
            "endpoint": "",
            "bucketName": "",
            bucketUrl: ''
        },
        s3ConfigStaging: {
            "accessKeyId": "",
            "secretAccessKey": "",
            "region": "",
            "endpoint": "",
            "bucketName": "",
            bucketUrl: ''
        },
        s3ConfigDev: {
            "accessKeyId": "",
            "secretAccessKey": "",
            "region": "",
            "endpoint": "",
            "bucketName": "",
            bucketUrl: ''
        },
        s3ConfigTemplate: {
            "accessKeyId": "",
            "secretAccessKey": "",
            "region": "",
            "endpoint": "",
            "bucketName": "",
            bucketUrl: ''
        },
        businessEmail: false
    });
    const [loadingChange, setLoadingChange] = React.useState(false);
    const [getLoading, setGetLoading] = React.useState(true);
    const [resolutions, setResolutions] = useState([]);


    // Fetching settings data from server when component mounts
    React.useEffect(() => {
        dispatch(getSettings()).then(data => {
            if (data) {
                setSettings({
                    ...data.result,
                })
                setGetLoading(false)
            }
        });
        // Cleaning up state variables when component unmounts
        return () => {
            setSettings({
                "sitemapGeneratorLink": "",
                "widgetLink": "",
                affiliateLink: "",
                scanReportLink: "",
                openaiTokenPrice: "",
                widgetTrialDays: 0,
                widgetSubscriptionPrice: 0,
                onePageScannerTemplateLink: '',
                onePageScannerOpenaiKey: "",
                s3ConfigProd: {
                    "accessKeyId": "",
                    "secretAccessKey": "",
                    "region": "",
                    "endpoint": "",
                    "bucketName": "",
                    bucketUrl: ''
                },
                s3ConfigStaging: {
                    "accessKeyId": "",
                    "secretAccessKey": "",
                    "region": "",
                    "endpoint": "",
                    "bucketName": "",
                    bucketUrl: ''
                },
                s3ConfigDev: {
                    "accessKeyId": "",
                    "secretAccessKey": "",
                    "region": "",
                    "endpoint": "",
                    "bucketName": "",
                    bucketUrl: ''
                },
                s3ConfigTemplate: {
                    "accessKeyId": "",
                    "secretAccessKey": "",
                    "region": "",
                    "endpoint": "",
                    "bucketName": "",
                    bucketUrl: ''
                }
            })
            setLoadingChange(false)
            setGetLoading(false)
        }
    }, [])

    // Handling change events of form inputs
    function handleChangeValue(e) {
        setSettings({
            ...settings,
            [e.target.name]: e.target.value
        })
    }

    function handleChangeValues3ConfigProd(e) {
        setSettings({
            ...settings,
            s3ConfigProd: {
                ...settings.s3ConfigProd,
                [e.target.name]: e.target.value
            }
        })
    }

    function handleChangeValues3ConfigStaging(e) {
        setSettings({
            ...settings,
            s3ConfigStaging: {
                ...settings.s3ConfigStaging,
                [e.target.name]: e.target.value
            }
        })
    }
    function handleChangeValues3ConfigDev(e) {
        setSettings({
            ...settings,
            s3ConfigDev: {
                ...settings.s3ConfigDev,
                [e.target.name]: e.target.value
            }
        })
    }
    function handleChangeValues3ConfigTemplate(e) {
        setSettings({
            ...settings,
            s3ConfigTemplate: {
                ...settings.s3ConfigTemplate,
                [e.target.name]: e.target.value
            }
        })
    }

    function handleChangeEmail(e) {
        console.log(e)
        console.log(e.target.checked)
        setSettings({
            ...settings,
            businessEmail: e.target.checked
        })
    }

    // Handling form submission
    function handleSubmit(e) {
        e.preventDefault();
        setLoadingChange(true);
        dispatch(updateSettings({ ...settings, auditorResolutions: resolutions })).then(data => {
            if (data) {
                setLoadingChange(false);
            } else {
                setLoadingChange(false);
            }
        }).catch(err => {
            setLoadingChange(false)
        })
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />


            {!getLoading ? <Card sx={{ p: 3, mt: 2 }} component='form' onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Sitemap Link" value={settings?.sitemapGeneratorLink} name='sitemapGeneratorLink' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Widget Link" type='text' value={settings?.widgetLink} name='widgetLink' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Widget Trial Days" type='text' value={settings?.widgetTrialDays} name='widgetTrialDays' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Widget Subscription Price" type='text' value={settings?.widgetSubscriptionPrice} name='widgetSubscriptionPrice' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Affiliate Link" type='text' value={settings?.affiliateLink} name='affiliateLink' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Scan Report Link" type='text' value={settings?.scanReportLink} name='scanReportLink' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="One Page Scanner Tempalte Link" type='text' value={settings?.onePageScannerTemplateLink} name='onePageScannerTemplateLink' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="One Page Scanner OpenAI Key" type='text' value={settings?.onePageScannerOpenaiKey} name='onePageScannerOpenaiKey' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl style={{ width: '100%' }} >
                            <InputLabel>Widget Flow</InputLabel>
                            <Select
                                fullWidth
                                labelId="order-test-widget"
                                id="demo-simple-select"
                                label='Widget Flow'
                                name='widgetFlow'
                                value={settings?.widgetFlow}
                                onChange={handleChangeValue}
                            >
                                <MenuItem value={'automatic'} sx={{ display: 'block' }}>Automatic
                                    <MDTypography sx={{ fontSize: 12 }}>For automatic page views service required.    </MDTypography>
                                </MenuItem>
                                <MenuItem value={'manual'}>Manual</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="OpenAI Token Price" type='number' InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }} value={settings?.openaiTokenPrice} name='openaiTokenPrice' required={true} fullWidth onChange={handleChangeValue} />
                    </Grid>
                </Grid>

                <MDTypography variant='h4' sx={{ my: 1 }}>S3 Config Prod</MDTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Access Key Id" value={settings?.s3ConfigProd?.accessKeyId} name='accessKeyId' required={true} fullWidth onChange={handleChangeValues3ConfigProd} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Secret Access Key" type='text' value={settings?.s3ConfigProd?.secretAccessKey} name='secretAccessKey' required={true} fullWidth onChange={handleChangeValues3ConfigProd} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Region" type='text' value={settings?.s3ConfigProd?.region} name='region' required={true} fullWidth onChange={handleChangeValues3ConfigProd} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Endpoint" type='text' value={settings?.s3ConfigProd?.endpoint} name='endpoint' required={true} fullWidth onChange={handleChangeValues3ConfigProd} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket Name" type='text' value={settings?.s3ConfigProd?.bucketName} name='bucketName' required={true} fullWidth onChange={handleChangeValues3ConfigProd} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket URL" type='text' value={settings?.s3ConfigProd?.bucketUrl} name='bucketUrl' required={true} fullWidth onChange={handleChangeValues3ConfigProd} />
                    </Grid>
                </Grid>

                <MDTypography variant='h4' sx={{ my: 1 }}>S3 Config Staging</MDTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Access Key Id" value={settings?.s3ConfigStaging?.accessKeyId} name='accessKeyId' required={true} fullWidth onChange={handleChangeValues3ConfigStaging} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Secret Access Key" type='text' value={settings?.s3ConfigStaging?.secretAccessKey} name='secretAccessKey' required={true} fullWidth onChange={handleChangeValues3ConfigStaging} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Region" type='text' value={settings?.s3ConfigStaging?.region} name='region' required={true} fullWidth onChange={handleChangeValues3ConfigStaging} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Endpoint" type='text' value={settings?.s3ConfigStaging?.endpoint} name='endpoint' required={true} fullWidth onChange={handleChangeValues3ConfigStaging} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket Name" type='text' value={settings?.s3ConfigStaging?.bucketName} name='bucketName' required={true} fullWidth onChange={handleChangeValues3ConfigStaging} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket URL" type='text' value={settings?.s3ConfigStaging?.bucketUrl} name='bucketUrl' required={true} fullWidth onChange={handleChangeValues3ConfigStaging} />
                    </Grid>
                </Grid>

                <MDTypography variant='h4' sx={{ my: 1 }}>S3 Config Dev</MDTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Access Key Id" value={settings?.s3ConfigDev?.accessKeyId} name='accessKeyId' required={true} fullWidth onChange={handleChangeValues3ConfigDev} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Secret Access Key" type='text' value={settings?.s3ConfigDev?.secretAccessKey} name='secretAccessKey' required={true} fullWidth onChange={handleChangeValues3ConfigDev} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Region" type='text' value={settings?.s3ConfigDev?.region} name='region' required={true} fullWidth onChange={handleChangeValues3ConfigDev} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Endpoint" type='text' value={settings?.s3ConfigDev?.endpoint} name='endpoint' required={true} fullWidth onChange={handleChangeValues3ConfigDev} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket Name" type='text' value={settings?.s3ConfigDev?.bucketName} name='bucketName' required={true} fullWidth onChange={handleChangeValues3ConfigDev} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket URL" type='text' value={settings?.s3ConfigDev?.bucketUrl} name='bucketUrl' required={true} fullWidth onChange={handleChangeValues3ConfigDev} />
                    </Grid>
                </Grid>


                <MDTypography variant='h4' sx={{ my: 1 }}>S3 Config Template</MDTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Access Key Id" value={settings?.s3ConfigTemplate?.accessKeyId} name='accessKeyId' required={true} fullWidth onChange={handleChangeValues3ConfigTemplate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Secret Access Key" type='text' value={settings?.s3ConfigTemplate?.secretAccessKey} name='secretAccessKey' required={true} fullWidth onChange={handleChangeValues3ConfigTemplate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Region" type='text' value={settings?.s3ConfigTemplate?.region} name='region' required={true} fullWidth onChange={handleChangeValues3ConfigTemplate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Endpoint" type='text' value={settings?.s3ConfigTemplate?.endpoint} name='endpoint' required={true} fullWidth onChange={handleChangeValues3ConfigTemplate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket Name" type='text' value={settings?.s3ConfigTemplate?.bucketName} name='bucketName' required={true} fullWidth onChange={handleChangeValues3ConfigTemplate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput sx={{ my: 0.5 }} label="Bucket URL" type='text' value={settings?.s3ConfigTemplate?.bucketUrl} name='bucketUrl' required={true} fullWidth onChange={handleChangeValues3ConfigTemplate} />
                    </Grid>
                </Grid>
                <MDBox my={3}>
                    <ResolutionComponent resolutions={resolutions} setResolutions={setResolutions} />
                </MDBox>
                <MDBox my={3} style={{ display: "flex", alignItems: "center" }}>
                    <MDTypography variant='h4' sx={{ my: 1 }}>Buisness Email</MDTypography>
                    <MDBox ml={4}>
                        <MDSwitch onChange={handleChangeEmail} email={settings.businessEmail} />
                    </MDBox>
                </MDBox>

                <MDBox display='flex' justifyContent='center' alignItems='center' my={3}>
                    <MDButton color='info' variant='contained' type='submit' loading={loadingChange}>Update</MDButton>
                </MDBox>
            </Card> :
                <MDBox height='65vh' display='flex' alignItems='center' justifyContent='center'>
                    <MDProgress circular />
                </MDBox>
            }
        </DashboardLayout>
    )
}




const ResolutionComponent = ({ resolutions, setResolutions }) => {
    const [newResolution, setNewResolution] = useState('');
    const [newDeviceType, setNewDeviceType] = useState('');
    const auditorResolutions = useSelector(state => state.auth.config?.auditorResolutions) || []

    const handleResolutionDelete = (index) => {
        const updatedResolutions = [...resolutions];
        updatedResolutions.splice(index, 1);
        setResolutions(updatedResolutions);
    };

    const handleResolutionAdd = () => {
        if (newResolution.trim() !== '' && newDeviceType.trim() !== '') {
            const resolutionObject = {
                resolution: newResolution,
                deviceType: newDeviceType,
            };
            setResolutions([...resolutions, resolutionObject]);
            setNewResolution('');
            setNewDeviceType('');
        }
    };
    React.useEffect(() => {
        if (auditorResolutions) {
            setResolutions(auditorResolutions)
        }
    }, [auditorResolutions])
    return (
        <MDBox>
            <MDTypography sx={{ mb: 3 }} variant="h5">
                Auditor Resolutions
            </MDTypography>
            <Grid container spacing={2}>
                {resolutions.map((resolution, index) => (
                    <Grid item xs={12} sm={6} lg={3} key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MDTypography variant='subtitle2' sx={{ fontWeight: 'bold' }}>{index + 1}- {`${resolution.deviceType} - ${resolution.resolution}`}</MDTypography>
                            <MDButton
                                variant="outlined"
                                color="error"
                                size='small'
                                onClick={() => handleResolutionDelete(index)}
                            >
                                Delete
                            </MDButton>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <MDBox display='flex' gap={2} alignItems='center'>
                <MDInput
                    label="Device Type"
                    value={newDeviceType}
                    onChange={(e) => setNewDeviceType(e.target.value)}
                    sx={{ marginTop: 2 }}
                />
                <MDInput
                    label="Resolution"
                    value={newResolution}
                    onChange={(e) => setNewResolution(e.target.value)}
                    sx={{ marginTop: 2 }}
                />
                <MDButton variant="contained" color='success' size='small' sx={{ mt: 1.5, padding: "4px 20px" }} onClick={handleResolutionAdd}>
                    Add Resolution
                </MDButton>
            </MDBox>
        </MDBox>
    );
};