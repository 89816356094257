import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDProgress from "components/MDProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useDispatch } from "react-redux";
import { getOtherWidgets } from "redux/actions/userDashboard.action";


import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { TableContainer } from "@mui/material";
import { useMaterialUIController } from "context";

import PermissionBox from "components/PermissionBox";

export default function CustomWidgets() {

	const dispatch = useDispatch();
    const {others}=useSelector(state=>state.auth.user.permissions)

	const [page, setPage] = React.useState(1);
	const [pages, setPages] = React.useState(1);
	const [data, setData] = React.useState([]);

	const [getLoading, setGetLoading] = React.useState(false);


	function getLink() {
		setGetLoading(true);
		dispatch(getOtherWidgets(page)).then(data => {
			if (data) {
				setData(data.result);
				setPages(data.pages);

				setGetLoading(false);
			}
		}).catch(err => {
			setGetLoading(false)
		});
	}


	React.useEffect(() => {
		getLink()
		return () => {
			setGetLoading(false)
		}
	}, [page])


	return (
		<DashboardLayout>
			<DashboardNavbar />
			{
				others && others.viewOtherWidgets ?
			<MDBox mt={3}>
				<Widgets page={page} pages={pages} rows={data} setPage={setPage} getLink={getLink} loading={getLoading} />

			</MDBox>:
			<PermissionBox text={"to view other widget details"} />
			}
		</DashboardLayout>
	)
}



function Widgets({ pages, rows, page, setPage, loading, getLink }) {


    const {others}=useSelector(state=>state.auth.user.permissions)

	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	return (
		<Card sx={{ mt: 6 }}>
			<MDBox
				mx={2}
				mt={-3}
				py={3}
				px={2}
				variant="gradient"
				bgColor="info"
				borderRadius="lg"
				coloredShadow="info"
			>
				<MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize' }}>
					Other	Widgets
				</MDTypography>
			</MDBox>
			<TableContainer>
				<Table size="small" aria-label="a dense table">
					<thead>
						<TableRow>
							<TableCell align="left" sx={{ minWidth: 190 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									ID
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Active
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Website
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Trial
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Snippet
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 200 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Snippet Injector
								</MDTypography>
							</TableCell>
							{
								others && others?.editOtherWidgets &&
							<TableCell align="left" sx={{ minWidth: 200 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Actions
								</MDTypography>
							</TableCell>
							}
							
						</TableRow>
					</thead>
					{loading ?
						<TableBody>
							<TableRow >
								<TableCell align="center" colSpan={6} >
									<MDBox
										display="flex"
										py={1}
										flexDirection='column'
										justifyContent="center"
										alignItems="center"
									>
										<MDProgress circular />
										<MDTypography variant="h6">
											Loading Widgets...
										</MDTypography>
									</MDBox>
								</TableCell>
							</TableRow>
						</TableBody>
						:
						<TableBody>
							{rows && rows.length > 0 ? rows.map((row, index) => {
								return <OrderRow getLink={getLink} row={row} key={`order-${index}`} edit={others && others?.editOtherWidgets} />
							}) :
								<TableRow>
									<TableCell align="center" colSpan={6} >
										<MDTypography variant='caption' component='span' fontWeight="bold">no widgets found</MDTypography>
									</TableCell>
								</TableRow>
							}
						</TableBody>}
				</Table>
				{rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
					<Pagination color='info' variant="outlined"
						sx={{
							'& .MuiPagination-ul > li > button': {
								fontWeight: 400,
								color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
							},
							'& .MuiPagination-ul > li > div': {
								color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
							}
						}}
						count={pages} page={page} onChange={(e, value) => setPage(value)} />
				</MDBox>}
			</TableContainer>
		</Card>


	);
};


function OrderRow({ row ,edit}) {
	const navigate=useNavigate()
	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" >
						{row?.id}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row?.active ? "True" : "False"}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={`https://${row?.hostname}`}>
						{row?.hostname}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row?.trial ? "True" : "False"}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => handleOpenDialog({ data: row.snippet, type: 'Snippet' })} >
						Show Snippet
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => handleOpenDialog({ data: row.snippetInjector, type: 'Snippet Injector' })} >
						Show Snippet Injector
					</MDTypography>
				</TableCell>
				{
					edit &&
				<TableCell align="left">
                    <MDBox component='span' display='flex' alignItems='center' gap={2}>
                        <MDButton variant='contained' color="info" onClick={() => navigate(`/user/main/accessBlend/edit/widget/${row.id}`)}> Settings</MDButton>
                        <MDButton variant='contained' color="info" onClick={() => navigate(`/user/main/accessBlend/snippets/${row.id}`)}> Manage Snippets</MDButton>
                    </MDBox>
                </TableCell>
				}
			</TableRow>
			{/* <Dialog open={Boolean(open)} onClose={handleCloseDialog} fullWidth maxWidth='md'>
				<DialogTitle>{open.type}</DialogTitle>

				<DialogContent>
					<MDBox display='flex' alignItems='center' gap={2} p={2}><MDTypography variant='body2' sx={{ fontSize: '12px' }}><code>{open.data}</code></MDTypography><CopyToClipboard text={open.data} onCopy={() => toast.success("Snippet Copied!")}>
						<IconButton edge="end">
							<FileCopyIcon />
						</IconButton>
					</CopyToClipboard></MDBox>
				</DialogContent>
			</Dialog>
			<Dialog open={openD} onClose={loading ? null : closeConfirmationDialog}>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete?</p>
				</DialogContent>
				<DialogActions>
					<MDButton onClick={closeConfirmationDialog} disabled={loading}>Cancel</MDButton>
					<MDButton onClick={handleConfirmation} color="primary" loading={loading}>Confirm</MDButton>
				</DialogActions>
			</Dialog> */}
		</React.Fragment>
	)
}