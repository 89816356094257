
import config from 'config'

let BASE_URL = "";
if (process.env.REACT_APP_ENVIRONM === "prod") {
    BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
} else {
    console.log("BASE URL:: ", process.env.REACT_APP_BASE_URL_DEV)
    BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
}

let FIREBASE_API_KEY = config.FIREBASE_API_KEY;
const FIREBASE_MAIN_CONFIG = config.FIREBASE_MAIN_CONFIG;


export { BASE_URL, FIREBASE_API_KEY, FIREBASE_MAIN_CONFIG };