import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import colors from 'assets/theme/base/colors';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectInput({ width, id, label, ...rest }) {
  const { grey } = colors;
  const [versions, setVersions] = React.useState([])

  React.useEffect(() => {
    if (localStorage.getItem("config")) {
      const config = JSON.parse(localStorage.getItem("config"))
      setVersions(config['auditorVersions'])
    }
  }, [localStorage.getItem("config")])

  return (
    <Box sx={{ width: '100%', maxWidth: width ? width : 500, borderRadius: "10px" }}>
      <FormControl fullWidth>
        <Autocomplete
          {...rest}
          label={label}
          options={versions}

          renderInput={(params) => <TextField {...params} label="Select or type Version" />}
          freeSolo
          autoSelect
          selectOnFocus
        />
      </FormControl>
    </Box>
  );
}