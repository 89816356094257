import MDBox from "components/MDBox";
// import OnBoardLayout from './components/BasicLayout/index';
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import emailVerify from 'assets/images/verify-email.png'
import OnBoardLayout from "pages/onboarding/enterprise/BasicLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUserWithoutLoading } from "redux/actions/auth.action";
import { PREV_PATH } from "redux/actions/auth.action";



export default function VerifyEmail(props) {
	const location = useLocation()
	const email = location?.state?.email;
	const user = useSelector(state => state.auth.user);
	const prevPath = useSelector(state => state.auth.prevPath)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	React.useEffect(() => {
		const interval = setInterval(() => {
			dispatch(loadUserWithoutLoading())
		}, 5000);

		// Clean up the interval when the component unmounts
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		dispatch({
			type: PREV_PATH,
			payload: null
		})
	}, [])

	if (user.emailVerified) {
		navigate("/user/main/accessBlend/websites")
	}
	return (
		<>
			<OnBoardLayout image={bgImage}>
				<MDBox
					height='100%'
					justifyContent='center'
					mx='auto'
					px={5}
					display='flex'
					width='100%'
					maxWidth={900}
					minHeight={300}
				>
					<Card sx={{ width: `100%` }} >
						<CardContent sx={{ height: '100%', }} >
							<MDBox
								height='100%'
								justifyContent='center'
								alignItems='center'
								mx='auto'
								pt={5}
								px={5}
								display='flex'
								width='100%'
								flexDirection='column'
								textAlign='center'

							>
								<img src={emailVerify} style={{ width: '100%', maxWidth: 350 }} />
								<MDTypography variant='h2' sx={{ fontWeight: 400, my: 2 }}>Verify your email</MDTypography>
								<MDTypography variant='body2' align='center' sx={{ mb: 3 }}>We have sent an email to <b>{email}. </b>Kindly verify your email address and activate your account.The link in the email will expire in 24 hours. </MDTypography>
							</MDBox>
						</CardContent>
					</Card>
				</MDBox>
			</OnBoardLayout>
		</>
	)
}