import { FormControl, Grid, Card, CardContent, Radio, DialogContent, Dialog, IconButton, InputAdornment, DialogTitle, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDProgress from "components/MDProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWidgetLink, getWidgetOrderDetails } from "redux/actions/userDashboard.action";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { TableContainer } from "@mui/material";

import { useMaterialUIController } from "context";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import WidgetBilling from "./Billing";
import PermissionBox from "components/PermissionBox";

export default function Widget() {

    const {widget}=useSelector(state=>state.auth.user.permissions)


    const dispatch = useDispatch();
    const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)

    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(1);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [orderDetails, setOrderDetails] = React.useState(null);


    const [getLoading, setGetLoading] = React.useState(false);


    const [getWidget, setGetWidget] = React.useState(false);

    function getLink() {
        setGetLoading(true);
        if(selectedSubdomain){

            dispatch(getWidgetLink({ page, websiteId: selectedSubdomain.id })).then(data => {
                if (data) {
                    setData(data.result);
                    setPages(data.pages);
    
                    setGetLoading(false);
                }
            }).catch(err => {
                setGetLoading(false)
            });
        }
    }



    React.useEffect(() => {
        if (selectedSubdomain && selectedSubdomain.widget) {
            getLink();
        }
        return () => {
            setGetLoading(false)
        }
    }, [page, selectedSubdomain])


    function handleClick() {
        setGetWidget(true)
    }


    function getOrderDetails() {
        setLoading(true);
        if(selectedSubdomain){
            dispatch(getWidgetOrderDetails(selectedSubdomain.id)).then(data => {
                if (data) {
                    setOrderDetails(data.result);
                    setLoading(false);
                }
            }).catch(err => {
                setLoading(false)
            });
        }
    }

    React.useEffect(() => {
        if (selectedSubdomain?.widget) {
            getOrderDetails();
        } else {
            setLoading(false);

        }
    }, [selectedSubdomain]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {loading ?
                <MDBox display='flex' alignItems='center' justifyContent='center' height='60vh' >
                    <CircularProgress size={36} />
                </MDBox>
                : 
                <MDBox>
                    {selectedSubdomain && !getWidget && !selectedSubdomain.widget ? <>

                        <Card sx={{ mt: 3, p: 2 }}>
                            <MDBox mt={3}>
                                <MDTypography variant='h3' sx={{ mb: 1, textAlign: 'center' }}>Enhance Accessibility, Embrace Inclusivity</MDTypography>


                                <MDTypography variant='subtitle2' sx={{ fontWeight: 'regular', textAlign: 'center', maxWidth: 800, mx: 'auto' }} >Make your website or application a more inclusive and compliant with the AccessBlend
                                    Accessibility Widget. Our powerful tool is designed to enhance the accessibility of
                                    your website, ensuring that all users, including those with disabilities, can engage with
                                    your platform effortlessly. </MDTypography>


                                <Grid container spacing={2} sx={{ mt: 3, mb: 2 }}>
                                    <Grid item xs={12} md={6}>
                                        <MDTypography variant='h4' sx={{ mb: 2, ml: 1 }}>Salient Features:</MDTypography>
                                        <ol style={{ listStyleType: 'decimal', paddingLeft: '1.5rem' }}>
                                            <li><MDTypography variant='subtitle2' sx={{ fontWeight: 'regular' }} ><strong>Disability Profiles</strong>: Our widget allows your
                                                users to select different disability profiles,
                                                ensuring your website caters to the
                                                specific needs of disabled individuals,
                                                making it truly inclusive and compliant.</MDTypography></li>
                                            <li><MDTypography variant='subtitle2' sx={{ fontWeight: 'regular' }} ><strong>Live Adjustment</strong>: Achieve accessibility
                                                compliance in real-time with our live UI
                                                adjustment features. Users can make
                                                necessary modifications on the fly to
                                                enhance their experience during their
                                                interactive session.</MDTypography></li>
                                            <li><MDTypography variant='subtitle2' sx={{ fontWeight: 'regular' }} ><strong>Live Code Injection</strong>: Fix accessibility
                                                issues on your website instantly with our
                                                JavaScript code injection capability.
                                                Watch as your site becomes more
                                                accessible with every line of code you
                                                inject!</MDTypography></li>
                                            <li><MDTypography variant='subtitle2' sx={{ fontWeight: 'regular' }} ><strong>AND MANY MORE...</strong></MDTypography></li>
                                        </ol>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MDBox textAlign='center'>
                                            <img src='/assets/widgets/default.png' style={{ height: 400, margin: 'auto' }} />
                                        </MDBox>
                                    </Grid>
                                </Grid>
                                <MDTypography variant='subtitle2' sx={{ fontWeight: 'regular' }}  >
                                    Discover how <strong>AccessBlend</strong> can transform your website's accessibility and take it to the
                                    next level. Click the button below to learn more about our features, pricing, and
                                    implementation process.
                                </MDTypography>
                                <MDBox display='flex' justifyContent='center' my={3} >
                                    <MDButton variant='gradient' color='primary' size='large' onClick={() => handleClick()}>Learn More</MDButton>
                                </MDBox>
                                <MDTypography variant='h3' sx={{ mb: 1, textAlign: 'center' }}>        Empower Your Website, Empower Your Users</MDTypography>
                                <MDTypography variant='subtitle2' sx={{ fontWeight: 'regular' }} textAlign='center' >By incorporating AccessBlend, you are taking a significant step towards building a more accessible
                                    and inclusive digital experience. Join the ranks of organizations committed to inclusivity and get
                                    compliant today!</MDTypography>
                                <MDBox display='flex' justifyContent='center' my={3} >
                                    <MDButton variant='gradient' color='primary' size='large' onClick={() => handleClick()}>YES, MAKE US COMPLIANT NOW!</MDButton>
                                </MDBox>

                            </MDBox>


                        </Card>


                    </> : <MDBox>
                        <Card sx={{ mt: 3, p: 2 }}>
                            <WidgetBilling orderDetails={orderDetails} />
                        </Card>
                        {orderDetails?.status === 'completed' && <Widgets page={page} pages={pages} rows={data} setPage={setPage} loading={getLoading} />}
                    </MDBox>
                    }
                </MDBox>}
        </DashboardLayout>
    )
}



function Widgets({ pages, rows, page, setPage, loading }) {

    const {widget}=useSelector(state=>state.auth.user.permissions)

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <Card sx={{ mt: 6 }}>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize' }}>
                    Widgets
                </MDTypography>
            </MDBox>
            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <thead>
                        <TableRow>
                            <TableCell align="left" sx={{ minWidth: 190 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    ID
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Active
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Website
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Trial
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Snippet
                                </MDTypography>
                            </TableCell>
                            {/* <TableCell align="left" sx={{ minWidth: 200 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Snippet Injector
                                </MDTypography>
                            </TableCell> */}
                            <TableCell align="left" sx={{ minWidth: 400 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Action
                                </MDTypography>
                            </TableCell>
                        </TableRow>
                    </thead>
                    {loading ?
                        <TableBody>
                            <TableRow >
                                <TableCell align="center" colSpan={6} >
                                    <MDBox
                                        display="flex"
                                        py={1}
                                        flexDirection='column'
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <MDProgress circular />
                                        <MDTypography variant="h6">
                                            Loading Widgets...
                                        </MDTypography>
                                    </MDBox>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {rows && rows.length > 0 ? rows.map((row, index) => {
                                return <OrderRow row={row} key={`order-${index}`}  editSnipt={widget && widget?.editWidgetSnippets}
                                editSetting={widget && widget?.editWidgetSettings}
                                />
                            }) :
                                <TableRow>
                                    <TableCell align="center" colSpan={6} >
                                        <MDTypography variant='caption' component='span' fontWeight="bold">no widgets found</MDTypography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>}
                </Table>
                {rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                    <Pagination color='info' variant="outlined"
                        sx={{
                            '& .MuiPagination-ul > li > button': {
                                fontWeight: 400,
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            },
                            '& .MuiPagination-ul > li > div': {
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            }
                        }}
                        count={pages} page={page} onChange={(e, value) => setPage(value)} />
                </MDBox>}
            </TableContainer>
        </Card>


    );
};


function OrderRow({ row,editSnipt,editSetting }) {
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);

    const handleOpenDialog = (data) => {
        setOpen(data);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };
const [controller]=useMaterialUIController()
  const { darkMode } = controller;
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row?.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.active ? "True" : "False"}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={`https://${row?.hostname}`}>
                        {row?.hostname}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.trial ? "True" : "False"}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => handleOpenDialog({ data: row, type: 'Snippet' })} >
                        Show Snippet
                    </MDTypography>
                </TableCell>
                {/* <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => handleOpenDialog({ data: row.snippetInjector, type: 'Snippet Injector' })} >
                        Show Snippet Injector
                    </MDTypography>
                </TableCell> */}
                <TableCell align="left">
                    <MDBox component='span' display='flex' alignItems='center' gap={2}>
                        {
                            editSetting &&
                        <MDButton variant='contained' color="info" onClick={() => navigate(`/user/main/accessBlend/edit/widget/${row.id}`)}> Settings</MDButton>
                        }
                        {
                            editSnipt &&
                        <MDButton variant='contained' color="info" onClick={() => navigate(`/user/main/accessBlend/snippets/${row.id}`)}> Manage Snippets</MDButton>
                        }
                    </MDBox>
                </TableCell>
            </TableRow>
            <Dialog open={Boolean(open)} onClose={handleCloseDialog} fullWidth maxWidth='md'>
                <DialogTitle>{open.type}</DialogTitle>

                <DialogContent>
                    {"<!-- AccessBlend Widget and Snippets Injector code -->"}

                    <MDBox display='flex' alignItems='center' gap={2} p={2}>
                        <MDTypography variant='body2' sx={{ fontSize: '12px' }}><code>{open?.data?.snippet}</code></MDTypography><CopyToClipboard text={open.data} onCopy={() => toast.success("Snippet Copied!")}>
                            <IconButton edge="end">
                                <FileCopyIcon sx={{ color:darkMode?"white":"black"}} />
                            </IconButton>
                        </CopyToClipboard></MDBox>
                    <MDBox display='flex' alignItems='center' gap={2} p={2}>
                        <MDTypography variant='body2' sx={{ fontSize: '12px' }}><code>{open?.data?.snippetInjector}</code></MDTypography><CopyToClipboard text={open.data} onCopy={() => toast.success("Snippet Injector Copied!")}>
                            <IconButton edge="end">
                                <FileCopyIcon sx={{ color:darkMode?"white":"black"}} />
                            </IconButton>
                        </CopyToClipboard></MDBox>

                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}






