import * as Actions from '../actions/auth.action';

const initialState = {
  isAuth: false,
  user: null,
  userLoading: true,
  createuserloading: false,
  config: null,
  showAds: false,
  emailVerified: true,
  email: '',
  prevPath: null
};

function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Actions.USER_LOADED:
      return {
        ...state,
        user: payload.user,
        userLoading: false,
        showAds: payload?.user?.package?.name === "Free",
        config: JSON.parse(localStorage.getItem("config"))
      };
    case Actions.USER_LOADING:
      return {
        ...state,
        userLoading: true
      };
    case Actions.LOGOUT_USER:
      return {
        ...state,
        user: null,
        userLoading: false
      };
    case Actions.EMAIL_VERIFIED:
      return {
        ...state,
        emailVerified: payload.flag,
        email: payload.email,

      };
    case Actions.PREV_PATH:
      return {
        ...state,
        prevPath: payload,
      };
    case Actions.CREATING_USER:
      return {
        ...state,
        createuserloading: !state.createuserloading
      };
    default:
      return state;
  }
}

export default auth;
