import * as Actions from '../actions/userDashboard.action';

const initialState = {
  analytics: null,
  loading: true,

  allUserOrders: [],
  allUserOrdersColumns: [],
  allUserOrdersPages: 0,
  allUserOrdersLoading: false,
  websites: [],
  subdomains: [],
  selectedWebsite: null,
  selectedSubdomain: null,

  otherWebsites: [],
  otherWidgets: [],

};

function userDashboard(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_USER_ANALYTICS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_USER_ANALYTICS:
      return {
        ...state,
        analytics: payload,
        loading: false,
      };
    case Actions.GET_ALL_USERS_ORDERS:
      return {
        ...state,
        allUserOrders: payload.result,
        allUserOrdersPages: payload.pages,
        allUserOrdersLoading: false
      };
    case Actions.GET_ALL_USERS_ORDERS_LOADING:
      return {
        ...state,
        allUserOrdersLoading: !state.allUserOrdersLoading
      };
    case Actions.GET_ALL_USERS_ORDERS_LOADING:
      return {
        ...state,
        allUserOrdersLoading: !state.allUserOrdersLoading
      };
    case Actions.SET_USER_WEBSITES:
      return {
        ...state,
        websites: payload,
      };
    case Actions.SET_SELECTED_WEBSITE:
      return {
        ...state,
        selectedWebsite: payload
      };
    case Actions.SET_USER_SUBDOMAIN:
      return {
        ...state,
        selectedSubdomain: payload
      };
    default:
      return state;
  }
}

export default userDashboard;
