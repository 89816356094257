import app from "firebase-config";
import { getAuth } from "firebase/auth";
const auth = getAuth();


function getFRToken() {
  if (auth.currentUser) {
    const tok = auth.currentUser.getIdToken().then(function (token) {
      return `Bearer ${token}`
    });
    return tok
  }
}




export { getFRToken, auth }