import React from 'react';

//   components
import MDBox from "components/MDBox";

//   example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import WidgetOrders from './WidgetOrders';


function AllWidgetOrders() {

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox py={3}>
				<>
					<MDBox mt={6}>
						<WidgetOrders status='pending' />
						<WidgetOrders status='processing' />
						<WidgetOrders status='completed' />
						<WidgetOrders status='cancelled' />
					</MDBox>
				</>
			</MDBox>
		</DashboardLayout>
	);
}

export default AllWidgetOrders;
