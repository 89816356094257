import { FormControl, Grid, InputLabel, MenuItem, Card, CardContent, Radio, Dialog, } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDProgress from "components/MDProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWidgetAdminSnippets, addWidgetAdminSnippets } from "redux/actions/adminSmokiDashboard.action";
import MuiSelect from '@mui/material/Select'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { TableContainer } from "@mui/material";
import { useMaterialUIController } from "context";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { deleteAdminSnippet } from "redux/actions/adminSmokiDashboard.action";
import { useParams } from "react-router-dom";


export default function AdminSnippets() {

	const dispatch = useDispatch();

	const { widgetId } = useParams()
	const [page, setPage] = React.useState(1);
	const [pages, setPages] = React.useState(1);
	const [data, setData] = React.useState([]);


	const [snippetValues, setSnippetValues] = React.useState({
		widgetId: '',
		global: false,
		disable: false,
		name: '',
		path: '*',
		code: ''
	})
	const [genWidgetLoading, setGenWidgetLoading] = React.useState(false);
	const [getLoading, setGetLoading] = React.useState(false);

	const [eLoading, setELoading] = React.useState(false);

	function getLink() {
		setGetLoading(true);
		dispatch(getWidgetAdminSnippets({ page, widgetId })).then(data => {
			if (data) {
				setData(data.result);
				setPages(data.pages);
				setGetLoading(false);
			}
		}).catch(err => {
			setGetLoading(false)
		});
	}


	React.useEffect(() => {
		getLink()
		return () => {
			setELoading(false)
			setGetLoading(false)
		}
	}, [page])

	function genWidget(e) {
		e.preventDefault();
		setGenWidgetLoading(true)
		dispatch(addWidgetAdminSnippets({
			...snippetValues, widgetId
		})).then(data => {
			if (data) {
				setSnippetValues({
					widgetId: '',
					global: false,
					disable: false,
					name: '',
					path: '*',
					code: ''
				})
				getLink(1)
			}
			setGenWidgetLoading(false)

		}).catch(err => {
			setGenWidgetLoading(false)
		})
	}

	function handleChange(e) {
		setSnippetValues({ ...snippetValues, [e.target.name]: e.target.value })
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox>
				<MDBox component='form' onSubmit={genWidget} mt={2}>
					<Card sx={{ p: 2 }}>
						<MDTypography variant='h5' sx={{ my: 2 }}>Add Widget Snippet</MDTypography>
						<Grid container spacing={3}>
							{/* <Grid item xs={12} md={12}>
								<MDInput variant='outlined' name='widgetId' label='Widget ID' fullWidth onChange={handleChange} required={true} />
							</Grid> */}
							<Grid item xs={12} md={6}>
								<MDInput variant='outlined' name='name' label='Name' fullWidth onChange={handleChange} value={snippetValues.name} required={true} />
							</Grid>
							<Grid item xs={12} md={6}>
								<MDInput variant='outlined' name='path' label='Path' fullWidth onChange={handleChange} value={snippetValues.path} required={true} />
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl style={{ width: '100%' }} >
									<InputLabel>Disable</InputLabel>
									<MuiSelect
										fullWidth onChange={handleChange}
										labelId="order-test"
										id="demo-simple-select"
										label='Disable'
										name='disable'
										value={Boolean(snippetValues?.disable)}
									>
										<MenuItem value={true}>True</MenuItem>
										<MenuItem value={false}>False</MenuItem>
									</MuiSelect>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={12}>
								<MDInput variant='outlined' name='code' label='Code' multiline rows={8} fullWidth onChange={handleChange} value={snippetValues.code} required={true} />
							</Grid>

						</Grid>
						<MDBox display='flex' justifyContent='center' >
							<MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={genWidgetLoading}>Submit</MDButton>
						</MDBox>
					</Card>
				</MDBox>

				<Widgets page={page} pages={pages} rows={data} setPage={setPage} loading={getLoading} getLink={getLink} widgetId={widgetId} />

			</MDBox>
		</DashboardLayout>
	)
}



function Widgets({ pages, rows, page, setPage, loading, getLink, widgetId }) {


	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	return (
		<Card sx={{ mt: 6 }}>
			<MDBox
				mx={2}
				mt={-3}
				py={3}
				px={2}
				variant="gradient"
				bgColor="info"
				borderRadius="lg"
				coloredShadow="info"
			>
				<MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize' }}>
					Snippets
				</MDTypography>
			</MDBox>
			<TableContainer>
				<Table size="small" aria-label="a dense table">
					<thead>
						<TableRow>
							<TableCell align="left" sx={{ minWidth: 190 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Name
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Global
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Disabled
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Path
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 180 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Snippet Link
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 200 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Action
								</MDTypography>
							</TableCell>
						</TableRow>
					</thead>
					{loading ?
						<TableBody>
							<TableRow >
								<TableCell align="center" colSpan={6} >
									<MDBox
										display="flex"
										py={1}
										flexDirection='column'
										justifyContent="center"
										alignItems="center"
									>
										<MDProgress circular />
										<MDTypography variant="h6">
											Loading Widgets Snippets...
										</MDTypography>
									</MDBox>
								</TableCell>
							</TableRow>
						</TableBody>
						:
						<TableBody>
							{rows && rows.length > 0 ? rows.map((row, index) => {
								return <OrderRow row={row} key={`order-${index}`} getLink={getLink} widgetId={widgetId} />
							}) :
								<TableRow>
									<TableCell align="center" colSpan={6} >
										<MDTypography variant='caption' component='span' fontWeight="bold">no Widgets Snippets found</MDTypography>
									</TableCell>
								</TableRow>
							}
						</TableBody>}
				</Table>
				{rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
					<Pagination color='info' variant="outlined"
						sx={{
							'& .MuiPagination-ul > li > button': {
								fontWeight: 400,
								color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
							},
							'& .MuiPagination-ul > li > div': {
								color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
							}
						}}
						count={pages} page={page} onChange={(e, value) => setPage(value)} />
				</MDBox>}
			</TableContainer>
		</Card>


	);
};


function OrderRow({ row, getLink, widgetId }) {
	const websites = useSelector(state => state.userDashboard.websites)
const [controller]=useMaterialUIController()
  const { darkMode } = controller;
	const dispatch = useDispatch();

	const [deleteLoading, setDeleteLoading] = React.useState(false)

	const [actionModal, setActionModal] = React.useState("");
	const [snippetValues, setSnippetValues] = React.useState({
		widgetId: widgetId,
		global: row.global,
		disable: row.disable,
		name: row.name,
		path: row.path,
		code: row.code
	})

	function openActioneModal(data) {
		setActionModal(data)
	}

	function handleDelete() {
		setDeleteLoading(true);
		dispatch(deleteAdminSnippet(row.id)).then(data => {
			getLink()
			setDeleteLoading(false)
			openActioneModal(null)
		})
	}

	const [genWidgetLoading, setGenWidgetLoading] = React.useState(false);

	function genWidget(e) {
		e.preventDefault();
		setGenWidgetLoading(true)
		dispatch(addWidgetAdminSnippets({
			...snippetValues
		})).then(data => {
			if (data) {
				setSnippetValues({
					widgetId: '',
					global: false,
					disable: false,
					name: '',
					path: '*',
					code: ''
				})
				getLink()
			}
			setGenWidgetLoading(false)

		}).catch(err => {
			setGenWidgetLoading(false)
		})
	}

	function handleChange(e) {
		setSnippetValues({ ...snippetValues, [e.target.name]: e.target.value })
	}

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" >
						{row?.name}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row?.global ? "True" : "False"}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row?.disable ? "True" : "False"}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={`https://${row?.website}`}>
						{row?.path}
					</MDTypography>
				</TableCell>

				<TableCell align="left">
					<CopyToClipboard text={row.link} onCopy={() => toast.success("Snippet Copied!")}>
						<MDTypography variant="caption" fontWeight="bold" style={{ cursor: 'pointer' }} >
							Copy Snippet
						</MDTypography>
					</CopyToClipboard>
				</TableCell>
				<TableCell align="left">
					<MDBox component='span' display='flex' alignItems='center' gap={2}>
						<MDButton variant='contained' color="info" onClick={() => openActioneModal("update")}> Update</MDButton>

						<MDButton variant='contained' color="error" onClick={() => openActioneModal("delete")}> Delete</MDButton>

					</MDBox>
				</TableCell>
			</TableRow>

			<Dialog fullWidth maxWidth='sm' open={Boolean(actionModal)} onClose={() => !deleteLoading ? openActioneModal(null) : null}>
				{actionModal === 'delete' ? <MDBox p={3}>
					<MDTypography variant='h6' align='center' sx={{ mb: 3 }} > Delete Snippet</MDTypography>
					<MDButton variant='contained' color='success' disabled={deleteLoading} sx={{ mx: 1 }} onClick={() => {
						openActioneModal(null)
					}} >Cancel</MDButton>
					<MDButton variant='contained' color='warning' loading={deleteLoading} onClick={() => handleDelete()}>Confirm</MDButton>
				</MDBox> :
					<MDBox p={3}>
						<MDBox component='form' onSubmit={genWidget} >
							<MDTypography variant='h5' sx={{ my: 2 }}>Update Widget Snippet</MDTypography>

							<Grid container spacing={3}>
								{/* <Grid item xs={12} md={12}>
									<MDInput variant='outlined' name='widgetId' label='Widget ID' value={snippetValues.widgetId} fullWidth onChange={handleChange} required={true} />
								</Grid> */}
								<Grid item xs={12} md={6}>
									<MDInput variant='outlined' name='name' label='Name' value={snippetValues.name} fullWidth onChange={handleChange} required={true} />
								</Grid>
								<Grid item xs={12} md={6}>
									<MDInput variant='outlined' name='path' value={snippetValues.path} label='Path' fullWidth onChange={handleChange} required={true} />
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl style={{ width: '100%' }} >
										<InputLabel>Disable</InputLabel>
										<MuiSelect
											fullWidth onChange={handleChange}
											labelId="order-test"
											id="demo-simple-select"
											label='Disable'
											name='disable'
											value={Boolean(snippetValues?.disable)}
										>
											<MenuItem value={true}>True</MenuItem>
											<MenuItem value={false}>False</MenuItem>
										</MuiSelect>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={12}>
									<MDInput variant='outlined' name='code' value={snippetValues.code} label='Code' multiline rows={8} fullWidth onChange={handleChange} required={true} />
								</Grid>

							</Grid>
							<MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={genWidgetLoading}>Submit</MDButton>
						</MDBox>
					</MDBox>
				}
			</Dialog>
		</React.Fragment>
	)
}

