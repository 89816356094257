import React from "react";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import AuditorInvoices from "./AuditorInvoices";

const AdminAuditorInvoices = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <AuditorInvoices type="website-auditor-order-subscription" name="Auditor Paid" paid="true" />
            <AuditorInvoices type="website-auditor-order-subscription" name="Auditor Unpaid" paid="false" />
        </DashboardLayout>
    );
};

export default AdminAuditorInvoices; 