import { Autocomplete, Card, CardContent, Checkbox, Chip, FormControl, FormControlLabel, Grid, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDVersionSelect from "components/MDVersionSelect";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useDebounce from "hooks/useDebouce";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateClusterScansOptions } from "redux/actions/adminSmokiDashboard.action";
import { updateUserOrderClusterMaintenance } from "redux/actions/adminSmokiDashboard.action";
import { updateUserOrderCluster } from "redux/actions/adminSmokiDashboard.action";
import { getAdminClusters } from "redux/actions/adminSmokiDashboard.action";
import { addUserOrderCluster } from "redux/actions/adminSmokiDashboard.action";
import AllClusters from "./AllClusters";

export default function Clusters() {

    const [loading, setLoading] = React.useState(false);
    const [loadingClusterStatus, setLoadingClusterStatus] = React.useState(false);
    const [loadingClusterMaintenance, setLoadingClusterMaintenance] = React.useState(false);
    const [numberOfScansLoading, setNumberOfScansLoading] = React.useState(false);


    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [action, setAction] = React.useState({
        action: '',
        data: null
    })
    const [status, setStatus] = React.useState('')
    const [maintenance, setMaintenance] = React.useState(false)
    const [numberOfScans, setNumberOfScans] = React.useState(0);

    const [data, setData] = React.useState({
        "clusterDetails": {
            version: '1.0.0',
            name: '',
            appUri: '',
            orderId: '',
            "description": "",
            // openaiKey: '',
            // openaiModel: '',
            metadata: {},
            "smtp": {
                "hostname": "",
                "port": 0,
                "secure": true,
                "username": "",
                "password": ""
            },
            "databases": {
                "mongoUri": "",
                "postgresUri": "",
                "redisUri": "",
                backups: "true",
                backupsFrequency: 1,
            },
            "companyInfo": {
                "frontendUrl": "",
                // backendUrl: "",
                "companyName": "",
                "companyLogoName": ""
            }
        }
    })

    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [rows, setRows] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState('');
    const [type, setType] = React.useState('id');

    const [loading2, setLoading2] = React.useState(false);
    const [value, setValue] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');


    // This function updates the `smtp` object within the `clusterDetails` object in the `data` state with the new value from the event target.
    function handleSmtpkey(e) {
        setData({
            ...data,
            clusterDetails: {
                ...data.clusterDetails,
                smtp: {
                    ...data.clusterDetails.smtp,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    // This function updates the `databases` object within the `clusterDetails` object in the `data` state with the new value from the event target.
    function handleDbs(e) {
        setData({
            ...data,
            clusterDetails: {
                ...data.clusterDetails,
                databases: {
                    ...data.clusterDetails.databases,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    // This function updates the `companyInfo` object within the `clusterDetails` object in the `data` state with the new value from the event target.
    function handleCompanyURls(e) {
        setData({
            ...data,
            clusterDetails: {
                ...data.clusterDetails,
                companyInfo: {
                    ...data.clusterDetails.companyInfo,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    // This function updates the `clusterDetails` object in the `data` state with the new value from the event target.
    function handleOpenAi(e) {
        setData({
            ...data,
            clusterDetails: {
                ...data.clusterDetails,
                [e.target.name]: e.target.value
            }
        })
    }

    // This function submits the form data for adding or editing a cluster.
    // If the `action` is 'edit', it calls `editCluster`, otherwise it dispatches `addUserOrderCluster`.
    const submitApp = (e) => {
        e.preventDefault();
        if (action.action === 'edit') {
            editCluster()
        } else {
            setLoading(true)
            dispatch(addUserOrderCluster({
                ...data, clusterDetails: { ...data.clusterDetails, metadata: configVars, auditorVersions: value }
            })).then(data => {
                setLoading(false)
                fetchClusters()
            }).catch(err => {
                setLoading(false)
            })
        }
    };

    // This function updates an existing cluster.
    // It dispatches `updateUserOrderCluster` with the `clusterId` and the updated `data`.
    const editCluster = (e) => {
        setLoading(true)
        dispatch(updateUserOrderCluster({
            clusterId: action.data.id, ...data, clusterDetails: { ...data.clusterDetails, metadata: configVars, auditorVersions: value }
        })).then(data => {
            setLoading(false);
            fetchClusters()
        }).catch(err => {
            setLoading(false)
        })
    };

    // This effect runs whenever `action` changes.
    // It sets the initial values for various fields, and initializes the `data` state with the `clusterDetails` object from `action.data`.
    React.useEffect(() => {
        if (action.data) {
            scrollToEditCluster();
            setStatus(action.data.status)
            setMaintenance(action.data.maintenance)
            setNumberOfScans(action?.data?.numberOfScans)
            setData({
                clusterDetails: {
                    orderId: action.data.order,
                    smtp: action.data.smtp,
                    databases: action.data.databases,
                    version: action.data.version,
                    companyInfo: action.data.companyInfo,
                    name: action.data.name,
                    appUri: action.data.appUri,
                    description: action.data.description,
                    openaiKey: action.data.openaiKey,
                    openaiModel: action.data.openaiModel,
                    metadata: action.data?.metadata,
                    auditorVersions: action.data?.auditorVersions
                }
            })
            setValue(action.data?.auditorVersions)
            setConfigVars(action.data?.metadata)
        }
    }, [action])

    // Function to fetch clusters from the server
    function fetchClusters() {
        setLoading2(true)

        // Dispatch an action to get admin clusters, passing in page and searchValue
        dispatch(getAdminClusters({ page, [type]: searchValue })).then(data => {
            if (data) {
                // If data is returned, update rows and pages with the result
                setRows(data.result)
                setPages(data.pages)
                setLoading2(false)
            }
        });
    }

    // Debounce the fetchClusters function to run only after a delay of 500ms
    useDebounce(() => {
        fetchClusters()
    }, [searchValue, page], 500);

    // Function to reset the data state and set action state to empty
    function handleCancel() {
        setData({
            "clusterDetails": {
                version: '1.0.0',
                name: '',
                appUri: '',
                orderId: '',
                // openaiKey: '',
                // openaiModel: '',
                "smtp": {
                    "hostname": "",
                    "port": 0,
                    "secure": true,
                    "username": "",
                    "password": ""
                },
                "databases": {
                    "mongoUri": "",
                    "postgresUri": "",
                    backups: "true",
                    backupsFrequency: 7
                },
                "companyInfo": {
                    "frontendUrl": "",
                    backendUrl: "",
                    "companyName": "",
                    "companyLogoName": ""
                }
            }
        })
        setConfigVars({})
        setAction({ action: '', data: null })
    }

    // Function to scroll to the "edit-cluster" section of the page
    function scrollToEditCluster() {
        var getMeTo = document.getElementById("edit-cluster");
        if (getMeTo) {
            getMeTo.scrollIntoView({ behavior: 'smooth' }, true);
        }
    }

    // Function to update the status of a cluster
    function changeStatus() {
        setLoadingClusterStatus(true)
        dispatch(updateUserOrderCluster({
            clusterId: action.data.id, clusterDetails: {
                status
            }
        })).then(data => {
            setLoadingClusterStatus(false);
            fetchClusters()
        }).catch(err => {
            setLoadingClusterStatus(false)
        })
    }

    // Function to update the maintenance status of a cluster
    function changeMaintenance() {
        setLoadingClusterMaintenance(true)
        dispatch(updateUserOrderClusterMaintenance({
            clusterId: action.data.id, clusterDetails: {
                maintenance
            }
        })).then(data => {
            setLoadingClusterMaintenance(false);
            fetchClusters();
        }).catch(err => {
            setLoadingClusterMaintenance(false)
        })
    }

    // Function to update the number of scans for a cluster
    function changeNumberOfScans(e) {
        e.preventDefault()
        setNumberOfScansLoading(true)
        dispatch(updateClusterScansOptions({
            clusterId: action.data.id, clusterDetails: {
                numberOfScans
            }
        })).then(data => {
            setNumberOfScansLoading(false);
            fetchClusters();
        }).catch(err => {
            setNumberOfScansLoading(false)
        })
    }
    const [configVars, setConfigVars] = React.useState({});

    const [pair, setPair] = React.useState({
        key: '',
        value: ''
    });

    function handleSubmit() {

        if (configVars[pair.key]) {
            toast.error("Key already exist")
        } else {
            setConfigVars({
                ...configVars,
                [pair.key]: pair.value
            })

            setPair({
                key: '',
                value: ''
            })
        }

    }

    function handleChange(e) {

        setPair({
            ...pair,
            [e.target.name]: e.target.value
        })
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={5}>
                <AllClusters setSearchValue={setSearchValue} setType={setType} type={type} searchValue={searchValue} page={page} setPage={setPage} pages={pages} fetchClusters={fetchClusters} loading={loading2} rows={rows} setAction={setAction} />
            </MDBox>
            <Box
                height='100%'
                justifyContent='center'
                mx='auto'
                px={3}
                display='flex'
                flexDirection='column'
                width='100%'
                maxWidth={1000}
                sx={{ mt: 3, mb: 2, minHeight: 300 }}
            >

                <MDTypography variant='h3' align='center' sx={{ mb: 3 }}>{action.action === 'edit' ? "Edit" : "Add"} Cluster</MDTypography>
                <Card sx={{ width: `100%` }} >
                    <CardContent sx={{ height: '100%', }} >
                        <>
                            <Box sx={{ width: '100%' }} pb={5} component='form' id="edit-cluster" onSubmit={submitApp}>
                                <React.Fragment>
                                    <Box >
                                        <Box p={3} my={2}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDVersionSelect 
                                                    fullWidth
                                                    name='version' 
                                                    label='Version' 
                                                    value={data?.clusterDetails?.version || ""} 
                                                    required={true}
                                                    onChange={(e) => 

                                                        {
                                                            
                                                            setData({
                                                            ...data,
                                                            clusterDetails: {
                                                                ...data.clusterDetails,
                                                                version: e.target.value
                                                            }
                                                        })}
                                                        }
                                                    />
                                                    {/* <MDInput required={true} label='Version' value={data?.clusterDetails?.version || ""} name='version' fullWidth onChange={(e) => setData({
                                                        ...data,
                                                        clusterDetails: {
                                                            ...data.clusterDetails,
                                                            version: e.target.value
                                                        }
                                                    })} /> */}
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Cluster Name' value={data?.clusterDetails?.name || ""} name='name' fullWidth onChange={(e) => setData({
                                                        ...data,
                                                        clusterDetails: {
                                                            ...data.clusterDetails,
                                                            name: e.target.value
                                                        }
                                                    })} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='App Uri' value={data?.clusterDetails?.appUri || ""} name='appUri' fullWidth onChange={(e) => setData({
                                                        ...data,
                                                        clusterDetails: {
                                                            ...data.clusterDetails,
                                                            appUri: e.target.value
                                                        }
                                                    })} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput label='Order Id' value={data?.clusterDetails?.orderId || ""} name='orderId' fullWidth onChange={(e) => setData({
                                                        ...data,
                                                        clusterDetails: {
                                                            ...data.clusterDetails,
                                                            orderId: e.target.value
                                                        }
                                                    })} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Smtp Host Name' value={data?.clusterDetails?.smtp?.hostname || ""} name='hostname' fullWidth onChange={handleSmtpkey} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Smtp Host Port' type='number' value={data?.clusterDetails?.smtp?.port || ""} name='port' fullWidth onChange={handleSmtpkey} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Smtp Host SSL</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name='secure'
                                                            value={Boolean(data?.clusterDetails?.smtp?.secure)}
                                                            label="Host SSL"
                                                            onChange={handleSmtpkey}
                                                        >
                                                            <MenuItem value={true}>True</MenuItem>
                                                            <MenuItem value={false}>False</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Smtp Host Username' value={data?.clusterDetails?.smtp?.username || ""} name='username' fullWidth onChange={handleSmtpkey} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Smtp Host Password' value={data?.clusterDetails?.smtp?.password || ""} name='password' fullWidth onChange={handleSmtpkey} />
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <MDTypography variant='button' fontWeight='bold'>Description:</MDTypography> <br />
                                                    <MDInput value={data?.clusterDetails?.description || ""} name='description' multiline rows={10} required={true} onChange={(e) => setData({
                                                        ...data,
                                                        clusterDetails: {
                                                            ...data.clusterDetails,
                                                            description: e.target.value
                                                        }
                                                    })} fullWidth />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Box>
                                    <Box>
                                        <Box p={3} my={2}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <MDInput required={true} label='Mongo DB URI' value={data?.clusterDetails?.databases?.mongoUri || ""} name='mongoUri' fullWidth onChange={handleDbs} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MDInput required={true} label='Postgres URI' value={data?.clusterDetails?.databases?.postgresUri || ""} name='postgresUri' fullWidth onChange={handleDbs} />
                                                </Grid>
                                                 <Grid item xs={12}>
                                                    <MDInput required={true} label='Redis URI' value={data?.clusterDetails?.databases?.redisUri || ""} name='redisUri' fullWidth onChange={handleDbs} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel control={<Checkbox name='backups' checked={Boolean(data?.clusterDetails?.databases.backups)} onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            clusterDetails: {
                                                                ...data.clusterDetails,
                                                                databases: {
                                                                    ...data.clusterDetails.databases,
                                                                    backups: e.target.checked
                                                                }
                                                            }
                                                        })
                                                    }} />} label="Do you want backups?" />
                                                </Grid>
                                                {data?.clusterDetails?.databases.backups && <Grid item xs={12}>
                                                    <MDInput
                                                    type={"number"}
                                                    InputProps={{ inputProps: { min: 1 } }}
                                                     required={true} label='Backups Frequency' value={data?.clusterDetails?.databases?.backupsFrequency || ""} name='backupsFrequency' fullWidth onChange={handleDbs} />
                                                </Grid>}
                                            </Grid>
                                        </Box>

                                    </Box>

                                    <Box >
                                        <Box p={3} mt={2}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Company Name' value={data?.clusterDetails?.companyInfo?.companyName || ""} name='companyName' fullWidth onChange={handleCompanyURls} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Company Logo Name' value={data?.clusterDetails?.companyInfo?.companyLogoName || ""} name='companyLogoName' fullWidth onChange={handleCompanyURls} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='Frontend URL' value={data?.clusterDetails?.companyInfo?.frontendUrl || ""} name='frontendUrl' fullWidth onChange={handleCompanyURls} />
                                                </Grid>

                                            </Grid>
                                        </Box>
                                        {/* <Box px={3} >
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='OpenAI Key' value={data?.clusterDetails?.openaiKey || ""} name='openaiKey' fullWidth onChange={handleOpenAi} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <MDInput required={true} label='OpenAI Model' value={data?.clusterDetails?.openaiModel || ""} name='openaiModel' fullWidth onChange={handleOpenAi} />
                                                </Grid>
                                            </Grid>
                                        </Box> */}
                                    </Box>
                                </React.Fragment>

                                <Box mt={4} mb={2}>

                                    {/* <Autocomplete
                                        multiple
                                        id="tags-filled"
                                        options={[]}
                                        value={value || []}
                                        onChange={(event, newValue) => {
                                            setValue(newValue);
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        freeSolo
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        // renderInput={(params) => (
                                        //     // <MDInput
                                        //     //     {...params}
                                        //     //     variant="outlined"
                                        //     //     label="Auditor Versions (Enter version and press enter to add)"
                                        //     //     placeholder="Enter version and press enter to add"
                                        //     // />
                                        // )}
                                    /> */}
                                </Box>
                                <Box mb={2} mt={2}>
                                    <MDTypography textAlign='center' variant='h4' sx={{ mb: 4 }}>Metadata</MDTypography>
                                    {Object.keys(configVars).length > 0 && <>
                                        {Object.keys(configVars).map((val, index) => {
                                            return (<ValuePair key={index} val={{ key: val, value: configVars[val] }} setConfigVars={setConfigVars} configVars={configVars} objIndex={index} />)
                                        })}
                                    </>}

                                    <MDBox >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4}>
                                                <MDInput type='text' name='key' label='KEY' fullWidth placeholder='KEY' value={pair.key} onChange={handleChange} />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MDInput type='text' name='value' label="VALUE" fullWidth placeholder='VALUE' value={pair.value} onChange={handleChange} />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <MDButton onClick={() => handleSubmit()} color='info' variant='gradient'><Icon>add</Icon></MDButton>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: action.action === 'edit' ? 'column' : 'row', mt: 2, gap: 2, width: '100%' }}>
                                    {action.action === 'edit' ? <>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2 }}>
                                            <MDButton variant='gradient' color='info' type='submit' loading={loading}>Update</MDButton>
                                            <MDButton variant='gradient' color='error' onClick={handleCancel} >Cancel</MDButton>
                                        </Box>


                                    </> :
                                        <MDButton
                                            loading={loading}
                                            type='submit'
                                            variant="gradient"
                                            color='info'
                                        >
                                            ADD
                                        </MDButton>
                                    }
                                </Box>
                            </Box>
                        </>


                        {action.action === 'edit' && <>
                            <MDBox display='flex' alignItems='center' justifyContent='flex-start' width='100%' mb={3} gap={2}>
                                <FormControl fullWidth sx={{ maxWidth: '200px' }}>
                                    <InputLabel id="cluster-status">Cluster Status</InputLabel>
                                    <Select
                                        labelId="cluster-status"
                                        id="cluster-status-select"
                                        name='secure'
                                        value={status.toLowerCase()}
                                        label="Cluster Status"
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <MenuItem value={"live"}>Live</MenuItem>
                                        <MenuItem value={"completed"}>Completed</MenuItem>
                                        <MenuItem value={"pending"}>Pending</MenuItem>
                                    </Select>
                                </FormControl>
                                <MDButton variant='gradient' color='info' size='small' loading={loadingClusterStatus} onClick={() => changeStatus()}>
                                    Update Cluster Status
                                </MDButton>
                            </MDBox>

                            <MDBox display='flex' alignItems='center' justifyContent='flex-start' width='100%' mb={3} gap={2}>
                                <FormControl fullWidth sx={{ maxWidth: '200px' }}>
                                    <InputLabel id="cluster-maintenance">Cluster Maintenance</InputLabel>
                                    <Select
                                        labelId="cluster-maintenanace"
                                        id="cluster-maintenanace-select"
                                        name='secure'
                                        value={Boolean(maintenance)}
                                        label="Cluster Maintenance"
                                        onChange={(e) => setMaintenance(e.target.value)}
                                    >
                                        <MenuItem value={true}>Enabled</MenuItem>
                                        <MenuItem value={false}>Disabled</MenuItem>
                                    </Select>
                                </FormControl>
                                <MDButton variant='gradient' color='info' size='small' loading={loadingClusterMaintenance} onClick={() => changeMaintenance()}>
                                    Updte Maintenance
                                </MDButton>
                            </MDBox>

                            <MDBox component='form' mb={2} onSubmit={
                                changeNumberOfScans
                            } display='flex' alignItems='center' justifyContent='flex-start' width='100%' gap={2}>
                                <MDInput label='Number of Simultaneous Scans' type='number'
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        }
                                    }}
                                    required={true}
                                    value={numberOfScans || 0} onChange={(e) => setNumberOfScans(e.target.value)} sx={{ width: '100%', maxWidth: 250 }} />
                                <MDButton variant='gradient' color='info' size='small' type='submit' loading={numberOfScansLoading} >
                                    Update Scans
                                </MDButton>
                            </MDBox>
                        </>}
                    </CardContent>
                </Card>


            </Box>
        </DashboardLayout>
    )
}



function ValuePair({ val, objIndex, configVars, setConfigVars }) {

    const [edit, setEdit] = React.useState(false);


    function handleSubmit(e) {

        const vjk = Object.keys(configVars).map((item, index) => {
            const current = index === objIndex
            if (current) {
                return { [pair.key]: pair.value }
            } else
                return {
                    [item]: configVars[item]
                }
        })

        const jng = vjk.reduce(function (result, item) {
            var key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
        }, {})


        setConfigVars(jng)
        setEdit(false)

    }

    function handleDelete() {
        const vjk = Object.keys(configVars).map((item) => {
            return {
                [item]: configVars[item]
            }
        })

        const filtered = vjk.filter((v, i) => i !== objIndex)

        const jng = filtered.reduce(function (result, item) {
            var key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
        }, {})

        setConfigVars(jng)
    }

    function handleChange(e) {
        setPair({
            ...pair,
            [e.target.name]: e.target.value
        })
    }

    const [pair, setPair] = React.useState({
        key: '',
        value: ''
    });

    React.useEffect(() => {
        if (val) {
            setPair({ ...val })
        }
    }, [val])

    function handleEdit(e) {
        e.preventDefault();
        setEdit(true)
    }

    return (
        <MDBox my={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <MDInput type='text' name='key' label='KEY' disabled={!edit} fullWidth placeholder='KEY' value={pair.key} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MDInput type='text' name='value' label='VALUE' disabled={!edit} fullWidth placeholder='VALUE' value={pair.value} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <MDBox display='flex' gap={2}>
                        {edit ?
                            <MDButton onClick={handleSubmit} color='success' variant='gradient' ><Icon>done</Icon></MDButton> :
                            <>
                                <MDButton type='button' color='secondary' variant='gradient' onClick={(e) => handleEdit(e)}><Icon>edit</Icon></MDButton>
                                <MDButton color='error' variant='gradient' onClick={handleDelete}><Icon>delete</Icon></MDButton>
                            </>
                        }
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    )
}