import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

const SetupForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setLoading(true);
        try {
            if (!stripe || !elements) {
                // Stripe.js hasn't yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return null;
            }

            const { error } = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: `${process.env.REACT_APP_AFTER_PAYMENT_URL}/user/main/accessBlend/billing`,
                },
            });

            if (error) {
                toast.error(error.message);
                setLoading(false);

            }
        } catch (error) {

            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <MDBox display='flex' justifyContent='center' my={3}>
                <MDButton variant='contained' color='primary' type='submit' disabled={!stripe} loading={loading}>
                    Submit
                </MDButton>
            </MDBox>
        </form>
    )
};

export default SetupForm;