import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import simpleDateparser from "utils/simpledateParser";

export default function data(users, loading, link, genLink) {

  return {
    userColumns: [
      { Header: "Id", accessor: "id", width: "200px", align: "left" },
      { Header: "email", accessor: "email", width: "200px", align: "left" },
      { Header: "Name", accessor: "displayName", align: "left", width: "180px" },
      { Header: "Organization Name", accessor: "orgName", align: "left", width: "200px" },
      { Header: "Organization Email", accessor: "orgEmail", align: "left", width: "200px" },
      { Header: "Role", accessor: "role", align: "left", width: "180px" },
      { Header: "Actions", accessor: "actions", align: "left", width: "550px" },
    ],

    userRows: users.length > 0 ?
      users.map((user) => {

        return ({
          email: <MDTypography color="text" fontWeight="medium" variant="caption">{user.email}</MDTypography>,
          id: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user?.id}
            </MDTypography>
          ),
          role: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user?.role}
            </MDTypography>
          ),
          displayName: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user?.displayName}
            </MDTypography>
          ),
          orgName: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user?.orgName}
            </MDTypography>
          ),
          orgEmail: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user?.orgEmail}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {simpleDateparser(user?.createdAt)}
            </MDTypography>
          ),
          actions: (
            <MDBox display='flex' gap={1} style={{ width: '600px' }}> <MDButton variant='contained' color='primary' to={`/accessblend-admin/saas/accessBlend/websites/${user.id}`} component={Link}>View User Websites</MDButton>

              <MDButton variant='contained' color='primary' to={`/accessblend-admin/saas/accessBlend/orders/${user.id}`} component={Link}>View Auditor Orders</MDButton>
              {!link ? <MDButton color='success' onClick={() => genLink(user.id)} loading={loading === user.id} disabled={loading} variant='contained'>Generate Login Link</MDButton> : link?.id !== user.id ? <MDButton color='success' onClick={() => genLink(user.id)} loading={loading === user.id} disabled={loading} variant='contained'>Generate Login Link</MDButton> :
                <MDButton variant='contained' color='success' onClick={() => {
                  navigator.clipboard.writeText(`${link?.link}`)
                  toast.success("Link Copied")
                }}>Copy Login Link</MDButton>}
            </MDBox>
          )
        }
        )
      }) : []
  };
}
