import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDSwitch from 'components/MDSwitch';
import Pagination from '@mui/material/Pagination';
import colors from 'assets/theme/base/colors';
import Menu from '@mui/material/Menu';

import {
  Card,
  CircularProgress,
  Dialog,
  Icon,
  TableContainer,
} from '@mui/material';
import MDProgress from 'components/MDProgress';

import simpleDateparser from 'utils/simpledateParser';
import { useDispatch } from 'react-redux';

import MDButton from 'components/MDButton';
import { useNavigate } from 'react-router-dom';
import { voidInvoice } from 'redux/actions/adminSmokiDashboard.action';
import { useMaterialUIController } from 'context';
import { getChildWebsites } from 'redux/actions/adminSmokiDashboard.action';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { getAdminClustersList } from 'redux/actions/adminSmokiDashboard.action';
import { FormControl, Grid, InputLabel, MenuItem, Select,FormGroup,FormControlLabel,Checkbox } from '@mui/material';

import { childWesiteAction } from 'redux/actions/adminSmokiDashboard.action';
import MDInput from 'components/MDInput';
import { getDashboardType } from 'utils/getDashboardType';
import { childScanAction } from 'redux/actions/adminSmokiDashboard.action';
import { useSelector } from 'react-redux';

const ChildWebites = () => {
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const [rows, setRows] = React.useState(0);
  const [gettingClusters, setGettingClusters] = React.useState(true);
  const [selectedResolutions, setSelectedResolutions] = React.useState([])

  const [web, setWeb] = React.useState({
    orgName: '',
    website: '',
    sitemapUrl: '',
  });

  const [clusters, setClusters] = React.useState([]);
  const [selectedCluster, setSelectedCluster] = React.useState('');
  const [addLoading, setAddLoading] = React.useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Define a function named fetchWebsites which sets loading state to true and dispatches getChildWebsites action with the current page value. If the data is successfully returned, set the state of rows and pages to the received data and setLoading state to false.

  function fetchWebsites() {
    setLoading(true);
    dispatch(getChildWebsites(page)).then((data) => {
      if (data) {
        setRows(data.result);
        setPages(data.pages);
        setLoading(false);
      }
    });
  }

  // Use React useEffect hook to call fetchWebsites function every time the page value is changed.

  React.useEffect(() => {
    fetchWebsites();
  }, [page]);

  // Define a function named handleWeb that updates the state of org when the value of an input field is changed.

  function handleWeb(e) {
    setOrg({
      ...org,
      [e.target.name]: e.target.value,
    });
  }

  // Define a function named addWebsite which is triggered on form submission. It sets the addLoading state to true and dispatches childWesiteAction with the action of "add" and details object containing web, selectedCluster and other details. If the data is successfully added, it sets the addLoading state to false, fetches the updated websites and resets the values of web state to empty strings.

  function addWebsite(e) {
    e.preventDefault();
    setAddLoading(true);
    dispatch(
      childWesiteAction({
        action: 'add',
        details: {
          ...web,
          clusterId: selectedCluster,
          resolutions: selectedResolutions,
        },
      })
    )
      .then((data) => {
        setAddLoading(false);
        fetchWebsites();
        setWeb({
          website: '',
          orgName: '',
          sitemapUrl: '',
        });
      })
      .catch((err) => {
        setAddLoading(false);
      });
  }

  // Define another function named handleWeb that updates the state of web when the value of an input field is changed.

  function handleWeb(e) {
    setWeb({
      ...web,
      [e.target.name]: e.target.value,
    });
  }

  // Use MaterialUIController to define a controller object with a darkMode property.

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // Define an asynchronous function named fetchClsuters that sets the gettingClusters state to true and dispatches getAdminClustersList action. If the data is successfully returned, set the state of clusters to the received data and set gettingClusters state to false.

  async function fetchClsuters() {
    setGettingClusters(true);
    dispatch(getAdminClustersList(1, 100))
      .then((data) => {
        setClusters(data.result);
      })
      .then((data) => {
        setGettingClusters(false);
      })
      .catch((err) => {
        setGettingClusters(false);
      });
  }

  // Use React useEffect hook to call fetchClsuters function once on component mount.

  React.useEffect(() => {
    fetchClsuters();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} myt={3} mb={6}>
        {!gettingClusters ? (
          <Card style={{ marginBottom: 20, marginTop: 20, padding: 20 }}>
            <FormControl style={{ width: '100%', maxWidth: 400 }}>
              <InputLabel id='cluster-selection'>
                Select Cluster To Perform Action
              </InputLabel>
              <Select
                labelId='cluster-selection'
                id='demo-simple-select'
                name='clusterId'
                value={selectedCluster}
                label='Select Cluster To Perform Action'
                onChange={(e) => setSelectedCluster(e.target.value)}
              >
                {clusters.map((cluster) => {
                  return (
                    <MenuItem key={cluster.id} value={cluster?.id}>
                      {cluster?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Card>
        ) : (
          <MDTypography variant='h5'>Getting Clusters...</MDTypography>
        )}
      </MDBox>
      <br />
      <MDBox mb={6} component='form' onSubmit={addWebsite}>
        <Card>
          <MDBox sx={{ p: 2, borderRadius: 2 }}>
            <MDTypography variant='h5' sx={{ ml: 1 }}> Add Website </MDTypography>
            <Grid container spacing={3} mt={0.2}>
              <Grid item xs={4}>
                <MDInput
                  required={true}
                  value={web.orgName}
                  name='orgName'
                  label='Auditor Organization Id/Name'
                  onChange={handleWeb}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <MDInput
                  required={true}
                  value={web.website}
                  name='website'
                  label='Website'
                  onChange={handleWeb}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <MDInput
                  required={true}
                  value={web.sitemapUrl}
                  name='sitemapUrl'
                  label='Sitemap URL'
                  onChange={handleWeb}
                  fullWidth
                />
              </Grid>
            </Grid>
            <MDTypography variant='h5' sx={{ ml: 1, mt:2, marginBottom: 2 }}> Select Resolutions </MDTypography>
            <ResolutionComponent selectedResolutions={selectedResolutions} setSelectedResolutions={setSelectedResolutions} />
            <MDBox mt={3} display='flex' justifyContent='center'>
              <MDButton
                variant='gradient'
                color='info'
                type='submit'
                loading={addLoading}
                disabled={!selectedCluster}
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Card>
        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
        >
          <MDTypography variant='h6' color='white'>
            All Websites
          </MDTypography>
        </MDBox>

        <TableContainer>
          <Table size='small' aria-label='a dense table'>
            <thead>
              <TableRow>
                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Website
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 220 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Website ID
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Cluster ID
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 180 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Order ID
                  </MDTypography>
                </TableCell>

                <TableCell align='left' sx={{ minWidth: 150 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Organization ID
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 120 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    User ID
                  </MDTypography>
                </TableCell>
                {getDashboardType() === 'saas' && (
                  <TableCell align='left' sx={{ minWidth: 180 }}>
                    <MDTypography
                      variant='caption'
                      component='span'
                      fontWeight='medium'
                      color={darkMode ? 'white' : 'info'}
                    >
                      Price
                    </MDTypography>
                  </TableCell>
                )}
                <TableCell align='left' sx={{ minWidth: 230 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Created At
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 120 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Sitemap
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 120 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Approved
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 120 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Last action
                  </MDTypography>
                </TableCell>
                <TableCell align='left' sx={{ minWidth: 750 }}>
                  <MDTypography
                    variant='caption'
                    component='span'
                    fontWeight='medium'
                    color={darkMode ? 'white' : 'info'}
                  >
                    Action
                  </MDTypography>
                </TableCell>
              </TableRow>
            </thead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell align='center' colSpan={6}>
                    <MDBox
                      display='flex'
                      py={1}
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <MDProgress circular />
                      <MDTypography variant='h6'>
                        Loading Websites...
                      </MDTypography>
                    </MDBox>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {rows && rows.length > 0 ? (
                  rows.map((row, index) => {
                    return (
                      <OrderRow
                        fetchWebsites={fetchWebsites}
                        clusterId={selectedCluster}
                        darkMode={darkMode}
                        row={row}
                        rows={rows}
                        key={`order-${index}`}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align='center' colSpan={11}>
                      <MDTypography
                        variant='caption'
                        component='span'
                        fontWeight='bold'
                      >
                        no websites found
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {rows.length > 0 && !loading && (
            <MDBox
              mt={2}
              mb={2}
              display='flex'
              justifyContent='center'
              width='100%'
            >
              <Pagination
                color='info'
                variant='outlined'
                sx={{
                  '& .MuiPagination-ul > li > button': {
                    fontWeight: 400,
                    color: darkMode
                      ? 'white !important'
                      : `${colors?.info?.main} !important`,
                    borderColor: darkMode
                      ? 'white !important'
                      : `${colors?.info?.main} !important`,
                  },
                  '& .MuiPagination-ul > li > div': {
                    color: darkMode
                      ? 'white !important'
                      : `${colors?.info?.main} !important`,
                  },
                }}
                count={pages}
                page={page}
                onChange={(e, value) => setPage(value)}
              />
            </MDBox>
          )}
        </TableContainer>
      </Card>
    </DashboardLayout>
  );
};

function OrderRow({ row, fetchWebsites }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [report, setReport] = React.useState(null);
  const [downloadingSeo, setDownloadingSeo] = React.useState(false);

  const [actionModal, setActionModal] = React.useState({
    open: false,
    websiteId: null,
    action: '',
  });

  // This function sets the state to open a modal and passes in the data
  function openCancelInvoiceModal(data) {
    setActionModal(data);
  }

  // This function is called when the user clicks on the delete button
  function handleDelete() {
    // This sets a loading state to true
    setDeleteLoading(true);
    // This checks if the action modal is set to approve
    if (actionModal.action === 'approve') {
      // If so, it dispatches an action to update the website details with the new information
      dispatch(
        childWesiteAction({
          action: 'update',
          details: {
            approved: true,
            clusterId: row.cluster,
            domainId: actionModal.websiteId,
          },
        })
      ).then((data) => {
        // This sets the loading state to false
        setDeleteLoading(false);
        // This closes the modal by resetting the state
        openCancelInvoiceModal({
          open: false,
          websiteId: null,
          action: '',
        });
        // This fetches the updated website details
        fetchWebsites();
      });
    } else if (actionModal.action === 'disapprove') {
      // This checks if the action modal is set to disapprove
      // If so, it dispatches an action to update the website details with the new information
      dispatch(
        childWesiteAction({
          action: 'update',
          details: {
            approved: false,
            clusterId: row.cluster,
            domainId: actionModal.websiteId,
          },
        })
      ).then((data) => {
        // This sets the loading state to false
        setDeleteLoading(false);
        // This closes the modal by resetting the state
        openCancelInvoiceModal({
          open: false,
          websiteId: null,
          action: '',
        });
        // This fetches the updated website details
        fetchWebsites();
      });
    } else {
      // If the action modal is neither approve nor disapprove, it dispatches an action to delete the website
      dispatch(
        childWesiteAction({
          action: 'delete',
          details: {
            clusterId: row.cluster,
            domainId: actionModal.websiteId,
          },
        })
      ).then((data) => {
        // This sets the loading state to false
        setDeleteLoading(false);
        // This closes the modal by resetting the state
        openCancelInvoiceModal({
          open: false,
          websiteId: null,
          action: '',
        });
        // This fetches the updated website details
        fetchWebsites();
      });
    }
  }

  // This function is called when the user clicks on the CSV button
  function handleCsv(action) {
    // This sets the state to the current action
    setReport(action);
    // This dispatches an action to fetch the website details
    dispatch(
      childWesiteAction({
        action,
        details: {
          clusterId: row.cluster,
          domainId: row.websiteId,
        },
      })
    )
      .then((data) => {
        // This sets the state to null after the data has been fetched
        setReport(null);
      })
      .catch((err) => {
        // This sets the state to null if there is an error while fetching the data
        setReport(null);
      });
  }

  // These three lines initialize state variables for the anchor element and open state of a menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // This sets the anchor element to the current target of the event
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align='left'>
          <MDTypography
            variant='caption'
            fontWeight='bold'
            component='a'
            target='_blank'
            href={row?.fullDomain}
          >
            {row?.domain}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.websiteId}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.cluster}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.order}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.organizationId}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.userId}
          </MDTypography>
        </TableCell>
        {getDashboardType() === 'saas' && (
          <TableCell align='left'>
            <MDTypography variant='caption' fontWeight='bold'>
              {row?.price}
            </MDTypography>
          </TableCell>
        )}

        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {simpleDateparser(row?.createdAt)}
          </MDTypography>
        </TableCell>

        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.checklist.hasSitemap ? 'true' : 'false'}
          </MDTypography>
        </TableCell>

        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.payWall ? 'true' : 'false'}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.action}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          {row.action.toLowerCase() !== 'deleted' && (
            <MDBox display='flex' alignItems='center' gap={2}>
              {row.payWall ? (
                <MDButton
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={() =>
                    openCancelInvoiceModal({
                      action: 'approve',
                      open: true,
                      websiteId: row.websiteId,
                    })
                  }
                >
                  {' '}
                  Approve
                </MDButton>
              ) : (
                <MDButton
                  variant='contained'
                  color='info'
                  size='small'
                  onClick={() =>
                    openCancelInvoiceModal({
                      action: 'disapprove',
                      open: true,
                      websiteId: row.websiteId,
                    })
                  }
                >
                  {' '}
                  Disapprove
                </MDButton>
              )}
              <MDButton
                variant='contained'
                color='error'
                size='small'
                onClick={() =>
                  openCancelInvoiceModal({
                    action: 'delete',
                    open: true,
                    websiteId: row.websiteId,
                  })
                }
              >
                {' '}
                Delete
              </MDButton>
              {/* <MDButton color='primary' variant='contained' onClick={() => handleFullReport("executive-summary")}>Download Summary {report === "executive-summary" && <CircularProgress sx={{ ml: 3 }} size={14} />} </MDButton> */}
              <MDButton
                variant='contained'
                color='primary'
                size='small'
                onClick={handleClick}
              >
                Download Reports
              </MDButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={!report ? handleClose : null}
              >
                <MenuItem
                  disabled={report}
                  onClick={() => handleCsv('accessibility-sheet')}
                >
                  Download Accessibility XLSX{' '}
                  {report === 'accessibility-sheet' && (
                    <CircularProgress sx={{ ml: 3 }} size={14} />
                  )}{' '}
                </MenuItem>
                <MenuItem
                  disabled={report}
                  onClick={() => handleCsv('seo-sheet')}
                >
                  Download SEO XLSX{' '}
                  {report === 'seo-sheet' && (
                    <CircularProgress sx={{ ml: 3 }} size={14} />
                  )}{' '}
                </MenuItem>
                <MenuItem
                  disabled={report}
                  onClick={() => handleCsv('html-sheet')}
                >
                  Download HTML XLSX{' '}
                  {report === 'html-sheet' && (
                    <CircularProgress sx={{ ml: 3 }} size={14} />
                  )}{' '}
                </MenuItem>
                <MenuItem
                  disabled={report}
                  onClick={() => handleCsv('css-sheet')}
                >
                  Download CSS XLSX{' '}
                  {report === 'css-sheet' && (
                    <CircularProgress sx={{ ml: 3 }} size={14} />
                  )}{' '}
                </MenuItem>
                <MenuItem
                  disabled={report}
                  onClick={() => handleCsv('javascript-issues')}
                >
                  Download JavaScript XLSX{' '}
                  {report === 'javascript-issues' && (
                    <CircularProgress sx={{ ml: 3 }} size={14} />
                  )}{' '}
                </MenuItem>
                <MenuItem
                  disabled={report}
                  onClick={() => handleCsv('sitemap-xml')}
                >
                  Download Sitemap XML{' '}
                  {report === 'sitemap-xml' && (
                    <CircularProgress sx={{ ml: 3 }} size={14} />
                  )}{' '}
                </MenuItem>
              </Menu>
            </MDBox>
          )}
        </TableCell>
      </TableRow>

      <Dialog
        fullWidth
        maxWidth='sm'
        open={actionModal.open}
        onClose={() =>
          !deleteLoading
            ? openCancelInvoiceModal({
              open: false,
              action: '',
              websiteId: null,
            })
            : null
        }
      >
        <MDBox p={3}>
          <MDTypography
            variant='h6'
            align='center'
            sx={{ mb: 3, textTransform: 'capitalize' }}
          >
            {' '}
            {actionModal.action} Website
          </MDTypography>
          <MDButton
            variant='contained'
            color='success'
            disabled={deleteLoading}
            sx={{ mx: 1 }}
            onClick={() => {
              openCancelInvoiceModal({
                open: false,
                action: '',
                websiteId: null,
              });
            }}
          >
            Cancel
          </MDButton>
          <MDButton
            variant='contained'
            color='warning'
            loading={deleteLoading}
            onClick={() => handleDelete()}
          >
            Confirm
          </MDButton>
        </MDBox>
      </Dialog>
    </React.Fragment>
  );
}


const ResolutionComponent = ({ selectedResolutions, setSelectedResolutions }) => {
  const resolutions = useSelector(state => state.auth.config?.auditorResolutions) || []
  const handleResolutionChange = (resolution) => {
      const selectedIndex = selectedResolutions.indexOf(resolution);
      let newSelectedResolutions = [...selectedResolutions];

      if (selectedIndex === -1) {
          newSelectedResolutions.push(resolution);
      } else {
          newSelectedResolutions.splice(selectedIndex, 1);
      }

      setSelectedResolutions(newSelectedResolutions);
  };



  return (
      <FormGroup>
          <Grid container spacing={2}>
              {resolutions.map((res, index) => (
                  <Grid item xs={12} sm={6} lg={3} key={index}>
                      <FormControlLabel
                          control={
                              <Checkbox
                                  checked={selectedResolutions.includes(res.resolution)}
                                  onChange={() => handleResolutionChange(res.resolution)}
                              />
                          }
                          label={`${res.deviceType} - ${res.resolution}`}
                      />
                  </Grid>
              ))}
          </Grid>
      </FormGroup>
  );
};

export default ChildWebites;
