import { FormControl, Grid, InputLabel, MenuItem, Card, CardContent, Radio, DialogContent, Dialog, IconButton, InputAdornment, DialogTitle, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDProgress from "components/MDProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useDispatch } from "react-redux";
import { genAdminWidgetLink, geAdminCustomWidget } from "redux/actions/adminSmokiDashboard.action";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { TableContainer } from "@mui/material";
import { useMaterialUIController } from "context";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { deleteAdminWidget } from "redux/actions/adminSmokiDashboard.action";
import { unAssignAdminCustomWidget } from "redux/actions/adminSmokiDashboard.action";

export default function CustomWidgets() {

	const dispatch = useDispatch();
	const [page, setPage] = React.useState(1);
	const [pages, setPages] = React.useState(1);
	const [data, setData] = React.useState([]);
	const [website, setWebsite] = React.useState({ website: '', trialDays: 0, lifetime: false });

	const [genWidgetLoading, setGenWidgetLoading] = React.useState(false);
	const [getLoading, setGetLoading] = React.useState(false);


	function getLink() {
		setGetLoading(true);
		dispatch(geAdminCustomWidget(page)).then(data => {
			if (data) {
				setData(data.result);
				setPages(data.pages);

				setGetLoading(false);
			}
		}).catch(err => {
			setGetLoading(false)
		});
	}


	React.useEffect(() => {
		getLink()
		return () => {
			setGetLoading(false)
		}
	}, [page])

	function genWidget(e) {
		e.preventDefault();
		setGenWidgetLoading(true)
		dispatch(genAdminWidgetLink({ ...website })).then(data => {
			if (data) {
				setWebsite({ website: '', trialDays: 0, lifetime: false })
				getLink()
			}
			setGenWidgetLoading(false)

		}).catch(err => {
			setGenWidgetLoading(false)
		})
	}


	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox mt={3}>
				<Widgets page={page} pages={pages} rows={data} setPage={setPage} getLink={getLink} loading={getLoading} />
				<Card sx={{ p: 2, mt:2 }}>
					<MDTypography variant='h4' sx={{ mb: 2 }}>Add Custom Widget</MDTypography>
					<MDBox component='form' onSubmit={genWidget} mt={2}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<MDInput variant='outlined' name='website' label='Website' fullWidth onChange={(e) => setWebsite({ ...website, website: e.target.value })} value={website.website} required={true} />
							</Grid>
							<Grid item xs={12} md={4}>
								<MDInput variant='outlined' InputProps={{
									inputProps: {
										min: 0
									}
								}} name='trialDays' label='Trial Days' type='number' fullWidth onChange={(e) => setWebsite({ ...website, trialDays: e.target.value })} required={true} />
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControlLabel control={<Checkbox checked={website.lifetime} onChange={(e) => setWebsite({ ...website, lifetime: e.target.checked })} />} label="Lifetime" />
							</Grid>
							<Grid item xs={12} md={6}>
								<MDButton variant='gradient' type='submit' color='info' loading={genWidgetLoading}>Submit</MDButton>
							</Grid>
						</Grid>
					</MDBox>
				</Card>

			</MDBox>
		</DashboardLayout>
	)
}



function Widgets({ pages, rows, page, setPage, loading, getLink }) {


	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	return (
		<Card sx={{ mt: 6 }}>
			<MDBox
				mx={2}
				mt={-3}
				py={3}
				px={2}
				variant="gradient"
				bgColor="info"
				borderRadius="lg"
				coloredShadow="info"
			>
				<MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize' }}>
					Custom	Widgets
				</MDTypography>
			</MDBox>
			<TableContainer>
				<Table size="small" aria-label="a dense table">
					<thead>
						<TableRow>
							<TableCell align="left" sx={{ minWidth: 190 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									ID
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 200, maxWidth: 270 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Website
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Active
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Trial
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 220 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Assigned
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 120 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Snippet
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 200 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Snippet Injector
								</MDTypography>
							</TableCell>
							<TableCell align="left" sx={{ minWidth: 600 }}>
								<MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
									Action
								</MDTypography>
							</TableCell>
						</TableRow>
					</thead>
					{loading ?
						<TableBody>
							<TableRow >
								<TableCell align="center" colSpan={6} >
									<MDBox
										display="flex"
										py={1}
										flexDirection='column'
										justifyContent="center"
										alignItems="center"
									>
										<MDProgress circular />
										<MDTypography variant="h6">
											Loading Widgets...
										</MDTypography>
									</MDBox>
								</TableCell>
							</TableRow>
						</TableBody>
						:
						<TableBody>
							{rows && rows.length > 0 ? rows.map((row, index) => {
								return <OrderRow getLink={getLink} row={row} key={`order-${index}`} />
							}) :
								<TableRow>
									<TableCell align="center" colSpan={6} >
										<MDTypography variant='caption' component='span' fontWeight="bold">no widgets found</MDTypography>
									</TableCell>
								</TableRow>
							}
						</TableBody>}
				</Table>
				{rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
					<Pagination color='info' variant="outlined"
						sx={{
							'& .MuiPagination-ul > li > button': {
								fontWeight: 400,
								color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
								borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
							},
							'& .MuiPagination-ul > li > div': {
								color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
							}
						}}
						count={pages} page={page} onChange={(e, value) => setPage(value)} />
				</MDBox>}
			</TableContainer>
		</Card>


	);
};


function OrderRow({ row, getLink }) {
	const navigate = useNavigate();

	const [open, setOpen] = React.useState(false);
	const [textValue, setTextValue] = React.useState('');

	const handleOpenDialog = (data) => {
		setOpen(data);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};


	const [loading, setLoading] = React.useState(false);
	const [openD, setOpenD] = React.useState(false);
	const dispatch = useDispatch();




	function openConfirmationDialog() {
		setOpenD(true);
	}

	function closeConfirmationDialog() {
		setOpenD(false);
	}

	function handleConfirmation() {
		setLoading(true)
		dispatch(deleteAdminWidget(row.id)).then(data => {
			setLoading(false)
			getLink()
		}).catch(err => {
			setLoading(false)
		})
	}

	function handleUnassign(e) {
		e.preventDefault();
		setLoading(true);
		dispatch(unAssignAdminCustomWidget({
			widgetId: row.id,
		})).then(data => {
			setLoading(false)
			getLink()
		}).catch(err => {
			setLoading(false)

		})
	}

const [controller]=useMaterialUIController()
  const { darkMode } = controller;

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" >
						{row?.id}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={`https://${row?.hostname}`}>
						{row?.hostname}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row?.active ? "True" : "False"}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold">
						{row?.trial ? "True" : "False"}
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					{row?.assigned === false || row?.assigned === null ? (
						<MDTypography variant="caption" fontWeight="bold">
							Not Assigned
						</MDTypography>
					) : (
						<MDTypography variant="caption" fontWeight="bold">
							{row?.assigned}
						</MDTypography>
					)}
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => handleOpenDialog({ data: row.snippet, type: 'Snippet' })} >
						Show Snippet
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDTypography variant="caption" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => handleOpenDialog({ data: row.snippetInjector, type: 'Snippet Injector' })} >
						Show Snippet Injector
					</MDTypography>
				</TableCell>
				<TableCell align="left">
					<MDBox component='span' display='flex' alignItems='center' gap={2}>
						<MDButton variant='contained' color="info" onClick={() => navigate(`/accessblend-admin/saas/accessBlend/edit/widget/${row.id}`)}> Settings</MDButton>
						<MDButton variant='contained' color="info" onClick={() => navigate(`/accessblend-admin/saas/accessBlend/snippets/${row.id}`)}> Manage Snippets</MDButton>
						<MDButton variant='contained' color='info' onClick={openConfirmationDialog}>
							Delete
						</MDButton>

						{row?.assigned === false || row?.assigned === null ? (
							<MDButton variant='contained' color='info' onClick={() => navigate(`/accessblend-admin/saas/accessBlend/assign/custom-widget/${row.id}`)}>
								assign
							</MDButton>
						) : (
							<MDButton variant='contained' color='info' onClick={handleUnassign}>
								unassign
							</MDButton>
						)}

					</MDBox>
				</TableCell>
			</TableRow>
			<Dialog open={Boolean(open)} onClose={handleCloseDialog} fullWidth maxWidth='md'>
				<DialogTitle>{open.type}</DialogTitle>

				<DialogContent>
					<MDBox display='flex' alignItems='center' gap={2} p={2}><MDTypography variant='body2' sx={{ fontSize: '12px' }}><code>{open.data}</code></MDTypography><CopyToClipboard text={open.data} onCopy={() => toast.success("Snippet Copied!")}>
						
						<IconButton edge="end"
						sx={{
							color:darkMode?"white":"black"
						}}
						>
							<FileCopyIcon />
						</IconButton>
					</CopyToClipboard></MDBox>
				</DialogContent>
			</Dialog>
			<Dialog open={openD} onClose={loading ? null : closeConfirmationDialog}>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to delete?</p>
				</DialogContent>
				<DialogActions>
					<MDButton onClick={closeConfirmationDialog} disabled={loading}>Cancel</MDButton>
					<MDButton onClick={handleConfirmation} color="primary" loading={loading}>Confirm</MDButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}






