import auth from './authReducer';
import dashboard from './dashboard.reducer';
import adminSmokiDashboard from './adminSmokiDashboard.reducer';
import userDashboard from './userDashboard.reducer';
import adminUserManagement from './adminUserManagement.reducer';


import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  auth,
  dashboard,
  adminSmokiDashboard,
  userDashboard,
  adminUserManagement,
});

export default rootReducer;
