import { Alert, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import MDTypography from "components/MDTypography";
import PermissionBox from "components/PermissionBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateBilling } from "redux/actions/auth.action";

export default function UpdateBilling() {
	const billings = useSelector(state => state.auth.user)
	 const { billing } = useSelector((state) => state.auth.user.permissions);
	

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [submitLoading, setSubmitLoading] = React.useState(false);
	const [details, setDetails] = React.useState({
		details: {
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			country: '',
			email: '',
			postcode: '',
			phone: '',
			company: ''
		},

	})

	function handleChangeBilling(e) {
		setDetails({
			...details,
			details: {
				...details.details,
				[e.target.name]: e.target.value
			}
		})
	}

	function handleSubmit(e) {
		e.preventDefault();
		setSubmitLoading(true);
		dispatch(updateBilling({ details })).then(data => {
			setSubmitLoading(false)
			if(data){
				setDetails({
		details: {
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			country: '',
			email: '',
			postcode: '',
			phone: '',
			company: ''
		},

	})
			}
		}).catch(err => {
			setSubmitLoading(false)
		})
	}

	React.useEffect(() => {
		if (billings) {
			setDetails({ details: { ...billings.organization.billingDetails.details } })
		}
	}, [billings])

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{
				billing?.editBillingDetails ?
			<Card sx={{ mt: 3, p: 2 }}>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<MDTypography marginBottom variant='h5'>Billing Details</MDTypography>

					<Grid container spacing={0} style={{ margin: '20px 0px' }}>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='firstName' value={details.details.firstName} onChange={handleChangeBilling} required={true} label='First Name' type='text' />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='lastName' value={details.details.lastName} onChange={handleChangeBilling} required={true} label='Last Name' type='text' />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='address1' onChange={handleChangeBilling} required={true} label='Address' type='text' value={details.details.address1} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='address2' onChange={handleChangeBilling} label='Address 2' type='text' value={details.details.address2} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='city' onChange={handleChangeBilling} required={true} label='City' type='text' value={details.details.city} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='state' onChange={handleChangeBilling} required={true} label='State' type='text' value={details.details.state} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='country' onChange={handleChangeBilling} required={true} label='Country' type='text' value={details.details.country} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='postcode' onChange={handleChangeBilling} required={true} label='Zipcode' type='text' value={details.details.postcode} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='email' onChange={handleChangeBilling} required={true} label='Email' type='email' value={details.details.email} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='phone' onChange={handleChangeBilling} required={true} label='Phone' type='text' value={details.details.phone} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='company' onChange={handleChangeBilling} required={true} label='Company' type='text' value={details.details.company} />
						</Grid>
					</Grid>
					<MDBox display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
						<MDButton variant="contained" color="info" type='submit' loading={submitLoading}  >
							Submit Details
						</MDButton>
					</MDBox>
				</form>
			</Card>
			:
			<PermissionBox text={"to view edit billing details"}/>
			}
		</DashboardLayout>
	)
}
