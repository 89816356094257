import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, Dialog, FormControl, Grid, Icon, InputLabel, MenuItem, TableContainer,FormControlLabel } from "@mui/material";
import Select from 'react-select';
import MDProgress from "components/MDProgress";
import simpleDateparser from "utils/simpledateParser";
import { useDispatch } from "react-redux";
import { useMaterialUIController } from "context";
import { getUserBillingSubscriptions } from "redux/actions/userDashboard.action";


import useDebounce from "hooks/useDebouce";

const BillingAllSubscriptions = ({ type, title }) => {

    const dispatch = useDispatch();
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [rows, setRows] = React.useState(0);

    const [loading, setLoading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');
    const [selectedLabel, setSelectedLabel] = React.useState('Subscription Id');
    const [selectedValue, setSelectedValue] = React.useState('id');

    function fetchSubscriptions() {
        setLoading(true)
        dispatch(getUserBillingSubscriptions(type, page,searchValue, selectedLabel, selectedValue)).then(data => {
            if (data) {
                setRows(data.result)
                setPages(data.pages)
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false)
        })
    }

    useDebounce(() => {
        fetchSubscriptions()
    }, [searchValue, page], 500);

    React.useEffect(() => {
        () => {
            setPage(1)
            setPages(0)
            setRows(0)
            setLoading(false)
            setSearchValue("")
            // setType("order")
        }
    }, [])


    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (

        <Card sx={{ mt: 5 }}>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display='flex' justifyContent='space-between' alignItems='center'
            >
                <MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize', width: '100%' }}>
                    {title} Subscriptions
                </MDTypography>

                <MDBox display='flex' flexDirection={{ xs: 'column', sm: 'row' }} alignItems='center' width='100%' >
                    <FormControl style={{ width: '100%', maxWidth: { xs: '100%', sm: 150 }}} >

                        <Select
                            id='change-api'
                            defaultValue={{ label: 'Subscription Id', value: 'id' }}
                            options={[
                                {
                                    value: "id",
                                    label: 'Subscription Id'
                                },
                                {
                                    value: "status",
                                    label: 'Status'
                                },
                                {
                                    value: "order",
                                    label: 'Order'
                                },
                                {
                                    value: "website",
                                    label: 'Website'
                                },
                            ]}
                            isSearchable={false}
                            onChange={(newValue) => {
                                setSelectedLabel(newValue.label);
                                setSelectedValue(newValue.value);
                            }}
                        />
                    </FormControl>
                    <MDInput variant='outlined'

                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'white !important',

                            },
                            '&::placeholder': {
                                color: '#999', // change the color of the placeholder text
                                fontStyle: 'italic', // add italic font style to the placeholder text
                                opacity: 0.5, // reduce the opacity of the placeholder text
                            },
                            marginLeft: { xs: 0, sm: 2 },
                            marginTop: { xs: 2, sm: 0 },
                        }}
                        fullWidth placeholder='Enter search value' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                </MDBox>

            </MDBox>

            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <thead>
                        <TableRow>
                            <TableCell align="left" sx={{ minWidth: 300 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Subscription ID
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Order Id
                                </MDTypography>
                            </TableCell>

                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Status
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Total
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Discount
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Total Discounted
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Link
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 230 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Created At
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 230 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Period Start
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 230 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Period End
                                </MDTypography>
                            </TableCell>


                        </TableRow>
                    </thead>
                    {loading ?
                        <TableBody>
                            <TableRow >
                                <TableCell align="center" colSpan={8} >
                                    <MDBox
                                        display="flex"
                                        py={1}
                                        flexDirection='column'
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <MDProgress circular />
                                        <MDTypography variant="h6">
                                            Loading Subscriptions...
                                        </MDTypography>
                                    </MDBox>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {rows && rows.length > 0 ? rows.map((row, index) => {
                                return <OrderRow fetchSubscriptions={fetchSubscriptions} darkMode={darkMode} row={row} rows={rows} key={`order-${index}`} />
                            }) :
                                <TableRow>
                                    <TableCell align="center" colSpan={6} >
                                        <MDTypography variant='caption' component='span' fontWeight="bold">no  subscriptions found</MDTypography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>}
                </Table>
                {rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                    <Pagination color='info' variant="outlined"
                        sx={{
                            '& .MuiPagination-ul > li > button': {
                                fontWeight: 400,
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            },
                            '& .MuiPagination-ul > li > div': {
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            }
                        }}
                        count={pages} page={page} onChange={(e, value) => setPage(value)} />
                </MDBox>}
            </TableContainer>
        </Card>

    );
};


function OrderRow({ row, fetchSubscriptions, darkMode }) {
    const dispatch = useDispatch();





    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.orderId}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.status}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.total}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.discount}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.totalDiscounted}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" component='a' href={row?.stripeLink} target="_blank">
                        view
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {simpleDateparser(row?.created)}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {simpleDateparser(row?.currentPeriodStart)}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.endedAt ? simpleDateparser(row?.endedAt) : "N/A"}
                    </MDTypography>
                </TableCell>
            </TableRow>



        </React.Fragment>
    )
}

export default BillingAllSubscriptions; 