import React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";

//   components
import MDBox from "components/MDBox";

//   example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useDispatch, useSelector } from "react-redux";
import MDProgress from "components/MDProgress";
import { getUserAnalytics } from "redux/actions/userDashboard.action";

import shortNumber from 'short-number';
import MDTypography from 'components/MDTypography';

function UserDashboard() {

  const dispatch = useDispatch();

  const { analytics, loading } = useSelector(state => state.userDashboard);

  React.useEffect(() => {
    dispatch(getUserAnalytics());
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {!loading ?
          <>
            <MDBox mb={6}>
              <MDTypography variant='h5' sx={{ mt: 1, mb: 5 }}>Orders</MDTypography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="list_alt"
                      title="Total Orders"
                      count={analytics ? shortNumber(analytics.orders) : 0}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="add_task"
                      title="Completed Orders"
                      count={analytics? shortNumber(analytics.completedOrders):0}

                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="secondary"
                      icon="pending_actions"
                      title="Pending Orders"
                      count={analytics ? shortNumber(analytics.pendingOrders):0}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDTypography variant='h5' sx={{ mt: 1, mb: 5 }}>Invoices</MDTypography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="receipt_long"
                      title="Total Invoices"
                      count={analytics ? shortNumber(analytics.invoices):0}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="secondary"
                      icon="success"
                      title="Paid Invoices"
                      count={analytics ? shortNumber(analytics.paidInvoices):0}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="secondary"
                      icon="block"
                      title="Void Invoices"
                      count={analytics? shortNumber(analytics.voidInvoices):0}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDTypography variant='h5' sx={{ mt: 1, mb: 5 }}>Clusters</MDTypography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="info"
                      icon="density_small"
                      title="Total Clusters"
                      count={analytics ? shortNumber(analytics.clusters):0}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="warning"
                      icon="dns"
                      title="Active Clusters"
                      count={analytics ? shortNumber(analytics.activeClusters):0}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="error"
                      icon="dns"
                      title="Inactive Clusters"
                      count={analytics && shortNumber(analytics.inactiveClusters)}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDTypography variant='h5' sx={{ mt: 1, mb: 5 }}>Accessibility & Frontend Issues</MDTypography>

              <Grid container spacing={3}>

                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="error"
                      icon="accessibility_new"
                      title="Total Accesscibility Issues"
                      count={analytics && shortNumber(analytics.totalAccessibilityIssues)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="psychology"
                      title="Total Cognitive Issues"
                      count={analytics && shortNumber(analytics.totalAccessibilityCognitiveIssues)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="blind"
                      title="Total Visual Issues"
                      count={analytics && shortNumber(analytics.totalAccessibilityVisualIssues)}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="info"
                      icon="hearing"
                      title="Total Hearing Issues"
                      count={analytics && shortNumber(analytics.totalAccessibilityHearingIssues)}
                    />
                  </MDBox>
                </Grid>


                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="error"
                      icon="accessible"
                      title="Total Motor Issues"
                      count={analytics && shortNumber(analytics.totalAccessibilityMotorIssues)}
                    />
                  </MDBox>
                </Grid>


                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="warning"
                      icon="code_off"
                      title="Total Section508 Issues"
                      count={analytics && shortNumber(analytics.totalAccessibilitySection508Issues)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="code"
                      title="Total Frontend Issues"
                      count={analytics && shortNumber(analytics.totalFrontendIssues)}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="dark"
                      icon="html"
                      title="Total HTML Issues"
                      count={analytics && shortNumber(analytics.totalHtmlIssues)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="secondary"
                      icon="css"
                      title="Total CSS Issues"
                      count={analytics && shortNumber(analytics.totalCssIssues)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="info"
                      icon="javascript"
                      title="Total JavaScript Issues"
                      count={analytics && shortNumber(analytics.totalJavascriptIssues)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="warning"
                      icon="speed"
                      title="Total Performance Issues"
                      count={analytics && shortNumber(analytics.totalPerformanceIssues)}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDTypography variant='h5' sx={{ mt: 1, mb: 5 }}>Links & Sitemaps</MDTypography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="link"
                      title="Total Links"
                      count={analytics && shortNumber(analytics.totalLinks)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="secondary"
                      icon="leak_add"
                      title="Sitemap Issues"
                      count={analytics && shortNumber(analytics.totalSitemapIssues)}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </>
          :
          <MDProgress circular />
        }
      </MDBox>
    </DashboardLayout>
  );
}

export default UserDashboard;
