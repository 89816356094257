import React from 'react';
import { useSelector } from 'react-redux';
//   components
import MDBox from "components/MDBox";
//   example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import BillingAllSubscriptions from './BillingAllSubscriptions';
import PermissionBox from 'components/PermissionBox';
// import WidgetSubscriptions from './Widgetsubscriptions';

function BillingUserSubscriptions() {
     const { billing } = useSelector((state) => state.auth.user.permissions);

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <MDBox py={3}>
                {billing && billing?.viewSubscriptions ?
                <>
                    <MDBox mt={6}>
                        <BillingAllSubscriptions type='website-auditor-order-subscription' title="Auditor" />
                    </MDBox>
                    <MDBox mt={6}>
                        <BillingAllSubscriptions type='website-widget-order-subscription' title="Widget"/>
                    </MDBox>
                    <MDBox mt={6}>
                        <BillingAllSubscriptions type='organization-user-subscription' title="User"/>
                    </MDBox>
                </>
                :
                <PermissionBox text="to view subscription list" />
                }
            </MDBox>
        </DashboardLayout>
    );
}

export default BillingUserSubscriptions;
