

//   Base Styles
import colors from "assets/theme-dark/base/colors";

const { transparent } = colors;

export default {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      "&.Mui-disabled": {
        backgroundColor: `${transparent.main} !important`,
        color: '#000'
      },
    },
  },
};
