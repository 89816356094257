
import borders from "assets/theme-dark/base/borders";


const { borderRadius } = borders;

export default {
  styleOverrides: {
    paper: {
      // backgroundColor: 'transparent',

      // boxShadow: md,
      // padding: '8px 12px',
      borderRadius: borderRadius.md,
    },
  },
};
