

//   base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

//   helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      width: pxToRem(280),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(280),
      backgroundColor: colors.grey[900],
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: pxToRem(16),
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};
