import { useEffect } from "react";


// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Icon from "@mui/material/Icon";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
// context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import React from "react";
import { useSelector } from "react-redux";
import logoWhite from 'assets/images/logos/logo-white.png'
import sidenavFormat from "utils/sidenavFormat";

import Collapsible from "./Collapsible";
import WebsiteSelectorMobile from "./WebsiteSelectorMobile";
import { Hidden, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";




function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const theme = useTheme();
  const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)
  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const user = useSelector(state => state.auth.user)
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, transparentSidenav);
      setWhiteSidenav(dispatch, whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
    // eslint-disable-next-line
  }, [dispatch, location]);

  const adminDash = location.pathname.split('/')[2]
  const currentRoute = location.pathname;

  function routeLockerCollapse(route) {
    if (route !== "/payment-methods" && route !== "/settings") {
      if ((user.isFirstLogin && user.role !== 'accessblend-admin') || !user?.organization?.isWebsiteAdded && user.role !== 'accessblend-admin') {
        return true
      } else if (selectedSubdomain && !selectedSubdomain?.verified) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  function routeLocker(route, layout) {
    if (route !== "/payment-methods" && route !== "/settings") {
      if (user?.role !== 'accessblend-admin') {
        if (user?.isFirstLogin) {
          return '/user/main/accessBlend/settings'
        } else if (!user?.organization?.isWebsiteAdded) {
          return '/user/main/accessBlend/add-website'
        }
        else if (selectedSubdomain && !selectedSubdomain?.verified) {
          return "/user/main/accessBlend/verify-dns"
        }
        else {
          return `/${layout}${route}`
        }
      } else {
        return `/${layout}${route}`
      }
    } else {
      return `/${layout}${route}`
    }
  }


  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = sidenavFormat(routes.filter(item => (item.layoutName === (adminDash === "main" ? "user" : adminDash)))).map(({ items, name, icon, key, sidenav, route, layout }) => {
    let returnValue;
    if (sidenav) {
      // const type 
      if (items && items.length > 0) {
        returnValue = <Collapsible icon={icon} name={name} disabled={routeLockerCollapse(route)} childrens={items} currentRoute={currentRoute} active={`/${layout}${route}` === currentRoute} />
      } else {
        returnValue =
          <NavLink key={key} to={routeLocker(route, layout)}>
            <SidenavCollapse name={name} icon={icon} active={`/${layout}${route}` === `${currentRoute}`} />
          </NavLink>
      }
    }
    return returnValue;
  });

  function getCurrentYear() {
    const date = new Date();
    return date.getUTCFullYear()
  }
  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        {/* <img src={logoWhite} height={65} /> */}
        <MDBox component={NavLink} to="/" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          {brand && <MDBox component="img" src={darkMode ? logoWhite : brand} alt="Brand" my={3} height={65} />}

        </MDBox>

      </MDBox>
      {window.innerWidth < 1200 && <WebsiteSelectorMobile />}

      <List>{renderRoutes}
      </List>
      <MDBox px={2} pt={2} textAlign='center' mt='auto' py={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          textAlign: 'center',
          width: '100%',
          top: 'auto',
          background: 'inherit'

        }}
      >
        {/* <MDTypography variant='caption'>
          {currentDate}
        </MDTypography> */}

        <MDTypography variant='caption' sx={{ fontSize: 13, display: 'block', mb: 0.5 }}>
          © {getCurrentYear()} <MDTypography sx={{ cursor: 'pointer', fontSize: 13 }} onClick={() => window.open("https://accessblend.com/")} variant='caption'   >AccessBlend
          </MDTypography></MDTypography>
        <MDTypography variant='caption' sx={{ display: 'block' }}>
          <MDTypography sx={{ cursor: 'pointer' }} onClick={() => window.open("https://accessblend.com/terms-and-conditions/")} variant='caption'> Terms and Conditions </MDTypography> |  <MDTypography sx={{ cursor: 'pointer' }} onClick={() => window.open("https://accessblend.com/privacy-policy/")} variant='caption'> Privacy Policy </MDTypography></MDTypography>

      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
