import React from "react";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import UserManagementSubscriptions from "../subscriptions/UserManagementSubscription";

const AdminUserManagementSubscriptions = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <UserManagementSubscriptions type="organization-user-subscription" name="User" />
            <UserManagementSubscriptions type="website-widget-order-subscription" name="Widget" />
            <UserManagementSubscriptions type="website-auditor-order-subscription" name="Auditor" />
        </DashboardLayout>
    );
};

export default AdminUserManagementSubscriptions; 