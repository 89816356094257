import * as Actions from '../actions/dashboard.action';

const initialState = {
  analytics: null,
  backups: [],
  loading: true,
  loadingBackups: false,
  loadingHelp: false,
  backupCount: 0,
  backupPages: 0,
  notifications: {
    data: null,
    loading: false,
    filter_loading: false,
    count: 0,
    hasMoreData: false,
  },

  systemStatuses: [],
  systemStatusesLoading: true,

  databases: [],
  loadingDatabases: false,
};

function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_USER_ANALYTICS:
      return {
        ...state,
        analytics: payload,
        loading: false,
      };
    case Actions.GET_WEBSITE_BACKUPS:
      return {
        ...state,
        backups: payload,
        backupCount: payload.count,
        backupPages: payload.pages,
        loadingBackups: false
      };
    case Actions.GET_USER_ANALYTICS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_WEBSITE_BACKUPS_LOADING:
      return {
        ...state,
        loadingBackups: true
      };
    case Actions.SEND_HELP_QUERY_LOADING:
      return {
        ...state,
        loadingHelp: !state.loadingHelp
      };

    case Actions.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...state.notifications, data: payload.result, count: payload.count, loading: false, hasMoreData: payload.hasMore }
      }
    case Actions.FILTER_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...state.notifications, count: state.notifications.count - 1, filter_loading: false }
      }
    case Actions.FILTER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: { ...state.notifications, filter_loading: !state.notifications.filter_loading }
      }
    case Actions.LOADING_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...state.notifications, loading: true }
      }
    case Actions.GET_SYSTEM_STATUSES:
      return {
        ...state,
        systemStatuses: payload,
        systemStatusesLoading: false
      };
    case Actions.GET_SYSTEM_STATUSES_LOADING:
      return {
        ...state,
        systemStatusesLoading: true
      };
    case Actions.GET_WEBSITE_DATABASES:
      return {
        ...state,
        databases: payload,
        loadingDatabases: false
      };
    case Actions.GET_WEBSITE_DATABASES_LOADING:
      return {
        ...state,
        loadingDatabases: true
      };
    default:
      return state;
  }
}

export default auth;
