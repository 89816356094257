import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MDBox from 'components/MDBox';
import Slide from '@mui/material/Slide';
import MDButton from 'components/MDButton';
import { Card } from '@mui/material';
import { useMaterialUIController } from "context";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDTypography from 'components/MDTypography';

import { dnsVerify } from 'redux/actions/userDashboard.action';
import { Navigate, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default function VerifyDNS() {

	const [loading, setLoading] = React.useState(false);

	const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)
	const navigate = useNavigate()

	const dispatch = useDispatch();

	function handleAddWebsite() {
		setLoading(true)
		dispatch(dnsVerify({ websiteId: selectedSubdomain.id })).then(data => {
			if (data) {
				navigate("/user/main/accessBlend/websites");
				setLoading(false);
			} else {
				setLoading(false);
			}
		}).catch(err => {
			setLoading(false)
		})
	}

	if (selectedSubdomain?.verified) {
		navigate('/user/main/accessBlend/websites')
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox display='flex' justifyContent='center' alignItems='center' mt={10}>
				<Card sx={{ p: 4, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: 600 }}>
					{/* <Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>Guidelines</Typography>
						</AccordionSummary>
						<AccordionDetails>
						
						</AccordionDetails>
					</Accordion> */}
					<MDTypography variant='h4'>Guidelines</MDTypography>
					<MDTypography variant='subtitle2' marginBottom sx={{ fontWeight: 'regular' }}>
						<ol style={{ listStyleType: 'decimal', paddingLeft: '1.5rem' }}>
							<li>
								Obtain the verification details:
								<p>The verification token can be found below.</p>
							</li>
							<li>
								Access your DNS management:
								<p>
									Log in to your domain registrar or DNS hosting provider's website. You will need your account credentials to access your DNS management.
								</p>
							</li>
							<li>
								Locate the DNS settings:
								<p>
									Locate the DNS settings or DNS management section within your domain registrar or DNS hosting provider's website. This may be called something like "DNS management," "DNS settings," or "Manage DNS."
								</p>
							</li>
							<li>
								Add the DNS A record:
								<p>Add a new DNS A record using the verification token as the value.</p>
							</li>
							<li>
								Save the DNS changes:
								<p>Save the changes you made to the DNS settings. This will update the DNS records for your domain.</p>
							</li>
							<li>
								Verify DNS propagation:
								<p>
									DNS changes may take some time to propagate across the internet. You can use online DNS lookup tools to check if the new DNS A record is visible. Once the DNS propagation is complete, the verification system will be able to validate your domain ownership.
								</p>

							</li>
							<li>
								Submit for verification:
								<p>
									After confirming that the DNS A record is propagated and visible, click on verify to begin the verification.
								</p>
							</li>
						</ol>

					</MDTypography>

					<MDTypography variant='h6'>Verification Token: {selectedSubdomain?.verificationToken}</MDTypography>
					<MDTypography variant='subtitle1' sx={{ fontWeight: '500', mt: 2, mb: 2 }} >Your Website: {selectedSubdomain?.domainPrettyName}</MDTypography>
					<MDButton onClick={() => handleAddWebsite()} size='large' variant='contained' color='success' loading={loading}>Verify Website</MDButton>


				</Card>
			</MDBox>
		</DashboardLayout>
	)
}
