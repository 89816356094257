import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Grid, FormControlLabel, Switch, FormGroup, Card } from '@mui/material';
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from 'components/MDBox';
import { useParams } from 'react-router-dom';
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';
import { getWidgetDetail } from 'redux/actions/adminSmokiDashboard.action';
import MDProgress from 'components/MDProgress';
import { widgetControlCenter } from 'redux/actions/adminSmokiDashboard.action';


export default function EditWidget() {

    const { widgetId } = useParams();
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [getLoading, setGetLoading] = React.useState(true);
    const [active, setActive] = React.useState(false);
    const [selectedPalette, setSelectedPalette] = React.useState('');
    const [selectedLanguage, setSelectedLanguage] = React.useState('');

    const handlePaletteChange = (event) => {
        const value = event.target.value;
        setSelectedPalette(value);
    };


    const handleLanguageChange = (event) => {
        const value = event.target.value;
        setSelectedLanguage(value);
    };


    function handleMode(e) {
        setActive(e.target.checked)
    }

    const palettes = [
        "default",
        "blue",
        "gray",
        "green",
        "violet",
    ]

    const languages = [
        "english", "french", "spanish", "arabic"
    ]

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        dispatch(widgetControlCenter({
            widgetId,
            theme: selectedPalette,
            darkMode: active,
            language: selectedLanguage
        })).then(data => {
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    React.useEffect(() => {
        if (widgetId) {
            dispatch(getWidgetDetail({ widgetId })).then(data => {
                setGetLoading(false)
                setActive(data?.result?.settings?.darkMode);
                setSelectedPalette(data?.result?.settings?.theme || "default")
                setSelectedLanguage(data?.result?.settings?.language || "english")

            })
        }
    }, [widgetId])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card style={{ marginBottom: 20, marginTop: 20, padding: 20 }}>
                <MDTypography variant='h4' sx={{ mt: 2 }} marginBottom>Edit Widget Settings</MDTypography>
                {getLoading ? <MDBox display='flex' justifyContent='center' alignItems='center' height='100%'>
                    <MDProgress circular />
                </MDBox> :
                    <MDBox my={4} component='form' onSubmit={handleSubmit}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <FormSelect palettes={palettes} selectedPalette={selectedPalette}
                                    onChange={handlePaletteChange} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormSelect palettes={languages} label='Select Language' id='select-language' selectedPalette={selectedLanguage}
                                    onChange={handleLanguageChange} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormGroup>
                                    <FormControlLabel style={{ display: 'flex' }} control={<Switch checked={active} onChange={handleMode} />} label={"Dark Mode"} />
                                </FormGroup>
                            </Grid>

                        </Grid>
                        <MDBox display='flex' my={3}>
                            <MDButton variant='contained' color='primary' type='submit' loading={loading}>Update</MDButton>
                        </MDBox>
                        <MDBox width='100%' textAlign='center' >
                            <img src={!active ? `/assets/widgets/${selectedPalette}.png` : `/assets/widgets/dark-${selectedPalette}.png`} style={{ width: '100%', maxWidth: 400, margin: 'auto' }} />
                        </MDBox>
                    </MDBox>
                }
            </Card>
        </DashboardLayout>
    )
}





const FormSelect = ({ palettes, selectedPalette, onChange, label, id }) => {

    return (
        <FormControl fullWidth>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                fullWidth={true}
                label={label}
                labelId={id}
                id="color-palette-select"
                value={selectedPalette}
                onChange={onChange}
            >
                {palettes.map((palette, index) => (
                    <MenuItem key={index} value={palette}>
                        <span style={{ textTransform: 'capitalize' }}>{palette}</span>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};



