
import { FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SET_USER_SUBDOMAIN } from "redux/actions/userDashboard.action";
import { SET_SELECTED_WEBSITE } from "redux/actions/userDashboard.action";
import AddWebsiteDialog from "./AddWebsiteDialog";


export default function WebsiteSelectorMobile() {
	const user = useSelector(state => state.auth.user)

	const websites = useSelector(state => state.userDashboard.websites)
	const selectedWebsite = useSelector(state => state.userDashboard.selectedWebsite)
	const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)

	const [subdomains, setSubdomains] = React.useState([])


	const [openWebsiteDialog, setOpenWebsiteDialog] = React.useState(false);

	const dispatch = useDispatch();

	const handleClose = () => {
		setOpenWebsiteDialog(false);
	};

	const handleClickOpen = () => {
		setOpenWebsiteDialog(true);
	};

	React.useEffect(() => {
		if (selectedWebsite) {
			if (selectedWebsite.subdomains.length > 0) {
				setSubdomains(selectedWebsite.subdomains)
			}

		}
	}, [selectedWebsite])

	function handleWebsite(e) {
		const domain = websites.filter(web => web.id === e.target.value)[0];
		dispatch({
			type: SET_SELECTED_WEBSITE,
			payload: domain
		})

		if (domain?.subdomains?.length > 0) {
			setSubdomains(domain?.subdomains);
			dispatch({
				type: SET_USER_SUBDOMAIN,
				payload: domain?.subdomains[0]
			})

		} else {
			setSubdomains([]);
			dispatch({
				type: SET_USER_SUBDOMAIN,
				payload: null
			})

		}
	}


	function handleSubdomain(e) {
		const subdomain = e.target.value;
		dispatch({
			type: SET_USER_SUBDOMAIN,
			payload: subdomain
		})
		// setSelectedSubdomain(subdomain)

	}

	if (user.role !== 'accessblend-admin') {
		return (
			<MDBox mt={3} mb={2.5} mx={2.5}>
				{websites?.length > 0 ?
					<Grid container spacing={1} >
						<Grid item xs={10}>
							<FormControl fullWidth sx={{ maxWidth: '100%' }}>
								<InputLabel id="cluster-status">Select Website</InputLabel>
								<Select
									labelId="cluster-status"
									id="cluster-status-select"
									name='secure'
									value={selectedWebsite ? selectedWebsite.id :""}
									label="Select Website"
									onChange={handleWebsite}
								>
									{
										websites.map((website, index) => {
											return (
												<MenuItem value={website.id} key={website.id}>{website.website}</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								component={Link} to='/user/main/accessBlend/add-website'
							> <Icon>add</Icon> </IconButton>
						</Grid>
					</Grid> :

					<MDBox display='flex' justifyContent='center' >
						<MDButton variant='contained' color='info' size='small' component={Link} to='/user/main/accessBlend/add-website' >
							Add Website
						</MDButton>
					</MDBox>
				}
				{subdomains.length > 0 && <FormControl fullWidth sx={{ maxWidth: '100%', mt: 2.5 }}>
					<InputLabel id="cluster-status">Select Subdomain</InputLabel>
					<Select
						labelId="cluster-status"
						id="cluster-status-select"
						name='secure'
						value={selectedSubdomain}
						label="Select Subdomain"
						onChange={handleSubdomain}
					>
						{
							subdomains.map((subdomain, index) => {
								return (
									<MenuItem value={subdomain} key={subdomain.id}>{subdomain.website}</MenuItem>
								)
							})
						}
					</Select>
				</FormControl>}
				<AddWebsiteDialog open={openWebsiteDialog} handleClose={handleClose} />
			</MDBox>
		)
	}
	else {
		return <></>
	}
}

