import React from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import WidgetSubscriptions from "./Widgetsubscriptions";

const AdminWidgetSubscriptions = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <WidgetSubscriptions />
        </DashboardLayout>
    );
};

export default AdminWidgetSubscriptions; 