import { useSelector } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuditorOrders from "./AuditorOrders";
import PermissionBox from "components/PermissionBox";
export default function Orders() {
const { auditor } = useSelector((state) => state.auth.user.permissions);
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                auditor && auditor?.viewAuditorOrders ?
                <AuditorOrders />:
                <PermissionBox text={"to view auditor orders details"} />
            }
        </DashboardLayout>)
}