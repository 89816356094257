import React from 'react';
import Icon from "@mui/material/Icon";
// import ConfigVars from 'pages/Admin/KeyValue';
import ViewInvoice from 'pages/Admin/AllInvoices/invoices/ViewInvoice';
import ViewOrder from 'pages/Admin/orders/ViewOrder';
import ViewSaasOrder from 'pages/Admin/orders/ViewSaasOrder';

// import UserOrders from 'pages/User/orders';
import Settings from 'pages/Admin/settings/Index';
import UserWebsites from 'pages/User/websites';
import UserSettings from 'pages/User/Settings';

import OtherWidgets from 'pages/User/Other-Services/OtherWidgets';
import OtherWebsites from 'pages/User/Other-Services/other-websites';




// import ViewUserInvoice from 'pages/User/UserInvoices/ViewInvoice';
import userDashboard from 'pages/User/dashboard';
import ResetPassword from 'pages/authentication/reset-password/cover';
import ClusterVars from 'pages/Admin/ClusterVars';
import ChildUsers from 'pages/Admin/child-users';
import ChildWebsites from 'pages/Admin/child-websites';
import Organizations from 'pages/Admin/organizations';

import AuditorSubscriptions from 'pages/Admin/auditor-subscriptions/Index';
import AdminAuditorInvoices from 'pages/Admin/auditor-invoices/index';
import AuditorOrders from 'pages/User/auditor-orders/index'

import UserViewOrder from 'pages/User/orders/UserViewOrder';
import UserViewWidgetOrder from 'pages/User/orders/UserViewWidgetOrder';

import UnifiedAnalytics from 'pages/Admin/UnifiedAnalytics';
import Clusters from 'pages/Admin/Clusters';

import Widget from 'pages/User/Widget';
import WidgetLogs from 'pages/Admin/widget-logs';
import Billings from 'pages/User/billings';
import UserBilling from 'pages/User/billings/user-billing';

import EditWidget from 'pages/User/EditWidget';
import WidgetPackages from 'pages/Admin/widget-packages';

import AdminWidgetSubscriptions from 'pages/Admin/widget-subscriptions/Index';
import AdminWidgetInvoices from 'pages/Admin/widgetInvoices/index';
import WidgetUserInvoices from 'pages/User/widgetInvoices/WidgetInvoices';
import WidgetUserSubscriptions from 'pages/User/widget-subscriptions/Index';
import WidgetOrders from 'pages/User/widget-orders';

// Billing Invocies
import BillingAllInvoices from 'pages/User/billings/all-invoices/index';

// Billing Subscriptions
import BillingUserSubscriptions from 'pages/User/billings/all-subscriptions/Index'

import AdminUserManagementSubscriptions from 'pages/Admin/user-management/subscriptions/index'
import AdminUserManagementInvoices from 'pages/Admin/user-management/invoices/index'
import AdminUsers from 'pages/Admin/user-management';
import AdminOrganizations from 'pages/Admin/user-management/organizations/Organizations';
import AdminOrganizationUsers from 'pages/Admin/user-management/user-management';

import UpdateAdminOrganizationUser from 'pages/Admin/user-management/user-management/UpdateUser';
import UpdateAdminOrganization from 'pages/Admin/user-management/organizations/UpdateOrganization';


import VerifyEmail from 'pages/authentication/verify-email';
import UserManagement from 'pages/User/user-management';
import Snippets from 'pages/User/snippets';
import AddWebsite from 'pages/User/AddWebsite';
import WebsiteReport from 'pages/User/WebsiteReport';
import CustomWebsites from 'pages/Admin/custom-websites';
import AdminSnippets from 'pages/Admin/snippets';
import CustomWidgets from 'pages/Admin/CustomWidgets';
import WidgetBilling from 'pages/User/Billing';
import VerifyDNS from 'pages/User/DNSVerify';


import AdminUserWebsites from 'pages/Admin/user-websites';
import LoginCallBack from 'pages/authentication/LoginCallback';
import AutomaticAuditor from 'pages/User/AutomaticAuditor';
import UserAuditorBilling from 'pages/User/AutomaticAuditor/UserAuditorBilling';
import AllWidgetOrders from 'pages/Admin/orders/orders';
import AllAuditorOrdersTable from 'pages/Admin/orders/orders/index2';
import UpdateBilling from 'pages/User/UpdateBilling';
import ViewWidgetOrder from 'pages/Admin/orders/ViewWidgetOrder';
import AssignAdminWidget from 'pages/Admin/widget-assign/AssignAdminWidget'
import AssignAdminWebsite from 'pages/Admin/website-assign/AssignAdminWebsite'

import ListAltIcon from '@mui/icons-material/ListAlt';
//public routes
const SystemStatus = React.lazy(() => import('pages/system-status'));

// user routes
const SignIn = React.lazy(() => import('pages/authentication/sign-in'));
const SignUp = React.lazy(() => import('pages/authentication/sign-up'));


// @mui icons

// admin wordpress routes
const AdminDashboard = React.lazy(() => import('pages/Admin/dashboard'));

// const HealthStatus = React.lazy(() => import('pages/Admin/health-status'));
const Orders = React.lazy(() => import('pages/Admin/orders'));
const GenerateNewInvoice = React.lazy(() => import('pages/Admin/orders/GenerateNewInvoice'));

const AllInvoices = React.lazy(() => import('pages/Admin/AllInvoices'));


const Maintenace = React.lazy(() => import('pages/Admin/maintenance'));
const StripeMode = React.lazy(() => import("pages/Admin/stripe-mode"));


const routes = [

  {
    no: 101,
    name: "Dashboard",
    key: "admin-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: AdminDashboard,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },

  {
    no: 104,
    name: "Organizations",
    key: "user-management",
    icon: <Icon fontSize="small">
      accountTree
    </Icon>,
    route: "/users/:orgId",
    component: AdminOrganizationUsers,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 104,
    name: "Organizations",
    // type: 'widget',
    key: "user-management",
    icon: <Icon fontSize="small">
      widgets
    </Icon>,
    route: "/users/update/:orgId/:userId/:pageNum",
    component: UpdateAdminOrganizationUser,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 104,

    name: "Users",
    key: "user-management-1",
    type: 'User Management',
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: AdminUsers,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 104,

    name: "Organizations",
    type: 'User Management',
    key: "user-management-2",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/user-management",
    component: AdminOrganizations,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },

  {
    no: 104,

    name: "Subscriptions",
    type: 'User Management',
    key: "user-management-3",
    icon: <Icon fontSize="small">shop_two</Icon>,
    route: "/user-management-subscription/:orgId",
    component: AdminUserManagementSubscriptions,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 104,

    name: "Invoices",
    type: 'User Management',
    key: "user-management-4",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/user-management-invoices/:orgId",
    component: AdminUserManagementInvoices,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Custom Home Page Reports",
    key: "admin-custom-websites",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/custom-websites",
    component: CustomWebsites,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Snippets",
    // type: 'widget',
    key: "widget-snippets 1",
    icon: <Icon fontSize="small">
      widgets
    </Icon>,
    route: "/snippets/:widgetId",
    component: AdminSnippets,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "admin user websites",
    // type: 'widget',
    key: "admin-user-websites",
    icon: <Icon fontSize="small">
      widgets
    </Icon>,
    route: "/websites/:userId",
    component: AdminUserWebsites,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Custom Widgets",
    // type: 'widget',
    key: "custom-widgets 1",
    icon: <Icon fontSize="small">
      widgets
    </Icon>,
    route: "/custom-widgets",
    component: CustomWidgets,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 103,

    name: "Invoices",
    type: 'widget',
    key: "widget-logs 1",
    icon: <Icon fontSize="small">
      receipt_long
    </Icon>,
    route: "/widget-invoices",
    component: AdminWidgetInvoices,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 103,

    name: "Orders",
    type: 'widget',
    key: "widget-logs 1",
    icon: <Icon fontSize="small">
      shopping_cart
    </Icon>,
    route: "/widget/orders",
    component: AllWidgetOrders,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Subscriptions",
    type: 'widget',
    key: "widget-logs 1",
    icon: <Icon fontSize="small">
      shop_two
    </Icon>,
    route: "/widget-subscriptions",
    component: AdminWidgetSubscriptions,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },

  // {
  //   name: "Packages",
  //   type: 'widget',
  //   key: "widget-packages",
  //   icon: <Icon fontSize="small">
  //     widgets
  //   </Icon>,
  //   route: "/widget-packages",
  //   component: WidgetPackages,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  {
    no: 4,
    name: "My Auditor",
    type: 'Automatic Auditor',
    key: "user-automatic-auditor",
    icon: <Icon fontSize="small">verified_user</Icon>,
    route: "/automatic-auditor",
    component: AutomaticAuditor,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 4,
    name: "Orders",
    type: 'Automatic Auditor',
    key: "user-automatic-auditor",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/automatic-auditor-orders",
    component: AuditorOrders,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 102,
    name: "Orders",
    key: "user-automatic-auditor",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/view/automatic-auditor-order/:orderId",
    component: UserViewOrder,
    private: true,
    sidenav: false,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 102,
    name: "Orders",
    type: 'Automatic Auditor',
    key: "Automatic Auditor Orders",
    icon: <Icon fontSize="small">
      shopping_cart
    </Icon>,
    route: "/auditor/orders",
    component: AllAuditorOrdersTable,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 102,
    name: "Subscription",
    type: 'Automatic Auditor',
    key: "user-automatic-auditor",
    icon: <Icon fontSize="small">shop_two</Icon>,
    route: "/auditor/subscriptions",
    component: AuditorSubscriptions,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 102,
    name: "Invoices",
    type: 'Automatic Auditor',
    key: "user-automatic-auditor",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/automatic-auditor/invoices",
    component: AdminAuditorInvoices,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  // {
  //   name: "Billing",
  //   type: 'Automatic Auditor',
  //   key: "user-automatic-auditor",
  //   icon: <Icon fontSize="small">widgets</Icon>,
  //   route: "/auditor/billing",
  //   component: UserAuditorBilling,
  //   private: true,
  //   sidenav: true,
  //   layout: 'user/main/accessBlend',
  //   layoutName: 'user'
  // },
  // {

  //   name: "Scan Reports",
  //   key: "scan-report-dashboard 2",
  //   icon: <Icon fontSize="small">report</Icon>,
  //   route: "/scan-reports",
  //   component: AllScansReports,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  {
    name: "Order-new-invoice",
    key: "admin-order-new-invoice",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/order/new-invoice/:orderId",
    component: GenerateNewInvoice,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Order",
    key: "admin-order",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/view-order/:orderId",
    component: ViewOrder,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Order",
    key: "admin-order",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/view-widget-order/:orderId",
    component: ViewWidgetOrder,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "View Invoice",
    key: "admin-invoices 5",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/invoice/:invoiceId",
    component: ViewInvoice,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },

  {
    no: 105,
    name: "Clusters",
    type: 'Clusters',
    key: "cluster 3",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/cluster",
    component: Clusters,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 105,
    name: "Auditor Websites",
    key: "child-website-dashboard",
    type: 'Clusters',
    icon: <Icon fontSize="small">websites</Icon>,
    route: "/child-websites",
    component: ChildWebsites,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 105,
    name: "Auditor Users",
    type: 'Clusters',
    key: "auditor-dashboard",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/auditor-users",
    component: ChildUsers,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    no: 105,
    name: "Auditor Organizations",
    type: 'Clusters',
    key: "child-organiations-dashboard-saas",
    icon: <Icon fontSize="small">corporate_fare</Icon>,
    route: "/child-organizations",
    component: Organizations,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },

  // {
  //   name: "Cluster Vars",
  //   key: "cluster-vars",
  //   icon: <Icon fontSize="small">density_large</Icon>,
  //   route: "/cluster-vars",
  //   component: ClusterVars,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  {
    name: "Stripe Mode",
    key: "stripe-mode 4",
    icon: <Icon fontSize="small">attach_money
    </Icon>,
    route: "/stripe-mode",
    component: StripeMode,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Maintenance Mode",
    key: "maintenance-mode 3",
    icon: <Icon fontSize="small">engineering</Icon>,
    route: "/maintenance-mode",
    component: Maintenace,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Widget",
    type: 'widget',
    key: "admin-widget",
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/edit/widget/:widgetId",
    component: EditWidget,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Assign Widget",
    type: 'widget',
    key: "admin-widget",
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/assign/custom-widget/:widgetId",
    component: AssignAdminWidget,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Assign Website",
    type: 'website',
    key: "admin-website",
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/assign/custom-website/:websiteId",
    component: AssignAdminWebsite,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Settings",
    key: "admin-settings 2",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: Settings,
    private: true,
    sidenav: true,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Orders",
    type: 'auditor',
    key: "admin-orders 4",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/orders/:userId",
    component: Orders,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },
  {
    name: "Order",
    key: "admin-order",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/view-order/:orderId",
    component: ViewOrder,
    private: true,
    sidenav: false,
    layout: 'accessblend-admin/saas/accessBlend',
    layoutName: 'saas'
  },


  // admin SASS routes
  // {
  //   name: "Unified Analytics",
  //   key: "admin-saas-settings",
  //   icon: <Icon fontSize="small">group_work</Icon>,
  //   route: "/unified-analytics",
  //   component: UnifiedAnalytics,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {

  //   name: "Dashboard",
  //   key: "admin-dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: AdminDashboard,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {
  //   name: "Auditor Users",
  //   type: 'auditor',
  //   key: "auditor-dashboard",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/auditor-users",
  //   component: ChildUsers,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {
  //   name: "Widget",
  //   key: "widget-logs 3",
  //   icon: <Icon fontSize="small">
  //     monitoring
  //   </Icon>,
  //   route: "/widget",
  //   component: WidgetLogs,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {
  //   name: "Auditor Websites",
  //   key: "child-website-dashboard",
  //   type: 'auditor',

  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/child-websites",
  //   component: ChildWebsites,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {
  //   name: "Auditor Organizations",
  //   type: 'auditor',

  //   key: "child-organiations-dashboard-saas",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/child-organizations",
  //   component: Organizations,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },

  // {
  //   name: "Order-new-invoice",
  //   key: "admin-order-new-invoice",
  //   icon: <Icon fontSize="small">shopping_cart</Icon>,
  //   route: "/order/new-invoice/:orderId",
  //   component: GenerateNewInvoice,
  //   private: true,
  //   sidenav: false,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {
  //   name: "Order",
  //   type: 'auditor',

  //   key: "admin-order",
  //   icon: <Icon fontSize="small">shopping_cart</Icon>,
  //   route: "/view-order/:orderId",
  //   component: ViewSaasOrder,
  //   private: true,
  //   sidenav: false,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {
  //   name: "Invoices",
  //   type: 'auditor',
  //   key: "admin-invoices 1",
  //   icon: <Icon fontSize="small">receipt</Icon>,
  //   route: "/invoices",
  //   component: AllInvoices,
  //   private: true,
  //   sidenav: true,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },
  // {
  //   name: "View Invoice",
  //   key: "admin-invoices 2",
  //   icon: <Icon fontSize="small">receipt</Icon>,
  //   route: "/invoice/:invoiceId",
  //   component: ViewInvoice,
  //   private: true,
  //   sidenav: false,
  //   layout: 'accessblend-admin/saas/accessBlend',
  //   layoutName: 'saas'
  // },

  // user enterprise routes
  {
    no: 1,
    name: "Dashboard",
    key: "enterprise-user-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: userDashboard,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },

  {
    no: 9,
    name: "Billing Details",
    type: 'billing',
    key: "widget-billing",
    icon: <Icon fontSize="small">shopping_bags</Icon>,
    route: "/billing-details",
    component: UpdateBilling,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 9,
    name: "Payment Methods",
    type: 'billing',
    key: "Payment-methods",
    icon: <Icon fontSize="small">credit_card</Icon>,
    route: "/payment-methods",
    component: Billings,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 9,
    name: "User Billing",
    type: 'billing',
    key: "user-billing",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/user-billing",
    component: UserBilling,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 9,
    name: "Subscriptions",
    type: 'billing',
    key: "user-billing",
    icon: <Icon fontSize="small">shop_two</Icon>,
    route: "/user-billing-subscriptions",
    component: BillingUserSubscriptions,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 9,
    name: "Invoices",
    type: 'billing',
    key: "user-billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/user-billing-invoices",
    component: BillingAllInvoices,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 7,
    name: "Other Websites",
    type: 'Others',
    key: "other-services",
    icon: <Icon fontSize="small">website</Icon>,
    route: "/other/websites",
    component: OtherWebsites,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 7,
    name: "Other Widgets",
    type: 'Others',
    key: "other-services",
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/other/widgets",
    component: OtherWidgets,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    name: "Add Website",
    key: "add-website",
    // type: 'auditor',
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/add-website",
    component: AddWebsite,
    private: true,
    sidenav: false,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    name: "verify dns",
    key: "verify-dns",
    // type: 'auditor',
    icon: <Icon fontSize="small">webpage</Icon>,
    route: "/verify-dns",
    component: VerifyDNS,
    private: true,
    sidenav: false,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    name: "Snippets",
    key: "widget-snippets-users 1",
    icon: <Icon fontSize="small">
      widgets
    </Icon>,
    route: "/snippets/:widgetId",
    component: Snippets,
    private: true,
    sidenav: false,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 3,
    name: "My Websites",
    key: "user-website",
    // type: 'auditor',
    icon: <Icon fontSize="small">webpage</Icon>,
    route: "/websites",
    component: UserWebsites,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },

  {
    no: 5,
    name: "My Widget",
    type: 'widget',
    key: "user-widget",
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/widget",
    component: Widget,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },

  {
    name: "Orders",
    type: 'widget',
    key: "user-widget-orders",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/widget/orders",
    component: WidgetOrders,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    name: "Orders",
    key: "user-widget-orders",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/widget/orders/view/:orderId",
    component: UserViewWidgetOrder,
    private: true,
    sidenav: false,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    no: 2,
    name: "Home Page Report",
    key: "one-page-websites-dashboard-enterprise",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/home-page-report",
    component: WebsiteReport,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    name: "Widget",
    key: "user-widget",
    icon: <Icon fontSize="small">widgets</Icon>,
    route: "/edit/widget/:widgetId",
    component: EditWidget,
    private: true,
    sidenav: false,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  // {
  //   name: "Snippets",
  //   key: "snippets",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/snippets",
  //   component: Snippets,
  //   private: true,
  //   sidenav: true,
  //   layout: 'user/main/accessBlend',
  //   layoutName: 'user'
  // },
  {
    no: 8,
    name: "Manage Users",
    key: "user-management",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/manage-users",
    component: UserManagement,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    name: "Settings",
    key: "user-settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: UserSettings,
    private: true,
    sidenav: true,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },
  {
    layout: "dashboard",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/verify-email",
    private: true,
    component: VerifyEmail,
    sidenav: false,
    layout: 'user/main/accessBlend',
    layoutName: 'user'
  },


  // Auth routes

  {
    layout: "dashboard",
    name: "Sign Up",
    key: "sign-in",
    icon: <Icon fontSize="small">signup</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    sidenav: false
  },
  {
    layout: "dashboard",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    sidenav: false
  },
  {
    layout: "dashboard",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/callback",
    component: <LoginCallBack />,
    sidenav: false
  },
  // {
  //   layout: "dashboard",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/verify-email",
  //   component: <VerifyEmail />,
  //   sidenav: false
  // },
  {
    type: "simple",
    layout: "dashboard",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">reset</Icon>,
    route: "/authentication/reset-password",
    component: <ResetPassword />,
    sidenav: false
  },
  {

    layout: "dashboard",
    name: "Sign In",
    key: "sign-in",
    route: "/system-status",
    component: <SystemStatus />,
    sidenav: false
  }
];

export default routes;
