
import borders from "assets/theme/base/borders";


const { borderRadius } = borders;

export default {
  styleOverrides: {
    paper: {
      // backgroundColor: 'transparent',
      borderRadius: borderRadius.md,
    },
  },
};
