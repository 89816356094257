import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import Pagination from '@mui/material/Pagination';
import colors from 'assets/theme/base/colors';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import {
  Card,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Icon,
  TableContainer,
} from '@mui/material';
import MDProgress from 'components/MDProgress';

import simpleDateparser from 'utils/simpledateParser';
import { useDispatch } from 'react-redux';

import { useMaterialUIController } from 'context';
import { getChildUsers } from 'redux/actions/adminSmokiDashboard.action';
import MDButton from 'components/MDButton';
import { childUserAction } from 'redux/actions/adminSmokiDashboard.action';

const RoleUsers = ({ role, selectedCluster }) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const [rows, setRows] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  // This function fetches child users based on role and page number
  function fetchChildUsers() {
    // Set loading state to true to indicate data is being fetched
    setLoading(true);
    // Dispatch an action to get child users based on the provided role and page number
    dispatch(getChildUsers(role, page)).then((data) => {
      // If the data was successfully retrieved from the server
      if (data) {
        // Set the rows state to the result of the fetched data
        setRows(data.result);
        // Set the pages state to the total number of pages for the fetched data
        setPages(data.pages);
        // Set loading state to false to indicate that data has been fetched
        setLoading(false);
      }
    });
  }

  // This useEffect hook will run when the component is unmounted from the DOM.
  // It resets some states to their initial values.
  React.useEffect(() => {
    return () => {
      setPage(1);
      setPages(0);
      setRows([]);
      setLoading(false);
    };
  }, []);

  // This useEffect hook will run when the 'page' state changes
  // It will call the fetchChildUsers function to retrieve the data
  React.useEffect(() => {
    fetchChildUsers();
  }, [page]);

  // This line of code is using a custom hook 'useMaterialUIController'
  const [controller] = useMaterialUIController();
  // It then extracts the 'darkMode' value from the controller object
  const { darkMode } = controller;

  return (
    <Card sx={{ my: 5 }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant='gradient'
        bgColor='info'
        borderRadius='lg'
        coloredShadow='info'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <MDTypography variant='h6' color='white' textTransform='capitalize'>
          {role} Users
        </MDTypography>

        <MDButton
          color='white'
          variant='outlined'
          onClick={() => fetchChildUsers()}
        >
          <Icon>refresh</Icon>{' '}
        </MDButton>
      </MDBox>

      <TableContainer>
        <Table size='small' aria-label='a dense table'>
          <thead>
            <TableRow>
              <TableCell align='left' sx={{ minWidth: 100 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  User ID
                </MDTypography>
              </TableCell>{' '}
              <TableCell align='left' sx={{ minWidth: 120 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Cluster ID
                </MDTypography>
              </TableCell>
              <TableCell align='left' sx={{ minWidth: 150 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Organization ID
                </MDTypography>
              </TableCell>
              <TableCell align='left' sx={{ minWidth: 180 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Username
                </MDTypography>
              </TableCell>
              <TableCell align='left' sx={{ minWidth: 180 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Role
                </MDTypography>
              </TableCell>
              <TableCell align='left' sx={{ minWidth: 180 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Email
                </MDTypography>
              </TableCell>
              <TableCell align='left' sx={{ minWidth: 230 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Created At
                </MDTypography>
              </TableCell>
              <TableCell align='left' sx={{ minWidth: 120 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Last action
                </MDTypography>
              </TableCell>
              <TableCell align='left' sx={{ minWidth: 160 }}>
                <MDTypography
                  variant='caption'
                  component='span'
                  fontWeight='medium'
                  color={darkMode ? 'white' : 'info'}
                >
                  Action
                </MDTypography>
              </TableCell>
            </TableRow>
          </thead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell align='center' colSpan={8}>
                  <MDBox
                    display='flex'
                    py={1}
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <MDProgress circular />
                    <MDTypography variant='h6'>Loading Users...</MDTypography>
                  </MDBox>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rows && rows.length > 0 ? (
                rows.map((row, index) => {
                  return (
                    <OrderRow
                      darkMode={darkMode}
                      row={row}
                      clusterId={selectedCluster}
                      fetchChildUsers={fetchChildUsers}
                      rows={rows}
                      key={`order-${index}`}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align='center' colSpan={11}>
                    <MDTypography
                      variant='caption'
                      component='span'
                      fontWeight='bold'
                    >
                      no users found
                    </MDTypography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        {rows.length > 0 && !loading && (
          <MDBox
            mt={2}
            mb={2}
            display='flex'
            justifyContent='center'
            width='100%'
          >
            <Pagination
              color='info'
              variant='outlined'
              sx={{
                '& .MuiPagination-ul > li > button': {
                  fontWeight: 400,
                  color: darkMode
                    ? 'white !important'
                    : `${colors?.info?.main} !important`,
                  borderColor: darkMode
                    ? 'white !important'
                    : `${colors?.info?.main} !important`,
                },
                '& .MuiPagination-ul > li > div': {
                  color: darkMode
                    ? 'white !important'
                    : `${colors?.info?.main} !important`,
                },
              }}
              count={pages}
              page={page}
              onChange={(e, value) => setPage(value)}
            />
          </MDBox>
        )}
      </TableContainer>
    </Card>
  );
};

function OrderRow({ row, clusterId, fetchChildUsers }) {
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [delLoading, setDelLoading] = React.useState(false);
  const [authLoading, setAuthLoading] = React.useState(false);
  const [authLink, setAuthLink] = React.useState(false);

  const [selectedRole, setSelectedRole] = React.useState(false);
  const [defaultPermissions, setDefaultPermissions] = React.useState({
    addScan: true,
    viewScan: true,
    viewScanSummary: true,
    viewScanAccessibility: true,
    viewScanFrontendCode: true,
    viewScanSeo: true,
    viewScanSitemap: true,
    settings: true,
    addUser: false,
    removeUser: false,
    changeUserPermissions: false,
  });

  function handleValues(e) {
    setDefaultPermissions({
      ...defaultPermissions,
      [e.target.name]: e.target.checked,
    });
  }

  React.useEffect(() => {
    if (row.permissions) {
      setSelectedRole(row.role);
      setDefaultPermissions({ ...row.permissions });
    }
  }, [row.permissions]);

  function handleSubmit() {
    setLoading(true);
    dispatch(
      childUserAction({
        action: 'update',
        details: {
          clusterId: row.cluster,
          userId: row.id,
          permissions: defaultPermissions,
          role: row.role,
        },
      })
    )
      .then((data) => {
        setLoading(false);
        fetchChildUsers();
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function handleDelete() {
    setDelLoading(true);
    dispatch(
      childUserAction({
        action: 'delete',
        details: { clusterId: row.cluster, userId: row.id },
      })
    )
      .then((data) => {
        setDelLoading(false);
        fetchChildUsers();
      })
      .catch((error) => {
        setDelLoading(false);
      });
  }

  function genAuthLink() {
    setAuthLoading(true);
    dispatch(
      childUserAction({
        action: 'login-code',
        details: { clusterId: row.cluster, userId: row.id },
      })
    )
      .then((data) => {
        setAuthLoading(false);
        setAuthLink(data.result);
      })
      .catch((error) => {
        setAuthLoading(false);
      });
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.userId}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.cluster}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row?.organization?.id}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.username}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.role}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.email}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {simpleDateparser(row.createdAt)}
          </MDTypography>
        </TableCell>

        <TableCell align='left'>
          <MDTypography variant='caption' fontWeight='bold'>
            {row.action}
          </MDTypography>
        </TableCell>
        <TableCell align='left'>
          {row.action.toLowerCase() !== 'deleted' && (
            <MDBox component='span' display='flex' gap={2}>
              <MDButton
                variant='contained'
                size='small'
                color='info'
                disabled={delLoading}
                onClick={() => setOpen(true)}
              >
                Edit
              </MDButton>
              <MDButton
                variant='contained'
                size='small'
                color='error'
                loading={delLoading}
                onClick={() => handleDelete()}
              >
                Delete
              </MDButton>
            </MDBox>
          )}
        </TableCell>
      </TableRow>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>{row.username} Edit Info</DialogTitle>

        <MDBox pt={3} pb={1} px={5}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id='role-selection'>Change Role</InputLabel>
            <Select
              labelId='role-selection'
              id='demo-simple-select'
              name='role'
              value={selectedRole}
              label='Change Role'
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {/* <MenuItem value={'root'}>Root</MenuItem>
                            <MenuItem value={'super-admin'}>Super Admin</MenuItem> */}
              <MenuItem value={'admin'}>Admin</MenuItem>
              <MenuItem value={'client'}>Client</MenuItem>
              <MenuItem value={'viewer'}>Viewer</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='addScan'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions.addScan)}
              />
            }
            label='Add Scan'
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='viewScan'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.viewScan)}
              />
            }
            label='View Scan'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='viewScanSummary'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.viewScanSummary)}
              />
            }
            label='View Summary'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='viewScanAccessibility'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.viewScanAccessibility)}
              />
            }
            label='View Accessibility Issues'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='viewScanFrontendCode'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.viewScanFrontendCode)}
              />
            }
            label='View Frontend Code Issues'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='viewScanSeo'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.viewScanSeo)}
              />
            }
            label='View SEO Issues'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='viewScanSitemap'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.viewScanSitemap)}
              />
            }
            label='View Sitemaps'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='settings'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.settings)}
              />
            }
            label='View Settings'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='addUser'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.addUser)}
              />
            }
            label='Add User'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='removeUser'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.removeUser)}
              />
            }
            label='Remove User'
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={loading}
                color='primary'
                name='changeUserPermissions'
                onChange={(e) => handleValues(e)}
                checked={Boolean(defaultPermissions?.changeUserPermissions)}
              />
            }
            label='Change User Permissions'
          />
        </MDBox>
        <MDBox display='flex' justifyContent='center' p={2} my={1} gap={2}>
          <MDButton
            variant='contained'
            size='small'
            color='info'
            loading={authLoading}
            onClick={() => genAuthLink()}
          >
            Generate Auth Link
          </MDButton>
          {authLink && (
            <MDButton
              variant='contained'
              component='a'
              href={authLink}
              target='_blank'
              size='small'
              color='info'
              disabled={authLoading}
            >
              Open Auth Link
            </MDButton>
          )}
        </MDBox>
        <MDBox
          p={3}
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          gap={2}
        >
          <MDButton
            variant='contained'
            color='info'
            loading={loading}
            onClick={() => handleSubmit()}
          >
            Submit
          </MDButton>
          <MDButton color='error' variant='contained' onClick={handleClose}>
            Close
          </MDButton>
        </MDBox>
      </Dialog>
    </React.Fragment>
  );
}

export default RoleUsers;
