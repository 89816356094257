import React from 'react';
//   components
import MDBox from "components/MDBox";
//   example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import MDProgress from "components/MDProgress";
import { getAllUsers } from "redux/actions/adminSmokiDashboard.action";
import DataTable from 'examples/Tables/DataTable';
import usersData from './data/usersData';

import { Card } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { useLocation } from 'react-router-dom';
import { getAllRootUsers } from 'redux/actions/adminSmokiDashboard.action';
import { generateLoginLink } from 'redux/actions/adminSmokiDashboard.action';

function AdminUsers() {

  const dispatch = useDispatch();
  const location = useLocation();
  const { users, loadingUsers, pagesUsers, usersRoot, loadingRootUsers, pagesRootUsers } = useSelector(state => state.adminSmokiDashboard);
  const [pageU, setPageU] = React.useState(1);
  const [pageUR, setPageUR] = React.useState(1);

  const [genRL, setGenRL] = React.useState(false);
  const [genL, setGenL] = React.useState(false);

  const [rootLink, setRootLink] = React.useState(null);
  const [link, setLink] = React.useState(null);

  const { userColumns, userRows } = usersData(users, genL, link, genLink);
  const { userColumns: rootCols, userRows: rootRows } = usersData(usersRoot, genRL, rootLink, genRootLink);


  React.useEffect(() => {
    dispatch(getAllRootUsers(pageUR));
  }, [pageUR, location])

  React.useEffect(() => {
    dispatch(getAllUsers(pageU));
  }, [pageU, location])


  function genRootLink(id) {
    setGenRL(id)
    setRootLink(null)
    dispatch(generateLoginLink(id)).then(data => {
      setGenRL(false)
      setRootLink({ id, link: data.result })
    }).catch(err => {
      setGenRL(false)

    })
  }


  function genLink(id) {
    setGenL(id)
    // setLink({ id, link: "asdasd" })
    dispatch(generateLoginLink(id)).then(data => {
      setGenL(false)
      setLink({ id, link: data.result })
    }).catch(err => {
      setGenL(false)
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={7}>

        <>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Users
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {
                !loadingUsers ?
                  <DataTable
                    table={{ columns: userColumns, rows: userRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    pages={pagesUsers}
                    currentPage={pageU}
                    setPage={setPageU}
                  /> :
                  <MDProgress circular />
              }
            </MDBox>
          </Card>
        </>

      </MDBox>

      <MDBox mt={7}>

        <>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Root Users
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {
                !loadingRootUsers ?
                  <DataTable
                    table={{ columns: rootCols, rows: rootRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    pages={pagesRootUsers}
                    currentPage={pageUR}
                    setPage={setPageUR}
                  /> :
                  <MDProgress circular />
              }
            </MDBox>
          </Card>
        </>

      </MDBox>
    </DashboardLayout>
  );
}

export default AdminUsers;
