import React from 'react'
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Switch from '@mui/material/Switch';

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
// import Switch from "@mui/material/Switch";

//   components
import MDBox from "components/MDBox";


import NotificationItem from "examples/Items/NotificationItem";
import { getAuth, signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { logoutUser } from "redux/actions/auth.action";
import { useNavigate } from "react-router-dom";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
  websitesRow,
} from "examples/Navbars/DashboardNavbar/styles";

// context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setDarkMode
} from "context";

import {
  getNotifications,
  hideNotification,
} from "redux/actions/dashboard.action";
import MDButton from 'components/MDButton';
import Alert from '@mui/material/Alert';
import MDTypography from 'components/MDTypography';
import WebsiteSelector from 'examples/Sidenav/WebsiteSelector';


function DashboardNavbar({ absolute, light, isMini }) {
  const dispatchAction = useDispatch();

  const [navbarType, setNavbarType] = useState();
  const [screenWidth, setScreenWidth] = useState();

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);

  const [dType, setDtype] = useState(false);

  const route = useLocation().pathname.split("/").slice(1);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  const [page, setPage] = React.useState(1);

  let [notifications, setNotifications] = React.useState([]);
  const user = useSelector((state) => state.auth);
  const fetchNotifications = useSelector((state) => state.dashboard.notifications.data);
  let notificationsCount = useSelector((state) => state.dashboard.notifications.count);

  const hasMoreData = useSelector((state) => state.dashboard.notifications.hasMoreData);

  const location = useLocation()


  function hideNotifications(id) {
    const filteredNotifications = notifications.map((item) => {
      if (item.id == id) {
        return { ...item, seen: true }
      } else {
        return item
      }
    });
    setNotifications(filteredNotifications);
    dispatchAction(hideNotification(id));
    notificationsCount = notificationsCount - 1;
  }

  function loadMore() {
    dispatchAction(getNotifications(page + 1));
    setPage(page + 1);
  }


  React.useEffect(() => {
    if (user.user.isCardAdded && user.user.isWebsiteAdded) {
      dispatchAction(getNotifications(page));
    }
  }, [user]);

  React.useEffect(() => {
    if (location.pathname.split('/').includes('saas')) {
      setDtype(true);
    } else {
      setDtype(false);
    }
  }, [location]);


  React.useEffect(() => {
    if (fetchNotifications) {
      if (fetchNotifications.length > 0) {
        let notificationo = [...notifications, ...fetchNotifications];
        notificationo = Array.from(new Set(notificationo.map((a) => a.id))).map(
          (id) => {
            return notificationo.find((a) => a.id === id);
          }
        );
        setNotifications(notificationo);
      }
    }
    // eslint-disable-next-line
  }, [fetchNotifications]);


  let navigate = useNavigate();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications.length > 0 ?
        <>
          {notifications.map((item, index) => {
            return (
              <NotificationItem key={index} onClick={() => hideNotifications(item.id)} seen={item.seen} title={item.message} />
            );
          })}
          {hasMoreData &&
            <MDButton color="dark" variant="gradient"
              onClick={() => loadMore()}
              size="small">More </MDButton>
          }
        </> :
        <p className='font-600 text-12' style={{ padding: '0px 20px' }}> No Notifications</p>
      }
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  const singoutUser = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      dispatchAction(logoutUser(null))
      navigate('/authentication/sign-in');
    }).catch(function (error) {
      console.log(error)
    })
  }

  function handleChangeDashboard(rot) {
    if (rot === 'saas') {
      setDtype(true);
    } else {
      setDtype(false);
    }
    navigate(`/accessblend-admin/${rot}/accessBlend/dashboard`)
  }


  React.useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [window.innerWidth])

  return (
    <>
      <AppBar
        position={'static'}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar: false, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}

        >

          <WebsiteSelector />
          {isMini ? null : (
            <MDBox flexWrap="wrap" marginLeft='auto' sx={(theme) => navbarRow(theme, { isMini })}>
              {/* {user.user.role === "accessblend-admin" && <MDBox display="flex" alignItems="center" mr={2}>
                {window.location.pathname.split('/').includes('enterprise') && <MDTypography variant="body2" fontWeight="medium" color="text">Switch to SaaS Admin</MDTypography>}
                <Switch checked={dType} onChange={() => handleChangeDashboard(window.location.pathname.split('/').includes('saas') ? "enterprise" : "saas")} />
                {window.location.pathname.split('/').includes('saas') && <MDTypography variant="body2" fontWeight="medium" color="text">Switch to Enterprise Admin</MDTypography>}
              </MDBox>} */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <MDBox color={light ? "white" : "inherit"} marginLeft='auto'>
                <IconButton onClick={() => handleDarkMode()} sx={{ border: 1, p: 0.5 }} style={{ borderRadius: 8 }}>
                  {darkMode ? <Icon fontSize="medium" style={{ color: '#f8b314' }} >light_mode</Icon> :
                    <Icon fontSize="medium" style={{ color: '#344767' }} >dark_mode</Icon>
                  }
                </IconButton>

                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={singoutUser}
                >
                  <Icon sx={iconsStyle} fontSize="medium">logout</Icon>
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}

        </Toolbar>
      </AppBar >
      {user?.config?.maintenanceEnabled && <Alert severity="warning" sx={{ mb: 3 }}>You are in maintenance mode.</Alert>
      }
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
