import { Card } from "@mui/material"
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import React from "react"
import { useDispatch ,useSelector} from "react-redux"
import { getClientSecretKey } from "redux/actions/userDashboard.action"
import SetupForm from "./SetupForm"

export default function AddPAymentMethod() {

    const dispatch = useDispatch()
   

    const [stripe, setStripe] = React.useState(null)
    const [clientLoading, setClientLoading] = React.useState(null)

    const [clientSecret, setClientSecret] = React.useState(null)


    React.useEffect(() => {
        const getConfig = localStorage.getItem("config");
        const configValue = JSON.parse(getConfig)

        setStripe(loadStripe(configValue.stripeKey || configValue.stripeTestKey));

    }, [])

    // This object sets the appearance of the payment form to the Stripe theme
    const appearance = {
        theme: 'stripe',
    };

    // This object sets the payment options, including the client secret and appearance
    const paymentOptions = {
        clientSecret: clientSecret,
        appearance,
    }


    function getClinetSecret() {
        setClientLoading(true);
        dispatch(getClientSecretKey()).then(data => {
            setClientLoading(false)
            setClientSecret(data.result)
        }).catch(err => {
            setClientLoading(false)
        })
    }

    return (
        <>
            {!clientSecret ? <MDBox my={3} display='flex' justifyContent='center'> <MDButton color='info' variant='contained' loading={clientLoading} onClick={() => getClinetSecret()}>Add Payment Method</MDButton></MDBox>
                : <>
                    {paymentOptions.clientSecret && clientSecret && <Card sx={{ boxShadow: "0px 3px 25px rgba(0 0 0 / 15%)", padding: 18, backgroundColor: '#fff', my: 3, p: 3 }} >
                        {stripe && <Elements stripe={stripe} options={paymentOptions} >
                            <SetupForm />
                        </Elements>}
                    </Card>}
                </>}
        </>
    )
}