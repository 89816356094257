import React from 'react';

//   components
import MDBox from "components/MDBox";

//   example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuditorOrdersTable from './AuditorOrdersTable';


function AllAuditorOrdersTable() {

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox py={3}>
				<>
					<MDBox mt={6}>
						<AuditorOrdersTable status='pending' />
						<AuditorOrdersTable status='processing' />
						<AuditorOrdersTable status='completed' />
						<AuditorOrdersTable status='cancelled' />
					</MDBox>
				</>
			</MDBox>
		</DashboardLayout>
	);
}

export default AllAuditorOrdersTable;
