import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import simpleDateparser from "utils/simpledateParser";
import { useDispatch } from "react-redux";
import { useMaterialUIController } from "context";
import { getUserWebsites } from "redux/actions/userDashboard.action";
import MDButton from "components/MDButton";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Websites = ({ type, title }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [rows, setRows] = React.useState(0);
    const dispatch = useDispatch();
    function fetchWebsites() {
        setLoading(true)
        dispatch(getUserWebsites(page)).then(data => {
            if (data) {
                setRows(data.result)
                setPages(data.pages)
                setLoading(false)
            }
        });
    }

    React.useEffect(() => {
        fetchWebsites()
    }, [page])

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;


    return (


        <Card>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"

            >
                <MDBox display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'>
                    <MDTypography variant="h6" color="white">
                        {/* All {title}  */}
                        Websites
                    </MDTypography>

                    <MDButton variant='contained' color='primary' onClick={() => navigate("/user/main/accessBlend/add-website")} startIcon={<Add />}>Add Website</MDButton>
                </MDBox>
            </MDBox>

            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <thead>
                        <TableRow>
                            <TableCell align="left" sx={{ minWidth: 250 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Website
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 220 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Website ID
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Verified
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Subdomains
                                </MDTypography>
                            </TableCell>

                            {/* <TableCell align="left" sx={{ minWidth: 150 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Organization ID
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    User ID
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Price
                                </MDTypography>
                            </TableCell> */}

                            <TableCell align="left" sx={{ minWidth: 230 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Created At
                                </MDTypography>
                            </TableCell>

                            {/* <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Approved
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Last action
                                </MDTypography>
                            </TableCell> */}

                        </TableRow>
                    </thead>
                    {loading ?
                        <TableBody>
                            <TableRow >
                                <TableCell align="center" colSpan={6} >
                                    <MDBox
                                        display="flex"
                                        py={1}
                                        flexDirection='column'
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <MDProgress circular />
                                        <MDTypography variant="h6">
                                            Loading Websites...
                                        </MDTypography>
                                    </MDBox>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {rows && rows.length > 0 ? rows.map((row, index) => {
                                return <OrderRow fetchWebsites={fetchWebsites} row={row} key={`order-${index}`} />
                            }) :
                                <TableRow>
                                    <TableCell align="center" colSpan={11} >
                                        <MDTypography variant='caption' component='span' fontWeight="bold">no websites found</MDTypography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>}
                </Table>
                {rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                    <Pagination color='info' variant="outlined"
                        sx={{
                            '& .MuiPagination-ul > li > button': {
                                fontWeight: 400,
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            },
                            '& .MuiPagination-ul > li > div': {
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            }
                        }}
                        count={pages} page={page} onChange={(e, value) => setPage(value)} />
                </MDBox>}
            </TableContainer>
        </Card>


    );
};


function OrderRow({ row }) {


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={row?.website}>
                        {row?.subDomainPrettyName}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                        {row?.verified.toString()}
                    </MDTypography>
                </TableCell>

                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.subdomains.length}
                    </MDTypography>
                </TableCell>
                {/* <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.organizationId}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.userId}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row?.price ? row.price : "N/A"}
                    </MDTypography>
                </TableCell> */}


                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {simpleDateparser(row?.createdAt)}
                    </MDTypography>
                </TableCell>

                {/* <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.payWall ? "true" : "false"}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.action}
                    </MDTypography>
                </TableCell> */}

            </TableRow>




        </React.Fragment>
    )
}

export default Websites; 