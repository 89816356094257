import React from 'react';
//   components
import MDBox from 'components/MDBox';

//   example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import RoleUsers from './RoleUsers';
import { FormControl, Grid, InputLabel, MenuItem, Select, Card } from '@mui/material';

import { useDispatch } from 'react-redux';
import { getAdminClustersList } from 'redux/actions/adminSmokiDashboard.action';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import { childUserAction } from 'redux/actions/adminSmokiDashboard.action';

function ChildUsers() {
  const dispatch = useDispatch();
  const [clusters, setClusters] = React.useState([]);
  const [selectedCluster, setSelectedCluster] = React.useState('');
  const [gettingClusters, setGettingClusters] = React.useState(true);
  const [loadingAdd, setLoadingAdd] = React.useState(false);

  const [addChildUserValues, setAddChildUserValues] = React.useState({
    orgName: '',
    email: '',
    password: '',
    role: 'viewer',
  });

  // This function handles changes in the input fields for adding a new child user
  function handleAddChild(e) {
    // It sets the state of the 'addChildUserValues' object by spreading the previous state
    // and updating the value of the field that triggered the change event
    setAddChildUserValues({
      ...addChildUserValues,
      [e.target.name]: e.target.value,
    });
  }

  // This function submits the details of the new child user to the server
  function submitAddChild(e) {
    e.preventDefault(); // This prevents the form from submitting normally
    setLoadingAdd(true); // This sets the 'loadingAdd' state to true to indicate that a request is in progress
    dispatch(
      childUserAction({
        action: 'add',
        details: {
          clusterId: selectedCluster,
          ...addChildUserValues, // This sends the 'selectedCluster' and 'addChildUserValues' as part of the request payload
        },
      })
    )
      .then((data) => {
        setLoadingAdd(false); // This sets the 'loadingAdd' state back to false after the request is complete
      })
      .catch((err) => {
        setLoadingAdd(false); // This sets the 'loadingAdd' state back to false if there was an error during the request
      });
  }

  // This function fetches the list of clusters from the server
  async function fetchClsuters() {
    setGettingClusters(true); // This sets the 'gettingClusters' state to true to indicate that a request is in progress
    dispatch(getAdminClustersList(1, 100))
      .then((data) => {
        setClusters(data.result); // This sets the 'clusters' state to the list of clusters returned by the server
      })
      .then((data) => {
        setGettingClusters(false); // This sets the 'gettingClusters' state back to false after the request is complete
      })
      .catch((err) => {
        setGettingClusters(false); // This sets the 'gettingClusters' state back to false if there was an error during the request
      });
  }

  // This useEffect hook runs once on component mount to fetch the list of clusters and reset the states
  React.useEffect(() => {
    fetchClsuters(); // This calls the 'fetchClusters' function to fetch the list of clusters
    return () => {
      setClusters([]); // This resets the 'clusters' state to an empty array
      setSelectedCluster(''); // This resets the 'selectedCluster' state to an empty string
      setLoadingAdd(false); // This resets the 'loadingAdd' state to false
      setAddChildUserValues({
        // This resets the 'addChildUserValues' state to default values
        orgName: '',
        email: '',
        role: 'viewer',
      });
    };
  }, []); // The empty dependency array ensures that this hook only runs once on mount.

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        {!gettingClusters ? (
          <Card style={{ marginBottom: 20, marginTop: 20, padding: 20 }}>
            <FormControl style={{ width: '100%', maxWidth: 400 }}>
              <InputLabel id='cluster-selection'>
                Select Cluster To Perform Action
              </InputLabel>
              <Select
                labelId='cluster-selection'
                id='demo-simple-select'
                name='clusterId'
                value={selectedCluster}
                label='Select Cluster To Perform Action'
                onChange={(e) => setSelectedCluster(e.target.value)}
              >
                {clusters.map((cluster) => {
                  return (
                    <MenuItem key={cluster.id} value={cluster?.id}>
                      {cluster?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Card>
        ) : (
          <MDTypography variant='h5'>Getting Clusters...</MDTypography>
        )}
      </MDBox>

      <Card>
        <br />

        <MDBox p={2} component='form' onSubmit={submitAddChild}>

          <MDTypography variant='h5' marginBottom>
            Add Auditor User
          </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MDInput
                required
                fullWidth
                label='Auditor Organization Id/Name'
                name='orgName'
                onChange={handleAddChild}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                required
                fullWidth
                label='Email'
                name='email'
                type='email'
                onChange={handleAddChild}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDInput
                required
                fullWidth
                label='Password'
                name='password'
                type='text'
                onChange={handleAddChild}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id='cluster-selection'>Select Role</InputLabel>
                <Select
                  labelId='cluster-selection'
                  id='demo-simple-select'
                  name='role'
                  value={addChildUserValues.role}
                  label='Select Role'
                  onChange={(e) => handleAddChild(e)}
                >
                  {/* <MenuItem value={'root'}>Root</MenuItem>
                                <MenuItem value={'super-admin'}>Super Admin</MenuItem> */}
                  <MenuItem value={'admin'}>Admin</MenuItem>
                  <MenuItem value={'client'}>Client</MenuItem>
                  <MenuItem value={'viewer'}>Viewer</MenuItem>
                </Select>
              </FormControl>
              {/* <MDInput required fullWidth label='Role' name='role' onChange={handleAddChild} /> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <MDButton
                variant='gradient'
                color='info'
                loading={loadingAdd}
                type='submit'
                disabled={!selectedCluster}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>

      {!loadingAdd && (
        <MDBox py={3}>
          <>
            <MDBox mt={6}>
              <RoleUsers selectedCluster={selectedCluster} role='Admin' />
            </MDBox>
            <MDBox mt={6}>
              <RoleUsers selectedCluster={selectedCluster} role='client' />
            </MDBox>
            <MDBox mt={6}>
              <RoleUsers selectedCluster={selectedCluster} role='viewer' />
            </MDBox>
          </>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default ChildUsers;
