import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";

import { Card, Dialog, FormControl, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";

import simpleDateparser from "utils/simpledateParser";
import { useDispatch } from "react-redux";

import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { voidInvoice } from "redux/actions/adminSmokiDashboard.action";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import Select from 'react-select';

const AllClusters = ({ page, setPage, loading, rows, searchValue, setSearchValue, type, setType, pages, setAction, fetchClusters }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
        <Card>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"

                display='flex' justifyContent='space-between' alignItems='center'
            >
                <MDTypography variant="h6" color="white" sx={{ textTransform: 'capitalize', width: '100%' }}>
                    All Clusters
                </MDTypography>
                <MDBox display='flex' flexDirection={{ xs: 'column', sm: 'row' }} alignItems='center' width='100%' >
                    <FormControl style={{ width: '100%', maxWidth: { xs: '100%', sm: 150 }}} >

                        <Select

                            id='change-api'
                            defaultValue={{ label: 'Cluster Id', value: 'id' }}
                            options={[
                                {
                                    value: "id",
                                    label: 'Cluster Id'
                                },
                                {
                                    value: "order",
                                    label: 'Order'
                                },
                                {
                                    value: "appUri",
                                    label: 'AppUri'
                                },
                            ]}
                            isSearchable={false}
                            onChange={(newValue) => setType(newValue.value)}
                        />
                    </FormControl>
                    <MDInput variant='outlined'

                        sx={{
                            '& .MuiInputBase-input': {
                                color: 'white !important',

                            },
                            '&::placeholder': {
                                color: '#999', // change the color of the placeholder text
                                fontStyle: 'italic', // add italic font style to the placeholder text
                                opacity: 0.5, // reduce the opacity of the placeholder text
                            },
                            marginLeft: { xs: 0, sm: 2 },
                            marginTop: { xs: 2, sm: 0 },
                        }}
                        fullWidth placeholder='Enter search value' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                </MDBox>
            </MDBox>

            <TableContainer>
                <Table size="small" aria-label="a dense table">
                    <thead>
                        <TableRow>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Name
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 200 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Cluster ID
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 180 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Company Name
                                </MDTypography>
                            </TableCell>

                            <TableCell align="left" sx={{ minWidth: 120 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Status
                                </MDTypography>
                            </TableCell>

                            <TableCell align="left" sx={{ minWidth: 300 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Auth Key
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 220 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    AppUri
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 190 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Order
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 190 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    OpenAI Key
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 190 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    OpenAI Model
                                </MDTypography>
                            </TableCell>

                            <TableCell align="left" sx={{ minWidth: 230 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Created At
                                </MDTypography>
                            </TableCell>
                            <TableCell align="left" sx={{ minWidth: 160 }}>
                                <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                    Action
                                </MDTypography>
                            </TableCell>
                        </TableRow>
                    </thead>
                    {loading ?
                        <TableBody>
                            <TableRow >
                                <TableCell align="center" colSpan={11} >
                                    <MDBox
                                        display="flex"
                                        py={1}
                                        flexDirection='column'
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <MDProgress circular />
                                        <MDTypography variant="h6">
                                            Loading Clusters...
                                        </MDTypography>
                                    </MDBox>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {rows && rows.length > 0 ? rows.map((row, index) => {
                                return <OrderRow setAction={setAction} fetchClusters={fetchClusters} darkMode={darkMode} row={row} rows={rows} key={`order-${index}`} />
                            }) :
                                <TableRow>
                                    <TableCell align="center" colSpan={11} >
                                        <MDTypography variant='caption' component='span' fontWeight="bold">no clusters found</MDTypography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>}
                </Table>
                {rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                    <Pagination color='info' variant="outlined"
                        sx={{
                            '& .MuiPagination-ul > li > button': {
                                fontWeight: 400,
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            },
                            '& .MuiPagination-ul > li > div': {
                                color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                            }
                        }}
                        count={pages} page={page} onChange={(e, value) => setPage(value)} />
                </MDBox>}
            </TableContainer>
        </Card>
    );
};


function OrderRow({ row, fetchClusters, setAction, darkMode }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [deleteLoading, setDeleteLoading] = React.useState(false)

    const [actionModal, setActionModal] = React.useState({
        open: false,
        invoiceId: null
    });

    function openCancelInvoiceModal(data) {
        setActionModal(data)
    }

    function handleDelete() {
        setDeleteLoading(true);
        dispatch(voidInvoice(actionModal.invoiceId)).then(data => {
            setDeleteLoading(false)
            openCancelInvoiceModal({
                open: false,
                invoiceId: null
            })
            fetchClusters()
        })
    }
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.name}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.companyInfo?.companyName}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.status}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.xAuthKey}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.appUri}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.order}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.openaiKey}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.openaiModel}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {simpleDateparser(row?.createdAt)}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDBox display='flex' alignItems='center' gap={2}>
                        <MDButton variant='contained' color='info' size='small' onClick={() => setAction({
                            action: 'edit', data: row
                        })}> Edit</MDButton>
                    </MDBox>
                </TableCell>
            </TableRow>


            <Dialog fullWidth maxWidth='sm' open={actionModal.open} onClose={() => !deleteLoading ? openCancelInvoiceModal({
                open: false,
                invoiceid: null
            }) : null}>
                <MDBox p={3}>
                    <MDTypography variant='h6' align='center' sx={{ mb: 3 }} > Cancel Invoice</MDTypography>
                    <MDButton variant='contained' color='success' disabled={deleteLoading} sx={{ mx: 1 }} onClick={() => {
                        openCancelInvoiceModal({
                            open: false,
                            invoiceId: null
                        })
                    }} >Cancel</MDButton>
                    <MDButton variant='contained' color='warning' loading={deleteLoading} onClick={() => handleDelete()}>Confirm</MDButton>

                </MDBox>
            </Dialog>

        </React.Fragment>
    )
}

export default AllClusters; 