import React from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import simpleDateparser from "utils/simpledateParser";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWidgetOrders } from "redux/actions/userDashboard.action";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopyOutlined } from '@mui/icons-material';
import { toast } from "react-toastify";
import PermissionBox from "components/PermissionBox";

const WidgetOrders = () => {

    const dispatch = useDispatch();
    const {widget}=useSelector(state=>state.auth.user.permissions)

    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(1);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);


    const [controller] = useMaterialUIController();
    const { darkMode } = controller;


    React.useEffect(() => {
        dispatch(getWidgetOrders(page)).then(data => {
            if (data) {
                setLoading(false)
                setData(data.result)
                setPages(data.pages)
            }
        }).catch(err => {
            setLoading(false)
        })
    }, [page])


    return (
        <>
        {
            widget && widget?.viewWidgetOrders ?

            <Card sx={{ mt: 5 }}>
                <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white" style={{ textTransform: 'capitalize' }}>
                        All Widget Orders
                    </MDTypography>
                </MDBox>

                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <thead>
                            <TableRow>
                                {/* <TableCell align="left" sx={{ width: 60 }}>
                                    {""}
                                </TableCell> */}
                                <TableCell align="left" sx={{ minWidth: 190 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        ID
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 150, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Package
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        ORG Name
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 250 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Website
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 150 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Status
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Discount
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Total
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 150, maxWidth: 270 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Invoice Link
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 120 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Subscribed
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 230 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Created At
                                    </MDTypography>
                                </TableCell>
                                {
                                    widget && widget?.viewWidgetOrder &&
                                <TableCell align="left" sx={{ minWidth: 200, maxWidth: 270 }}>

                                </TableCell>
                                }
                            </TableRow>
                        </thead>
                        {loading ?
                            <TableBody>
                                <TableRow >
                                    <TableCell align="center" colSpan={9} >
                                        <MDBox
                                            display="flex"
                                            py={1}
                                            flexDirection='column'
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <MDProgress circular />
                                            <MDTypography variant="h6">
                                                Loading Orders...
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {data && data.length > 0 ? data.map((row, index) => {

                                    return <OrderRow darkMode={darkMode} row={row} data={data} key={`order-${index}`} viewOrder={widget && widget?.viewWidgetOrder} />
                                }) :
                                    <TableRow>
                                        <TableCell align="center" colSpan={9} >
                                            <MDTypography variant='caption' component='span' fontWeight="bold">no orders found</MDTypography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>}
                    </Table>
                    {data.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                        <Pagination color='info' variant="outlined"
                            sx={{
                                '& .MuiPagination-ul > li > button': {
                                    fontWeight: 400,
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                    borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                },
                                '& .MuiPagination-ul > li > div': {
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                }
                            }}
                            count={pages} page={page} onChange={(e, value) => setPage(value)} />
                    </MDBox>}
                </TableContainer>
            </Card>
            :
            <PermissionBox  text={"to view widget orders details"}/>
        }


        </>
    );
};


function OrderRow({ row, darkMode,viewOrder }) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const {widget}=useSelector(state=>state.auth.user.permissions)


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                {/* <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <Icon fontSize="medium">expand_more</Icon> : <Icon fontSize="medium">expand_less</Icon>}
                    </IconButton>
                </TableCell> */}
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.package?.name}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.orgName}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.websiteName}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.status}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        ${row.discount || 0}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        ${row.total}
                    </MDTypography>
                </TableCell>
                {row.invoiceLink ? (
                    <TableCell align="left">
                        <MDTypography variant="caption" fontWeight="bold" component='a' target='_blank' href={row?.invoiceLink}>
                            Open Invoice
                        </MDTypography>
                        {<CopyToClipboard
                            text={row?.invoiceLink}
                            onCopy={() => toast.success("Link Copied!")}
                        >
                            <FileCopyOutlined style={{ fontSize: '1.2rem', marginLeft: '10px', verticalAlign: 'middle', cursor:"pointer", color:darkMode ? "white":"black" }} />

                        </CopyToClipboard>}
                    </TableCell>

                ) : (
                    <TableCell align="left">
                        <MDTypography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                           
                        </MDTypography>
                    </TableCell>
                )}
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.subscribed.toString()}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {simpleDateparser(row?.createdAt)}
                    </MDTypography>
                </TableCell>
                {
viewOrder && 
                <TableCell align="left">
                    <MDBox display='flex' gap={2}>
                        <MDButton variant='contained' color='success' size='small' onClick={() => navigate(`/user/main/accessBlend/widget/orders/view/${row.id}`)}>View Order</MDButton>
                    </MDBox>
                </TableCell>
                }
            </TableRow>



        </React.Fragment>
    )
}


export default WidgetOrders; 