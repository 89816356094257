import axios from 'axios';
import { BASE_URL, FIREBASE_API_KEY } from 'Source/constant';
import { getFRToken } from 'services/firebase';
import { toast } from 'react-toastify';
import { getAuth, signOut } from "firebase/auth";
import { SET_SELECTED_WEBSITE, SET_USER_SUBDOMAIN } from './userDashboard.action';

export const USER_LOADED = 'USER_LOADED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_LOADING = 'USER_LOADING';
export const CREATING_USER = 'CREATING_USER';
export const LOAD_CONFIG = 'LOAD_CONFIG';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const PREV_PATH = 'PREV_PATH';


export const loadUser = () => async (dispatch) => {
  const token = await getFRToken();
  // dispatch({
  //   type: USER_LOADING
  // });

  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/check`);
    if (res.data) {

      // if (res?.data?.user?.emailVerified) {
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
      dispatch({
        type: EMAIL_VERIFIED,
        payload: { flag: true, email: res.data?.user?.email }
      })
      // }
      //  else {
      //   // dispatch({
      //   //   type: USER_LOADED,
      //   //   payload: res.data
      //   // });
      //   toast.error("Please verify your email!")
      //   const auth = getAuth();
      //   signOut(auth).then((data) => {

      //     dispatch(logoutUser());
      //   })
      //   dispatch({
      //     type: EMAIL_VERIFIED,

      //     payload: { flag: false, email: res.data?.user?.email }
      //   })
      // }
      return res.data



    }
  } catch (error) {
    const auth = getAuth();
    signOut(auth).then((data) => {

      dispatch(logoutUser());
    })
  }
};


export const loadUserWithoutLoading = () => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/check`);
    if (res.data) {
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const registerUser = (data) => async () => {
  try {
    const res = await axios.post(`${BASE_URL}/register`, {
      ...data
    });
    if (res.data) {
      return res.data.result
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateBilling = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/customer/billing-details`, {
      ...data
    });
    if (res.data) {
      await dispatch(loadUserWithoutLoading())
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const createUser = () => async (dispatch) => {
  const token = await getFRToken();
  dispatch({
    type: CREATING_USER
  });
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/user`);
    if (res.data) {
      dispatch({
        type: CREATING_USER
      });
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const linkStatus = (id) => async (dispatch) => {
  try {
    const params = {
      id
    }
    const res = await axios.get(`${BASE_URL}/oauth/status`, {
      params
    });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const loadConfig = () => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/config`);
    if (res.data) {
      dispatch({
        type: LOAD_CONFIG,
        payload: res.data.config
      })
      localStorage.setItem("config", JSON.stringify(res.data.config))
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
    payload: null
  });
  dispatch({
    type: PREV_PATH,
    payload: null
  })

  dispatch({
    type: SET_SELECTED_WEBSITE,
    payload: null
  })

  dispatch({
    type: SET_USER_SUBDOMAIN,
    payload: null
  })
};

export const loadingFalse = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
    payload: null
  });
};

export const userAction = (data) => async () => {
  try {
    const res = await axios.post(`${BASE_URL}/action`, {
      email: data.email,
      action: data.action
    });
    if (res.data) {
      return res.data.result
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const loginUser = (data) => async () => {
  try {
    const res = await axios.post(`${BASE_URL}/login`, {
      ...data
    });
    if (res.data) {
      return res.data.result
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const refreshToken = () => async (dispatch) => {
  const tok = localStorage.getItem('refreshToken');
  axios.defaults.headers.common['Authorization'] = "";
  try {
    const res = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${FIREBASE_API_KEY}&grant_type=refresh_token&refresh_token=${tok}`);
    if (res.data) {
      const expiresAt = JSON.stringify(new Date().getTime() + (50 * 60000));
      localStorage.setItem('accessToken', res.data.access_token);
      localStorage.setItem('refreshToken', res.data.refresh_token);
      localStorage.setItem('expiresIn', expiresAt)
    }
  } catch (error) {
    dispatch(logoutUser());
  }
};

export const updateUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/update-user`, {
      ...data
    });
    if (res.data) {
      toast.success(res.data.message);
      dispatch(loadUserWithoutLoading()).then(data => {
        return res.data.result
      }
      )
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updatePasswords = (data) => async () => {
  try {
    const res = await axios.post(`${BASE_URL}/update-user-password`, {
      ...data
    });
    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};