import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Pagination from "@mui/material/Pagination";
import colors from "assets/theme/base/colors";
import { Card, FormControl, Grid, InputLabel, MenuItem, TableContainer } from "@mui/material";
import MDProgress from "components/MDProgress";
import { useDispatch } from "react-redux";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getWidgetLogs, getWidgetLinks, addAdminWidget } from "redux/actions/adminSmokiDashboard.action";
import MDInput from "components/MDInput";
import MuiSelect from '@mui/material/Select'
import MDButton from "components/MDButton";
import { generateAdminWidgetLink } from "redux/actions/adminSmokiDashboard.action";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { widgetAction } from "redux/actions/adminSmokiDashboard.action";
import { useNavigate } from "react-router-dom";

const WidgetLogs = () => {

    const dispatch = useDispatch();
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [rows, setRows] = React.useState([]);

    const [page2, setPage2] = React.useState(1);
    const [pages2, setPages2] = React.useState(0);
    const [rows2, setRows2] = React.useState([]);

    const [loading, setLoading] = React.useState(false);
    const [loading2, setLoading2] = React.useState(false);
    const [addLoading, setAddLoading] = React.useState(false);
    const [genWidgetLoading, setGenWidgetLoading] = React.useState(false);


    const [widgetValues, setWidgetValues] = React.useState({
        "website": "",
        "userId": "",
        "option": "" // free,trial,paid
    })


    const [genWidgetLink, setGenWidgetLink] = React.useState({
        "website": "",
        "userId": "",
        "userEmail": "",
        "organization": "",
        totalPrice: 0,
        "environment": "",
        "options": "", // free,trial,paid
        trialDays: 0,
        numberOfViews: 0,
        discount_total: 0,
        recurring: {
            interval: 'month',
            intervalCount: 1
        },
        test: true
    })

    function fetchReports() {
        setLoading(true)
        dispatch(getWidgetLogs(page)).then(data => {
            if (data) {
                setRows(data.result)
                setPages(data.pages)
                setLoading(false)

            }
        }).catch(err => {
            setLoading(false)
        })
    }

    function fetchAdminLinks() {
        setLoading2(true)
        dispatch(getWidgetLinks(page)).then(data => {
            if (data) {
                setRows2(data.result)
                setPages2(data.pages)
                setLoading2(false)
            }
        }).catch(err => {
            setLoading2(false)
        })
    }

    function addWidget(e) {
        e.preventDefault();
        setAddLoading(true)
        dispatch(addAdminWidget(widgetValues)).then(data => {
            if (data) {
                fetchAdminLinks(1)
                setAddLoading(false)
            }
        }).catch(err => {
            setAddLoading(false)
        })
    }

    function genWidget(e) {
        e.preventDefault();
        setGenWidgetLoading(true)
        dispatch(generateAdminWidgetLink(genWidgetLink)).then(data => {
            if (data) {
                fetchAdminLinks(1)
            }
            setGenWidgetLoading(false)

        }).catch(err => {
            setGenWidgetLoading(false)
        })
    }

    React.useEffect(() => {
        return () => {
            setPage(1)
            setPages(0)
            setRows([])
            setLoading(false)
            setPage2(1)
            setPages2(0)
            setRows2([])
            setLoading2(false)
        }
    }, [])


    React.useEffect(() => {
        // fetchReports();
        fetchAdminLinks();
    }, [page])

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;



    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* <MDBox component='form' onSubmit={addWidget}>
                <MDTypography variant='h4' sx={{ mb: 2 }}>Add Widget</MDTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <MDInput variant='outlined' name='website' label='Website' onChange={(e) => {
                            setWidgetValues({ ...widgetValues, [e.target.name]: e.target.value })
                        }} fullWidth value={widgetValues.website} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDInput variant='outlined' name='userId' label='User Id' onChange={(e) => {
                            setWidgetValues({ ...widgetValues, [e.target.name]: e.target.value })
                        }} fullWidth value={widgetValues.userId} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl style={{ width: '100%' }} >
                            <InputLabel>Option</InputLabel>
                            <MuiSelect
                                fullWidth
                                labelId="order-test"
                                id="demo-simple-select"
                                label='OPtion'
                                name='option'
                                value={widgetValues?.option}
                                onChange={(e) => {
                                    setWidgetValues({
                                        ...widgetValues, option: e.target.value
                                    })
                                }}
                            >
                                <MenuItem value={'trial'}>Trial</MenuItem>
                                <MenuItem value={'free'}>Free</MenuItem>
                                <MenuItem value={'paid'}>Paid</MenuItem>
                            </MuiSelect>
                        </FormControl>
                    </Grid>
                </Grid>
                <MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={addLoading}>Submit</MDButton>
            </MDBox> */}


            <MDBox component='form' onSubmit={genWidget} mt={2}>
                <MDTypography variant='h4' sx={{ mb: 2 }}>Generate Widget Order</MDTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput variant='outlined' name='website' label='Website' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.website} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput type='number' InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }} variant='outlined' name='numberOfViews' label='No. of Pages Views' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.numberOfViews} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput variant='outlined' name='userId' label='User Id' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.userId} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput variant='outlined' name='userEmail' label='User Email' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.userEmail} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput variant='outlined' name='organization' label='User Organization' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.organization} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl style={{ width: '100%' }} >
                            <InputLabel>Option</InputLabel>
                            <MuiSelect
                                fullWidth
                                labelId="order-test"
                                id="demo-simple-select"
                                label='Option'
                                name='options'
                                value={genWidgetLink?.options}
                                onChange={(e) => {
                                    setGenWidgetLink({
                                        ...genWidgetLink, options: e.target.value
                                    })
                                }}
                            >
                                <MenuItem value={'trial'}>Trial</MenuItem>
                                <MenuItem value={'free'}>Free</MenuItem>
                                <MenuItem value={'paid'}>Paid</MenuItem>
                            </MuiSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl style={{ width: '100%' }} >
                            <InputLabel>Environment</InputLabel>
                            <MuiSelect
                                fullWidth
                                labelId="order-test"
                                id="demo-simple-select"
                                label='Environment'
                                name='environment'
                                value={genWidgetLink?.environment}
                                onChange={(e) => {
                                    setGenWidgetLink({
                                        ...genWidgetLink, environment: e.target.value
                                    })
                                }}
                            >
                                <MenuItem value={'production'}>Production</MenuItem>
                                <MenuItem value={'staging'}>Staging</MenuItem>
                                <MenuItem value={'development'}>Development</MenuItem>
                            </MuiSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput type='number' InputProps={{
                            inputProps: {
                                min: 1
                            }
                        }} variant='outlined' name='totalPrice' label='Total Price' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.totalPrice} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput type='number' InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }} variant='outlined' name='discount_total' label='Discount' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.discount_total} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput type='number' InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }} variant='outlined' name='trialDays' label='Trial Days' onChange={(e) => {
                            setGenWidgetLink({ ...genWidgetLink, [e.target.name]: e.target.value })
                        }} fullWidth value={genWidgetLink.trialDays} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <FormControl style={{ width: '100%' }} >
                            <InputLabel>Interval</InputLabel>
                            <MuiSelect
                                fullWidth
                                labelId="order-test"
                                id="demo-simple-select"
                                label='Option'
                                name='option'
                                value={genWidgetLink?.recurring?.interval}
                                onChange={(e) => {
                                    setGenWidgetLink({
                                        ...genWidgetLink, recurring: { ...genWidgetLink.recurring, interval: e.target.value }
                                    })
                                }}
                            >
                                <MenuItem value={'year'}>Yearly</MenuItem>
                                <MenuItem value={'month'}>Monthly</MenuItem>
                                <MenuItem value={'week'}>Weekly</MenuItem>
                            </MuiSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDInput type='number' InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }} variant='outlined' name='intervalCount' label='Interval Count' onChange={(e) => {
                            setGenWidgetLink({
                                ...genWidgetLink, recurring: { ...genWidgetLink.recurring, intervalCount: e.target.value }
                            })
                        }} fullWidth value={genWidgetLink?.recurring?.intervalCount} required={true} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>

                        <FormControl style={{ width: '100%' }} >
                            <InputLabel>Test</InputLabel>
                            <MuiSelect
                                fullWidth
                                labelId="order-test"
                                id="demo-simple-select"
                                label='Test'
                                name='test'
                                value={genWidgetLink?.test}
                                onChange={(e) => {
                                    setGenWidgetLink({
                                        ...genWidgetLink, test: e.target.value
                                    })
                                }}
                            >
                                <MenuItem value={'true'}>True</MenuItem>
                                <MenuItem value={'false'}>False</MenuItem>
                            </MuiSelect>
                        </FormControl>
                    </Grid>
                </Grid>
                <MDButton variant='gradient' type='submit' color='info' sx={{ mt: 2 }} loading={genWidgetLoading}>Submit</MDButton>
            </MDBox>
            <Card sx={{ my: 5 }}>
                <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white" textTransform='capitalize'>
                        Widget Links
                    </MDTypography>
                </MDBox>

                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <thead>
                            <TableRow>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        ID
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        User
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Website
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        No. of Page Views
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Total Price
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Trial
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Hostname
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 150 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Link
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 150 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Count
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 150 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Action
                                    </MDTypography>
                                </TableCell>

                            </TableRow>
                        </thead>
                        {loading2 ?
                            <TableBody>
                                <TableRow >
                                    <TableCell align="center" colSpan={6} >
                                        <MDBox
                                            display="flex"
                                            py={1}
                                            flexDirection='column'
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <MDProgress circular />
                                            <MDTypography variant="h6">
                                                Loading Widget Links...
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {rows2 && rows2.length > 0 ? rows2.map((row, index) => {

                                    return <OrderRow2 darkMode={darkMode} row={row} rows={rows2} key={`order-${index}`} />
                                }) :
                                    <TableRow>
                                        <TableCell align="center" colSpan={11} >
                                            <MDTypography variant='caption' component='span' fontWeight="bold">no widget links found</MDTypography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>}
                    </Table>
                    {rows2.length > 0 && !loading2 && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                        <Pagination color='info' variant="outlined"
                            sx={{
                                '& .MuiPagination-ul > li > button': {
                                    fontWeight: 400,
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                    borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                },
                                '& .MuiPagination-ul > li > div': {
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                }
                            }}
                            count={pages2} page={page2} onChange={(e, value) => setPage2(value)} />
                    </MDBox>}
                </TableContainer>
            </Card>

            {/* <Card sx={{ my: 5 }}>
                <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white" textTransform='capitalize'>
                        Widget Logs
                    </MDTypography>
                </MDBox>

                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <thead>
                            <TableRow>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Widget ID
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        Website
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 200 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        IP Address
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: 400 }}>
                                    <MDTypography variant="caption" component='span' fontWeight="medium" color={darkMode ? 'white' : 'info'}>
                                        User Agent
                                    </MDTypography>
                                </TableCell>

                            </TableRow>
                        </thead>
                        {loading ?
                            <TableBody>
                                <TableRow >
                                    <TableCell align="center" colSpan={6} >
                                        <MDBox
                                            display="flex"
                                            py={1}
                                            flexDirection='column'
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <MDProgress circular />
                                            <MDTypography variant="h6">
                                                Loading Widget Logs...
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {rows && rows.length > 0 ? rows.map((row, index) => {

                                    return <OrderRow darkMode={darkMode} row={row} rows={rows} key={`order-${index}`} />
                                }) :
                                    <TableRow>
                                        <TableCell align="center" colSpan={11} >
                                            <MDTypography variant='caption' component='span' fontWeight="bold">no widget logs found</MDTypography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>}
                    </Table>
                    {rows.length > 0 && !loading && <MDBox mt={2} mb={2} display="flex" justifyContent="center" width="100%" >
                        <Pagination color='info' variant="outlined"
                            sx={{
                                '& .MuiPagination-ul > li > button': {
                                    fontWeight: 400,
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                    borderColor: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                },
                                '& .MuiPagination-ul > li > div': {
                                    color: darkMode ? 'white !important' : `${colors?.info?.main} !important`,
                                }
                            }}
                            count={pages} page={page} onChange={(e, value) => setPage(value)} />
                    </MDBox>}
                </TableContainer>
            </Card> */}
        </DashboardLayout>
    );
};


function OrderRow({ row }) {

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell>
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row.widgetId}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.website}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.ip}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.userAgent}
                    </MDTypography>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

function OrderRow2({ row }) {
    const navigate = useNavigate()

    const dispatch = useDispatch();
    const [active, setActive] = React.useState(row.active);
    const [loading, setLoading] = React.useState(false);

    function handleChange(e) {
        setLoading(true)
        setActive(e.target.checked)
        dispatch(widgetAction({
            widgetId: row.id,
            action: e.target.checked ? 'enable' : 'disable'
        })).then(data => {
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell>
                    <MDTypography variant="caption" fontWeight="bold" >
                        {row.id}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.user}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" component='a' href={row.website} target='_blank' fontWeight="bold">
                        {row.website}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.numberOfViews}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.totalPrice}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row?.trial.toString()}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.hostname}
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" component='a' href={row.link} target='_blank' fontWeight="bold">
                        View
                    </MDTypography>
                </TableCell>
                <TableCell align="left">
                    <MDTypography variant="caption" fontWeight="bold">
                        {row.count}
                    </MDTypography>
                </TableCell>

                <TableCell align="left">
                    <MDBox display='flex' compoenent='span' >
                        {!loading ? <FormGroup>
                            <FormControlLabel style={{ display: 'flex' }} control={<Switch checked={active} onChange={handleChange} />} label={active ? "Enabled" : 'Disabled'} />
                        </FormGroup> :
                            <MDProgress circular size={18} />
                        }
                        <MDButton variant='contained' color='primary' onClick={() => navigate(`/admin/enterprise/accessBlend/edit/widget/${row.id}`)}>Settings</MDButton>
                    </MDBox>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default WidgetLogs;

