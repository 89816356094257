import axios from 'axios';
import { BASE_URL } from 'Source/constant';
import { getFRToken } from 'services/firebase';
import { toast } from 'react-toastify';
import { getHealthStatuses } from './dashboard.action';
import { getDashboardType } from 'utils/getDashboardType';

export const GET_ADMIN_ANALYTICS = 'GET_ADMIN_ANALYTICS';
export const GET_ADMIN_ANALYTICS_ERROR = 'GET_ADMIN_ANALYTICS_ERROR';
export const GET_ADMIN_ANALYTICS_LOADING = 'GET_ADMIN_ANALYTICS_LOADING';

export const GET_ADMIN_UNIFIED_ANALYTICS = 'GET_ADMIN_UNIFIED_ANALYTICS';
export const GET_ADMIN_UNIFIED_ANALYTICS_ERROR = 'GET_ADMIN_UNIFIED_ANALYTICS_ERROR';
export const GET_ADMIN_UNIFIED_ANALYTICS_LOADING = 'GET_ADMIN_UNIFIED_ANALYTICS_LOADING';

export const GET_WEBSITE_BACKUPS = 'GET_WEBSITE_BACKUPS';
export const GET_WEBSITE_BACKUPS_LOADING = 'GET_WEBSITE_BACKUPS_LOADING';

export const GET_USERS_WORDPRESS = 'GET_USERS_WORDPRESS';
export const GET_USERS_WORDPRESS_LOADING = 'GET_USERS_WORDPRESS_LOADING';

export const GET_USERS_ROOT = 'GET_USERS_ROOT';
export const GET_USERS_ROOT_LOADING = 'GET_USERS_ROOT_LOADING';

export const GET_ADMIN_INVOICES = 'GET_ADMIN_INVOICES';
export const GET_ADMIN_INVOICES_LOADING = 'GET_ADMIN_INVOICES_LOADING';

export const GET_ADMIN_SUBSCRIPTIONS = 'GET_ADMIN_SUBSCRIPTIONS';
export const GET_ADMIN_SUBSCRIPTIONS_LOADING = 'GET_ADMIN_SUBSCRIPTIONS_LOADING';

export const GET_COUPONS = 'GET_COUPONS';
export const GET_COUPONS_LOADING = 'GET_COUPONS_LOADING';

export const CREATE_COUPON_LOADING = 'CREATE_COUPON_LOADING';
export const COUPON_DELETE_LOADING = 'COUPON_DELETE_LOADING';

export const GET_AFFILIATES = 'GET_AFFILIATES';
export const GET_AFFILIATES_LOADING = 'GET_AFFILIATES_LOADING';

export const EDIT_AFFILIATE_LOADING = 'EDIT_AFFILIATE_LOADING';
export const CREATE_AFFILIATE_LOADING = 'CREATE_AFFILIATE_LOADING';

export const GET_AFFILIATES_LINKS = 'GET_AFFILIATES_LINKS';
export const GET_AFFILIATES_LINKS_LOADING = 'GET_AFFILIATES_LINKS_LOADING';

export const SEND_ADMIN_TICKET_LOADING = "SEND_ADMIN_TICKET_LOADING";

export const ADD_HEALTH_STATUS_LOADING = 'ADD_HEALTH_STATUS_LOADING';
export const EDIT_HEALTH_STATUS_LOADING = 'EDIT_HEALTH_STATUS_LOADING';

export const GET_WORDPRESS_PACKAGES = 'GET_WORDPRESS_PACKAGES';
export const GET_WORDPRESS_PACKAGES_LOADING = 'GET_WORDPRESS_PACKAGES_LOADING';

export const GET_SUBSCRIPTION_INVOICES = 'GET_SUBSCRIPTION_INVOICES';
export const GET_SUBSCRIPTION_INVOICES_LOADING = 'GET_SUBSCRIPTION_INVOICES_LOADING';

export const GET_USER_CHARGE_INVOICES = 'GET_USER_CHARGE_INVOICES';
export const GET_USER_CHARGE_INVOICES_LOADING = 'GET_USER_CHARGE_INVOICES_LOADING';

export const GET_ONE_TIME_CHARGE_INVOICES = 'GET_ONE_TIME_CHARGE_INVOICES';
export const GET_ONE_TIME_CHARGE_INVOICES_LOADING = 'GET_ONE_TIME_CHARGE_INVOICES_LOADING';

export const GET_WORDPRESS_PLUGINS = 'GET_WORDPRESS_PLUGINS';
export const GET_WORDPRESS_PLUGINS_LOADING = 'GET_WORDPRESS_PLUGINS_LOADING';

export const GET_ADMIN_SHOPIFY_SUPPORT_REQUESTS = 'GET_ADMIN_SHOPIFY_SUPPORT_REQUESTS';
export const GET_ADMIN_SHOPIFY_SUPPORT_REQUESTS_LOADING = 'GET_ADMIN_SHOPIFY_SUPPORT_REQUESTS_LOADING';

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ALL_ORDERS_LOADING = 'GET_ALL_ORDERS_LOADING';

export const GET_TOKEN_ORDERS = 'GET_TOKEN_ORDERS';
export const GET_TOKEN_ORDERS_LOADING = 'GET_TOKEN_ORDERS_LOADING';

export const GET_ORDER_INVOICES = 'GET_ORDER_INVOICES';
export const GET_ORDER_INVOICES_LOADING = 'GET_ORDER_INVOICES_LOADING';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_LOADING = 'GET_ORGANIZATIONS_LOADING';

export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS';
export const UPDATE_ORGANIZATIONS_LOADING = 'UPDATE_ORGANIZATIONS_LOADING';


export const getAdminUnifiedAnalytics = () => async (dispatch) => {
  const token = await getFRToken();

  dispatch({
    type: GET_ADMIN_UNIFIED_ANALYTICS_LOADING
  })

  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/unified-analytics`);
    if (res.data) {
      dispatch({
        type: GET_ADMIN_UNIFIED_ANALYTICS,
        payload: res.data.result
      })
      return res.data
    }

  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: GET_ADMIN_UNIFIED_ANALYTICS_ERROR
    })
  }
};

export const getAdminAnalytics = () => async (dispatch) => {
  const token = await getFRToken();

  dispatch({
    type: GET_ADMIN_ANALYTICS_LOADING
  })

  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/analytics`);
    if (res.data) {
      dispatch({
        type: GET_ADMIN_ANALYTICS,
        payload: res.data.result
      })
      return res.data
    }

  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: GET_ADMIN_ANALYTICS_ERROR
    })
  }
};

export const getAllUsers = (page) => async (dispatch) => {
  dispatch({
    type: GET_USERS_WORDPRESS_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/users?page=${page}&limit=${5}`
    );
    if (res.data) {
      dispatch({
        type: GET_USERS_WORDPRESS,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAllRootUsers = (page) => async (dispatch) => {
  dispatch({
    type: GET_USERS_ROOT_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/root-users?page=${page}&limit=${5}`
    );
    if (res.data) {
      dispatch({
        type: GET_USERS_ROOT,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getRootUsers = () => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/root-users?page=1&limit=${100}`
    );
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const assignAdminCustomWidget = (widgetId, userId) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(
      `${BASE_URL}/accessblend/custom-widgets/assign`,
      widgetId, userId
    );
    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const unAssignAdminCustomWidget = (widgetId) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(
      `${BASE_URL}/accessblend/custom-widgets/unassign`,
      widgetId
    );
    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const assignAdminCustomWebsite = (websiteId, userId) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(
      `${BASE_URL}/accessblend/admin-websites/assign`,
      websiteId, userId
    );
    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const unAssignAdminCustomWebsite = (websiteId) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(
      `${BASE_URL}/accessblend/admin-websites/unassign`,
      websiteId
    );
    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const verifyWebsite = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/admin-user-websites/verify`,
      { ...data },
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data

    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const addCustomWebsite = (website) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/admin-websites/add`, { website });
    if (res.data) {
      toast.success(res.data.message);

      return res.data;
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const addUserCustomWebsite = (website, userId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/admin-user-websites/add`, { website, userId });
    if (res.data) {
      toast.success(res.data.message);

      return res.data;
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const generateLoginLink = (userId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      userId
    }
    const res = await axios.post(`${BASE_URL}/login-link`, { userId });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getCustomWebsites = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 5,
      page
    }
    const res = await axios.get(`${BASE_URL}/accessblend/admin-websites`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserCustomWebsites = (page, userId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 5,
      page,
      userId
    }
    const res = await axios.get(`${BASE_URL}/accessblend/admin-user-websites`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getAdminInvoices = () => async (dispatch) => {
  dispatch({
    type: GET_ADMIN_INVOICES_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/invoices`
    );
    if (res.data) {
      dispatch({
        type: GET_ADMIN_INVOICES,
        payload: res.data.result
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const addHealthStatus = (data) => async (dispatch) => {
  dispatch({
    type: ADD_HEALTH_STATUS_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/wordpress/health-status`,
      { ...data }
    );

    if (res.data) {
      dispatch({
        type: ADD_HEALTH_STATUS_LOADING
      });
      dispatch(getHealthStatuses());
      toast.success(res.data.message);

      return res.data
    }
  } catch (error) {
    dispatch({
      type: ADD_HEALTH_STATUS_LOADING
    })
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const editHealthStatus = (data) => async (dispatch) => {
  dispatch({
    type: EDIT_HEALTH_STATUS_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/wordpress/health-status`,
      { ...data }
    );

    if (res.data) {
      dispatch({
        type: EDIT_HEALTH_STATUS_LOADING
      });
      dispatch(getHealthStatuses());
      toast.success(res.data.message);

      return res.data
    }
  } catch (error) {
    dispatch({
      type: EDIT_HEALTH_STATUS_LOADING
    })
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getSubscriptionInvoices = (page) => async (dispatch) => {
  dispatch({
    type: GET_SUBSCRIPTION_INVOICES_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/wordpress/invoices?page=${page}&limit=${5}`
    );
    if (res.data) {
      dispatch({
        type: GET_SUBSCRIPTION_INVOICES,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getWidgetLogs = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/widget-logs?page=${page}&limit=${5}`
    );
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getWidgetLinks = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/admin-widget-link?page=${page}&limit=${5}`
    );
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getUserChargeInvoices = (page) => async (dispatch) => {
  dispatch({
    type: GET_USER_CHARGE_INVOICES_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/wordpress/usage-charge-invoices?page=${page}&limit=${5}`
    );
    if (res.data) {
      dispatch({
        type: GET_USER_CHARGE_INVOICES,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getOneTimeChargeInvoices = (page) => async (dispatch) => {
  dispatch({
    type: GET_ONE_TIME_CHARGE_INVOICES_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/wordpress/one-time-charge-invoices?page=${page}&limit=${5}`
    );
    if (res.data) {
      dispatch({
        type: GET_ONE_TIME_CHARGE_INVOICES,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getMode = () => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/get-mode`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const setMode = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/saas/set-mode`, { ...data });
    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getBillingMode = () => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/get-billing-mode`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const setBillingMode = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/set-billing-mode`, { ...data });
    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAllOrders = (data) => async (dispatch) => {
  dispatch({
    type: GET_ALL_ORDERS_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      ...data
    }
    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-auditor-orders?limit=${5}`, { params });
    if (res.data) {
      dispatch({
        type: GET_ALL_ORDERS,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getTokenOrders = (data) => async (dispatch) => {
  dispatch({
    type: GET_TOKEN_ORDERS_LOADING
  })
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      ...data
    }
    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-token-orders?limit=${5}`, { params });
    if (res.data) {
      dispatch({
        type: GET_TOKEN_ORDERS,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getOrderDetails = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-auditor-orders/details?orderId=${orderId}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getWidgetOrderDetails = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-widget-orders/details?orderId=${orderId}`);
    if (res.data) {

      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const widgetOrderSubscribe = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-widget-orders/subscription/make`, { orderId });
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const widgetOrderUnSubscribe = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-widget-orders/subscription/cancel`, { orderId });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};



export const auditorAdminOrderSubscribe = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-auditor-orders/subscription/make`, { orderId });
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const auditorAdminOrderUnSubscribe = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-auditor-orders/subscription/cancel`, { orderId });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};




export const auditorOrderSubscribe = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-auditor-orders/subscription/make`, { orderId });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const auditorOrderUnSubscribe = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(`${BASE_URL}/accessblend/saas/admin-auditor-orders/subscription/cancel`, { orderId });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateOrderDetails = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-auditor-orders/update`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const updateWidgetOrderDetails = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-widget-orders/update`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateConfigVars = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/config-vars`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getConfigVars = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(
      `${BASE_URL}/accessblend/saas/get-config-vars`
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getUserOrderClusters = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(
      `${BASE_URL}/accessblend/saas/order-clusters?orderId=${orderId}`
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAdminClusters = (data) => async (dispatch) => {

  const token = await getFRToken();
  const params = {
    ...data
  }
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(
      `${BASE_URL}/accessblend/saas/admin-clusters?limit=5`,
      { params }
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAdminClustersList = (page, limit) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(
      `${BASE_URL}/accessblend/saas/admin-clusters?limit=${limit}&page=${page}`
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateUserOrderCluster = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-clusters/update`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateClusterScansOptions = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-clusters/scan-options`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const updateUserOrderClusterMaintenance = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-clusters/maintenance`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const addUserOrderCluster = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-clusters/add`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getOrderInvoices = (data) => async (dispatch) => {
  dispatch({
    type: GET_ORDER_INVOICES_LOADING
  })
  const token = await getFRToken();

  const params = {
    ...data
  }
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/user-orders/invoices`,
      { params }
    );
    if (res.data) {
      dispatch({
        type: GET_ORDER_INVOICES,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const generateInvoice = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-orders/invoice`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const updateOrderStatus = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-orders/update`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const deleteOrder = (orderId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(
      `${BASE_URL}/accessblend/saas/admin-orders/delete?orderId=${orderId}`
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getInvoiceDetail = (invoiceId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(
      `${BASE_URL}/accessblend/saas/admin-invoices/details?invoiceId=${invoiceId}`
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const voidInvoice = (invoiceId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(
      `${BASE_URL}/accessblend/saas/admin-invoices/void?invoiceId=${invoiceId}`
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getInvoices = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    const params = {
      ...data
    }
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/invoices?limit=${5}`, { params });
    if (res.data) {

      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getUserTokenInvoices = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    const params = {
      ...data
    }
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/enterprise/billing/user-token-invoices?limit=${5}`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAdminTokenInvoices = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    const params = {
      ...data
    }
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/token-invoices?limit=${5}`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getSettings = () => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/get-settings`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const updateSettings = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/saas/set-settings`, { ...data });
    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const updateClusterVars = (data) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/cluster-vars`,
      { ...data }
    );
    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getClusterVars = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(
      `${BASE_URL}/accessblend/saas/get-cluster-vars`
    );
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const allScanSupports = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/scan-reports?page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getPdf = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/saas/child-website-action`, { ...data });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const addOrder = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/saas/add-order`,
      { ...data }
    );

    if (res.data) {

      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const addAdminOrder = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-orders/add`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const getPaymentOrderDetails = (orderId) => async (dispatch) => {

  try {
    const res = await axios.get(`${BASE_URL.slice(0, -11)}/onboard/saas/order-details?orderId=${orderId}`);
    if (res.data) {
      return res.data.result
    }
  } catch (error) {

  }
};

export const getWidgetPaymentOrderDetails = (orderId) => async (dispatch) => {

  try {
    const res = await axios.get(`${BASE_URL.slice(0, -11)}/onboard/global/widget-order-details?orderId=${orderId}`);
    if (res.data) {
      return res.data.result
    }
  } catch (error) {

  }
};

export const geTokenOrderDetails = (orderId) => async (dispatch) => {

  try {
    const res = await axios.get(`${BASE_URL.slice(0, -11)}/onboard/saas/token-order-details?orderId=${orderId}`);
    if (res.data) {
      return res.data.result
    }
  } catch (error) {

  }
};

export const getUpdatePaymentDetails = (paymentId) => async (dispatch) => {

  try {
    const res = await axios.get(`${BASE_URL.slice(0, -11)}/onboard/saas/order-update-details?paymentId=${paymentId}`);
    if (res.data) {
      return res.data.result
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getUpdateWidgetPaymentDetails = (paymentId) => async (dispatch) => {

  try {
    const res = await axios.get(`${BASE_URL.slice(0, -11)}/onboard/global/widget-order-update-details?paymentId=${paymentId}`);
    if (res.data) {
      return res.data.result
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const updatePaymentDetails = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/saas/change-order-update-details`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const updateWidgetPaymentDetails = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/global/widget-order-update-details`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const widgetAction = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/admin-widget-action`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    console.log(error)
    toast.error(error.response.data.message);
  }
}


export const makeOrderPayment = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-orders/make-payment`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const updateOnboardOrderDetails = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/saas/change-order-details`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const getAdminAuditorSubscriptions = (page, type, searchValue, selectedLabel, selectedValue) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/admin-subscriptions?page=${page}&limit=5&type=${type}&${selectedValue}=${searchValue}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAdminWidgetSubscriptions = (page, type, searchValue, selectedLabel, selectedValue) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/admin-subscriptions?page=${page}&limit=5&type=${type}&${selectedValue}=${searchValue}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAdminUserSubscriptions = (page, type, orgId, searchValue, selectedLabel, selectedValue) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    if (orgId) {
      const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/admin-subscriptions?page=${page}&limit=5&type=${type}&orgId=${orgId}&${selectedValue}=${searchValue}`);
      if (res.data) {
        return res.data
      }
    } else {
      const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/admin-subscriptions?page=${page}&limit=5&type=${type}&${selectedValue}=${searchValue}`);
      if (res.data) {
        return res.data
      }
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};



export const getAdminWidgetInvoices = (page, type, paid, orgId, searchValue, selectedLabel, selectedValue) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    if (orgId) {
      const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/admin-invoices?page=${page}&limit=5&paid=${paid}&type=${type}&orgId=${orgId}&${selectedValue}=${searchValue}`);
      if (res.data) {
        return res.data
      }
    } else {
      const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/admin-invoices?page=${page}&limit=5&paid=${paid}&type=${type}&${selectedValue}=${searchValue}`);
      if (res.data) {
        return res.data
      }
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getAdminWidgetRequests = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/admin-widget-requests?limit=${5}&page=${page}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};




export const updateOnboardWidgetOrderDetails = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/global/change-widget-order-details`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getOrderConfigDetails = (orderId) => async (dispatch) => {

  try {
    const res = await axios.get(
      `${BASE_URL.slice(0, -11)}/onboard/saas/order-config-details?orderId=${orderId}`
    );

    if (res.data) {
      return res.data
    }
  } catch (error) {
    return error.response.data.message
    // toast.error(error.response.data.message);
  }
}

export const orderConfigSubmit = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/saas/order-config`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const getSubscriptions = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    const params = {
      ...data
    }
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/billing/subscriptions?limit=${5}`, {
      params
    });
    if (res.data) {
      return res.data;
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const cancelSubscription = (subscriptionId) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(
      `${BASE_URL}/accessblend/saas/billing/subscriptions/cancel?subscriptionId=${subscriptionId}`
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const cancelWidgetSubscription = (subscriptionId) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.delete(
      `${BASE_URL}/cancel-widget-order-subscription?subscriptionId=${subscriptionId}`
    );
    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const widgetSubscriptionDowngradeAction = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/downgrade-widget-order-subscription`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const widgetSubscriptionUpdateAction = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/update-widget-order-subscription`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const subscriptionUpdateAction = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/billing/subscriptions/update`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const subscriptionDowngradeAction = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/billing/subscriptions/downgrade`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const updteSubscription = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/saas/change-order-update-subscription`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const widgetControlCenter = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/update-widget-center`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getWidgetDetail = (data) => async (dispatch) => {

  try {
    const res = await axios.get(
      `${BASE_URL}/widget-details`,
      { params: data }
    );

    if (res.data) {

      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const updateWidgetOrder = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/update-widget-order`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);

      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getWidgetPackages = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    const params = {
      ...data
    }
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.get(`${BASE_URL}/admin-widget-packages/all?limit=${5}`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const addWidgetPackage = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/admin-widget-packages/add`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const deleteWidgetPackage = (id) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/admin-widget-packages/delete?packageId=${id}`,
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const updateWidgetPackage = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${BASE_URL}/admin-widget-packages/update`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const updteWidgetSubscription = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL.slice(0, -11)}/onboard/global/change-widget-order-update-subscription`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}



export const getChildUsers = (role, page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/child-users?page=${page}&role=${role}&limit=${5}`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getChildWebsites = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/child-websites?page=${page}&limit=5`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const getChildOrganizations = (page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/child-organizations?page=${page}&limit=5`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const childOrganizationAction = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/child-organization-action`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const userchildOrganizationAction = (type, data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/${type}/child-organization-action`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const generateSitemap = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-orders/generate-sitemaps`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getSitemaps = (orderId, page) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-orders/sitemaps?orderId=${orderId}&page=${page}&limit=5`);
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const childOrganizationLicMode = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/lic-key-mode`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const changeLickey = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/lic-key-change`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const childOrganizationLickey = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/lic-key-action`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const childOrganizationEdit = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/child-organization-action`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const childWesiteAction = (data) => async (dispatch) => {

  try {

    if (data.action.includes("sheet")) {
      const res = await axios.post(`${BASE_URL}/accessblend/saas/child-website-action`,
        { ...data },
      );
      if (res.data) {
        window.open(res.data.result)
      }
    } else {
      const res = await axios.post(
        `${BASE_URL}/accessblend/saas/child-website-action`,
        { ...data }
      );

      if (res.data) {
        toast.success(res.data.message);
        return res.data
      }
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const childScanAction = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/saas/child-scan-action`,
      { ...data },
    );
    if (res.data) {
      window.open(res.data.result)
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const addAdminWidget = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/admin/add-widget`,
      { ...data },
    );
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const getWidgetAdminOrders = (page, test) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/admin-widget-orders?test=${test}&page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getWidgetOrders = (page, status) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-widget-orders?status=${status}&page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const getAdminAuditorOrders = (page, status) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-auditor-orders?status=${status}&page=${page}&limit=${5}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const generateAdminWidgetLink = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/generate-admin-widget-link`,
      { ...data },
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}

export const childUserAction = (data) => async (dispatch) => {

  try {
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/child-user-action`,
      { ...data }
    );

    if (res.data) {
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}




export const getWidgetAdminSnippets = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const params = {
      limit: 5,
      ...data
    }
    const res = await axios.get(`${BASE_URL}/accessblend/custom-widget-snippets`, { params });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const addWidgetAdminSnippets = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(`${BASE_URL}/accessblend/custom-widget-snippets/add`, { ...data });
    if (res.data) {
      return res.data
    }

  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};


export const deleteAdminSnippet = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(`${BASE_URL}/accessblend/custom-widget-snippets/remove?widgetSnippetId=${id}`);
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const deleteAdminReport = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.delete(`${BASE_URL}/accessblend/admin-websites/remove?websiteId=${id}`);
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const deleteAdminWidget = (id) => async (dispatch) => {

  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;

    const res = await axios.post(`${BASE_URL}/accessblend/widget-delete`, { widgetId: id });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
};

export const geAdminCustomWidget = (page) => async (dispatch) => {
  const token = await getFRToken();
  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/custom-widgets?page=${page}&limit=${5}`
    );
    if (res.data) {
      return res.data
    }

  } catch (error) {

  }
};


export const genAdminWidgetLink = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${BASE_URL}/accessblend/custom-widgets/add`,
      { ...data },
    );
    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (error) {
    if (error.hasOwnProperty("response")) {
      if (error.response.hasOwnProperty("data")) {
        if (error.response.data.hasOwnProperty("message")) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      toast.error('Something went wrong');
    }
  }
}


export const getAllOrganizations = (page) => async (dispatch) => {
  const token = await getFRToken();

  dispatch({
    type: GET_ORGANIZATIONS_LOADING,
    payload: true
  })

  try {
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.get(`${BASE_URL}/accessblend/saas/admin-org?page=${page}&limit=${5}`);
    if (res.data) {
      dispatch({
        type: GET_ORGANIZATIONS,
        payload: res.data
      })
      return res.data
    }

  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: GET_ORGANIZATIONS_LOADING,
      payload: false
    })
  }
};


export const updateOrganization = (data) => async (dispatch) => {

  const token = await getFRToken();
  try {
    dispatch({
      type: UPDATE_ORGANIZATIONS_LOADING,
      payload: true
    })
    axios.defaults.headers.common['Authorization'] = token;
    const res = await axios.post(
      `${BASE_URL}/accessblend/saas/admin-org/update`,
      { ...data }
    );
    if (res.data) {
      dispatch({
        type: UPDATE_ORGANIZATIONS_LOADING,
        payload: false
      })
      toast.success(res.data.message);
      return res.data
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ORGANIZATIONS_LOADING,
      payload: false
    })
    toast.error(error.response.data.message);
  }
};