import { Alert, Card, CardContent, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuditorOrderDetails } from "redux/actions/userDashboard.action";
import { userAuditorOrderAdd } from "redux/actions/userDashboard.action";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { unsubscribeAuditor } from "redux/actions/userDashboard.action";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { updateBilling } from "redux/actions/auth.action";

export default function UserAuditorBilling({ orderDetails }) {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selectedSubdomain = useSelector(state => state.userDashboard.selectedSubdomain)
	const billings = useSelector(state => state.auth.user)

	const [submitLoading, setSubmitLoading] = React.useState(false);
	const [getAuditorLoading, setGetAuditorLoading] = React.useState(false);
	const [extraInfo, setExtraInfo] = React.useState(null);

	const [auditorData, setAuditorData] = React.useState(null);
	const [unsubscribeOpen, setUnsubscribeOpen] = React.useState(false);
	const [unsubscribeLoading, setUnsubscribeLoading] = React.useState(false);



	const [details, setDetails] = React.useState({
		couponCode: "",
		// email: '',
		// phone: '',
		// name: "",
		billing: {
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			country: '',
			email: '',
			postcode: '',
			phone: '',
			company: ''
		},

	})
	function handleChangeBilling(e) {
		setDetails({
			...details,
			billing: {
				...details.billing,
				[e.target.name]: e.target.value
			}
		})
	}

	function handleBillingDetails(e) {
		e.preventDefault();
		setSubmitLoading(true);
		dispatch(updateBilling({ details: details.billing })).then(data => {
			setSubmitLoading(false)
		}).catch(err => {
			setSubmitLoading(false)
		})
	}


	function handleChangeOrderDetails(e) {
		setDetails({
			...details,
			[e.target.name]: e.target.value
		})
	}

	function handleSubmit(e) {
		e.preventDefault();
		setSubmitLoading(true);
		dispatch(userAuditorOrderAdd({ websiteId: selectedSubdomain.id, details: { ...details, resolutions: selectedResolutions, extraInfo: extraInfo } })).then(data => {
			setSubmitLoading(false)
		}).catch(err => {
			setSubmitLoading(false)
		})
	}

	React.useEffect(() => {
		if (orderDetails) {
			setAuditorData(orderDetails)
		}
	}, [orderDetails])

	// function getAuditorBilling(id) {
	// 	setGetAuditorLoading(true);
	// 	dispatch(getAuditorOrderDetails(id)).then(data => {
	// 		setGetAuditorLoading(false)
	// 		setAuditorData(data.result)
	// 	}).catch(err => {
	// 		setGetAuditorLoading(false)
	// 	})
	// }

	// React.useEffect(() => {
	// 	if (selectedSubdomain.auditor) {
	// 		getAuditorBilling(selectedSubdomain.id)
	// 	} else {
	// 		setGetAuditorLoading(null)
	// 	}
	// }, [selectedSubdomain])
	const [selectedResolutions, setSelectedResolutions] = useState([]);


	React.useEffect(() => {
		if (billings) {
			setDetails({ ...details, billing: { ...billings.organization.billingDetails.details } })
		}
	}, [billings])



	function openUnsubscribeDialog() {
		setUnsubscribeOpen(true);
	}

	function closeUnsubscribenDialog() {
		setUnsubscribeOpen(false);
	}

	function handleUnsubscribe() {
		setUnsubscribeLoading(true);
		dispatch(
			unsubscribeAuditor({ websiteId: selectedSubdomain.id })
		).then((data) => {
			if (data) {
				setUnsubscribeLoading(false)
				closeUnsubscribenDialog()
			}
		}).catch((err) => {
			setUnsubscribeLoading(false)
		});
	}


	return (

		<Card sx={{ mt: 3, p: 2 }}>
			{selectedSubdomain && !selectedSubdomain.auditor ?
				<form autoComplete="off" onSubmit={handleSubmit}>
					{/* <MDTypography marginBottom variant='h5'>Order Details</MDTypography>
					<Grid container spacing={0} style={{ margin: '20px 0px' }}>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='name' value={details.name} onChange={handleChangeOrderDetails} required={true} label='Name' type='text' />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='email' value={details.email} onChange={handleChangeOrderDetails} required={true} label='Email' type='text' />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='phone' onChange={handleChangeOrderDetails} required={true} label='Phone' type='text' value={details.phone} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='couponCode' onChange={handleChangeOrderDetails} label='Coupon Code' type='text' value={details.couponCode} />
						</Grid>
					</Grid> */}

					<MDTypography marginBottom variant='h5'>Billing Details</MDTypography>

					<Grid container spacing={0} style={{ margin: '20px 0px' }}>

						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='firstName' value={details.billing.firstName} onChange={handleChangeBilling} required={true} label='First Name' type='text' />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='lastName' value={details.billing.lastName} onChange={handleChangeBilling} required={true} label='Last Name' type='text' />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='address1' onChange={handleChangeBilling} required={true} label='Address' type='text' value={details.billing.address1} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='address2' onChange={handleChangeBilling} label='Address 2' type='text' value={details.billing.address2} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='city' onChange={handleChangeBilling} required={true} label='City' type='text' value={details.billing.city} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='state' onChange={handleChangeBilling} required={true} label='State' type='text' value={details.billing.state} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='country' onChange={handleChangeBilling} required={true} label='Country' type='text' value={details.billing.country} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='postcode' onChange={handleChangeBilling} required={true} label='Zipcode' type='text' value={details.billing.postcode} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='email' onChange={handleChangeBilling} required={true} label='Email' type='email' value={details.billing.email} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='phone' onChange={handleChangeBilling} required={true} label='Phone' type='text' value={details.billing.phone} />
						</Grid>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='company' onChange={handleChangeBilling} required={true} label='Company' type='text' value={details.billing.company} />
						</Grid>
					</Grid>
					{/* <MDBox display='flex' justifyContent='center' alignItems='center'>
						<MDButton variant="contained" color="info" onClick={handleBillingDetails} >
							Save Billing Details
						</MDButton>
					</MDBox> */}
					<ResolutionComponent selectedResolutions={selectedResolutions} setSelectedResolutions={setSelectedResolutions} />

					<MDTypography marginBottom={true} variant='h5'>Coupon Code</MDTypography>
					<Grid container spacing={0} style={{ margin: '20px 0px' }}>
						<Grid item xs={12} md={4} sx={{ p: 1 }} >
							<MDInput variant='outlined' fullWidth name='couponCode' onChange={handleChangeOrderDetails} label='Coupon Code' type='text' value={details.couponCode} />
						</Grid>
					</Grid>
					<MDTypography marginBottom={true} variant='h5'>Enter Any Extra Info</MDTypography>
					<MDInput
					rows={5}
					multiline label='Enter Extra Info' onChange={(e) => setExtraInfo(e.target.value)} sx={{ mx: 'auto', maxWidth: 300, width: '100%' }} />
					<MDBox display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
						<MDTypography variant='subtitle2' sx={{ fontWeight: 'bold' }} marginBottom={true}>*You will not be billed.</MDTypography>
						<MDButton variant="contained" color="info" type='submit' loading={submitLoading}  >
							Submit Order
						</MDButton>
					</MDBox>
				</form> :
				<>
					{getAuditorLoading ?
						<MDBox display='flex' justifyContent='center' alignItems='center' height='200px'>
							<MDProgress circular size={36} />
						</MDBox> :
						<MDBox>
							{auditorData?.status === 'pending-payment' ?
								<></> :
								auditorData?.status === 'completed' ?

									<>
										<MDTypography sx={{ textAlign: 'center', mb: 4, mt: 10 }} variant='h4'>Your Subscribed Package</MDTypography>
										<Grid container spacing={2} justifyContent='center'>

											<Grid item key={orderDetails?.package} xs={12} sm={6} md={4} >
												<Card
													// sx={{ p: 2 }}
													variant={'elevation'}
													sx={{
														cursor: 'pointer',
														px: 2,
														py: 3,
														pt: 4,
														overflow: 'hidden',
														boxShadow:

															'0px 4px 8px rgba(0, 0, 0, 0.2)'

													}}
												> {orderDetails?.package?.pkgForYou && <MDTypography
													variant="body2"
													color="textSecondary"

													sx={{ top: 0, left: 0, position: 'absolute', background: '#e91e63', width: '100%', textAlign: 'center', fontWeight: 'bold', color: 'white !important' }}
												>
													Recommended for you
												</MDTypography>}
													<CardContent sx={{ p: 0, pb: '0 !important', textAlign: 'center' }}>

														<MDTypography variant="h5" sx={{ textTransform: 'capitalize', mb: 0.5 }} >{orderDetails?.package?.name}</MDTypography>
														{/* <MDTypography variant="body2" marginBottom>{orderDetails?.package?.description}</MDTypography> */}

														<MDTypography variant="body2" fontWeight='regular'>
															Total Price: ${orderDetails?.package?.total}
														</MDTypography>
														<MDTypography variant="body2" fontWeight='regular'>
															Total Discount: ${orderDetails?.package?.totalDiscounted}
														</MDTypography>
														{/* <MDTypography variant="body2" fontWeight='regular'>
															Number of Views: {orderDetails?.package?.numberOfViews}
														</MDTypography> */}
														{/* <MDTypography variant="body2" fontWeight='regular'>
															Number of Characters: {orderDetails?.package?.numberOfCharacters}
														</MDTypography> */}
														<MDTypography variant="body2" fontWeight='regular'>
															Recurring Interval: {orderDetails?.package?.recurring?.intervalCount}{' '}
															{orderDetails?.package?.recurring?.interval}
														</MDTypography>

														<MDBox display='flex' justifyContent='center' alignItems='center' mt={2}>
															<MDButton variant='contained' color='error' onClick={() => openUnsubscribeDialog()}>Cancel Subscription</MDButton>
														</MDBox>
													</CardContent>
												</Card>
											</Grid>
										</Grid>
									</> :
									<Alert severity="warning">Your Order is being reviewed</Alert>
								// <MDBox display='flex' justifyContent='center' height='60hv' alignItems='center'>
								// 	<MDTypography variant='h5'>Your Order is being reviewed</MDTypography>
								// </MDBox>
							}
						</MDBox>}
				</>
			}

			<Dialog open={unsubscribeOpen} onClose={unsubscribeLoading ? null : closeUnsubscribenDialog}>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					<p>Are you sure you want to unsubscribe?</p>
				</DialogContent>
				<DialogActions>
					<MDButton onClick={closeUnsubscribenDialog} disabled={unsubscribeLoading}>Cancel</MDButton>
					<MDButton onClick={handleUnsubscribe} color="primary" loading={unsubscribeLoading}>Confirm</MDButton>
				</DialogActions>
			</Dialog>
		</Card>
		// </DashboardLayout>
	)
}









const ResolutionComponent = ({ selectedResolutions, setSelectedResolutions }) => {
	const resolutions = useSelector(state => state.auth.config?.auditorResolutions) || []
	const handleResolutionChange = (resolution) => {
		const selectedIndex = selectedResolutions.indexOf(resolution);
		let newSelectedResolutions = [...selectedResolutions];

		if (selectedIndex === -1) {
			newSelectedResolutions.push(resolution);
		} else {
			newSelectedResolutions.splice(selectedIndex, 1);
		}

		setSelectedResolutions(newSelectedResolutions);
	};

	return (
		<FormGroup>
			<MDTypography sx={{ mb: 3 }} variant='h5'>Select Resolutions</MDTypography>
			<Grid container spacing={2}>
				{resolutions.map((res, index) => (
					<Grid item xs={12} sm={6} lg={3} key={index}>
						<FormControlLabel
							control={
								<Checkbox
									checked={selectedResolutions.includes(res.resolution)}
									onChange={() => handleResolutionChange(res.resolution)}
								/>
							}
							label={`${res.deviceType} - ${res.resolution}`}
						/>
					</Grid>
				))}
			</Grid>
		</FormGroup>
	);
};
